import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoRequestCompareExcelRepeatValues_Position from "./DtoRequestCompareExcelRepeatValues_Position";
import DtoRequestCompareExcelRepeatValues_Column from "./DtoRequestCompareExcelRepeatValues_Column";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestCompareExcelRepeatValues {
    constructor() {
        //无参构造函数
        this.Columns = [];
        this.MinRate = 0;
    }

    [proName: string]: any;
    /**
     * 
     */
    PositionA?: DtoRequestCompareExcelRepeatValues_Position;
    /**
     * 
     */
    PositionB?: DtoRequestCompareExcelRepeatValues_Position;
    /**
     * 
     */
    Columns?: DtoRequestCompareExcelRepeatValues_Column[];
    /**
     * 
     */
    MinRate?: number;
}

/**
 * -表单校验
 */
export const DtoRequestCompareExcelRepeatValuesRules = {
}

/**
 * -设置项
 */
export const DtoRequestCompareExcelRepeatValuesMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "PositionA",
    Label: "",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "PositionB",
    Label: "",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Columns",
    Label: "",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "MinRate",
    Label: "",
    Type: EnumMiezzFormItemType.InputText,
  },
]

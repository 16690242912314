import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemPriceServiceShareSearch_PriceServiceShareSearchRelation from "./DtoListItemPriceServiceShareSearch_PriceServiceShareSearchRelation";

/**
 * 价格表共享搜索分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemPriceServiceShareSearch extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 关联条件
     */
    Relations?: DtoListItemPriceServiceShareSearch_PriceServiceShareSearchRelation[];
    /**
     * 值
     */
    Value?: string;
}

/**
 * 价格表共享搜索分页列表-项-表单校验
 */
export const DtoListItemPriceServiceShareSearchRules = {
}

/**
 * 价格表共享搜索分页列表-项-设置项
 */
export const DtoListItemPriceServiceShareSearchMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Name",
    Label: "名称",
  },
  {
    Prop: "Relations",
    Label: "关联条件",
  },
  {
    Prop: "Value",
    Label: "值",
  },
]


import CompanyAppService from "@/apis.machine/CompanyAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoListItemPrice from "@/models.machine/DtoListItemPrice";
import DtoPageListSearchPrice from "@/models.machine/DtoPageListSearchPrice";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import { EnumPriceAmountType } from "@/models.machine/EnumPriceAmountType";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import PricePageList from "../PricePageList.vue";
@Options({
  components: {
    PricePageList,
  },
})
export default class PriceCostPageList extends Vue {
  list = new MiezzPageList<DtoPageListSearchPrice, DtoListItemPrice, string>(
    "PriceManagement_Cost"
  );
  amountType = EnumPriceAmountType.Cost;
  companies?: DtoSelectOptionCompany[] = [];
  companyId?: string;
  tableId?: string;

  created(): void {
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "delete-all",
        Label: "删除全部",
        Icon: "delete",
        Type: "primary",
        Message: "是否确认删除",
        Size: "small",
        PowerCode: "PriceManagement_Cost_DeleteAll",
      },
      {
        Code: "download",
        Label: "下载模板",
        Icon: "download",
        Type: "primary",
        Size: "small",
        PowerCode: "PriceManagement_Cost_Import",
      },
      {
        Code: "import",
        Label: "导入",
        Icon: "top",
        Type: "primary",
        Size: "small",
        FileUpload: true,
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        PowerCode: "PriceManagement_Cost_Import",
      },
      {
        Code: "export",
        Label: "导出",
        Icon: "bottom",
        Type: "primary",
        Size: "small",
        PowerCode: "PriceManagement_Cost_Export",
      },
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "PriceManagement_Cost_AddEdit",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "default",
        Size: "small",
        PowerCode: "PriceManagement_Cost_AddEdit",
      },
      {
        Code: "history",
        Title: "历史记录",
        Icon: "Stopwatch",
        Type: "default",
        Size: "small",
        PowerCode: "PriceManagement_Cost_History",
      },
      {
        Code: "delete",
        Title: "删除",
        Icon: "delete",
        Type: "default",
        Message: "是否确认删除",
        Size: "small",
        PowerCode: "PriceManagement_Cost_Delete",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      // {
      //   Code: "mark",
      //   Label: "标记",
      //   Type: "color",
      //   Size: "small",
      //   PowerCode: "PriceManagement_Cost_AddEdit",
      // },
      {
        Code: "amount-update",
        Label: "批量编辑",
        Icon: "edit",
        Type: "text",
        PowerCode: "PriceManagement_Cost_AddEdit",
      },
      {
        Code: "export",
        Label: "导出",
        Icon: "bottom",
        Type: "text",
        PowerCode: "PriceManagement_Cost_Export",
      },
      {
        Code: "batch-delete",
        Label: "删除",
        Icon: "delete",
        Type: "text",
        PowerCode: "PriceManagement_Cost_Delete",
      },
      {
        Code: "add-calc",
        Label: "添加到计算器",
        Icon: "plus",
        Type: "text",
        PowerCode: "PriceManagement_Calc",
      },
    ];

    this.handleRefreshCompaniesOrUsers(this.$route.query.TableId as string);
  }

  handleRefreshCompaniesOrUsers(tableId?: string, refresh?: boolean): void {
    this.tableId = tableId;
    CompanyAppService.GetSelect({
      CompanyType: EnumCompanyType.Supplier,
      AmountType: EnumPriceAmountType.Cost,
      PriceTableId: tableId,
      SearchPriceTableRelation: true,
    }).then((r) => {
      this.companies = r.data.Data ?? [];
      const page = JSON.parse(localStorage.getItem(this.$route.path) ?? "{}");
      let companyId: string | undefined;
      if (this.$route.query.CompanyId) {
        companyId = this.$route.query.CompanyId as string;
      } else {
        if (
          page.companyId &&
          this.companies.any((it) => it.Id == page.companyId)
        ) {
          companyId = page.companyId;
        } else {
          companyId = this.companies.firstOrDefault()?.Id;
        }
      }
      if (this.companies.all((it) => it.Id != companyId)) {
        companyId = this.companies.firstOrDefault()?.Id;
      }
      this.companyId = companyId;
      page.companyId = companyId;

      if (this.list.Dto && (this.list.Dto.CompanyId != companyId || refresh)) {
        this.list.Dto.CompanyId = companyId;
        (this.$refs["ref-price-page-list"] as PricePageList).handleUrlGet();
      }
      localStorage.setItem(this.$route.path, JSON.stringify(page));
    });
  }
}

import { EnumPriceAmountType, EnumPriceAmountTypeOption } from "./EnumPriceAmountType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestPriceSync {
    constructor() {
        //无参构造函数
        this.PriceIds = [];
        this.SyncObjectIds = [];
    }

    [proName: string]: any;
    /**
     * 金额类型
     */
    AmountType?: EnumPriceAmountType;
    /**
     * 价格表Id
     */
    TableId?: string;
    /**
     * 源对象Id
     */
    SourceObjectId?: string;
    /**
     * 选中Id
     */
    PriceIds?: string[];
    /**
     * 同步对象Id
     */
    SyncObjectIds?: string[];
}

/**
 * -表单校验
 */
export const DtoRequestPriceSyncRules = {
}

import { RouteRecordRaw } from "vue-router";
import PriceSetUp from "./SetUp/PriceSetUp.vue"
import PriceTablePageList from "./SetUp/PriceTable/PriceTablePageList.vue"
import PriceTableDetail from "./SetUp/PriceTable/PriceTableDetail.vue"
import PriceServicePageList from "./SetUp/PriceService/PriceServicePageList.vue"
import PriceServiceDetail from "./SetUp/PriceService/Detail/PriceServiceDetail.vue"
import PriceServiceDetailRemark from "./SetUp/PriceService/Detail/Remark/PriceServiceDetailRemark.vue"
import PriceServiceDetailRemarkEdit from "./SetUp/PriceService/Detail/Remark/PriceServiceDetailRemarkEdit.vue"
import PriceServiceDetailCostComposition from "./SetUp/PriceService/Detail/CostComposition/PriceServiceDetailCostComposition.vue"
import PriceServiceDetailCostCompositionSurcharge from "./SetUp/PriceService/Detail/CostComposition/PriceServiceDetailCostCompositionSurcharge.vue"
import PriceServiceDetailCostCompositionSurchargeNotAdded from "./SetUp/PriceService/Detail/CostComposition/PriceServiceDetailCostCompositionSurchargeNotAdded.vue"
import PriceServiceDetailCostCompositionSurchargeAdded from "./SetUp/PriceService/Detail/CostComposition/PriceServiceDetailCostCompositionSurchargeAdded.vue"
import PriceCostPageList from "./Cost/PriceCostPageList.vue"
import PriceSettlementPageList from "./Settlement/PriceSettlementPageList.vue"
import PriceGuidePageList from "./Guide/PriceGuidePageList.vue"
import PriceAgreementPageList from "./Agreement/PriceAgreementPageList.vue"
import PriceQuotationSheetPageList from "./QuotationSheet/PriceQuotationSheetPageList.vue"
import PriceQuotationSheetForm from "./QuotationSheet/PriceQuotationSheetForm.vue"
import PriceQuotationSheetDetail from "./QuotationSheet/PriceQuotationSheetDetail.vue"
import PriceInquiryPageList from "./Inquiry/PriceInquiryPageList.vue"
import PriceInquiryDetail from "./Inquiry/PriceInquiryDetail.vue"
import PriceSearch from "./Search/PriceSearch.vue"
import PriceSearchRemark from "./Search/Remark/PriceSearchRemark.vue"
import PriceSearchDetail from "./Search/Detail/PriceSearchDetail.vue"
import PriceSearchDetailSurcharge from "./Search/Detail/PriceSearchDetailSurcharge.vue"

const routes: Array<RouteRecordRaw> = [
    {
        path: '/price/cost',
        name: 'PriceManagement_Cost',
        component: PriceCostPageList,
        meta: {
            title: "价格 - 成本管理"
        }
    },
    {
        path: '/price/settlement',
        name: 'PriceManagement_Settlement',
        component: PriceSettlementPageList,
        meta: {
            title: "价格 - 结算价管理"
        }
    },
    {
        path: '/price/guide',
        name: 'PriceManagement_Guide',
        component: PriceGuidePageList,
        meta: {
            title: "价格 - 指导价管理"
        }
    },
    {
        path: '/price/agreement',
        name: 'PriceManagement_Agreement',
        component: PriceAgreementPageList,
        meta: {
            title: "价格 - 协议价管理"
        }
    },
    {
        path: '/price/quotation-sheet',
        name: 'PriceManagement_QuotationSheet',
        component: PriceQuotationSheetPageList,
        meta: {
            title: "价格 - 报价表管理"
        }
    },
    {
        path: '/price/quotation-sheet/:id',
        name: 'PriceManagement_QuotationSheet_Detail',
        component: PriceQuotationSheetDetail,
        meta: {
            title: "价格 - 报价表管理 - 详情"
        }
    },
    {
        path: '/price/quotation-sheet/add',
        name: 'PriceManagement_QuotationSheet_Add',
        component: PriceQuotationSheetForm,
        meta: {
            title: "价格 - 报价表管理 - 新增"
        }
    },
    {
        path: '/price/quotation-sheet/edit/:id',
        name: 'PriceManagement_QuotationSheet_Edit',
        component: PriceQuotationSheetForm,
        meta: {
            title: "价格 - 报价表管理 - 编辑"
        }
    },
    {
        path: '/price/inquiry',
        name: 'PriceManagement_Inquiry',
        component: PriceInquiryPageList,
        meta: {
            title: "价格 - 我的询盘"
        }
    },
    {
        path: '/price/inquiry/:id',
        name: 'PriceManagement_Inquiry_Detail',
        component: PriceInquiryDetail,
        meta: {
            title: "价格 - 我的询盘 - 详细"
        }
    },
    {
        path: '/price/setup',
        name: 'PriceManagement_SetUp',
        component: PriceSetUp,
        meta: {
            title: "价格 - 设置"
        },
    },
    {
        path: '/price/setup/table',
        name: 'PriceManagement_Table',
        component: PriceTablePageList,
        meta: {
            title: "价格 - 设置 - 基础价格表"
        },
    },
    {
        path: '/price/setup/table/detail',
        name: 'PriceManagement_Table_Detail',
        component: PriceTableDetail,
        meta: {
            title: "价格 - 设置 - 基础价格表 - 详细"
        }
    },
    {
        path: '/price/setup/service',
        name: 'PriceManagement_Service',
        component: PriceServicePageList,
        meta: {
            title: "价格 - 设置 - 服务"
        },
    },
    {
        path: '/price/setup/service/detail/:id',
        name: 'PriceManagement_Service_Detail',
        component: PriceServiceDetail,
        meta: {
            title: "价格 - 设置 - 服务 - 详细"
        },
    },
    {
        path: '/price/setup/service/detail/:id/remark',
        name: 'PriceManagement_Service_Detail_Remark',
        component: PriceServiceDetailRemark,
        meta: {
            title: "价格 - 设置 - 服务 - 详细 - 摘要与注意事项"
        },
    },
    {
        path: '/price/setup/service/detail/:id/remark/edit',
        name: 'PriceManagement_Service_Detail_Remark_Edit',
        component: PriceServiceDetailRemarkEdit,
        meta: {
            title: "价格 - 设置 - 服务 - 详细 - 摘要与注意事项 - 编辑"
        },
    },
    {
        path: '/price/setup/service/detail/:id/cost-composition',
        name: 'PriceManagement_Service_Detail_CostComposition',
        component: PriceServiceDetailCostComposition,
        meta: {
            title: "价格 - 设置 - 服务 - 详细 - 费用组成"
        },
    },
    {
        path: '/price/setup/service/detail/:id/cost-composition/surcharge',
        name: 'PriceManagement_Service_Detail_CostComposition_AddSurcharge',
        component: PriceServiceDetailCostCompositionSurcharge,
        meta: {
            title: "价格 - 设置 - 服务 - 详细 - 费用组成 - 附加费"
        },
    },
    {
        path: '/price/setup/service/detail/:id/cost-composition/surcharge/added',
        name: 'PriceManagement_Service_Detail_CostComposition_Surcharge_Added',
        component: PriceServiceDetailCostCompositionSurchargeAdded,
        meta: {
            title: "价格 - 设置 - 服务 - 详细 - 费用组成 - 附加费 - 已添加"
        },
    },
    {
        path: '/price/setup/service/detail/:id/cost-composition/surcharge/not-added',
        name: 'PriceManagement_Service_Detail_CostComposition_Surcharge_NotAdded',
        component: PriceServiceDetailCostCompositionSurchargeNotAdded,
        meta: {
            title: "价格 - 设置 - 服务 - 详细 - 费用组成 - 附加费 - 未添加"
        },
    },
    
    {
        path: '/price/search',
        name: 'PriceManagement_Search',
        component: PriceSearch,
        meta: {
            title: "价格 - 运价查询"
        },
    },
    {
        path: '/price/search/remark/:id',
        name: 'PriceManagement_Search_Remark',
        component: PriceSearchRemark,
        meta: {
            title: "价格 - 运价查询 - 摘要与注意事项"
        },
    },
    {
        path: '/price/search/detail/:id',
        name: 'PriceManagement_Search_Detail',
        component: PriceSearchDetail,
        meta: {
            title: "价格 - 运价查询 - 详细"
        },
    },
    {
        path: '/price/search/detail/:id/surcharge',
        name: 'PriceManagement_Search_Detail_Surcharge',
        component: PriceSearchDetailSurcharge,
        meta: {
            title: "价格 - 运价查询 - 详细 - 附加费"
        },
    },
]

export default routes;
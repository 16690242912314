
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import router from "@/router";
import MiezzPageList, { MiezzPageListType } from "@/models/MiezzPageList";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import MiezzButton from "@/models/MiezzButton";
import { ElMessage, ElMessageBox, ElTable } from "element-plus";
import CurrentLogier from "@/apis/CurrentLogier";
import moment from "moment";
import DynamicSearchBracket from "@/models.machine/DynamicSearchBracket";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";
import vuedraggable from "vuedraggable";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import DynamicSearchBracketItem from "@/models.machine/DynamicSearchBracketItem";
import elementResize from "element-resize-detector";

@Options({
  components: {
    vuedraggable,
  },
})
export default class MiezzPageListComponent extends Vue {
  @Prop() leftHeaderWidth?: string;
  @Prop() isDefaultTip?: boolean = true;
  @Prop() className?: string;
  /**设置 */
  @Model() modelValue = new MiezzPageList();

  hideProps: string[] = [];
  cells?: MiezzListItem[] = [];
  isSearchClick = false;
  /**选中项 */
  rows: any[] = [];
  listRef?: InstanceType<typeof ElTable>;
  MiezzPageListType = MiezzPageListType;
  back: MiezzButton = {
    Code: "back",
    Label: "返回",
    MiezzIcon: "back",
    Type: "default",
    Size: "small",
  };
  openSearch = false;
  create = true;

  /**创建时 */
  created(): void {
    if (
      this.modelValue.DefaultSelectIds == null ||
      this.modelValue.DefaultSelectIds.length == 0
    ) {
      this.modelValue.SelectRows = [];
      this.modelValue.SelectIds = [];
    }

    CurrentLogier.GetMenus().then((menus) => {
      this.hideProps = menus?.HideMenuFields ?? [];
      this.onItemsChanged();
    });

    if (this.modelValue.Dto && !this.modelValue.DisabledRouter) {
      const query = this.$route.query;
      for (const key in query) {
        this.modelValue.Dto[key] = query[key];
      }

      if (!this.modelValue.Dto.CurrentPage || !this.modelValue.Dto.PageSize) {
        this.modelValue.Dto.CurrentPage = 1;
        const json = localStorage.getItem(this.$route.path);
        if (json) {
          const page = JSON.parse(json);
          this.modelValue.Dto.PageSize = page.pageSize;
        } else {
          this.modelValue.Dto.PageSize = 10;
        }
      }

      this.openSearch = this.modelValue.Dto.OpenSearch == "true";
    } else {
      var openSearch = this.$route.query.OpenSearch as string;
      if (openSearch == "true") {
        this.openSearch = true;
      } else {
        this.openSearch = false;
      }
    }

    if (!this.modelValue.DisabledCreatedHandleGet) this.handleGet();
    this.handleGetHistories();
    this.onBottomButtonsChanged();
  }

  tableHeight = "100%";
  mounted(): void {
    this.listRef = this.$refs["listRef"] as InstanceType<typeof ElTable>;

    this.onDefaultSelectIdsChanged();

    var erd = elementResize();
    erd.listenTo(document.getElementById("search") as HTMLElement, (e: any) => {
      this.$nextTick(function () {
        this.tableHeight = `calc(100% - ${e.offsetHeight}px)`;
      });
    });

    window.onkeydown = (e: any) => {
      if (e.key == "ArrowLeft" || e.key == "ArrowRight") {
        const wrapper = this.listRef?.scrollBarRef.wrapRef;
        if (wrapper) {
          let left = wrapper.scrollLeft;
          if (e.key == "ArrowLeft") {
            left = left - 10;
            if (left < 0) left = 0;
            this.listRef?.setScrollLeft(left);
          } else if (e.key == "ArrowRight") {
            const maxLeft = wrapper.scrollWidth - wrapper.offsetWidth;
            left = left + 10;
            if (left > maxLeft) left = maxLeft;
            this.listRef?.setScrollLeft(left);
          }
        }
      }
    };
  }

  onChild(row: any): void {
    (this.$refs["listRef"] as any).toggleRowExpansion(row);
  }

  @Watch("modelValue.DynamicSearchCode")
  handleGetHistories(): void {
    if (this.modelValue.EnabledDynamicSearch) {
      let code = this.modelValue.DynamicSearchCode;
      if (!code && !this.modelValue.DynamicSearchHistoryOnlyByCode) {
        code = this.modelValue.PowerCode;
      }
      if (code) {
        DynamicSearchAppService.Get({
          Code: code,
        }).then((r) => {
          this.modelValue.DynamicSearchHistory = r.data.Data;
          this.modelValue.RadioSearchDynamicItems =
            r.data.Data?.filter((it) => it.Data.ShowTab) ?? [];
        });
      }
    }
  }

  hnadleDrop(e: any): void {
    if (
      e.oldIndex != e.newIndex &&
      this.modelValue.Data?.Items &&
      this.modelValue.Data.Items.length > 1
    ) {
      const draggingIndex = e.newIndex;
      let dropIndex;
      let type: EnumUiDragType;
      if (draggingIndex == 0) {
        type = EnumUiDragType.Before;
        dropIndex = 1;
      } else {
        type = EnumUiDragType.After;
        dropIndex = draggingIndex - 1;
      }
      const dragging = this.modelValue.Data.Items[draggingIndex];
      const drop = this.modelValue.Data.Items[dropIndex];
      this.handleDragSort(dragging.Id, drop.Id, type);
    }
  }

  handleCheck(check: boolean, row: any, e?: any): void {
    if (!e || e.target.nodeName == "DIV") {
      row.Check = check;
      if (this.modelValue.EnabledSingleSelect) {
        if (check) {
          for (const selectRow of this.modelValue.SelectRows) {
            if (selectRow != row) {
              selectRow.Check = false;
            }
          }
        }
        this.modelValue.SelectRows = [row];
      } else {
        const index = this.modelValue.SelectRows.findIndex(
          (it) => it.Id == row.Id
        );
        if (check && index == -1) {
          this.modelValue.SelectRows.push(row);
        } else if (!check && index > -1) {
          this.modelValue.SelectRows.splice(index, 1);
        }
      }
      this.modelValue.SelectIds = this.modelValue.SelectRows.map(
        (it) => it.Id as string
      );
    }
  }

  @Emit()
  handleDragSort(draggingId?: any, dropId?: any, type?: EnumUiDragType): void {
    //
  }

  @Watch("modelValue.Data")
  onDataChanged(): void {
    if (this.modelValue.Type == MiezzPageListType.Card) {
      this.modelValue.SelectRows =
        this.modelValue.Data?.Items?.filter((it) =>
          this.modelValue.SelectIds.contains(it.Id)
        ) ?? [];
      this.modelValue.SelectIds = this.modelValue.SelectRows.map(
        (it) => it.Id as any
      );
    }
    if (
      this.modelValue.DragSort != null &&
      this.modelValue.DragSort != undefined
    ) {
      this.modelValue.DragSort(
        ".el-table__body-wrapper tbody",
        (items, draggingId, dropId, type) => {
          this.$nextTick(() => {
            if (this.modelValue.Data) this.modelValue.Data.Items = items;
            this.handleDragSort(draggingId, dropId, type);
          });
        }
      );
    }
  }

  @Watch("modelValue.Items")
  onItemsChanged(): void {
    if (this.hideProps)
      this.cells = this.modelValue.Items?.filter(
        (it) =>
          it.Prop &&
          !this.hideProps.contains(`${this.modelValue.PowerCode}|${it.Prop}`)
      );
    else this.cells = this.modelValue.Items;
  }

  @Watch("modelValue.BottomButtons")
  onBottomButtonsChanged(): void {
    for (const button of this.modelValue.BottomButtons ?? []) {
      if (button.Message) {
        button.MessageBox = true;
        button.DisabledConfirm = true;
      }
    }
  }

  @Emit()
  handleGet(): void {
    if (!this.modelValue.DisabledRouter) {
      if (this.modelValue.Dto) {
        var RadioSearch = this.$route.query.RadioSearch as string;
        /**路由地址追加分页参数 */
        for (const key in this.modelValue.Dto) {
          const value = this.modelValue.Dto[key]?.toString();
          this.$route.query[key] = value;
        }
        if (
          this.modelValue.IsRadioSearch == true &&
          this.isSearchClick == true
        ) {
          this.isSearchClick = false;
          this.$route.query["RadioSearch"] = RadioSearch;
        }
        router.push({
          path: this.$route.path,
          params: this.$route.params,
          query: this.$route.query,
          force: true,
        });
      }
    }
    this.openSearch = this.modelValue.Dto?.OpenSearch == "true";
  }

  @Watch("modelValue.DefaultSelectIds")
  onDefaultSelectIdsChanged(): void {
    if (
      this.modelValue &&
      this.modelValue.Data &&
      this.modelValue.Data.Items &&
      this.modelValue.DefaultSelectIds &&
      this.modelValue.DefaultSelectIds.length > 0
    ) {
      for (const item of this.modelValue.Data.Items) {
        if (this.modelValue.DefaultSelectIds.contains(item.Id)) {
          this.$nextTick(() => {
            this.listRef?.toggleRowSelection(item, true);
          });
          console.log("选中：", this.modelValue.Data.Items, item);
        }
      }
    } else {
      this.listRef?.clearSelection();
    }
  }

  HandleRowClass(row: { row: any; index: number }): string | undefined {
    let name: string | undefined;
    if (this.modelValue.HandleRowClass) {
      name = this.modelValue.HandleRowClass(row.row, row.index);
    }
    return name ?? "";
  }

  handleSelectable(row: any, index: number): boolean {
    return this.modelValue.HandleSelectable
      ? this.modelValue.HandleSelectable(row, index)
      : true;
  }

  formart(model: MiezzListItem, row: any): string | undefined {
    if (model.Prop) {
      if (this.modelValue.HandleFormat) {
        const value = this.modelValue.HandleFormat(model, row);
        if (value) return value;
      }
      const v = row[model.Prop];
      if (v === null || v === undefined || v === "") return "暂无数据";
      if (model.Pipe == EnumPipe.Boolean)
        return v ? model.WhenTrue : model.WhenFalse;
      else if (model.Pipe == EnumPipe.Enum)
        return (
          model.Options?.firstOrDefault((it) => it.Value == v)?.Label ??
          "暂无数据"
        );
      else if (model.Pipe == EnumPipe.Date) {
        return moment(v).format("YYYY/MM/DD");
      } else if (model.Pipe == EnumPipe.Time) {
        return moment(v).format("YYYY/MM/DD HH:mm");
      } else if (model.Prop.endsWith("ActiveSecond")) {
        const second: number = v;
        if (second == 0) {
          return "暂无数据";
        } else if (second < 60) {
          return `${second}秒`;
        } else if (second < 60 * 60) {
          return `${(second / 60).toRound(0)}分钟`;
        } else {
          return `${(second / 60 / 60).toRound(0)}小时`;
        }
      }
      if (Array.isArray(v) && v.length == 0) return "暂无数据";
      return v;
    }
    return "暂无数据";
  }

  handleFormat(model: MiezzListItem, row: any): string | undefined {
    let value = this.formart(model, row)?.toString();
    // zbh2023.7.20 列表要显示暂无数据
    // if (value) {
    //   value = value.replace(/暂无数据$/g, "").replace(/[/]+$/g, "");
    // }
    return value;
  }

  checkRowButtonShow(btn: MiezzButton, row: any, index: number): boolean {
    return this.modelValue.CheckRowButtonShow
      ? this.modelValue.CheckRowButtonShow(btn, row, index)
      : true;
  }

  handleRadioClick(): void {
    this.openSearch = !this.openSearch;
  }

  /**事件-单选搜索 */
  @Emit()
  handleRadioSearch(): void {
    this.openSearch = this.modelValue.Dto?.OpenSearch == "true";
    if (
      this.modelValue.IsRadioSearch == true &&
      this.modelValue.Dto &&
      this.modelValue.Dto.OpenSearch == "true"
    ) {
      this.isSearchClick = true;
    }
    if (!this.modelValue.DisabledRadioSearchRefresh) {
      this.handleGet();
    }
  }

  /**事件-动态搜索 */
  @Emit()
  handleDynamicSearch(value?: string): void {
    if (this.modelValue.Dto) {
      this.modelValue.Dto.DynamicSearchId = value;
      if (!this.modelValue.Dto.DynamicSearchId) {
        this.modelValue.Dto.DynamicSearchId = "";
        this.modelValue.Dto.OpenSearch = "false";
      } else {
        this.modelValue.Dto.OpenSearch = "true";
      }
    }
    if (this.modelValue.IsRadioSearch == true) {
      this.isSearchClick = true;
    }
    this.handleGet();
  }

  /**改变条件 */
  @Emit()
  handleChangeWhere(
    bracket: DynamicSearchBracket,
    item: DynamicSearchBracketItem,
    i: number
  ): void {
    //
  }

  @Emit()
  handleBracketsBuild(brackets?: DynamicSearchBracket[]): void {
    //
  }

  /**顶部按钮点击 */
  @Emit()
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    if (btn.Code == "back") {
      CurrentLogier.Back();
      complete();
    }
  }

  @Emit()
  handleTopButtonUpload(model: MiezzButton, file: any): void {
    //
  }

  /**底部按钮点击 */
  handleBottomButtonBeforeClick(btn: MiezzButton, complete: () => void): void {
    if (
      btn.NoNeedBatchSelect ||
      (this.modelValue.SelectRows && this.modelValue.SelectRows.length > 0)
    ) {
      if (btn.Message) {
        ElMessageBox.confirm(btn.Message, "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.handleBottomButtonClick(btn, complete);
          })
          .catch(() => {
            complete();
          });
      } else {
        this.handleBottomButtonClick(btn, complete);
      }
    } else if (btn.IsNumber != true) {
      ElMessage.warning("请至少选择一条信息");
      complete();
    } else {
      this.handleBottomButtonClick(btn, complete);
    }
  }
  /**单击整行事件 */
  @Emit()
  handleRowClick(row: any, column: any): void {
    //
  }

  @Emit()
  handleRowDbClick(row: any): void {
    //
  }

  /**底部按钮点击 */
  @Emit()
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    //
  }

  /**行按钮点击 */
  @Emit()
  handleRowButtonClick(
    btn: MiezzButton,
    row: any,
    complete: () => void,
    index: number
  ): void {
    //
  }

  /**事件-选中行之前 */
  @Emit()
  handleSelectOne(rows: any[], row: any): void {
    //
  }

  @Emit()
  handleCurrentChange(row: any): void {
    if (this.modelValue.EnabledSingleSelect && row) {
      this.modelValue.SelectRows = [row];
      this.modelValue.SelectIds = [row.Id];
    }
  }

  /**事件-选中行之前 */
  @Emit()
  handleSelect(rows: any[]): void {
    this.modelValue.SelectRows = rows;
    this.modelValue.SelectIds = rows.map((it) => it.Id) as string[];
  }

  /**事件-分页参数变更时 */
  hanlePageChange(currentPage: number, pageSize: number): void {
    if (this.modelValue.Dto) {
      this.modelValue.Dto.CurrentPage = currentPage;
      this.modelValue.Dto.PageSize = pageSize;

      this.handleGet();
    }
  }
}

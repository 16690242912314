import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3863283b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  style: {"display":"block","text-align":"center"}
}
const _hoisted_2 = { key: 2 }
const _hoisted_3 = {
  key: 3,
  style: {"display":"block","text-align":"center"}
}
const _hoisted_4 = {
  key: 5,
  style: {"display":"block","text-align":"center"}
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_miezz_text_ellipsis = _resolveComponent("miezz-text-ellipsis")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!
  const _component_express_tracking = _resolveComponent("express-tracking")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_page_list, {
      ref: "ref-miezz-page-list",
      class: "list",
      modelValue: _ctx.list,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.list) = $event)),
      onHandleGet: _ctx.handleGet,
      onHandleBottomButtonClick: _ctx.handleBottomButtonClick
    }, {
      "dynamic-search-customize": _withCtx(({ model }) => [
        (model.Where.Prop == 'DynamicSearch_LoadingPortId')
          ? (_openBlock(), _createBlock(_component_miezz_select, {
              key: 0,
              modelValue: model.Where.Value,
              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
              options: _ctx.loadingPorts,
              clearable: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
          : (model.Where.Prop == 'DynamicSearch_DeliveryPortId')
            ? (_openBlock(), _createBlock(_component_miezz_select, {
                key: 1,
                modelValue: model.Where.Value,
                "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                options: _ctx.deliveryPorts,
                clearable: ""
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
            : (model.Where.Prop == 'DynamicSearch_DeliveryPlaceLabel')
              ? (_openBlock(), _createBlock(_component_miezz_select, {
                  key: 2,
                  modelValue: model.Where.Value,
                  "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                  options: _ctx.deliveryPlaces,
                  clearable: ""
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
              : (model.Where.Prop == 'DynamicSearch_ShipName')
                ? (_openBlock(), _createBlock(_component_miezz_select, {
                    key: 3,
                    modelValue: model.Where.Value,
                    "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                    options: _ctx.shipNames,
                    clearable: ""
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                : (model.Where.Prop == 'DynamicSearch_VoyageNumber')
                  ? (_openBlock(), _createBlock(_component_miezz_select, {
                      key: 4,
                      modelValue: model.Where.Value,
                      "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                      options: _ctx.voyageNumbers,
                      clearable: ""
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                  : (model.Where.Prop == 'DynamicSearch_Flight')
                    ? (_openBlock(), _createBlock(_component_miezz_select, {
                        key: 5,
                        modelValue: model.Where.Value,
                        "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                        options: _ctx.flights,
                        clearable: ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                    : (model.Where.Prop == 'DynamicSearch_RailwayNumber')
                      ? (_openBlock(), _createBlock(_component_miezz_select, {
                          key: 6,
                          modelValue: model.Where.Value,
                          "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                          options: _ctx.railwayNumbers,
                          clearable: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                      : (model.Where.Prop == 'DynamicSearch_ContainerNo')
                        ? (_openBlock(), _createBlock(_component_miezz_select, {
                            key: 7,
                            modelValue: model.Where.Value,
                            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                            options: _ctx.containerNos,
                            clearable: ""
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                        : (model.Where.Prop == 'DynamicSearch_ClientUserId')
                          ? (_openBlock(), _createBlock(_component_miezz_select, {
                              key: 8,
                              modelValue: model.Where.Value,
                              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                              options: _ctx.searchClientUsers,
                              clearable: ""
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                          : (model.Where.Prop == 'DynamicSearch_ServiceRemark')
                            ? (_openBlock(), _createBlock(_component_miezz_select, {
                                key: 9,
                                modelValue: model.Where.Value,
                                "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                options: _ctx.serviceRemarks,
                                clearable: ""
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                            : (model.Where.Prop == 'DynamicSearch_Status')
                              ? (_openBlock(), _createBlock(_component_miezz_select, {
                                  key: 10,
                                  modelValue: model.Where.Value,
                                  "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                  options: _ctx.EnumOrderPoolStatusOption,
                                  number: true,
                                  clearable: ""
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                              : (model.Where.Prop == 'DynamicSearch_OrderStatus')
                                ? (_openBlock(), _createBlock(_component_miezz_select, {
                                    key: 11,
                                    modelValue: model.Where.Value,
                                    "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                    options: _ctx.EnumOrderStatusOption,
                                    number: true,
                                    clearable: ""
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                : (model.Where.Prop == 'DynamicSearch_BookingStatus')
                                  ? (_openBlock(), _createBlock(_component_miezz_select, {
                                      key: 12,
                                      modelValue: model.Where.Value,
                                      "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                      options: _ctx.EnumBookingStatusOption,
                                      number: true,
                                      clearable: ""
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                  : (
          model.Where.Prop == 'DynamicSearch_DeparturePortCustomsStatus'
        )
                                    ? (_openBlock(), _createBlock(_component_miezz_select, {
                                        key: 13,
                                        modelValue: model.Where.Value,
                                        "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                        options: _ctx.EnumCustomsStatusOption,
                                        number: true,
                                        clearable: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                    : (
          model.Where.Prop == 'DynamicSearch_DestinationPortCustomsStatus'
        )
                                      ? (_openBlock(), _createBlock(_component_miezz_select, {
                                          key: 14,
                                          modelValue: model.Where.Value,
                                          "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                          options: _ctx.EnumCustomsStatusOption,
                                          number: true,
                                          clearable: ""
                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                      : (model.Where.Prop == 'DynamicSearch_DeparturePortTrunkStatus')
                                        ? (_openBlock(), _createBlock(_component_miezz_select, {
                                            key: 15,
                                            modelValue: model.Where.Value,
                                            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                            options: _ctx.EnumTrunkStatusOption,
                                            number: true,
                                            clearable: ""
                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                        : (
          model.Where.Prop == 'DynamicSearch_DestinationPortTrunkStatus'
        )
                                          ? (_openBlock(), _createBlock(_component_miezz_select, {
                                              key: 16,
                                              modelValue: model.Where.Value,
                                              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                              options: _ctx.EnumTrunkStatusOption,
                                              number: true,
                                              clearable: ""
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                          : (
          model.Where.Prop == 'DynamicSearch_DeparturePortExpressStatus'
        )
                                            ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                key: 17,
                                                modelValue: model.Where.Value,
                                                "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                options: _ctx.EnumExpressStatusOption,
                                                number: true,
                                                clearable: ""
                                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                            : (
          model.Where.Prop == 'DynamicSearch_DestinationPortExpressStatus'
        )
                                              ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                  key: 18,
                                                  modelValue: model.Where.Value,
                                                  "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                  options: _ctx.EnumExpressStatusOption,
                                                  number: true,
                                                  clearable: ""
                                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                              : (
          model.Where.Prop ==
          'DynamicSearch_DeparturePortContainerTrailerStatus'
        )
                                                ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                    key: 19,
                                                    modelValue: model.Where.Value,
                                                    "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                    options: _ctx.EnumDeparturePortContainerTrailerStatusOption,
                                                    number: true,
                                                    clearable: ""
                                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                : (
          model.Where.Prop ==
          'DynamicSearch_DestinationPortContainerTrailerStatus'
        )
                                                  ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                      key: 20,
                                                      modelValue: model.Where.Value,
                                                      "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                      options: _ctx.EnumDestinationPortContainerTrailerStatusOption,
                                                      number: true,
                                                      clearable: ""
                                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                  : (
          model.Where.Prop == 'DynamicSearch_DeparturePortIncomingGoodsStatus'
        )
                                                    ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                        key: 21,
                                                        modelValue: model.Where.Value,
                                                        "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                        options: _ctx.EnumIncomingGoodsStatusOption,
                                                        number: true,
                                                        clearable: ""
                                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                    : (
          model.Where.Prop == 'DynamicSearch_DestinationPortIncomingGoodsStatus'
        )
                                                      ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                          key: 22,
                                                          modelValue: model.Where.Value,
                                                          "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                          options: _ctx.EnumIncomingGoodsStatusOption,
                                                          number: true,
                                                          clearable: ""
                                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                      : _createCommentVNode("", true)
      ]),
      column: _withCtx(({ model, row }) => [
        (model.Prop == 'BookingNo' && row.BookingNo)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              target: "_blank",
              to: `/order/pool/summary/${row.Id}`
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_text_ellipsis, {
                  text: row.BookingNo
                }, null, 8, ["text"])
              ]),
              _: 2
            }, 1032, ["to"]))
          : (model.Prop == 'BookingNo')
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "——"))
            : (model.Prop == 'Code' && row.Code)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(row.Code), 1))
              : (model.Prop == 'Code')
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, "——"))
                : (model.Prop == 'OrderCode' && row.OrderCode)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 4,
                      target: "_blank",
                      to: `/order/summary/${row.OrderId}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_miezz_text_ellipsis, {
                          text: row.OrderCode
                        }, null, 8, ["text"])
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (model.Prop == 'OrderCode')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, "——"))
                    : (row[model.Prop] != '——')
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 6,
                          onClick: ($event: any) => (_ctx.handleRowCommand(model.Prop, row))
                        }, [
                          _createElementVNode("div", null, [
                            _createVNode(_component_miezz_text_ellipsis, {
                              style: _normalizeStyle(
              row[model.Prop].split('\n')[0] == '计划时间缺失'
                ? 'color: red;'
                : ''
            ),
                              text: row[model.Prop].split('\n')[0] ?? '暂无数据'
                            }, null, 8, ["style", "text"])
                          ]),
                          _createElementVNode("div", null, [
                            _createVNode(_component_miezz_text_ellipsis, {
                              class: "exec-time",
                              text: row[model.Prop].split('\n')[1] ?? '暂无数据'
                            }, null, 8, ["text"])
                          ])
                        ], 8, _hoisted_5))
                      : (_openBlock(), _createElementBlock("span", {
                          key: 7,
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleEmptyProcess && _ctx.handleEmptyProcess(...args))),
                          style: {"display":"block","text-align":"center"}
                        }, "——"))
      ]),
      _: 1
    }, 8, ["modelValue", "onHandleGet", "onHandleBottomButtonClick"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modal,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modal) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_miezz_page_list, {
          class: "modal-list",
          modelValue: _ctx.modalList,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalList) = $event))
        }, {
          column: _withCtx(({ model, row }) => [
            (model.Prop == 'Code' && row.Code && row.Link)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  target: "_blank",
                  to: row.Link
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_miezz_text_ellipsis, {
                      text: row.Code
                    }, null, 8, ["text"])
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : (model.Prop == 'Code')
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(row.Code ?? "暂无数据"), 1))
                : _createCommentVNode("", true),
            (model.Prop == 'Process')
              ? (_openBlock(), _createElementBlock("a", {
                  key: 2,
                  onClick: ($event: any) => (_ctx.handleProcess(row))
                }, [
                  _createVNode(_component_miezz_text_ellipsis, {
                    text: "查看详情",
                    style: {"color":"#409eff"}
                  })
                ], 8, _hoisted_7))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalTracking,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modalTracking) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_express_tracking, {
          modelValue: _ctx.trackingId,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.trackingId) = $event)),
          onHandleUpdateEntity: _ctx.handleGet
        }, null, 8, ["modelValue", "onHandleUpdateEntity"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
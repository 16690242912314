import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormPriceServiceShareSearch_PriceServiceShareSearchRelation from "./DtoFormPriceServiceShareSearch_PriceServiceShareSearchRelation";
import UiGroup from "./UiGroup";

/**
 * 价格表共享搜索表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceServiceShareSearch extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 价格服务Id
     */
    ServiceId?: string;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 关联条件
     */
    Relations?: DtoFormPriceServiceShareSearch_PriceServiceShareSearchRelation[];
}

/**
 * 价格表共享搜索表单-表单校验
 */
export const DtoFormPriceServiceShareSearchRules = {
    Name: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 价格表共享搜索表单-设置项
 */
export const DtoFormPriceServiceShareSearchMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Relations",
    Label: "关联条件",
    Customize: true,
  },
]

import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11e2a5a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { style: {"white-space":"break-spaces","word-break":"break-all","word-wrap":"break-word"} }
const _hoisted_6 = ["onMouseenter"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 5 }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 7 }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 9 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_lock = _resolveComponent("lock")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_miezz_text_ellipsis = _resolveComponent("miezz-text-ellipsis")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_order_pool_choose_service = _resolveComponent("order-pool-choose-service")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_order_pool_process = _resolveComponent("order-pool-process")!
  const _component_order_task = _resolveComponent("order-task")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_page_list, {
      ref: "ref-miezz-page-list",
      modelValue: _ctx.list,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
      onHandleGet: _ctx.handleGet,
      onHandleTopButtonClick: _ctx.handleTopButtonClick,
      onHandleRowButtonClick: _ctx.handleRowButtonClick,
      onHandleBottomButtonClick: _ctx.handleBottomButtonClick,
      onHandleRadioSearch: _ctx.handleRadioSearch,
      onHandleRowDbClick: _ctx.handleRowDbClick
    }, {
      "dynamic-search-customize": _withCtx(({ model }) => [
        (model.Where.Prop == 'DynamicSearch_Color')
          ? (_openBlock(), _createBlock(_component_miezz_select, {
              key: 0,
              modelValue: model.Where.Value,
              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
              options: _ctx.colors,
              clearable: "",
              style: _normalizeStyle(`background-color: ${model.Where.Value}`),
              class: "select-color"
            }, {
              default: _withCtx(({ model }) => [
                _createElementVNode("div", {
                  style: _normalizeStyle(`background-color: ${model.value}; margin: 0px -27px 0px -15px; color: #FFF`)
                }, _toDisplayString(model.value), 5)
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "options", "style"]))
          : (model.Where.Prop == 'DynamicSearch_Lock')
            ? (_openBlock(), _createBlock(_component_miezz_select, {
                key: 1,
                modelValue: model.Where.Value,
                "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                options: _ctx.lockOptions,
                clearable: ""
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
            : _createCommentVNode("", true),
        (model.Where.Prop == 'DynamicSearch_LoadingPortId')
          ? (_openBlock(), _createBlock(_component_miezz_select, {
              key: 2,
              modelValue: model.Where.Value,
              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
              options: _ctx.loadingPorts,
              clearable: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
          : (model.Where.Prop == 'DynamicSearch_DeliveryPortId')
            ? (_openBlock(), _createBlock(_component_miezz_select, {
                key: 3,
                modelValue: model.Where.Value,
                "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                options: _ctx.deliveryPorts,
                clearable: ""
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
            : (model.Where.Prop == 'DynamicSearch_CompanySource')
              ? (_openBlock(), _createBlock(_component_miezz_select, {
                  key: 4,
                  modelValue: model.Where.Value,
                  "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                  options: _ctx.companySources,
                  clearable: ""
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
              : (model.Where.Prop == 'DynamicSearch_DeliveryPlaceLabel')
                ? (_openBlock(), _createBlock(_component_miezz_select, {
                    key: 5,
                    modelValue: model.Where.Value,
                    "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                    options: _ctx.deliveryPlaces,
                    clearable: ""
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                : (model.Where.Prop == 'DynamicSearch_ShipName')
                  ? (_openBlock(), _createBlock(_component_miezz_select, {
                      key: 6,
                      modelValue: model.Where.Value,
                      "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                      options: _ctx.shipNames,
                      clearable: ""
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                  : (model.Where.Prop == 'DynamicSearch_VoyageNumber')
                    ? (_openBlock(), _createBlock(_component_miezz_select, {
                        key: 7,
                        modelValue: model.Where.Value,
                        "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                        options: _ctx.voyageNumbers,
                        clearable: ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                    : (model.Where.Prop == 'DynamicSearch_Flight')
                      ? (_openBlock(), _createBlock(_component_miezz_select, {
                          key: 8,
                          modelValue: model.Where.Value,
                          "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                          options: _ctx.flights,
                          clearable: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                      : (model.Where.Prop == 'DynamicSearch_RailwayNumber')
                        ? (_openBlock(), _createBlock(_component_miezz_select, {
                            key: 9,
                            modelValue: model.Where.Value,
                            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                            options: _ctx.railwayNumbers,
                            clearable: ""
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                        : (model.Where.Prop == 'DynamicSearch_CarrierId')
                          ? (_openBlock(), _createBlock(_component_miezz_select, {
                              key: 10,
                              modelValue: model.Where.Value,
                              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                              options: _ctx.carriers,
                              clearable: ""
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                          : (model.Where.Prop == 'DynamicSearch_ContainerType')
                            ? (_openBlock(), _createBlock(_component_miezz_select, {
                                key: 11,
                                modelValue: model.Where.Value,
                                "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                options: _ctx.containerTypes,
                                clearable: ""
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                            : (model.Where.Prop == 'DynamicSearch_ContainerNo')
                              ? (_openBlock(), _createBlock(_component_miezz_select, {
                                  key: 12,
                                  modelValue: model.Where.Value,
                                  "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                  options: _ctx.containerNos,
                                  clearable: ""
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                              : (model.Where.Prop == 'DynamicSearch_ClientUserId')
                                ? (_openBlock(), _createBlock(_component_miezz_select, {
                                    key: 13,
                                    modelValue: model.Where.Value,
                                    "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                    options: _ctx.searchClientUsers,
                                    clearable: ""
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                : (model.Where.Prop == 'DynamicSearch_CustomerServiceId')
                                  ? (_openBlock(), _createBlock(_component_miezz_select, {
                                      key: 14,
                                      modelValue: model.Where.Value,
                                      "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                      options: _ctx.searchCustomerServices,
                                      clearable: ""
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                  : (model.Where.Prop == 'DynamicSearch_TaskUserId')
                                    ? (_openBlock(), _createBlock(_component_miezz_select, {
                                        key: 15,
                                        modelValue: model.Where.Value,
                                        "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                        options: _ctx.taskUsers,
                                        clearable: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                    : (model.Where.Prop == 'DynamicSearch_SalesmanId')
                                      ? (_openBlock(), _createBlock(_component_miezz_select, {
                                          key: 16,
                                          modelValue: model.Where.Value,
                                          "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                          options: _ctx.searchSalesmans,
                                          clearable: ""
                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                      : (model.Where.Prop == 'DynamicSearch_SupplierId')
                                        ? (_openBlock(), _createBlock(_component_miezz_select, {
                                            key: 17,
                                            modelValue: model.Where.Value,
                                            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                            options: _ctx.suppliers,
                                            clearable: ""
                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                        : (model.Where.Prop == 'DynamicSearch_TransportDirection')
                                          ? (_openBlock(), _createBlock(_component_miezz_select, {
                                              key: 18,
                                              modelValue: model.Where.Value,
                                              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                              options: _ctx.EnumTransportDirectionOption,
                                              number: true,
                                              clearable: ""
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                          : (model.Where.Prop == 'DynamicSearch_TransportType')
                                            ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                key: 19,
                                                modelValue: model.Where.Value,
                                                "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                options: _ctx.EnumTransportTypeOption,
                                                number: true,
                                                clearable: ""
                                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                            : (model.Where.Prop == 'DynamicSearch_TransportContainerLoad')
                                              ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                  key: 20,
                                                  modelValue: model.Where.Value,
                                                  "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                  options: _ctx.EnumTransportContainerLoadOption,
                                                  number: true,
                                                  clearable: ""
                                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                              : (model.Where.Prop == 'DynamicSearch_ServiceRemark')
                                                ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                    key: 21,
                                                    modelValue: model.Where.Value,
                                                    "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                    options: _ctx.serviceRemarks,
                                                    clearable: ""
                                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                : (model.Where.Prop == 'DynamicSearch_Status')
                                                  ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                      key: 22,
                                                      modelValue: model.Where.Value,
                                                      "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                      options: _ctx.EnumOrderPoolStatusOption,
                                                      number: true,
                                                      clearable: ""
                                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                  : (model.Where.Prop == 'DynamicSearch_BookingStatus')
                                                    ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                        key: 23,
                                                        modelValue: model.Where.Value,
                                                        "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                        options: _ctx.EnumBookingStatusOption,
                                                        number: true,
                                                        clearable: ""
                                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                    : (
          model.Where.Prop == 'DynamicSearch_DeparturePortCustomsStatus'
        )
                                                      ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                          key: 24,
                                                          modelValue: model.Where.Value,
                                                          "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                          options: _ctx.EnumCustomsStatusOption,
                                                          number: true,
                                                          clearable: ""
                                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                      : (
          model.Where.Prop == 'DynamicSearch_DestinationPortCustomsStatus'
        )
                                                        ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                            key: 25,
                                                            modelValue: model.Where.Value,
                                                            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                            options: _ctx.EnumCustomsStatusOption,
                                                            number: true,
                                                            clearable: ""
                                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                        : (model.Where.Prop == 'DynamicSearch_DeparturePortTrunkStatus')
                                                          ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                              key: 26,
                                                              modelValue: model.Where.Value,
                                                              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                              options: _ctx.EnumTrunkStatusOption,
                                                              number: true,
                                                              clearable: ""
                                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                          : (
          model.Where.Prop == 'DynamicSearch_DestinationPortTrunkStatus'
        )
                                                            ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                                key: 27,
                                                                modelValue: model.Where.Value,
                                                                "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                                options: _ctx.EnumTrunkStatusOption,
                                                                number: true,
                                                                clearable: ""
                                                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                            : (
          model.Where.Prop == 'DynamicSearch_DeparturePortExpressStatus'
        )
                                                              ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                                  key: 28,
                                                                  modelValue: model.Where.Value,
                                                                  "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                                  options: _ctx.EnumExpressStatusOption,
                                                                  number: true,
                                                                  clearable: ""
                                                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                              : (
          model.Where.Prop == 'DynamicSearch_DestinationPortExpressStatus'
        )
                                                                ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                                    key: 29,
                                                                    modelValue: model.Where.Value,
                                                                    "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                                    options: _ctx.EnumExpressStatusOption,
                                                                    number: true,
                                                                    clearable: ""
                                                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                                : (
          model.Where.Prop ==
          'DynamicSearch_DeparturePortContainerTrailerStatus'
        )
                                                                  ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                                      key: 30,
                                                                      modelValue: model.Where.Value,
                                                                      "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                                      options: _ctx.EnumDeparturePortContainerTrailerStatusOption,
                                                                      number: true,
                                                                      clearable: ""
                                                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                                  : (
          model.Where.Prop ==
          'DynamicSearch_DestinationPortContainerTrailerStatus'
        )
                                                                    ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                                        key: 31,
                                                                        modelValue: model.Where.Value,
                                                                        "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                                        options: _ctx.EnumDestinationPortContainerTrailerStatusOption,
                                                                        number: true,
                                                                        clearable: ""
                                                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                                    : (
          model.Where.Prop == 'DynamicSearch_DeparturePortIncomingGoodsStatus'
        )
                                                                      ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                                          key: 32,
                                                                          modelValue: model.Where.Value,
                                                                          "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                                          options: _ctx.EnumIncomingGoodsStatusOption,
                                                                          number: true,
                                                                          clearable: ""
                                                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                                      : (
          model.Where.Prop == 'DynamicSearch_DestinationPortIncomingGoodsStatus'
        )
                                                                        ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                                            key: 33,
                                                                            modelValue: model.Where.Value,
                                                                            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                                            options: _ctx.EnumIncomingGoodsStatusOption,
                                                                            number: true,
                                                                            clearable: ""
                                                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                                        : (model.Where.Prop == 'DynamicSearch_CollectionSubmitStatus')
                                                                          ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                                              key: 34,
                                                                              modelValue: model.Where.Value,
                                                                              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                                              options: _ctx.EnumSubmitStatusOption,
                                                                              clearable: ""
                                                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                                          : (model.Where.Prop == 'DynamicSearch_PaymentSubmitStatus')
                                                                            ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                                                key: 35,
                                                                                modelValue: model.Where.Value,
                                                                                "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                                                options: _ctx.EnumSubmitStatusOption,
                                                                                clearable: ""
                                                                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                                            : (model.Where.Prop == 'DynamicSearch_SalerSubmitStatus')
                                                                              ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                                                  key: 36,
                                                                                  modelValue: model.Where.Value,
                                                                                  "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                                                  options: _ctx.EnumSubmitStatusOptionSaler,
                                                                                  clearable: ""
                                                                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                                              : (
          model.Where.Prop == 'DynamicSearch_CollectionSettlementStatus'
        )
                                                                                ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                                                    key: 37,
                                                                                    modelValue: model.Where.Value,
                                                                                    "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                                                    options: _ctx.EnumSettlementStatusOption,
                                                                                    clearable: ""
                                                                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                                                : (model.Where.Prop == 'DynamicSearch_PaymentSettlementStatus')
                                                                                  ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                                                      key: 38,
                                                                                      modelValue: model.Where.Value,
                                                                                      "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                                                      options: _ctx.EnumSettlementStatusOption,
                                                                                      clearable: ""
                                                                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                                                  : _createCommentVNode("", true)
      ]),
      column: _withCtx(({ model, row }) => [
        (model.Prop == 'Color')
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
              _createVNode(_component_miezz_button, {
                model: {
            Code: 'mark',
            Type: 'color',
          },
                color: row.Color,
                onHandleClick: (arg1, arg2) => _ctx.handleRowButtonClick(arg1, row, arg2)
              }, null, 8, ["color", "onHandleClick"])
            ]))
          : _createCommentVNode("", true),
        (model.Prop == 'BookingNo')
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              (row.Lock || row.OrderLock)
                ? (_openBlock(), _createBlock(_component_el_icon, {
                    key: 0,
                    class: "lock"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_lock)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (row.BookingNo)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(row.BookingNo), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (model.Prop == 'CustomerSupplierName')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_el_tooltip, {
                placement: "top",
                disabled: row.tooltipFlag
              }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(row.CustomerSupplierName ?? "暂无数据"), 1)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    class: "text-ellipsis",
                    onMouseenter: ($event: any) => (_ctx.visibilityChange($event, row))
                  }, _toDisplayString(row.CustomerSupplierName ?? "暂无数据"), 41, _hoisted_6)
                ]),
                _: 2
              }, 1032, ["disabled"])
            ]))
          : _createCommentVNode("", true),
        (model.Prop == 'OrderCode' && _ctx.canLinkOrder && row.OrderId)
          ? (_openBlock(), _createElementBlock("a", {
              key: 3,
              onClick: ($event: any) => (_ctx.handleRowCommand(model.Prop, row))
            }, [
              _createVNode(_component_miezz_text_ellipsis, {
                text: row.OrderCode ?? '暂无数据'
              }, null, 8, ["text"])
            ], 8, _hoisted_7))
          : (model.Prop == 'OrderCode' && row.OrderId)
            ? (_openBlock(), _createElementBlock("span", {
                key: 4,
                onClick: ($event: any) => (_ctx.onCopyBookingNo(row.OrderCode))
              }, _toDisplayString(row.OrderCode ?? "暂无数据"), 9, _hoisted_8))
            : (model.Prop == 'OrderCode')
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, "暂无数据"))
              : _createCommentVNode("", true),
        (_ctx.showAssignClientUser && model.Prop == 'ClientUserId')
          ? (_openBlock(), _createElementBlock("a", {
              key: 6,
              onClick: ($event: any) => (_ctx.handleRowCommand(model.Prop, row))
            }, [
              _createVNode(_component_miezz_text_ellipsis, {
                text: row.ClientUserLabel ?? '暂无数据',
                miezzIcon: "assign"
              }, null, 8, ["text"])
            ], 8, _hoisted_10))
          : (model.Prop == 'ClientUserId')
            ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(row.ClientUserLabel ?? "暂无数据"), 1))
            : _createCommentVNode("", true),
        (_ctx.showAssignCustomerService && model.Prop == 'CustomerServiceId')
          ? (_openBlock(), _createElementBlock("a", {
              key: 8,
              onClick: ($event: any) => (_ctx.handleRowCommand(model.Prop, row))
            }, [
              _createVNode(_component_miezz_text_ellipsis, {
                text: row.CustomerServiceLabel ?? '暂无数据',
                miezzIcon: "assign"
              }, null, 8, ["text"])
            ], 8, _hoisted_12))
          : (model.Prop == 'CustomerServiceId')
            ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(row.CustomerServiceLabel ?? "暂无数据"), 1))
            : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "onHandleGet", "onHandleTopButtonClick", "onHandleRowButtonClick", "onHandleBottomButtonClick", "onHandleRadioSearch", "onHandleRowDbClick"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalChooseService,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalChooseService) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_order_pool_choose_service, {
          modelValue: _ctx.modalChooseService,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalChooseService) = $event)),
          onHandleSubmit: _ctx.handleChooseServiceSubmit
        }, null, 8, ["modelValue", "onHandleSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalAssignClientUser,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modalAssignClientUser) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "ref-form-assign-client-user",
          model: _ctx.modalAssignClientUserData,
          rules: _ctx.modalAssignClientUserRules,
          "label-width": "150px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              prop: "ClientUserId",
              label: "执行人"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_select, {
                  modelValue: _ctx.modalAssignClientUserData.ClientUserId,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalAssignClientUserData.ClientUserId) = $event)),
                  label: _ctx.modalAssignClientUserData.ClientUserLabel,
                  "onUpdate:label": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modalAssignClientUserData.ClientUserLabel) = $event)),
                  options: _ctx.clientUsers,
                  clearable: false
                }, null, 8, ["modelValue", "label", "options"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalAssignCustomerService,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.modalAssignCustomerService) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "ref-form-assign-customer-service",
          model: _ctx.modalAssignCustomerServiceData,
          rules: _ctx.modalAssignCustomerServiceRules,
          "label-width": "150px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              prop: "CustomerServiceId",
              label: "客服"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_select, {
                  modelValue: _ctx.modalAssignCustomerServiceData.CustomerServiceId,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modalAssignCustomerServiceData.CustomerServiceId) = $event)),
                  label: _ctx.modalAssignCustomerServiceData.CustomerServiceLabel,
                  "onUpdate:label": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.modalAssignCustomerServiceData.CustomerServiceLabel) = $event)),
                  options: _ctx.customerServices,
                  clearable: false
                }, null, 8, ["modelValue", "label", "options"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalProcess,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.modalProcess) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_order_pool_process, {
          modelValue: _ctx.modalProcess,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.modalProcess) = $event)),
          id: _ctx.id
        }, null, 8, ["modelValue", "id"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_order_task, {
      modelValue: _ctx.modalTask,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.modalTask) = $event)),
      url: "/order/pool",
      listPower: "OrderManagement_Pool_Task",
      userPower: "OrderManagement_Pool",
      id: _ctx.id,
      cancel: _ctx.cancel
    }, null, 8, ["modelValue", "id", "cancel"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalCopy,
      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.modalCopy) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_miezz_form, {
          modelValue: _ctx.formCopy,
          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.formCopy) = $event)),
          ref: "ruleFormCopy"
        }, {
          "form-item": _withCtx(({ item }) => [
            (item.Prop == 'ClientCompanyId')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  prop: item.Prop,
                  label: item.Label
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_miezz_select, {
                      modelValue: _ctx.formCopy.Data.ClientCompanyId,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.formCopy.Data.ClientCompanyId) = $event)),
                      label: _ctx.formCopy.Data.ClientCompanyLabel,
                      "onUpdate:label": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.formCopy.Data.ClientCompanyLabel) = $event)),
                      options: _ctx.filterCopyCompanies,
                      "filter-Method": _ctx.filterCopyClientCompanies,
                      disabled: item.Disabled,
                      labelKey: "NameZh/NameEn",
                      valueKey: "Id"
                    }, null, 8, ["modelValue", "label", "options", "filter-Method", "disabled"])
                  ]),
                  _: 2
                }, 1032, ["prop", "label"]))
              : (item.Prop == 'ClientUserId')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 1,
                    prop: item.Prop,
                    label: item.Label
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_select, {
                        modelValue: _ctx.formCopy.Data.ClientUserId,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.formCopy.Data.ClientUserId) = $event)),
                        label: _ctx.formCopy.Data.ClientUserLabel,
                        "onUpdate:label": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.formCopy.Data.ClientUserLabel) = $event)),
                        options: _ctx.copyUsers,
                        disabled: item.Disabled
                      }, null, 8, ["modelValue", "label", "options", "disabled"])
                    ]),
                    _: 2
                  }, 1032, ["prop", "label"]))
                : (item.Prop == 'BookingNoBuildType')
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 2,
                      prop: item.Prop,
                      label: item.Label
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_miezz_select, {
                          modelValue: _ctx.formCopy.Data.BookingNoBuildType,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.formCopy.Data.BookingNoBuildType) = $event)),
                          options: _ctx.EnumOrderPoolCopyBookingNoBuildTypeOption,
                          clearable: false
                        }, null, 8, ["modelValue", "options"])
                      ]),
                      _: 2
                    }, 1032, ["prop", "label"]))
                  : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
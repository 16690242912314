import UiSelectOption from "./UiSelectOption";

/**
 * 显示范围
 * @description 自动生成的代码,请勿修改
 */
export enum EnumPriceTableShowRange {
    /**
     * 服务
     */
    Service = 1,
    /**
     * 结算
     */
    Settlement = 2,
    /**
     * 指导
     */
    Guide = 3,
    /**
     * 协议
     */
    Agreement = 4,
    /**
     * 成本
     */
    Cost = 5,
}

/**
 * 显示范围-选项
 */
export const EnumPriceTableShowRangeOption: UiSelectOption<number>[] = [
    { Label: "服务", Value: 1, Data: "Service" },
    { Label: "结算", Value: 2, Data: "Settlement" },
    { Label: "指导", Value: 3, Data: "Guide" },
    { Label: "协议", Value: 4, Data: "Agreement" },
    { Label: "成本", Value: 5, Data: "Cost" },
];

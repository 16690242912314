
    import OSS from "ali-oss";
    import AliyunOSS from "@/apis/AliyunOSS";
    import moment from "moment";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
    import MiezzForm from "@/models/MiezzForm";
    import CurrentLogier from "@/apis/CurrentLogier";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import { ElMessageBox } from "element-plus";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
    import MiezzButton from "@/models/MiezzButton";
    import FinanceAppService from "@/apis.machine/FinanceAppService";
    import DtoPageListSearchFinance, { DtoPageListSearchFinanceDynamicSearchItems } from "@/models.machine/DtoPageListSearchFinance";
    import DtoPageListFinance from "@/models.machine/DtoPageListFinance";
    import DtoRequestFinance from "@/models.machine/DtoRequestFinance";
    import DtoRequestInvoice from "@/models.machine/DtoRequestInvoice";
    import DtoRequestSettlement from "@/models.machine/DtoRequestSettlement";
    import DtoFromInvoiceItem from "@/models.machine/DtoFromInvoiceItem";
    import DtoRequestSettlementItem from "@/models.machine/DtoRequestSettlementItem";
    import DtoCallbackStat from "@/models.machine/DtoCallbackStat";
    import DtoRequestBill from "@/models.machine/DtoRequestBill";
    import DtoAssociationInvoice from "@/models.machine/DtoAssociationInvoice";
    import DtoRequestOneSettlement from "@/models.machine/DtoRequestOneSettlement";
    import DtoFormSettlement from "@/models.machine/DtoFormSettlement";
    import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
    import BillHistoryAppService from "@/apis.machine/BillHistoryAppService";
    import DtoPageListSearchBillHistory, { DtoPageListSearchBillHistoryDynamicSearchItems } from "@/models.machine/DtoPageListSearchBillHistory";
    import DtoPageListBillHistory from "@/models.machine/DtoPageListBillHistory";
    import DtoRequestBatchSettlement from "@/models.machine/DtoRequestBatchSettlement";
    import DtoRequestBatchSettlementItem from "@/models.machine/DtoRequestBatchSettlementItem";
    import BillAppService from "@/apis.machine/BillAppService";
    import DtoRequestOrderLock from "@/models.machine/DtoRequestOrderLock";
    import DtoFromInvoiceAnnex from "@/models.machine/DtoFromInvoiceAnnex";
    import DtoCallbackPartSettlement from "@/models.machine/DtoCallbackPartSettlement";
    import DtoFormBillCheckStatus from "@/models.machine/DtoFormBillCheckStatus";
    import DtoFormAccountPeriod from "@/models.machine/DtoFormAccountPeriod";
    import DtoFormStartDate from "@/models.machine/DtoFormStartDate";
    import DtoRequestAccount from "@/models.machine/DtoRequestAccount";
    import DtoDetailInvoice from "@/models.machine/DtoDetailInvoice";
    import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
    import { EnumRelation } from "@/models.machine/EnumRelation";
    import { EnumCompare } from "@/models.machine/EnumCompare";
    import DtoListItemBillHistory, {
        DtoListItemBillHistoryMiezzListItems,
    } from "@/models.machine/DtoListItemBillHistory";
    import {
        EnumSettlementMode,
        EnumSettlementModeOption,
    } from "@/models.machine/EnumSettlementMode";
    import DtoListItemFinance, {
        DtoListItemFinanceMiezzListItems,
    } from "@/models.machine/DtoListItemFinance";
    import BillDetail from "./BillDetail.vue";
    import BillForm from "./BillForm.vue";
    import ParamPriceCostAppService from "@/apis.machine/ParamPriceCostAppService";
    import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
    import CompanyAppService from "@/apis.machine/CompanyAppService";
    import {
        EnumInvoiceStatus,
        EnumInvoiceStatusOption,
    } from "@/models.machine/EnumInvoiceStatus";

    import {
        EnumInvoiceSettlementStatus,
        EnumInvoiceSettlementStatusOption,
    } from "@/models.machine/EnumInvoiceSettlementStatus";

    import {
        EnumBillCheckStatus,
        EnumBillCheckStatusOption,
    } from "@/models.machine/EnumBillCheckStatus";


    import {
        EnumBillType
    } from "@/models.machine/EnumBillType";
    import { asString } from "html2canvas/dist/types/css/types/color";
    @Options({
        components: {
            BillDetail,
            BillForm,
        },
    })
    export default class FinancePageList extends Vue {
        isFinishStat = false;
        statDialogVisible = false;

        svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0);color:#000;"/>
      `;

        accountPeriodRuleForm: {
            Visiable: boolean,
            AccountPeriod: string,
            Time: Date | undefined,
            SelectId?: any[],
        } = {
                Visiable: false,
                AccountPeriod: "",
                Time: undefined,
                SelectId: [],
            };

        startDateRuleForm:{
            Visiable: boolean,
            Time: Date | undefined,
            SelectId?: any[],
        } = {
                Visiable: false,
                Time: undefined,
                SelectId: [],
            };
        startDateBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];


        accountPeriodBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        client?: OSS;
        setinvoiceForm = new DtoAssociationInvoice();
        checkStatusForm = new DtoFormBillCheckStatus();
        invoices: UiSelectOption<string>[] = [];

        isSelectAccount = false;
        accounts: UiSelectOption<string>[] = [];

        //费用名称
        costs: UiSelectOption<string>[] = [];
        dialogVisible = false;
        isLock = false;
        selectRows?: any;
        statForm = new DtoCallbackStat();
        statBottomButtons?: MiezzButton[] = [
            //{
            //    IsBlue: true,
            //    Code: "export",
            //    Label: "导出",
            //    Type: "primary",
            //    Size: "small",
            //},
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        partSettlementBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "save",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        companySources: UiSelectOptionWithStringValue[] = [];
        lockParam = new DtoRequestOrderLock();
        poolCustomers: UiSelectOption<string>[] = [];
        customerServices: UiSelectOption<string>[] = [];
        loadingPorts: UiSelectOption<string>[] = [];
        deliveryPorts: UiSelectOption<string>[] = [];
        makers: UiSelectOption<string>[] = [];
        salers: UiSelectOption<string>[] = [];
        clients: UiSelectOption<string>[] = [];
        deliveryPlaces: UiSelectOptionWithStringValue[] = [];
        serviceRemarks: UiSelectOptionWithStringValue[] = [];
        billObjects: UiSelectOptionWithStringValue[] = [];
        blnos: UiSelectOptionWithStringValue[] = [];
        containers: UiSelectOptionWithStringValue[] = [];
        //币制
        currencys: UiSelectOption<string>[] = [];
        currencyIndex = "";
        isHistory = true;
        /**分页列表 */
        historys = new MiezzPageList<DtoPageListSearchBillHistory, DtoListItemBillHistory, string>();

        /**分页列表 */
        list = new MiezzPageList<DtoPageListSearchFinance, DtoListItemFinance, string>("FinancialManagement");
        /**当前Id */
        id?: string;
        type?: number;
        /**详细模态框 */
        modalDetail = new MiezzModal();
        /**表单模态框 */
        modalForm = new MiezzModal();
        EnumSettlementModeOption = EnumSettlementModeOption;
        EnumInvoiceStatusOption = EnumInvoiceStatusOption;
        EnumBillCheckStatusOption = EnumBillCheckStatusOption;
        EnumInvoiceStateOption = [
            {
                Value: "10",
                Label: "已开票"
            },
            {
                Value: "20",
                Label: "未开票"
            },
        ];
        EnumInvoiceSettlementStatusOption = EnumInvoiceSettlementStatusOption;

        EnumInvoiceStatusOptionSearch = [
              {
                Value: "10",
                Label: "待添加"
            },
            {
                Value: "20",
                Label: "部分添加"
            },
            {
                Value: "30",
                Label: "已添加"
            },
        ];

        EnumInvoiceSettlementStatusOptionSearch = [
            {
                Value: "-1",
                Label: "未标记"
            },
            {
                Value: "0",
                Label: "未到账"
            },
            {
                Value: "10",
                Label: "部分到账"
            },
            {
                Value: "20",
                Label: "已到账"
            }
        ];

        EnumBillCheckStatusOptionSearch = [
            {
                Value: "10",
                Label: "未核对"
            },
            {
                Value: "20",
                Label: "有差异"
            },
            {
                Value: "30",
                Label: "核对一致"
            },
        ];

        EnumBillShareStatusOptionSearch = [
            {
                Value: "10",
                Label: "未分摊"
            },
            {
                Value: "20",
                Label: "已分摊"
            },
        ];

        EnumSubmitStatusOption = [
            {
                Value: "10",
                Label: "已提交"
            },
            {
                Value: "20",
                Label: "未提交"
            },
        ];
        EnumOverdueStatusOption = [
            {
                Value: "10",
                Label: "是"
            },
            {
                Value: "20",
                Label: "否"
            },
        ];

        batchSettlementTitleForm = {
            Visiable: false,
            Title: undefined,
            Type: 0,//0表示收款方抬头 1表示付款方抬头
        };

        batchSettlementTimeForm = {
            Visiable: false,
            SettlementTime: undefined,
            DisplayRate: "",
            RateVisiable: false,
        };
        batchSettlementRemarkForm = {
            Visiable: false,
            Remark: undefined,
        };

        batchSettlementModeForm = {
            Visiable: false,
            Mode: undefined,
        };

        isBatchSettlementTime = true;
        settlementTableRandom = 0;
        oneSettlementFormInit = new DtoFormSettlement();
        oneSettlementForm = new DtoFormSettlement();
        oneBadSettlementFormInit = new DtoFormSettlement();
        oneBadSettlementForm = new DtoFormSettlement();
        oneSettlementBottomButtons?: MiezzButton[] = [
            {
                //Icon: "select",
                IsBlue: true,
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            //{
            //    //Icon: "select",
            //    Code: "all",
            //    Label: "全部核销",
            //    Type: "primary",
            //    Size: "small",
            //},
            {
                IsBlue: true,
                //Icon: "select",
                Code: "import",
                Label: "导入待核销",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        oneBadSettlementBottomButtons?: MiezzButton[] = [
            {
                //Icon: "select",
                IsBlue: true,
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        types?: UiSelectOption<string>[] = [];
        searchSettlements?: UiSelectOption<string>[] = [];
        oneSettlementRules = {
            Price: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        this.oneSettlementForm.SurplusPrice = this.oneSettlementFormInit.SurplusPrice;
                        //callback(new Error("信息仅允许填写汉字"));

                        if (this.oneSettlementForm) {
                            var price = this.oneSettlementForm.Price;
                            if (price != undefined) {
                                var result = setDecimal(price.toString(), 2, false, true);
                                if (result != "") {
                                    this.oneSettlementForm.Price = Number(result);

                                }
                                else {
                                    this.oneSettlementForm.Price = undefined;
                                }

                                if (this.oneSettlementForm.Rate == null || this.oneSettlementForm.Rate == undefined) {
                                    this.oneSettlementForm.Rate = 1;
                                }

                                this.oneSettlementForm.ConvertPrice = undefined;
                                if (this.oneSettlementForm.NoSettlementPrice && this.oneSettlementForm.Price && this.oneSettlementForm.Rate) {
                                    price = this.oneSettlementForm.Price / this.oneSettlementForm.Rate;
                                    result = setDecimal(price.toString(), 2, false, true);
                                    if (result != "") {
                                        this.oneSettlementForm.ConvertPrice = Number(result);

                                    }
                                    if (this.oneSettlementForm.ConvertPrice && this.oneSettlementFormInit && this.oneSettlementFormInit.NoSettlementPrice) {
                                        this.oneSettlementForm.SurplusPrice = this.oneSettlementFormInit.NoSettlementPrice - this.oneSettlementForm.ConvertPrice;
                                    }

                                    price = this.oneSettlementForm.SurplusPrice;
                                    if (price != undefined) {
                                        result = setDecimal(price.toString(), 2, true, true);
                                        if (result != "") {
                                            this.oneSettlementForm.SurplusPrice = Number(result);
                                        }
                                        else {
                                            this.oneSettlementForm.SurplusPrice = undefined;
                                        }
                                    }

                                    if (this.oneSettlementForm && this.oneSettlementForm.NoSettlementPrice && this.oneSettlementForm.NoSettlementPrice > 0) {
                                        if (this.oneSettlementForm.Price && this.oneSettlementForm.Price < 0) {
                                            callback(new Error("不能录入<0的金额"));
                                            return false;
                                        }
                                    }

                                    if (this.oneSettlementForm && this.oneSettlementForm.NoSettlementPrice && this.oneSettlementForm.NoSettlementPrice < 0) {
                                        if (this.oneSettlementForm.Price && this.oneSettlementForm.Price > 0) {
                                            callback(new Error("不能录入>0的金额"));
                                            return false;
                                        }
                                    }

                                    if (this.oneSettlementForm && this.oneSettlementForm.SurplusPrice && this.oneSettlementForm.SurplusPrice < 0 && this.oneSettlementForm.NoSettlementPrice > 0) {
                                        callback(new Error("不能超出待核销金额"));
                                        return false;
                                    }

                                    if (this.oneSettlementForm && this.oneSettlementForm.SurplusPrice && this.oneSettlementForm.SurplusPrice > 0 && this.oneSettlementForm.NoSettlementPrice < 0) {
                                        callback(new Error("不能超出待核销金额"));
                                        return false;
                                    }
                                }
                            }
                        }
                        callback();
                    },
                    trigger: "blur",
                }
            ],
            CurrencySystemId: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ],
            DisplayRate: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ],
            SettlementDate: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ],
            CollectionTitle: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
            ],
            PaymentTitle: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
            ],
        };


        settlementForm = new DtoRequestSettlement();
        settlementBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
            //{
            //    //Icon: "select",
            //    Code: "import",
            //    Label: "核销",
            //    Type: "primary",
            //    Size: "small",
            //},
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        partSettlementForm = new DtoCallbackPartSettlement();
        batchSettlementForm = new DtoRequestBatchSettlement();
        batchBadSettlementForm = new DtoRequestBatchSettlement();
        batchSettlementBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "select",
                Code: "all",
                Label: "全部核销",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
            {
                IsBlue: true,
                //Icon: "select",
                Code: "import",
                Label: "导入待核销",
                Type: "primary",
                Size: "small",
            },
            //{
            //    IsBlue: true,
            //    //Icon: "select",
            //    Code: "partSettlement",
            //    Label: "部分核销",
            //    Type: "primary",
            //    Size: "small",
            //},
            //{
            //    //Icon: "select",
            //    Code: "import",
            //    Label: "核销",
            //    Type: "primary",
            //    Size: "small",
            //},
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        batchBadSettlementBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        batchSettlementRules = {
            CollectionTitle: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
            ],
            PaymentTitle: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
            ],
            Price: [
                {
                    required: true,
                    message: "信息不能为空或0",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        var fields = rule.field.replace("[", ".").replace("]", ".").split(".");
                        var index = Number(fields[1]);

                        if (this.batchSettlementForm && this.batchSettlementForm.Items) {
                            var price = this.batchSettlementForm.Items[index].Price;
                            if (price != undefined) {
                                var result = setDecimal(price.toString(), 2, false, true);
                                if (result != "") {
                                    this.batchSettlementForm.Items[index].Price = Number(result);

                                }
                                else {
                                    this.batchSettlementForm.Items[index].Price = undefined;
                                }

                                this.batchSettlementForm.Items[index].ConvertPrice = undefined;
                                if (this.batchSettlementForm && this.batchSettlementForm.Items && this.batchSettlementForm.Items[index].NoSettlementPrice && this.batchSettlementForm.Items[index].Price && this.batchSettlementForm.Items[index].Rate) {
                                    var temp1 = this.batchSettlementForm.Items[index].Price;
                                    var temp2 = this.batchSettlementForm.Items[index].Rate;
                                    if (temp1 && temp2) {
                                        price = temp1 / temp2;
                                    }

                                    result = setDecimal(price.toString(), 2, false, true);
                                    if (result != "") {
                                        this.batchSettlementForm.Items[index].ConvertPrice = Number(result);

                                    }
                                    if (this.batchSettlementForm.Items[index].ConvertPrice) {
                                        var temp3 = this.batchSettlementForm.Items[index].NoSettlementPrice;
                                        var temp4 = this.batchSettlementForm.Items[index].ConvertPrice;
                                        if (temp3 && temp4) {
                                            this.batchSettlementForm.Items[index].SurplusPrice = temp3 - temp4;
                                        }
                                    }

                                    price = this.batchSettlementForm.Items[index].SurplusPrice;
                                    if (price != undefined) {
                                        result = setDecimal(price.toString(), 2, true, true);
                                        if (result != "") {
                                            this.batchSettlementForm.Items[index].SurplusPrice = Number(result);
                                        }
                                        else {
                                            this.batchSettlementForm.Items[index].SurplusPrice = undefined;
                                        }
                                    }
                                    if (this.batchSettlementForm && this.batchSettlementForm) {
                                        if (this.batchSettlementForm.Items[index]) {
                                            var NoSettlementPrice = this.batchSettlementForm.Items[index].NoSettlementPrice;
                                            if (NoSettlementPrice) {
                                                var price1 = this.batchSettlementForm.Items[index].Price;
                                                if (NoSettlementPrice > 0 && price1 && price1 < 0) {
                                                    callback(new Error("不能录入<0的金额"));
                                                    return false;
                                                }

                                                if (NoSettlementPrice < 0 && price1 && price1 > 0) {
                                                    callback(new Error("不能录入>0的金额"));
                                                    return false;
                                                }

                                                if (this.batchSettlementForm.Items[index] && this.batchSettlementForm.Items[index].SurplusPrice && NoSettlementPrice > 0) {
                                                    var temp5 = this.batchSettlementForm.Items[index].SurplusPrice;
                                                    if (temp5 && temp5 < 0) {
                                                        callback(new Error("不能超出待核销金额"));
                                                        return false;
                                                    }
                                                }

                                                if (this.batchSettlementForm.Items[index] && this.batchSettlementForm.Items[index].SurplusPrice && NoSettlementPrice < 0) {
                                                    var temp6 = this.batchSettlementForm.Items[index].SurplusPrice;
                                                    if (temp6 && temp6 > 0) {
                                                        callback(new Error("不能超出待核销金额"));
                                                        return false;
                                                    }
                                                }
                                            }
                                        }
                                    }


                                }
                            }
                        }
                        callback();
                    },
                    trigger: "blur",
                }
            ],
            DisplayRate: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ],
            SettlementDate: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ],
        };


        batchBadSettlementRules = {
            SettlementDate: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ],
        };

        partSettlementRules = {
            CurrencySystemId: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
            ],
            Rate: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
            ],
            Price: [
                {
                    required: false,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: false,
                    validator: (rule: any, value: string, callback: any) => {
                        var fields = rule.field.replace("[", ".").replace("]", ".").split(".");
                        var index = Number(fields[1]);

                        if (this.partSettlementForm && this.partSettlementForm.Item) {
                            var price = this.partSettlementForm.Item[index].Price;
                            if (price != undefined) {
                                var result = setDecimal(price.toString(), 2, false, true);
                                if (result != "") {
                                    this.partSettlementForm.Item[index].Price = Number(result);

                                }
                                else {
                                    this.partSettlementForm.Item[index].Price = undefined;
                                }

                                var convertPrice = undefined;
                                if (this.partSettlementForm && this.partSettlementForm.Item && this.partSettlementForm.Item[index].Price && this.partSettlementForm.Item[index].Rate) {
                                    var temp1 = this.partSettlementForm.Item[index].Price;
                                    var temp2 = Number(this.partSettlementForm.Item[index].Rate);
                                    if (temp1 && temp2) {
                                        price = temp1 / temp2;
                                    }

                                    result = setDecimal(price.toString(), 2, false, true);
                                    if (result != "") {
                                        convertPrice = Number(result);

                                    }


                                    //if (this.partSettlementForm && this.partSettlementForm && this.partSettlementForm.Item && convertPrice) {
                                    //    var settlement = this.partSettlementForm.Item[index].SettlementPrice;
                                    //    if (settlement) {
                                    //        if (convertPrice > settlement) {
                                    //            callback(new Error("不能大于待核销金额"));
                                    //            return false;
                                    //        }
                                    //    }
                                    //}
                                }
                            }
                        }
                        callback();
                    },
                    trigger: "blur",
                }
            ],
            SettlementTime: [
                {
                    required: false,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: false,
                    validator: (rule: any, value: string, callback: any) => {
                        var fields = rule.field.replace("[", ".").replace("]", ".").split(".");
                        var index = Number(fields[1]);

                        if (this.partSettlementForm && this.partSettlementForm.Item) {
                            var price = this.partSettlementForm.Item[index].Price;
                            if (price != undefined) {
                                if (this.partSettlementForm.Item[index].SettlementTime == null || this.partSettlementForm.Item[index].SettlementTime == undefined) {
                                    callback(new Error("信息不能为空"));
                                    return false;
                                }
                            }
                        }
                        callback();
                    },
                    trigger: "blur",
                }
            ],
        };


        invoiceForm = new DtoRequestInvoice();
        invoiceFormInit = new DtoRequestInvoice();
        invoiceBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
            {
                IsBlue: true,
                //Icon: "select",
                Code: "search",
                Label: "搜索相关账单",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        isLoading = false;
        rules = {
            Number: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    validator: (rule: any, value: string, callback: any) => {
                        var param = new DtoRequestFinance();
                        param.InvoiceId = this.invoiceForm.InvoiceId;
                        param.BillIds = this.invoiceForm.BillIds;
                        param.Number = value;
                        FinanceAppService.IsSame(param).then((r) => {
                            if (r.data.Data && r.data.Data.IsSame == true) {
                                callback("存在多个对账对象,请重新填写");
                                return false;
                            }
                            else {
                                var fields = rule.field.replace("[", ".").replace("]", ".").split(".");
                                var number = Number(fields[1]);
                                if (this.invoiceForm.InvoiceItems && this.invoiceForm.InvoiceItems.length > 0) {
                                    for (var i = 0; i < this.invoiceForm.InvoiceItems.length; i++) {
                                        if (number != i && this.invoiceForm && this.invoiceForm.InvoiceItems && this.invoiceForm.InvoiceItems[i].Number) {
                                            if (this.invoiceForm.InvoiceItems[i].Number?.toUpperCase() == value.toUpperCase()) {
                                                callback("信息重复，请重新填写");
                                                return false;
                                            }
                                        }
                                    }

                                }
                                callback();
                            }
                        })



                    },
                    trigger: 'blur'
                }
            ],
        };

        onChangeInvoicePrice(index: number,type:number) {
            if (this.invoiceForm && this.invoiceForm.InvoiceItems && this.invoiceForm.InvoiceItems.length > 0) {
                var price = this.invoiceForm.InvoiceItems[index].Price;
                if (price != undefined) {
                    var result = setDecimal(price.toString(), 2, false, false);
                    if (result != "") {
                        this.invoiceForm.InvoiceItems[index].Price = Number(result);

                    }
                    else {
                        this.invoiceForm.InvoiceItems[index].Price = undefined;
                    }
                }

          


                if (this.currencys) {
                    for (var it of this.currencys) {
                        if (it.Value == this.invoiceForm.InvoiceItems[index].CurrencySystemId) {
                            this.invoiceForm.InvoiceItems[index].CurrencySystem = {
                                Code: it.Label,
                                CurrencySystemId: it.Value,
                            };
                        }

                        if (it.Value == this.invoiceForm.InvoiceItems[index].SettlementCurrencySystemId) {
                            this.invoiceForm.InvoiceItems[index].SettlementCurrencySystem = {
                                Code: it.Label,
                                CurrencySystemId: it.Value,
                            };
                        }
                    }

                    if (this.invoiceForm.InvoiceItems[index].IsChangeSettlementCurrencyId == true && (type == 1)) {
                        if (this.invoiceForm.InvoiceItems[index].CurrencySystemId != null && this.invoiceForm.InvoiceItems[index].CurrencySystemId != undefined) {
                            var it1 = this.invoiceForm.InvoiceItems[index].CurrencySystem;
                            if (it1) {
                                this.invoiceForm.InvoiceItems[index].SettlementCurrencySystemId = this.invoiceForm.InvoiceItems[index].CurrencySystemId;
                                this.invoiceForm.InvoiceItems[index].SettlementRate = 1;
                            }
                        }

                        //for (var it2 of this.currencys) {
                        //    if (it2.Value == this.invoiceForm.InvoiceItems[index].SettlementCurrencySystemId) {
                        //        this.invoiceForm.InvoiceItems[index].SettlementCurrencySystem = {
                        //            Code: it2.Label,
                        //            CurrencySystemId: it2.Value,
                        //        };
                        //    }
                        //}
                       
                    }


                    if (type == 2) {
                        this.invoiceForm.InvoiceItems[index].IsChangeSettlementCurrencyId = false;
                    }

                    var row = this.invoiceForm.InvoiceItems[index];
                    if (row != null && row.CurrencySystem != null && row.SettlementCurrencySystem != null && (type == 1 || type ==2)) {
                        if (this.invoiceForm.InvoiceItems[index].CurrencySystemId != this.invoiceForm.InvoiceItems[index].SettlementCurrencySystemId) {
                            ParamCurrencySystemAppService.GetExchangeRateByFromByTo(row.SettlementCurrencySystem.Code, row.CurrencySystem.Code).then((r) => {
                                if (r.data.Data) {
                                    row.SettlementRate = Number(r.data.Data);
                                    if (row.Price) {
                                        var settlementPrice = row.Price / row.SettlementRate;
                                        if (settlementPrice != undefined) {
                                            var result = setDecimal(settlementPrice.toString(), 2, false, false);
                                            if (result != "") {
                                                row.SettlementPrice = Number(result);

                                            }
                                            else {
                                                row.SettlementPrice = undefined;
                                            }
                                        }
                                    }

                                }
                            })
                        }
                        else {
                            row.SettlementPrice = row.Price;
                            row.SettlementRate = 1;
                        }
                    }
                    else {
                        if (row != null && row.SettlementRate != null && row.Price != null) {
                            var settlementPrice1 = row.Price / row.SettlementRate;
                            if (settlementPrice1 != undefined) {
                                var result1 = setDecimal(settlementPrice1.toString(), 2, false, false);
                                if (result1 != "") {
                                    row.SettlementPrice = Number(result1);

                                }
                                else {
                                    row.SettlementPrice = undefined;
                                }
                            }
                        }
                    }
                }

               


                this.onInvoiceSum();
            }
        }

        onChangeInvoiceSettlementRate(index: number) {
            if (this.invoiceForm && this.invoiceForm.InvoiceItems && this.invoiceForm.InvoiceItems.length > 0) {
                var price = this.invoiceForm.InvoiceItems[index].SettlementRate;
                if (price != undefined) {
                    var result = setDecimal(price.toString(), 4, false, false);
                    if (result != "") {
                        this.invoiceForm.InvoiceItems[index].SettlementRate = Number(result);

                    }
                    else {
                        this.invoiceForm.InvoiceItems[index].SettlementRate = undefined;
                    }
                }

                var row = this.invoiceForm.InvoiceItems[index];
                if (row.SettlementRate != null && row.Price != null) {
                    var settlementPrice = row.Price / row.SettlementRate;
                    if (settlementPrice != undefined) {
                        var result1 = setDecimal(settlementPrice.toString(), 2, false, false);
                        if (result1 != "") {
                            row.SettlementPrice = Number(result1);

                        }
                        else {
                            row.SettlementPrice = undefined;
                        }
                    }
                }


                this.onInvoiceSum();
            }
   
        }

        oneSettlementFormMode = 0;
        onChangeOneSettlementMode(): void {
            if (this.oneSettlementForm && this.oneSettlementForm.Mode == 10) {
                //if (this.oneSettlementForm.InvoiceId == null || this.oneSettlementForm.InvoiceId == undefined || this.oneSettlementForm.InvoiceId == "") {
                //    this.oneSettlementForm.Mode = 20;
                //    this.oneSettlementFormMode = Math.random();
                //    ElMessage.warning("未添加发票号码，无法选择该选项");
                //    return;
                //}
                console.log(this.oneSettlementForm);
            }
        }
        batchSettlementModeKey = 0;
        onChangeBatchSettlementMode(row: any): void {
            if (row && row.Mode == 10) {
                //if (row.InvoiceId == null || row.InvoiceId == undefined || row.InvoiceId == "") {
                //    row.Mode = 20;
                //    this.batchSettlementModeKey = Math.random();
                //    ElMessage.warning("未添加发票号码，无法选择该选项");
                //    return;
                //}
                console.log(row);
            }
        }

        onBatchSettlementRemark(isBad: any): void {
            if (isBad == true) {
                this.isBad = true;
            }
            else {
                this.isBad = false;
            }
            this.batchSettlementRemarkForm.Remark = undefined;
            this.batchSettlementRemarkForm.Visiable = true;
        }
        onSaveBatchSettlementRemark(): void {

            MiezzForm.customSubmit(this.$refs, "refBatchSettlementRamark", (v) => {
                if (v == true) {
                    if (this.isBad != true) {
                        if (this.batchSettlementForm && this.batchSettlementForm.Items) {
                            for (var it of this.batchSettlementForm.Items) {
                                it.Remark = this.batchSettlementRemarkForm.Remark;
                            }
                        }
                    }
                    else {
                        if (this.batchBadSettlementForm && this.batchBadSettlementForm.Items) {
                            for (var it1 of this.batchBadSettlementForm.Items) {
                                it1.Remark = this.batchSettlementRemarkForm.Remark;
                            }
                        }
                    }
        
                    this.batchSettlementRemarkForm.Remark = undefined;
                    this.batchSettlementRemarkForm.Visiable = false;
                }
            });
        }
        onCloseBatchSettlementRemark(): void {
            this.batchSettlementRemarkForm.Visiable = false;
        }


        onBatchSettlementMode(): void {
            this.batchSettlementModeForm.Visiable = true;
        }
        onSaveBatchSettlementMode(): void {

            MiezzForm.customSubmit(this.$refs, "refBatchSettlementMode", (v) => {
                if (v == true) {
                    if (this.batchSettlementForm && this.batchSettlementForm.Items) {
                        for (var it of this.batchSettlementForm.Items) {
                            it.Mode = this.batchSettlementModeForm.Mode;
                        }
                    }
                    this.batchSettlementModeForm.Mode = undefined;
                    this.batchSettlementModeForm.Visiable = false;
                }
            });
        }
        onCloseBatchSettlementMode(): void {
            this.batchSettlementModeForm.Visiable = false;
        }

        onCheckStatus(row: any): void {
            this.checkStatusForm.BillId = [];
            this.checkStatusForm.BillId.push(row.Id);
            FinanceAppService.GetCheckStatus(this.checkStatusForm).then((r) => {
                if (r.data.Data) {
                    this.checkStatusForm = r.data.Data;
                }
            })
        }
        onBatchCheckStatus(): void {
            this.checkStatusForm.BillId = [];
            this.checkStatusForm.BillId = this.list.SelectIds; 
            FinanceAppService.GetCheckStatus(this.checkStatusForm).then((r) => {
                if (r.data.Data) {
                    this.checkStatusForm = r.data.Data;
                }
            })
        }
        onSaveCheckStatus(): void {
            this.isLoading = true;
            MiezzForm.customSubmit(this.$refs, "refCheckStatusForm", (v) => {
                if (v == true) {
                    FinanceAppService.CheckStatus(this.checkStatusForm).then((r) => {
                        var pa = new DtoRequestInvoice();
                        pa.BillIds = this.checkStatusForm.BillId;
                        FinanceAppService.FillBillDatas(pa).then((r1) => {
                            this.onCloseCheckStatus();
                            this.handleGet();
                            ElMessage.success("设置对账状态成功");
                        })
                    }).catch(() => {
                        this.isLoading = false;
                    });
                }
            });
        }
        onCloseCheckStatus(): void {
            this.checkStatusForm.BillId = [];
            this.checkStatusForm.Status = undefined;
            this.checkStatusForm.Visiable = false;
        }




        onBatchSettlementRate(): void {
            if (this.isBatchSettlementTime == true) {
                this.batchSettlementTimeForm.DisplayRate = "";
                this.batchSettlementTimeForm.RateVisiable = true;
            }
            else {
                ElMessage.warning("待核销/本次核销金额币制不一致，无法批量操作");
                return;
            }
        }
        onSaveBatchSettlementRate(): void {

            MiezzForm.customSubmit(this.$refs, "refbatchSettlementRateForm", (v) => {
                if (v == true) {
                    if (this.batchSettlementForm && this.batchSettlementForm.Items) {
                        var index = 0;
                        for (var it of this.batchSettlementForm.Items) {
                            if (it.CurrencySystemId != it.BillCurrencySystemId) {
                                it.DisplayRate = Number(this.batchSettlementTimeForm.DisplayRate);
                                it.Rate = Number(this.batchSettlementTimeForm.DisplayRate);
                            }
                            this.onChangeBatchRate(index);
                            index++;
                        }
                    }
                    this.onBatchSettlementSum();
                    this.batchSettlementTimeForm.RateVisiable = false;
                }
            });
        }
        onCloseBatchSettlementRate(): void {
            this.batchSettlementTimeForm.RateVisiable = false;
        }
        onChangeBatchSettlementRate(): void {
            if (this.batchSettlementTimeForm && this.batchSettlementTimeForm.DisplayRate) {
                var price = this.batchSettlementTimeForm.DisplayRate;
                if (price != undefined) {
                    var result = setDecimal(price.toString(), 2, false, false);
                    if (result != "") {
                        this.batchSettlementTimeForm.DisplayRate = Number(result) + "";
                    }
                    else {
                        this.batchSettlementTimeForm.DisplayRate = "";
                    }
                }
            }
        }

        onDeleteBatchSettlement(row: any): void {
            if (this.batchSettlementForm && this.batchSettlementForm.Items) {
                var index = -1;
                for (var it of this.batchSettlementForm.Items) {
                    index++;
                    if (it.BillId == row.BillId) {
                        break;
                    }
                }
                if (index >= 0) {
                    this.batchSettlementForm.Items.splice(index, 1);
                }
            }
            this.onBatchSettlementSum();
        }

        isBad = false;
        onBatchSettlementTime(isBad: any): void {
            if (isBad == true) {
                this.batchSettlementTimeForm.SettlementTime = undefined;
                this.batchSettlementTimeForm.Visiable = true;
                this.isBad = true;
            }
            else {
                this.batchSettlementTimeForm.SettlementTime = undefined;
                this.batchSettlementTimeForm.Visiable = true;
                this.isBad = false;
            }

        }
        onSaveBatchSettlementTime(): void {

            MiezzForm.customSubmit(this.$refs, "refbatchSettlementTimeForm", (v) => {
                if (v == true) {
                    if (this.isBad == true) {
                        if (this.batchBadSettlementForm && this.batchBadSettlementForm.Items) {
                            for (var it1 of this.batchBadSettlementForm.Items) {
                                it1.SettlementDate = this.batchSettlementTimeForm.SettlementTime;
                            }
                        }
                    }
                    else {
                        if (this.batchSettlementForm && this.batchSettlementForm.Items) {
                            for (var it of this.batchSettlementForm.Items) {
                                it.SettlementDate = this.batchSettlementTimeForm.SettlementTime;
                            }
                        }
                    }

                    this.batchSettlementTimeForm.Visiable = false;
                }
            });
        }
        onCloseBatchSettlementTime(): void {
            this.batchSettlementTimeForm.Visiable = false;
        }


        onBatchSettlementTitle(type: number): void {
            this.batchSettlementTitleForm.Type = type;
            this.batchSettlementTitleForm.Title = undefined;
            this.batchSettlementTitleForm.Visiable = true;
        }
        onSaveBatchSettlementTitle(): void {

            MiezzForm.customSubmit(this.$refs, "refbatchSettlementTitleForm", (v) => {
                if (v == true) {
                    if (this.batchSettlementForm && this.batchSettlementForm.Items) {
                        for (var it of this.batchSettlementForm.Items) {
                            if (this.batchSettlementTitleForm.Type == 0) {
                                it.CollectionTitle = this.batchSettlementTitleForm.Title;
                            }
                            else {
                                it.PaymentTitle = this.batchSettlementTitleForm.Title;
                            }
                        }
                    }
                    this.batchSettlementTitleForm.Visiable = false;
                }
            });
        }
        onCloseBatchSettlementTitle(): void {
            this.batchSettlementTitleForm.Visiable = false;
        }



        onChangeInvoiceId(): void {
            MiezzForm.validateFieldCustom(this.$refs, "refSetinvoiceForm", "InvoiceId");
        }
        onSaveSetInvoiceForm(): void {
            MiezzForm.customSubmit(this.$refs, "refSetinvoiceForm", (v) => {
                if (v == true) {
                    this.isLoading = true;
                    FinanceAppService.SetInvoice(this.setinvoiceForm).then((r) => {
                        var pa = new DtoRequestInvoice();
                        pa.BillIds = this.setinvoiceForm.BillId;
                        FinanceAppService.FillBillDatas(pa).then((r1) => {
                            this.isLoading = false;
                            ElMessage.success("关联成功");
                            this.handleGet();
                            this.onCloseSetInvoiceForm();
                        })
                    })
                }
            });

        }
        onCloseSetInvoiceForm(): void {
            this.setinvoiceForm.InvoiceId = undefined;
            this.setinvoiceForm.BillId = [];
            this.setinvoiceForm.Visiable = false;
        }

        onChangeBatchSettlementPrice(row: any) {
            if (row) {

                var price = row.Price;
                if (price != undefined) {
                    var result = setDecimal(price.toString(), 2, false, true);
                    if (result != "") {
                        row.Price = Number(result);

                    }
                    else {
                        row.Price = undefined;
                    }
                    row.ConvertPrice = undefined;
                    if (row.NoSettlementPrice && row.Price && row.Rate) {
                        price = row.Price / row.Rate;
                        result = setDecimal(price.toString(), 2, false, true);
                        if (result != "") {
                            row.ConvertPrice = Number(result);

                        }
                        if (row.ConvertPrice) {
                            row.SurplusPrice = row.NoSettlementPrice - row.ConvertPrice;
                        }
                    }



                    price = row.SurplusPrice;
                    if (price != undefined) {
                        result = setDecimal(price.toString(), 2, true, true);
                        if (result != "") {
                            row.SurplusPrice = Number(result);
                        }
                        else {
                            row.SurplusPrice = undefined;
                        }
                    }
                }
                else {
                    if (row.SurplusPrice) {
                        row.SurplusPrice = row.SurplusPrice + row.ConvertPrice;
                    }
                }
            }
            this.onBatchSettlementSum();
        }

        onChangePrice() {
            this.oneSettlementForm.SurplusPrice = this.oneSettlementFormInit.SurplusPrice;
            if (this.oneSettlementForm) {
                var price = this.oneSettlementForm.Price;
                if (price != undefined) {
                    var result = setDecimal(price.toString(), 2, false, true);
                    if (result != "") {
                        this.oneSettlementForm.Price = Number(result);

                    }
                    else {
                        this.oneSettlementForm.Price = undefined;
                    }

                    this.oneSettlementForm.ConvertPrice = undefined;
                    if (this.oneSettlementForm.NoSettlementPrice && this.oneSettlementForm.Price && this.oneSettlementForm.Rate) {
                        price = this.oneSettlementForm.Price / this.oneSettlementForm.Rate;
                        result = setDecimal(price.toString(), 2, false, true);
                        if (result != "") {
                            this.oneSettlementForm.ConvertPrice = Number(result);

                        }
                        if (this.oneSettlementForm.ConvertPrice) {
                            this.oneSettlementForm.SurplusPrice = this.oneSettlementForm.NoSettlementPrice - this.oneSettlementForm.ConvertPrice;
                        }
                    }

                    //if (this.oneSettlementForm && this.oneSettlementForm.SurplusPrice && this.oneSettlementForm.SurplusPrice < 0) {
                    //    this.oneSettlementForm.SurplusPrice = 0;
                    //    this.oneSettlementForm.Price = this.oneSettlementForm.NoSettlementPrice;
                    //}



                    price = this.oneSettlementForm.SurplusPrice;
                    if (price != undefined) {
                        result = setDecimal(price.toString(), 2, true, true);
                        if (result != "") {
                            this.oneSettlementForm.SurplusPrice = Number(result);
                        }
                        else {
                            this.oneSettlementForm.SurplusPrice = undefined;
                        }
                    }
                }
                else {
                    if (this.oneSettlementForm.SurplusPrice && this.oneSettlementFormInit.ConvertPrice) {
                        this.oneSettlementForm.SurplusPrice = this.oneSettlementForm.SurplusPrice + this.oneSettlementFormInit.ConvertPrice;

                        price = this.oneSettlementForm.SurplusPrice;
                        result = setDecimal(price.toString(), 2, true, true);
                        if (result != "") {
                            this.oneSettlementForm.SurplusPrice = Number(result);
                        }
                        else {
                            this.oneSettlementForm.SurplusPrice = undefined;
                        }
                    }
                }
            }
        }
        onDeleteSettlement(index: number): void {
            if (this.settlementForm && this.settlementForm.Items) {
                if (this.settlementForm.NoSettlementPrice == null || this.settlementForm.NoSettlementPrice == undefined) {
                    this.settlementForm.NoSettlementPrice = 0;
                }
                var price = this.settlementForm.Items[index].ConvertPrice;
                if (price) {
                    this.settlementForm.NoSettlementPrice = this.settlementForm.NoSettlementPrice + price;
                    if (this.settlementForm.NoSettlementPrice) {
                        this.settlementForm.NoSettlementPrice = Number(this.settlementForm.NoSettlementPrice.toRound(4));
                    }
                }

                this.settlementForm.Items.splice(index, 1);
            }
            this.onSettlementSum();
        }

        onCurrency(currencyId: string): void {
            const Currency = this.$refs["Currency"] as any;
            Currency.onInit(currencyId);
        }
        onSelectCurrency(row: any): void {
            if (this.currencyIndex == "") {
                if (this.oneSettlementForm) {
                    this.oneSettlementForm.CurrencySystemId = row.Value;
                    this.oneSettlementForm.CurrencySystemName = row.Data.Code;
                    this.oneSettlementForm.CurrencySystem = row.Data;
                    this.oneSettlementForm.SurplusPrice = this.oneSettlementFormInit.SurplusPrice;
                    //MiezzForm.clearValidate(this.$refs, "ruleForm", "CurrencySystemId");
                    if (row.Value != this.oneSettlementForm.BillCurrencySystemId) {

                        ParamCurrencySystemAppService.GetExchangeRateByFromByTo(this.oneSettlementForm.BillCurrencySystemCode, row.Data.Code).then((r) => {
                            if (this.oneSettlementForm) {
                                var rate = r.data.Data;
                                if (rate != undefined && rate != "") {
                                    this.oneSettlementForm.DisplayRate = Number(rate);
                                }
                                else {
                                    this.oneSettlementForm.DisplayRate = undefined;
                                }

                                if (this.oneSettlementForm.DisplayRate) {
                                    this.oneSettlementForm.Rate = 1 * this.oneSettlementForm.DisplayRate;
                                }

                                this.oneSettlementForm.ConvertPrice = undefined;
                                if (this.oneSettlementForm.SurplusPrice && this.oneSettlementForm.Price && this.oneSettlementForm.Rate) {
                                    price = this.oneSettlementForm.Price * this.oneSettlementForm.Rate;
                                    result = setDecimal(price.toString(), 2, false, false);
                                    if (result != "") {
                                        this.oneSettlementForm.ConvertPrice = Number(result);

                                    }
                                    if (this.oneSettlementForm.ConvertPrice) {
                                        this.oneSettlementForm.SurplusPrice = this.oneSettlementForm.SurplusPrice - this.oneSettlementForm.ConvertPrice;
                                    }
                                }

                                var price = this.oneSettlementForm.SurplusPrice;
                                if (price != undefined) {
                                    var result = setDecimal(price.toString(), 2, true, true);
                                    if (result != "") {
                                        this.oneSettlementForm.SurplusPrice = Number(result);
                                    }
                                    else {
                                        this.oneSettlementForm.SurplusPrice = undefined;
                                    }
                                }
                            }
                            this.$nextTick(() => {
                                MiezzForm.validateFieldCustom(this.$refs, "refOneSettlementForm", "Price");
                            });
                        })
                    }
                    else {
                        this.oneSettlementForm.Rate = 1;
                        if (this.oneSettlementForm.SurplusPrice && this.oneSettlementForm.Price) {
                            this.oneSettlementForm.SurplusPrice = this.oneSettlementForm.SurplusPrice - this.oneSettlementForm.Price;
                        }
                        this.$nextTick(() => {
                            MiezzForm.validateFieldCustom(this.$refs, "refOneSettlementForm", "Price");
                        });
                    }
                }
            }
            else {
                var row1 = new DtoRequestBatchSettlementItem();
                var index = 0;
                if (this.batchSettlementForm && this.batchSettlementForm.Items) {
                    for (var b of this.batchSettlementForm.Items) {
                        if (b.BillId == this.currencyIndex) {
                            row1 = b;
                            break;
                        }
                        index++;
                    }
                }

                if (row1) {
                    row1.CurrencySystemId = row.Value;
                    row1.CurrencySystemName = row.Data.Code;
                    row1.CurrencySystem = row.Data;
                    if (row.Value != row1.BillCurrencySystemId) {

                        ParamCurrencySystemAppService.GetExchangeRateByFromByTo(row1.BillCurrencySystemCode, row.Data.Code).then((r) => {
                            if (row1) {
                                var rate = r.data.Data;
                                if (rate != undefined && rate != "") {
                                    row1.DisplayRate = Number(rate);
                                }
                                else {
                                    row1.DisplayRate = undefined;
                                }

                                if (row1.DisplayRate) {
                                    row1.Rate = 1 * row1.DisplayRate;
                                }

                                row1.ConvertPrice = undefined;
                                if (row1.SurplusPrice && row1.Price && row1.Rate) {
                                    price = row1.Price * row1.Rate;
                                    result = setDecimal(price.toString(), 2, false, false);
                                    if (result != "") {
                                        row1.ConvertPrice = Number(result);

                                    }
                                    if (row1.ConvertPrice) {
                                        row1.SurplusPrice = row1.SurplusPrice - row1.ConvertPrice;
                                    }
                                }

                                var price = row1.SurplusPrice;
                                if (price != undefined) {
                                    var result = setDecimal(price.toString(), 2, true, true);
                                    if (result != "") {
                                        row1.SurplusPrice = Number(result);
                                    }
                                    else {
                                        row1.SurplusPrice = undefined;
                                    }
                                }
                            }
                            this.$nextTick(() => {
                                var name = 'Items.' + index + '.Price';
                                MiezzForm.validateFieldCustom(this.$refs, "refBatchSettlementForm", name);
                            });
                            this.onBatchSettlementSum();
                        })

                    }
                    else {
                        row1.Rate = 1;
                        row1.DisplayRate = 1;
                        if (row1.SurplusPrice && row1.Price) {
                            row1.SurplusPrice = row1.SurplusPrice - row1.Price;
                        }
                        this.$nextTick(() => {
                            var name = 'Items.' + index + '.Price';
                            MiezzForm.validateFieldCustom(this.$refs, "refBatchSettlementForm", name);
                        });
                        this.onBatchSettlementSum();
                    }
                }
            }
            this.currencyIndex = "";

            this.isBatchSettlementTime = true;
            var typeBatchSettlement = "";
            if (this.batchSettlementForm && this.batchSettlementForm.Items) {
                var currecnyNumber = 0;
                var currencySystemId = "";
                for (var billBatchSettlement of this.batchSettlementForm.Items) {
                    var numberBatchSettlement = billBatchSettlement.TypeString + "";
                    if (typeBatchSettlement != numberBatchSettlement) {
                        typeBatchSettlement = numberBatchSettlement;
                    }
                    if (billBatchSettlement.CurrencySystemId != currencySystemId) {
                        currecnyNumber++;
                        currencySystemId = billBatchSettlement.CurrencySystemId + "";
                    }
                }
                if (currecnyNumber > 1) {
                    this.isBatchSettlementTime = false;
                }
                else {
                    currencySystemId = "";
                    for (var billBatchSettlement1 of this.batchSettlementForm.Items) {
                        if (billBatchSettlement1.BillCurrencySystemCode != currencySystemId) {
                            currecnyNumber++;
                            currencySystemId = billBatchSettlement1.BillCurrencySystemCode + "";
                        }
                    }

                    if (currecnyNumber > 1) {
                        this.isBatchSettlementTime = false;
                    }
                }
            }
        }

        onChangeRate(): void {
            var price = this.oneSettlementForm.DisplayRate;
            if (price != undefined) {
                var result = setDecimal(price.toString(), 4, false, false);
                if (result != "") {
                    this.oneSettlementForm.DisplayRate = Number(result);
                }
                else {
                    this.oneSettlementForm.DisplayRate = undefined;
                }
            }
            if (this.oneSettlementForm && this.oneSettlementForm.DisplayRate) {
                this.oneSettlementForm.Rate = 1 * this.oneSettlementForm.DisplayRate;

                var result1 = setDecimal(this.oneSettlementForm.Rate.toString(), 4, false, false);
                if (result1 != "") {
                    this.oneSettlementForm.Rate = Number(result1);
                }
                else {
                    this.oneSettlementForm.Rate = undefined;
                }
            }
            this.$nextTick(() => {
                MiezzForm.validateFieldCustom(this.$refs, "refOneSettlementForm", "Price");
            });
        }

        onChangeBatchRate(index: number): void {
            if (this.batchSettlementForm && this.batchSettlementForm.Items) {
                var price = this.batchSettlementForm.Items[index].DisplayRate;
                if (price != undefined) {
                    var result = setDecimal(price.toString(), 4, false, false);
                    if (result != "") {
                        this.batchSettlementForm.Items[index].DisplayRate = Number(result);
                    }
                    else {
                        this.batchSettlementForm.Items[index].DisplayRate = undefined;
                    }
                }

                if (this.batchSettlementForm.Items && this.batchSettlementForm.Items[index].DisplayRate) {
                    var display = this.batchSettlementForm.Items[index].DisplayRate;
                    if (display) {
                        this.batchSettlementForm.Items[index].Rate = 1 * display;
                        var rate = this.batchSettlementForm.Items[index].Rate;
                        if (rate) {
                            var result1 = setDecimal(rate.toString(), 4, false, false);
                            if (result1 != "") {
                                this.batchSettlementForm.Items[index].Rate = Number(result1);
                            }
                            else {
                                this.batchSettlementForm.Items[index].Rate = undefined;
                            }
                        }
                    }

                }


                this.$nextTick(() => {
                    var name = 'Items.' + index + '.Price';
                    MiezzForm.validateFieldCustom(this.$refs, "refBatchSettlementForm", name);
                });

                this.onBatchSettlementSum();
            }

        }

        onBatchSettlementCurrency(row: any): void {
            this.currencyIndex = row.BillId;
            const Currency = this.$refs["Currency"] as any;
            Currency.onInit(row.CurrencySystemId);
        }

        //访问委托书摘要的权限
        OrderManagement_Pool_Summary = false;
        //访问订单摘要的权限
        OrderManagement_Order_Summary = false;

        /*是否具有发票信息的权限 */
        FinancialManagement_BillSummary_InvoiceInformation = false;
        /*是否具有核销的权限 */
        FinancialManagement_BillSummary_Writeoff = false;
        /*是否具有坏账核销权限 */
        FinancialManagement_BillSummary_BadDebtWriteOff = false;

        /*是否具有修改记账起始时间的权限 */
        FinancialManagement_BillSummary_BookkeepingStartTime = false;
        /*是否具有修改账期的权限 */
        FinancialManagement_BillSummary_AccountingPeriodremainingDays = false;
        /*是否具有修改财务周期的权限 */
        FinancialManagement_BillSummary_FinancialCycle = false;
        //未提交账单添加发票/核销
        FinancialManagement_BillSummary_AddInvoicewriteOffForUnpaidBills = false;

        onChild(row: any): void {
            row.IsOpen = !row.IsOpen;
            (this.$refs["$FinanceTable"] as any).onChild(row);
        }

        /**创建时 */
        created(): void {
            this.client = AliyunOSS.GetClient();

            if (this.types) {
                this.types.push({
                    Label: "应收账单",
                    Value: "10",
                });

                this.types.push({
                    Label: "应付账单",
                    Value: "20",
                });

                //this.types.push({
                //    Label: "业务员结算账单",
                //    Value: "30",
                //});
            }
            if (this.searchSettlements) {
                this.searchSettlements.push({
                    Label: "待核销",
                    Value: "10",
                });


                this.searchSettlements.push({
                    Label: "已核销",
                    Value: "30",
                });

                this.searchSettlements.push({
                    Label: "部分核销",
                    Value: "20",
                });

                this.searchSettlements.push({
                    Label: "坏账",
                    Value: "999",
                });

            }

            if(this.EnumInvoiceStatusOption){
                for(var it of this.EnumInvoiceStatusOption){
                    it.disabled = false;
                }
            }
            //列表-传参
            this.historys.Dto = new DtoPageListSearchBillHistory();
            //列表-动态搜索-项
            this.historys.DynamicSearchItems = DtoPageListSearchBillHistoryDynamicSearchItems;
            //列表-数据-初始
            this.historys.Data = new DtoPageListBillHistory();


            this.FinancialManagement_BillSummary_InvoiceInformation = false;
            this.FinancialManagement_BillSummary_Writeoff = false;
            this.FinancialManagement_BillSummary_BadDebtWriteOff = false;
            CurrentLogier.GetPowerCodes().then((r) => {
                if (r?.contains("FinancialManagement_BillSummary_InvoiceInformation")) {
                    this.FinancialManagement_BillSummary_InvoiceInformation = true;
                    if (this.invoiceBottomButtons) {
                        this.invoiceBottomButtons[0].Hide = false;
                    }
                }
                if (r?.contains("FinancialManagement_BillSummary_Writeoff")) {
                    this.FinancialManagement_BillSummary_Writeoff = true;
                    if (this.settlementBottomButtons) {
                        this.settlementBottomButtons[0].Hide = false;
                    }
                }

                if (r?.contains("FinancialManagement_BillSummary_BadDebtWriteOff")) {
                    this.FinancialManagement_BillSummary_BadDebtWriteOff = true;
                    if (this.settlementBottomButtons) {
                        this.settlementBottomButtons[0].Hide = false;
                    }
                }

                if (r?.contains("FinancialManagement_BillSummary_BookkeepingStartTime")) {
                    this.FinancialManagement_BillSummary_BookkeepingStartTime = true;
                }

                if (r?.contains("FinancialManagement_BillSummary_AccountingPeriodremainingDays")) {
                    this.FinancialManagement_BillSummary_AccountingPeriodremainingDays = true;
                }

                if (r?.contains("FinancialManagement_BillSummary_FinancialCycle")) {
                    this.FinancialManagement_BillSummary_FinancialCycle = true;
                }
                if (r?.contains("FinancialManagement_BillSummary_AddInvoicewriteOffForUnpaidBills")) {
                    this.FinancialManagement_BillSummary_AddInvoicewriteOffForUnpaidBills = true;
                }
            });

            CompanyAppService.GetSources().then((r) => {
                this.companySources = r.data.Data ?? [];
            });

            FinanceAppService.GetContainer().then((r) => {
                this.containers = r.data.Data ?? [];
            });

            FinanceAppService.GetBLNO().then((r) => {
                this.blnos = r.data.Data ?? [];
            });

            FinanceAppService.GetLoadingPorts().then((r) => {
                this.loadingPorts = r.data.Data ?? [];
            });

            FinanceAppService.GetDeliveryPorts().then((r) => {
                this.deliveryPorts = r.data.Data ?? [];
            });

            FinanceAppService.GetDeliveryPlaces().then((r) => {
                this.deliveryPlaces = r.data.Data ?? [];
            });
            FinanceAppService.GetServiceRemarks().then((r) => {
                this.serviceRemarks = r.data.Data ?? [];
            });
            FinanceAppService.GetCustomerServices().then((r) => {
                this.customerServices = r.data.Data ?? [];
            });
            FinanceAppService.GetPoolCustomers().then((r) => {
                this.poolCustomers = r.data.Data ?? [];
            });
            FinanceAppService.GetObject().then((r) => {
                this.billObjects = r.data.Data ?? [];
            });
            FinanceAppService.GetSalers().then((r) => {
                this.salers = r.data.Data ?? [];
            });
            //FinanceAppService.GetCustomer().then((r) => {
            //    this.clients = r.data.Data ?? [];
            //});
            OrderPoolAppService.GetClientUsers().then((r) => {
                this.clients = r.data.Data ?? [];
            });
            FinanceAppService.GetMaker().then((r) => {
                this.makers = r.data.Data ?? [];
            });
            ParamCurrencySystemAppService.GetSelect().then(currency => {
                if (currency.data.Data) this.currencys = currency.data.Data;
            });

            ParamPriceCostAppService.GetSelect().then((cost) => {
                if (cost.data.Data) this.costs = cost.data.Data;
            })


            //列表-操作区域宽度
            //this.list.OperationWidth = "100px";
            //列表-传参
            this.list.Dto = new DtoPageListSearchFinance();
            //列表-动态搜索-项
            this.list.DynamicSearchItems = DtoPageListSearchFinanceDynamicSearchItems;
            //列表-数据-初始
            this.list.Data = new DtoPageListFinance();
            //列表-列配置
            //this.list.Items = DtoListItemFinanceMiezzListItems;
            //列表-列配置
            //this.list.Items = DtoListItemProductMiezzListItems;
            this.list.Items = DtoListItemFinanceMiezzListItems?.map((it) => {
                const item = Object.copy(it);
                if (item.Prop == "AccountPeriod") {
                    item.Width = 90;
                }
                if (item.Prop == "BookingNo") {
                    item.Width = 80;
                }
                if (item.Prop == "TypeString") {
                    item.Width = 40;
                }
                if (item.Prop == "CurrencySystemId") {
                    item.Width = 40;
                }
                return item;
            });


            this.list.HandleSelectable = (row) => {
                if (row.FromId != undefined && row.FromId != null) {
                    return false;
                }
                return true;
            };
            this.OrderManagement_Pool_Summary = false;
            this.OrderManagement_Order_Summary = false;
            CurrentLogier.GetPowerCodes().then((r) => {
                if (r?.contains("OrderManagement_Pool_Summary")) {
                    this.OrderManagement_Pool_Summary = true;
                }
                if (r?.contains("OrderManagement_Order_Summary")) {
                    this.OrderManagement_Order_Summary = true;
                }
            });
            //列表-顶部按钮
            //this.list.TopButtons = [
            //    {
            //        IsDropdown: true,
            //        Code: "add",
            //        Label: "添加账单",
            //        Icon: "plus",
            //        Type: "primary",
            //        Size: "small",
            //        PowerCode: "FinancialManagement_BillSummary_AddTo",
            //        Options: [
            //            {
            //                Code: EnumBillType.Collection.toString(),
            //                Label: "应收账单",
            //                Size: "small",
            //                Type: "primary",
            //            },
            //            {
            //                Code: EnumBillType.Payment.toString(),
            //                Label: "应付账单",
            //                Size: "small",
            //                Type: "primary",
            //            }
            //        ],
            //    },
            //];

            //this.list.DefaultTip = "<div style='font-size:14px;line-height:40px;font-weight:900; margin-top:-10px;'>应收合计：<span style='color:red;'>10000</span>   应付合计：2134</div>";
            ////列表-行按钮
            //this.list.RowButtons = [
            //    {
            //        Code: "detail",
            //        Title: "查看详细",
            //        Icon: "document",
            //        Type: "default",
            //        Size: "small",
            //    },
            //];
            //列表-底部按钮
            this.list.BottomButtons = [
                {
                    Code: "batch-edit",
                    Label: "编辑",
                    Icon: "Edit",
                    Type: "text",
                    Size: "small",
                    PowerCode: "FinancialManagement_BillSummary_BatchEditing",
                },
                {
                    Code: "invoice",
                    Label: "添加/编辑发票",
                    Icon: "Edit",
                    Type: "text",
                    Size: "small",
                    PowerCode: "FinancialManagement_BillSummary_InvoiceInformation",
                },
                {
                    Code: "batch-settlement",
                    Label: "核销",
                    Icon: "Edit",
                    Type: "text",
                    Size: "small",
                    PowerCode: "FinancialManagement_BillSummary_Writeoff",
                },
                {
                    Code: "batch-badSettlement",
                    Label: "坏账核销",
                    Icon: "Edit",
                    Type: "text",
                    Size: "small",
                    PowerCode: "FinancialManagement_BillSummary_BadDebtWriteOff",
                },
                {
                    Code: "removeSettlement",
                    Label: "取消核销",
                    Icon: "delete",
                    Type: "text",
                    Size: "small",
                    PowerCode: "FinancialManagement_BillSummary_CancelWriteOff",
                },
                {
                    Code: "removeInvoice",
                    Label: "删除发票",
                    Icon: "delete",
                    Type: "text",
                    Size: "small",
                    PowerCode: "FinancialManagement_BillSummary_InvoiceInformation",
                },
                {
                    Code: "batch-delete",
                    Label: "删除",
                    Icon: "delete",
                    Type: "text",
                    /*Message: "是否确认删除",*/
                    MessageBox: true,
                    Size: "small",
                    PowerCode: "FinancialManagement_BillSummary_Delete"
                },
                {
                    Code: "batch-checkStatus",
                    Label: "对账状态",
                    Icon: "Edit",
                    Type: "text",
                    MessageBox: true,
                    Size: "small",
                    PowerCode: "FinancialManagement_BillSummary_Writeoff"
                },
                {
                    Code: "batch-startDate",
                    Label: "记账起始时间",
                    Icon: "Edit",
                    Type: "text",
                    Size: "small",
                    PowerCode: "FinancialManagement_BillSummary_BookkeepingStartTime",
                },
                {
                    Code: "batch-accountPeriod",
                    Label: "账期",
                    Icon: "Edit",
                    Type: "text",
                    Size: "small",
                    PowerCode: "FinancialManagement_BillSummary_AccountingPeriodremainingDays",
                },
                {
                    Code: "batch-financialCycle",
                    Label: "财务周期",
                    Icon: "Edit",
                    Type: "text",
                    Size: "small",
                    PowerCode: "FinancialManagement_BillSummary_FinancialCycle",
                },
                //{
                //    Code: "export",
                //    Label: "导出",
                //    Icon: "download",
                //    Type: "text",
                //    MessageBox: true,
                //    Size: "small",
                //},
                {
                    IsDropdown: true,
                    Code: "export",
                    Label: "导出",
                    Icon: "download",
                    Type: "text",
                    Size: "small",
                    Hide: false,
                    Options: [
                        {
                            Code: "1",
                            Label: "委托书应收",
                            Size: "small",
                            Hide: false,
                        },
                        {
                            Code: "10",
                            Label: "不显示利润",
                            Size: "small",
                            Hide: false,
                        },
                        {
                            Code: "20",
                            Label: "显示利润",
                            Size: "small",
                            Hide: false,
                        },
                    ],
                },
                {
                    Code: "stat",
                    Label: "统计",
                    Icon: "Histogram",
                    Type: "text",
                    MessageBox: true,
                    Size: "small",
                },
            ];
        }
        handleClickBatchBadSettlement(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":

                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    if (this.batchBadSettlementForm && this.batchBadSettlementForm.BillIds) {
                        this.lockParam.BillId = this.batchBadSettlementForm.BillIds;
                    }
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("包含已锁定的订单，请重新选择");
                            complete();
                            return;
                        }
                        else {


                            MiezzForm.customSubmit(this.$refs, "refBatchBadSettlementForm", (v) => {
                                if (v == true) {
                                    this.isLoading = true;
                                    this.batchBadSettlementForm.IsBad = true;
                                    FinanceAppService.SaveBatchSettlement(this.batchBadSettlementForm).then((r) => {
                                        var pa = new DtoRequestInvoice();
                                        pa.BillIds = this.batchBadSettlementForm.BillIds;
                                        FinanceAppService.FillBillDatas(pa).then((r1) => {
                                            this.isLoading = false;
                                            ElMessage.success("核销成功");

                                            this.batchBadSettlementForm.Visiable = false;
                                            this.handleGet();
                                            complete();
                                        })

                                    }).catch(() => {
                                        this.isLoading = false;
                                        complete();
                                    });
                                }
                                else {
                                    complete();
                                }
                            });
                        }
                    })
                    break;
                case "close":
                    this.isLock = false;
                    this.batchBadSettlementForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }


        handleClickBatchSettlement(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":

                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    if (this.batchSettlementForm && this.batchSettlementForm.BillIds) {
                        //for (var settlement of this.batchSettlementForm.Items) {
                        //    this.lockParam.BillId.push(settlement.BillId as string);
                        //}
                        this.lockParam.BillId = this.batchSettlementForm.BillIds;
                    }
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("包含已锁定的订单，请重新选择");
                            complete();
                            return;
                        }
                        else {

                            

                            MiezzForm.customSubmit(this.$refs, "refBatchSettlementForm", (v) => {
                                if (v == true) {
                                    this.isLoading = true;
                                    FinanceAppService.SaveBatchSettlement(this.batchSettlementForm).then((r) => {
                                        var pa = new DtoRequestInvoice();
                                        pa.BillIds = this.batchSettlementForm.BillIds;
                                        FinanceAppService.FillBillDatas(pa).then((r1) => {
                                            this.isLoading = false;
                                            if (this.batchSettlementForm.IsNumber == true) {
                                                ElMessage.warning("核销成功，您所勾选的信息同时包含正值与负值，部分信息无法核销，需要再次进行核销操作。");
                                            }
                                            else {
                                                ElMessage.success("核销成功");
                                            }

                                            this.batchSettlementForm.Visiable = false;
                                            this.handleGet();
                                            complete();
                                        })
                                    }).catch(() => {
                                        this.isLoading = false;
                                        complete();
                                    });
                                }
                                else {
                                    complete();
                                }
                            });
                        }
                    })
                    break;
                case "all":

                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    if (this.batchSettlementForm && this.batchSettlementForm.Items) {
                        for (var settlement1 of this.batchSettlementForm.Items) {
                            this.lockParam.BillId.push(settlement1.BillId as string);
                        }
                    }
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("包含已锁定的订单，请重新选择");
                            complete();
                            return;
                        }
                        else {
                            MiezzForm.customSubmit(this.$refs, "refBatchSettlementForm", (v) => {
                                if (v == true) {
                                    this.isLoading = true;
                                    this.batchSettlementForm.IsAll = true;
                                    FinanceAppService.SaveBatchSettlement(this.batchSettlementForm).then((r) => {
                                        var pa = new DtoRequestInvoice();
                                        pa.BillIds = this.batchSettlementForm.BillIds;
                                        FinanceAppService.FillBillDatas(pa).then((r1) => {
                                            this.isLoading = false;
                                            ElMessage.success("核销成功");
                                            this.batchSettlementForm.Visiable = false;
                                            this.batchSettlementForm.IsAll = false;
                                            this.handleGet();
                                            complete();
                                        })
                                    }).catch(() => {
                                        this.isLoading = false;
                                        complete();
                                    });
                                }
                                else {
                                    complete();
                                }
                            });
                        }
                    })
                    break;
                case "import":

                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    if (this.batchSettlementForm && this.batchSettlementForm.Items) {
                        for (var settlement2 of this.batchSettlementForm.Items) {
                            this.lockParam.BillId.push(settlement2.BillId as string);
                        }
                    }
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("包含已锁定的订单，请重新选择");
                            complete();
                            return;
                        }
                        else {

                            if (this.batchSettlementForm && this.batchSettlementForm.Items) {
                                var index = 0;
                                for (var it of this.batchSettlementForm.Items) {
                                    //if(it.NoSettlementPrice != 0){ 
                                    //    it.Price = it.NoSettlementPrice;
                                    //}
                                    it.Price = it.NoSettlementPrice;
                                    if (it.Price) {
                                        it.Price = Number(setDecimal(it.Price.toString(), 2, false, true));
                                    }
                                    it.SurplusPrice = 0;
                                    it.CurrencySystemId = it.BillCurrencySystemId;
                                    it.Rate = 1;
                                    it.CurrencySystem = it.BillCurrencySystem;
                                    it.ConvertPrice = it.SurplusPrice;
                                    if (it.SettlementDate == null || it.SettlementDate == undefined) {
                                        it.SettlementDate = this.batchSettlementForm.CurrentTime;
                                    }

                                    if (it.CollectionTitle == null || it.CollectionTitle == undefined || it.CollectionTitle == "") {
                                        it.CollectionTitle = it.DefaultCollectionTitle;
                                    }
                                    if (it.PaymentTitle == null || it.PaymentTitle == undefined || it.PaymentTitle == "") {
                                        it.PaymentTitle = it.DefaultPaymentTitle;
                                    }

                                    var name = "";
                                    if (it.Price != null && it.Price != undefined) {
                                        name = 'Items.' + index + '.Price';
                                        MiezzForm.clearCustomValidate(this.$refs, "refBatchSettlementForm", name);
                                    }
                                    if (it.SettlementDate != null && it.SettlementDate != undefined) {
                                        name = 'Items.' + index + '.SettlementDate';
                                        MiezzForm.clearCustomValidate(this.$refs, "refBatchSettlementForm", name);
                                    }
                                    if (it.Rate != null && it.Rate != undefined) {
                                        name = 'Items.' + index + '.Rate';
                                        MiezzForm.clearCustomValidate(this.$refs, "refBatchSettlementForm", name);
                                    }
                                    if (it.CollectionTitle != null && it.CollectionTitle != undefined) {
                                        name = 'Items.' + index + '.CollectionTitle';
                                        MiezzForm.clearCustomValidate(this.$refs, "refBatchSettlementForm", name);
                                    }
                                    if (it.PaymentTitle != null && it.PaymentTitle != undefined) {
                                        name = 'Items.' + index + '.PaymentTitle';
                                        MiezzForm.clearCustomValidate(this.$refs, "refBatchSettlementForm", name);
                                    }

                                    index++;
                                }
                            }
                            this.onBatchSettlementSum();
                            complete();
                        }
                    })

                    break;
                case "partSettlement":
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一条信息");
                        complete();
                        return;
                    }
                    this.partSettlementForm = new DtoCallbackPartSettlement();
                    this.partSettlementForm.BillId = this.list.SelectIds;
                    this.partSettlementForm.IsInit = true;
                    FinanceAppService.PartSettlement(this.partSettlementForm).then((r) => {
                        var pa = new DtoRequestInvoice();
                        pa.BillIds = this.partSettlementForm.BillId;
                        FinanceAppService.FillBillDatas(pa).then((r1) => {
                            if (r.data.Data) {
                                this.partSettlementForm = r.data.Data;
                                this.partSettlementForm.IsInit = false;
                            }
                            complete();
                        })
                    })
                    complete();
                    break;
                case "close":
                    this.isLock = false;
                    this.batchSettlementForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        onChangePartSettlementCurrency(index: number): void {

            if (this.partSettlementForm && this.partSettlementForm.Item) {
                var row1 = this.partSettlementForm.Item[index];
                var code = row1.SettlementCurrencySystemCode;
                if (this.currencys) {
                    for (var cu of this.currencys) {
                        if (cu.Value == row1.CurrencySystemId) {
                            code = cu.Label;
                        }
                    }
                }

                ParamCurrencySystemAppService.GetExchangeRateByFromByTo(row1.SettlementCurrencySystemCode, code).then((r) => {
                    if (r.data.Data) {
                        row1.Rate = r.data.Data;
                    }
                })
            }
        }

        handleClickPartSettlement(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "save":

                    MiezzForm.customSubmit(this.$refs, "refPartSettlementForm", (v) => {
                        if (v == true) {
                            FinanceAppService.PartSettlement(this.partSettlementForm).then((r) => {
                                var pa = new DtoRequestInvoice();
                                pa.BillIds = this.partSettlementForm.BillId;
                                FinanceAppService.FillBillDatas(pa).then((r1) => {
                                    if (r.data.Data) {
                                        this.partSettlementForm = r.data.Data;
                                        if (this.batchSettlementForm && this.batchSettlementForm.Items && this.partSettlementForm && this.partSettlementForm.Result) {
                                            for (var it of this.batchSettlementForm.Items) {
                                                var flag = false;
                                                for (var re of this.partSettlementForm.Result) {
                                                    if (it.BillId == re.BillId) {
                                                        flag = true;
                                                        it.CurrencySystemId = re.CurrencySystemId;
                                                        if (it.CurrencySystem) {
                                                            it.CurrencySystem.Code = re.CurrencySystemCode;
                                                            it.CurrencySystem.FullName = re.CurrencySystemCode;
                                                        }
                                                        it.Price = re.Price;
                                                        it.Rate = Number(re.Rate);
                                                        it.DisplayRate = Number(re.Rate);
                                                        it.SettlementDate = re.SettlementTime;




                                                        var row = it;
                                                        var price = it.Price;
                                                        if (price) {
                                                            var result = setDecimal(price.toString(), 2, false, true);
                                                            if (result != "") {
                                                                row.Price = Number(result);

                                                            }
                                                            else {
                                                                row.Price = undefined;
                                                            }
                                                        }

                                                        row.ConvertPrice = undefined;
                                                        if (row.NoSettlementPrice && row.Price && row.Rate) {
                                                            price = row.Price / row.Rate;
                                                            result = setDecimal(price.toString(), 2, false, true);
                                                            if (result != "") {
                                                                row.ConvertPrice = Number(result);

                                                            }
                                                            if (row.ConvertPrice) {
                                                                row.SurplusPrice = row.NoSettlementPrice - row.ConvertPrice;
                                                            }
                                                        }



                                                        price = row.SurplusPrice;
                                                        if (price != undefined) {
                                                            result = setDecimal(price.toString(), 2, true, true);
                                                            if (result != "") {
                                                                row.SurplusPrice = Number(result);
                                                            }
                                                            else {
                                                                row.SurplusPrice = undefined;
                                                            }
                                                        }


                                                    }
                                                }

                                                if (flag == false) {
                                                    it.Price = undefined;
                                                    it.SurplusPrice = undefined;
                                                }
                                            }
                                            this.onBatchSettlementSum();
                                        }



                                        this.partSettlementForm.Visiable = false;

                                        this.lockParam.BillId = [];
                                        this.lockParam.OrderId = [];
                                        this.lockParam.PoolId = [];
                                        if (this.batchSettlementForm && this.batchSettlementForm.Items) {
                                            for (var settlement of this.batchSettlementForm.Items) {
                                                this.lockParam.BillId.push(settlement.BillId as string);
                                            }
                                        }
                                        BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                                            if (lock.data.Data == true) {
                                                ElMessage.warning("包含已锁定的订单，请重新选择");
                                                complete();
                                                return;
                                            }
                                            else {


                                                MiezzForm.customSubmit(this.$refs, "refBatchSettlementForm", (v) => {
                                                    if (v == true) {
                                                        this.isLoading = true;
                                                        FinanceAppService.SaveBatchSettlement(this.batchSettlementForm).then((r) => {
                                                            var pa = new DtoRequestInvoice();
                                                            pa.BillIds = this.batchSettlementForm.BillIds;
                                                            FinanceAppService.FillBillDatas(pa).then((r1) => {
                                                                this.isLoading = false;
                                                                ElMessage.success("核销成功");
                                                                this.batchSettlementForm.Visiable = false;
                                                                this.handleGet();
                                                                complete();
                                                            })
                                                        }).catch(() => {
                                                            this.isLoading = false;
                                                            complete();
                                                        });
                                                    }
                                                    else {
                                                        complete();
                                                    }
                                                });
                                            }
                                        })


                                    }
                                    complete();
                                })
                            })
                            complete();
                        }
                        else {
                            complete();
                        }
                    });
                    break;
                case "close":
                    this.partSettlementForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        onChangeBatchCollectionTitle(index: number): void {
            if (this.batchSettlementForm && this.batchSettlementForm.Items) {
                var name = 'Items.' + index + '.CollectionTitle';
                if (this.batchSettlementForm.Items[index].CollectionTitle != null && this.batchSettlementForm.Items[index].CollectionTitle != undefined) {

                    MiezzForm.clearCustomValidate(this.$refs, "refBatchSettlementForm", name);
                }
                else {
                    MiezzForm.validateFieldCustom(this.$refs, "refBatchSettlementForm", name);
                }
            }
        }
        onChangeBatchPaymentTitle(index: number): void {
            if (this.batchSettlementForm && this.batchSettlementForm.Items) {
                var name = 'Items.' + index + '.PaymentTitle';
                if (this.batchSettlementForm.Items[index].PaymentTitle != null && this.batchSettlementForm.Items[index].PaymentTitle != undefined) {
                    MiezzForm.clearCustomValidate(this.$refs, "refBatchSettlementForm", name);
                }
                else {
                    MiezzForm.validateFieldCustom(this.$refs, "refBatchSettlementForm", name);
                }
            }
        }

        handleClickSettlement(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    this.lockParam.BillId.push(this.settlementForm.BillId as string);
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("订单已锁定");
                            complete();
                            return;
                        }
                        else {


                            this.isLoading = true;
                            if (this.settlementForm && this.settlementForm.Items) {
                                for (var s of this.settlementForm.Items) {
                                    if (s.SettlementDateString != null && s.SettlementDateString != undefined && s.SettlementDateString != "") {
                                        s.SettlementDate = new Date(s.SettlementDateString);
                                    }
                                }
                            }

                            FinanceAppService.SaveSettlement(this.settlementForm).then((r) => {
                                var ids = [];
                                if (this.settlementForm.BillId) {
                                    ids.push(this.settlementForm.BillId);
                                }
                                var pa = new DtoRequestInvoice();
                                pa.BillIds = ids;
                                FinanceAppService.FillBillDatas(pa).then((r1) => {
                                    //FinanceAppService.SetAllRelateData().then((r2) => {

                                    //})
                                    this.isLoading = false;
                                    ElMessage.success("核销成功");
                                    this.settlementForm.Visiable = false;
                                    this.handleGet();
                                    complete();
                                })
                            }).catch(() => {
                                this.isLoading = false;
                                complete();
                            });

                        }
                    })
                    break;
                case "close":
                    this.settlementForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        handleClickStat(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "export":
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一条信息");
                        complete();
                        return;
                    }

                    var param10 = new DtoPageListSearchFinance();
                    param10.ExportId = this.list.SelectIds;
                    param10.DynamicSearchId = this.$route.query.DynamicSearchId as string;
                    param10.CurrentPage = 1;
                    param10.PageSize = 999999;
                    this.isLoading = true;
                    FinanceAppService.Export(param10)
                        .then((ep) => {
                            this.isLoading = false;
                            window.open(process.env.VUE_APP_BASE_URL + ep.data.Data, "_blank");
                            complete();
                        })
                        .catch(() => {
                            this.isLoading = false;
                            complete();
                        });


                    break;
                case "close":
                    this.statForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        handleClickOneBadSettlement(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    if (this.oneBadSettlementForm && this.oneBadSettlementForm.Items) {
                        for (var settlement1 of this.oneBadSettlementForm.Items) {
                            this.lockParam.BillId.push(settlement1.BillId as string);
                        }
                    }
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("包含已锁定的订单，请重新选择");
                            complete();
                            return;
                        }
                        else {

                            MiezzForm.customSubmit(this.$refs, "refOneBadSettlementForm", (v) => {
                                if (v == true) {
                                    this.oneBadSettlementForm.ConvertPrice = this.oneBadSettlementForm.Price;
                                    this.oneBadSettlementForm.Rate = 1;
                                    this.oneBadSettlementForm.DisPlayRate = 1;
                                    this.settlementForm.NoSettlementPrice = 0;
                                    var t = JSON.parse(JSON.stringify(this.oneBadSettlementForm));
                                    var exist = false;
                                    if (this.settlementForm && this.settlementForm.Items) {
                                        for (var it of this.settlementForm.Items) {
                                            if (it.Id == this.oneBadSettlementForm.Id) {
                                                it.Price = t.Price;
                                                it.PaymentTitle = t.PaymentTitle;
                                                it.CollectionTitle = t.CollectionTitle;
                                                it.Rate = t.Rate;
                                                it.DisplayRate = t.DisplayRate;
                                                it.CurrencySystemId = t.CurrencySystemId;
                                                it.CurrencySystem = t.CurrencySystem;
                                                it.SettlementDateString = moment(t.SettlementDate).format("YYYY/MM/DD HH:mm");
                                                //it.CreatedUserName = this.settlementForm.CreatedUserName;
                                                it.ConvertPrice = t.ConvertPrice;
                                                it.Mode = t.Mode;
                                                it.Remark = t.Remark;
                                                exist = true;
                                            }
                                        }
                                    }
                                    if (exist == false) {
                                        t.SettlementDateString = t.SettlementDate as string;
                                        t.SettlementDateString = moment(t.SettlementDate).format("YYYY/MM/DD HH:mm");
                                        t.CreatedUserName = this.settlementForm.CreatedUserName;
                                        t.BillCurrencySystem = this.oneBadSettlementForm.BillCurrencySystem;
                                        t.BillCurrencySystemCode = this.oneBadSettlementForm.BillCurrencySystemCode;
                                        t.BillCurrencySystemId = this.oneBadSettlementForm.BillCurrencySystemId;
                                        t.ConvertPrice = this.oneBadSettlementForm.ConvertPrice;
                                        if (this.settlementForm && this.settlementForm.Items) {
                                            this.settlementForm.Items.push(t);
                                        }
                                    }
                                   
                                    this.oneBadSettlementForm.Visiable = false;
                                    this.settlementTableRandom = Math.random();
                                    this.onSettlementSum();
                                    complete();
                                }
                                else {
                                    complete();
                                }
                            });

                        }
                    })

                    break;
                case "close":
                    this.isLock = false;
                    this.oneBadSettlementForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }


        handleClickOneSettlement(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    if (this.oneSettlementForm && this.oneSettlementForm.Items) {
                        for (var settlement1 of this.oneSettlementForm.Items) {
                            this.lockParam.BillId.push(settlement1.BillId as string);
                        }
                    }
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("包含已锁定的订单，请重新选择");
                            complete();
                            return;
                        }
                        else {

                            MiezzForm.customSubmit(this.$refs, "refOneSettlementForm", (v) => {
                                if (v == true) {
                                    var t = JSON.parse(JSON.stringify(this.oneSettlementForm));
                                    var exist = false;
                                    if (this.settlementForm && this.settlementForm.Items) {
                                        for (var it of this.settlementForm.Items) {
                                            if (it.Id == this.oneSettlementForm.Id) {
                                                it.Price = t.Price;
                                                it.PaymentTitle = t.PaymentTitle;
                                                it.CollectionTitle = t.CollectionTitle;
                                                it.Rate = t.Rate;
                                                it.DisplayRate = t.DisplayRate;
                                                it.CurrencySystemId = t.CurrencySystemId;
                                                it.CurrencySystem = t.CurrencySystem;
                                                it.SettlementDateString = moment(t.SettlementDate).format("YYYY/MM/DD HH:mm");
                                                //it.CreatedUserName = this.settlementForm.CreatedUserName;
                                                it.ConvertPrice = t.ConvertPrice;
                                                it.Mode = t.Mode;
                                                it.Remark = t.Remark;
                                                exist = true;
                                            }
                                        }
                                    }
                                    if (exist == false) {
                                        t.SettlementDateString = t.SettlementDate as string;
                                        t.SettlementDateString = moment(t.SettlementDate).format("YYYY/MM/DD HH:mm");
                                        t.CreatedUserName = this.settlementForm.CreatedUserName;
                                        t.BillCurrencySystem = this.oneSettlementForm.BillCurrencySystem;
                                        t.BillCurrencySystemCode = this.oneSettlementForm.BillCurrencySystemCode;
                                        t.BillCurrencySystemId = this.oneSettlementForm.BillCurrencySystemId;
                                        t.ConvertPrice = this.oneSettlementForm.ConvertPrice;
                                        if (this.settlementForm && this.settlementForm.Items) {
                                            this.settlementForm.Items.push(t);
                                        }
                                    }
                                    this.settlementForm.NoSettlementPrice = this.oneSettlementForm.SurplusPrice;
                                    //this.settlementForm.SettlementPrice = this.oneSettlementForm.SurplusPrice;
                                    this.oneSettlementForm.Visiable = false;
                                    this.settlementTableRandom = Math.random();
                                    this.onSettlementSum();
                                    complete();
                                }
                                else {
                                    complete();
                                }
                            });

                        }
                    })

                    break;
                case "import":

                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    if (this.oneSettlementForm && this.oneSettlementForm.Items) {
                        for (var settlement2 of this.oneSettlementForm.Items) {
                            this.lockParam.BillId.push(settlement2.BillId as string);
                        }
                    }
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("包含已锁定的订单，请重新选择");
                            complete();
                            return;
                        }
                        else {



                            if (this.oneSettlementForm && this.oneSettlementFormInit) {
                                this.oneSettlementForm.Price = this.oneSettlementFormInit.NoSettlementPrice;
                                this.oneSettlementForm.SurplusPrice = 0;
                                this.oneSettlementForm.CurrencySystemId = this.oneSettlementFormInit.BillCurrencySystemId;
                                this.oneSettlementForm.Rate = 1;
                                this.oneSettlementForm.CurrencySystem = this.oneSettlementFormInit.BillCurrencySystem;
                                this.oneSettlementForm.ConvertPrice = this.oneSettlementFormInit.SurplusPrice;

                                if (this.oneSettlementForm.CollectionTitle == null || this.oneSettlementForm.CollectionTitle == undefined || this.oneSettlementForm.CollectionTitle == "") {
                                    this.oneSettlementForm.CollectionTitle = this.settlementForm.CollectionTitle;
                                }
                                if (this.oneSettlementForm.PaymentTitle == null || this.oneSettlementForm.PaymentTitle == undefined || this.oneSettlementForm.PaymentTitle == "") {
                                    this.oneSettlementForm.PaymentTitle = this.settlementForm.PaymentTitle;
                                }
                            }
                            complete();

                        }
                    })

                    break;
                case "close":
                    this.isLock = false;
                    this.oneSettlementForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        onClickFile(row: any): void {
            var url = this.client?.signatureUrl(row.Url as string);
            window.open(
                url,
                "_blank"
            );
        }

        onSearchBill(row: any): void {
            var value = "";
            if (row) {
                value = row.Number as string;
            }
            DynamicSearchAppService.Post({
                Code: "FinancialManagement_BillSummary",
                Brackets: [
                    {
                        Relation: EnumRelation.And,
                        Items: [
                            {
                                Relation: EnumRelation.And,
                                Where: {
                                    Prop: "DynamicSearch_InvoiceNumber",
                                    Compare: EnumCompare.Equal,
                                    Value: value,
                                },
                            },
                        ],
                    },
                ],
            }).then((r) => {

                //this.$router.push({
                //    path: `/finance/bill`,
                //    query: {
                //        DynamicSearchId: r.data.Data,
                //        OpenSearch: "true",
                //        r: Math.random(),
                //        backUrl: this.$route.fullPath,
                //    },
                //});

                window.open(
                    `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                    "_blank"
                );
            });
        }

        handleClickInvoice(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":


                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    //if (this.invoiceForm && (this.invoiceForm.InvoiceItems == null || this.invoiceForm.InvoiceItems == undefined || this.invoiceForm.InvoiceItems.length <= 0)) {
                    //    ElMessage.warning("请至少添加一条信息");
                    //    complete();
                    //    return;
                    //}
                    if (this.invoiceForm && this.invoiceForm.BillIds) {
                        this.lockParam.BillId = this.invoiceForm.BillIds;
                    }
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            if (this.invoiceForm.BillIds && this.invoiceForm.BillIds.length == 1) {
                                ElMessage.warning("订单已锁定");
                            }
                            else {
                                ElMessage.warning("包含已锁定的订单，请重新选择");
                            }

                            complete();
                            return;
                        }
                        else {
                            MiezzForm.customSubmit(this.$refs, "refInvoiceForm", (v) => {
                                if (v == true) {
                                    if (this.invoiceForm && this.invoiceForm.InvoiceItems) {
                                        for (var it of this.invoiceForm.InvoiceItems) {
                                            if (it.Annexs) {
                                                var url = "";
                                                if (it.Annexs.length == 1) {
                                                    url = it.Annexs[0].Url + "";
                                                }
                                                else {
                                                    url = it.Annexs + "";
                                                }
                                                if (url && url != "") {
                                                    var annex = new DtoFromInvoiceAnnex();
                                                    annex.Url = url as string;
                                                    it.Annexs = [];
                                                    it.Annexs.push(annex);
                                                }
                                            }
                                        }
                                    }
                                    this.isLoading = true;
                                    FinanceAppService.SaveInvoice(this.invoiceForm).then((r) => {
                                        var pa = new DtoRequestInvoice();
                                        pa.BillIds = r.data.Data;
                                        FinanceAppService.FillBillDatas(pa).then((r1) => {
                                            ElMessage.success("保存成功");
                                            this.invoiceForm.Visiable = false;
                                            this.handleGet();
                                            complete();
                                        })
                                    }).catch(() => {
                                        this.isLoading = false;
                                        complete();
                                    });

                                }
                                else {
                                    complete();
                                }
                            });
                        }
                    })

                    break;
                case "search":
                    //var param = new DtoRequestFinance();
                    //param.BillIds = this.invoiceForm.BillIds;
                    //this.isLoading = true;
                    //FinanceAppService.PreBatchEdit(param)
                    //    .then((pre) => {
                    //        this.invoiceForm.Visiable = false;
                    //        if (pre.data.Data) {

                    //            //this.handleGet(pre.data.Data);
                    //            let routeUrl = this.$router.resolve({
                    //                path: this.$route.fullPath,
                    //                query: {
                    //                    BatchOperationId: pre.data.Data,
                    //                    backUrl: this.$route.fullPath
                    //                },
                    //            });
                    //            location.href = routeUrl.href;
                    //        }
                    //        complete();
                    //    })
                    //    .catch(complete);


                    var numbers = [];
                    if (this.invoiceFormInit && this.invoiceFormInit.InvoiceItems && this.invoiceFormInit.InvoiceItems.length > 0) {
                        for (var inv of this.invoiceFormInit.InvoiceItems) {
                            var num = inv.Number as string;
                            var flag = false;
                            for (var nu of numbers) {
                                if (nu == num) {
                                    flag = true;
                                }
                            }
                            if (flag == false) {
                                numbers.push(num);
                            }
                        }
                    }
                    var left = [];
                    var right = [];
                    var i = 0;
                    for (var nu1 of numbers) {
                        if (i % 2 == 0) {
                            left.push({
                                Relation: EnumRelation.Or,
                                Where: {
                                    Prop: "DynamicSearch_InvoiceNumber",
                                    Compare: EnumCompare.Equal,
                                    Value: nu1,
                                },
                            });
                        }
                        else {
                            right.push({
                                Relation: EnumRelation.Or,
                                Where: {
                                    Prop: "DynamicSearch_InvoiceNumber",
                                    Compare: EnumCompare.Equal,
                                    Value: nu1,
                                },
                            });
                        }
                        i++;
                    }
                    DynamicSearchAppService.Post({
                        Code: "FinancialManagement_BillSummary",
                        Brackets: [
                            {
                                Relation: EnumRelation.Or,
                                Items: left,
                            },
                            {
                                Relation: EnumRelation.Or,
                                Items: right,
                            },
                        ],
                    }).then((r) => {
                        //window.open(
                        //    `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                        //    "_blank"
                        //);
                        //window.location.href = `/#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`;

                        //this.$router.push(
                        //    `/finance/bill/batch/edit/${pre.data.Data}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);

                        this.$router.push({
                            path: `/finance/bill`,
                            query: {
                                DynamicSearchId: r.data.Data,
                                OpenSearch: "true",
                                r: Math.random(),
                                backUrl: this.$route.fullPath,
                            },
                        });
                        this.invoiceForm.Visiable = false;

                        complete();
                    });
                    break;
                case "close":
                    this.isLock = false;
                    this.invoiceForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
        onSettlement(row: any) {
            this.isLock = false;
            if (row.IsLock == true) {
                this.isLock = true;
            }
            if (row.IsLock == true) {
                ElMessage.warning("订单已锁定");
                return;
            }
            if (row.IsSave != true) {
                if (this.FinancialManagement_BillSummary_AddInvoicewriteOffForUnpaidBills != true) {
                    ElMessage.warning("您没有对未提交的账单进行添加发票/核销的权限，请联系有权限的用户进行操作");
                    return;
                }
            }
            FinanceAppService.GetSettlementByBillId(row.Id).then((r) => {
                if (r.data.Data) this.settlementForm = r.data.Data;
                //if (this.settlementForm) {
                //    this.settlementForm.PaymentTitle = row.BillObject;
                //}
            })

            if (this.historys.Dto) {
                this.historys.Dto.CurrentPage = 1;
                this.historys.Dto.PageSize = 999999999;
                this.historys.Dto.BillId = row.Id;
            }
            this.isHistory = true;
            BillHistoryAppService.Get(this.historys.Dto).then((r) => {
                this.historys.Data = r.data.Data;
            });

        }
        onEditSettlement(row: any) {
            if (this.isLock == true) {
                ElMessage.warning("订单已锁定");
                return;
            }
            this.oneSettlementForm = new DtoFormSettlement();
            this.oneSettlementForm.InvoiceId = row.InvoiceId;
            this.oneSettlementForm.Id = row.Id;
            this.oneSettlementForm.Price = row.Price;
            this.oneSettlementForm.PaymentTitle = row.PaymentTitle;
            this.oneSettlementForm.CollectionTitle = row.CollectionTitle;
            this.oneSettlementForm.SettlementDate = row.SettlementDate;
            this.oneSettlementForm.Rate = row.Rate;
            this.oneSettlementForm.DisplayRate = row.DisplayRate;
            this.oneSettlementForm.Remark = row.Remark;
            this.oneSettlementForm.Mode = row.Mode;
            this.oneSettlementForm.CurrencySystemId = row.CurrencySystemId;
            this.oneSettlementForm.CurrencySystem = row.CurrencySystem;
            this.oneSettlementForm.NoSettlementPrice = this.settlementForm.NoSettlementPrice + row.ConvertPrice;
            if (this.oneSettlementForm.NoSettlementPrice) {
                this.oneSettlementForm.NoSettlementPrice = Number(this.oneSettlementForm.NoSettlementPrice.toRound(4));
            }
            this.oneSettlementForm.SurplusPrice = this.settlementForm.NoSettlementPrice;
            this.oneSettlementForm.BillCurrencySystem = row.BillCurrencySystem;
            this.oneSettlementForm.BillCurrencySystemCode = row.BillCurrencySystemCode;
            this.oneSettlementForm.BillCurrencySystemId = row.BillCurrencySystemId;
            this.oneSettlementForm.ConvertPrice = row.ConvertPrice;
            this.oneSettlementFormInit = JSON.parse(JSON.stringify(this.oneSettlementForm));
            this.oneSettlementForm.Visiable = true;
        }
        onEditBadSettlement(row: any) {
            if (this.isLock == true) {
                ElMessage.warning("订单已锁定");
                return;
            }
            this.oneBadSettlementForm = new DtoFormSettlement();
            this.oneBadSettlementForm.InvoiceId = row.InvoiceId;
            this.oneBadSettlementForm.Id = row.Id;
            this.oneBadSettlementForm.Price = row.Price;
            this.oneBadSettlementForm.PaymentTitle = row.PaymentTitle;
            this.oneBadSettlementForm.CollectionTitle = row.CollectionTitle;
            this.oneBadSettlementForm.SettlementDate = row.SettlementDate;
            this.oneBadSettlementForm.Rate = row.Rate;
            this.oneBadSettlementForm.DisplayRate = row.DisplayRate;
            this.oneBadSettlementForm.Remark = row.Remark;
            this.oneBadSettlementForm.Mode = row.Mode;
            this.oneBadSettlementForm.CurrencySystemId = row.CurrencySystemId;
            this.oneBadSettlementForm.CurrencySystem = row.CurrencySystem;
            this.oneBadSettlementForm.NoSettlementPrice = this.settlementForm.NoSettlementPrice + row.ConvertPrice;
            if (this.oneBadSettlementForm.NoSettlementPrice) {
                this.oneBadSettlementForm.NoSettlementPrice = Number(this.oneBadSettlementForm.NoSettlementPrice.toRound(4));
            }
            this.oneBadSettlementForm.SurplusPrice = this.settlementForm.NoSettlementPrice;
            this.oneBadSettlementForm.BillCurrencySystem = row.BillCurrencySystem;
            this.oneBadSettlementForm.BillCurrencySystemCode = row.BillCurrencySystemCode;
            this.oneBadSettlementForm.BillCurrencySystemId = row.BillCurrencySystemId;
            this.oneBadSettlementForm.ConvertPrice = row.ConvertPrice;
            this.oneBadSettlementFormInit = JSON.parse(JSON.stringify(this.oneBadSettlementForm));
            this.oneBadSettlementForm.Visiable = true;
        }
        onAddSettlement(): void {
            if (this.isLock == true) {
                ElMessage.warning("订单已锁定");
                return;
            }
            var param = new DtoRequestOneSettlement();
            param.BillId = this.settlementForm.BillId;
            param.SettlementId = undefined;
            this.isLoading = true;
            FinanceAppService.GetOneSettlement(param).then((r) => {
                this.isLoading = false;
                if (r.data.Data) this.oneSettlementForm = r.data.Data;
                this.oneSettlementFormInit = JSON.parse(JSON.stringify(this.oneSettlementForm));
                if (this.settlementForm.CollectionTitles && this.settlementForm.CollectionTitles.length == 1) {
                    this.oneSettlementForm.CollectionTitle = this.settlementForm.CollectionTitle;
                }
                if (this.settlementForm.PaymentTitles && this.settlementForm.PaymentTitles.length == 1) {
                    this.oneSettlementForm.PaymentTitle = this.settlementForm.PaymentTitle;
                }
                this.oneSettlementForm.SurplusPrice = this.settlementForm.NoSettlementPrice;
                this.oneSettlementForm.NoSettlementPrice = this.settlementForm.NoSettlementPrice;
                this.oneSettlementFormInit.SurplusPrice = this.settlementForm.NoSettlementPrice;
                this.oneSettlementFormInit.NoSettlementPrice = this.settlementForm.NoSettlementPrice;
                this.oneSettlementForm.SettlementDate = r.data.Data?.CurrentTime;
                this.oneBadSettlementForm.IsBad = false;
                this.$nextTick(() => {
                    MiezzForm.clearCustomValidate(this.$refs, "refOneSettlementForm", "Price");
                    MiezzForm.clearCustomValidate(this.$refs, "refOneSettlementForm", "Rate");
                    MiezzForm.clearCustomValidate(this.$refs, "refOneSettlementForm", "CollectionTitle");
                    MiezzForm.clearCustomValidate(this.$refs, "refOneSettlementForm", "PaymentTitle");
                    MiezzForm.clearCustomValidate(this.$refs, "refOneSettlementForm", "SettlementDate");
                });
            })
        }

        onAddBadSettlement(): void {
            if (this.isLock == true) {
                ElMessage.warning("订单已锁定");
                return;
            }
            var param = new DtoRequestOneSettlement();
            param.BillId = this.settlementForm.BillId;
            param.SettlementId = undefined;
            this.isLoading = true;
            FinanceAppService.GetOneSettlement(param).then((r) => {
                this.isLoading = false;
                if (r.data.Data) this.oneBadSettlementForm = r.data.Data;
                this.oneBadSettlementFormInit = JSON.parse(JSON.stringify(this.oneBadSettlementForm));
                if (this.settlementForm.CollectionTitles && this.settlementForm.CollectionTitles.length == 1) {
                    this.oneBadSettlementForm.CollectionTitle = this.settlementForm.CollectionTitle;
                }
                if (this.settlementForm.PaymentTitles && this.settlementForm.PaymentTitles.length == 1) {
                    this.oneBadSettlementForm.PaymentTitle = this.settlementForm.PaymentTitle;
                }
                this.oneBadSettlementForm.SurplusPrice = this.settlementForm.NoSettlementPrice;
                this.oneBadSettlementForm.NoSettlementPrice = this.settlementForm.NoSettlementPrice;
                this.oneBadSettlementFormInit.SurplusPrice = this.settlementForm.NoSettlementPrice;
                this.oneBadSettlementFormInit.NoSettlementPrice = this.settlementForm.NoSettlementPrice;
                this.oneBadSettlementForm.SettlementDate = r.data.Data?.CurrentTime;
                this.oneBadSettlementForm.Price = this.oneBadSettlementForm.SurplusPrice;
                this.oneBadSettlementForm.IsBad = true;
                this.$nextTick(() => {
                    MiezzForm.clearCustomValidate(this.$refs, "refOneBadSettlementForm", "Price");
                    MiezzForm.clearCustomValidate(this.$refs, "refOneBadSettlementForm", "Rate");
                    MiezzForm.clearCustomValidate(this.$refs, "refOneBadSettlementForm", "CollectionTitle");
                    MiezzForm.clearCustomValidate(this.$refs, "refOneBadSettlementForm", "PaymentTitle");
                    MiezzForm.clearCustomValidate(this.$refs, "refOneBadSettlementForm", "SettlementDate");
                });
            })
        }

        onInvoice(row: any) {

            this.isLock = false;
            if (row.IsLock == true) {
                this.isLock = true;
            }

            if (row.IsLock == true) {
                ElMessage.warning("订单已锁定");
                return;
            }

            if (row.IsSave != true) {
                if (this.FinancialManagement_BillSummary_AddInvoicewriteOffForUnpaidBills != true) {
                    ElMessage.warning("您没有对未提交的账单进行添加发票/核销的权限，请联系有权限的用户进行操作");
                    return;
                }
            }
            var param = new DtoRequestFinance();
            param.BillIds = [];
            param.BillIds.push(row.Id);
            FinanceAppService.GetInvoice(param).then((r) => {
                if (r.data.Data) this.invoiceForm = r.data.Data;
                this.invoiceFormInit = JSON.parse(JSON.stringify(this.invoiceForm));

                //console.log(this);
                //this.$nextTick(() => {
                //    console.log(this.invoiceForm);
                //    if (this.invoiceForm && this.invoiceForm.InvoiceItems) {
                //        for (var i = 0; i < this.invoiceForm.InvoiceItems.length; i++) {
                //            MiezzForm.clearCustomValidate(this.$refs, "refInvoiceForm", "InvoiceItems." + i + ".Number");
                //        }
                //    }
                //})
                this.onInvoiceSum();

                if (this.invoiceForm && this.invoiceForm.InvoiceItems != null && this.invoiceForm.InvoiceItems != undefined && this.invoiceForm.InvoiceItems.length > 0) {
                    for (var it of this.EnumInvoiceStatusOption) {
                        if (it.Value == 10) {
                            it.disabled = true;
                        }
                    }
                }
                else {
                    for (var it1 of this.EnumInvoiceStatusOption) {
                        if (it1.Value == 10) {
                            it1.disabled = false;
                        }
                    }
                }

            })
        }

        onDeleteInvoice(index: number): void {
            if (this.isLock == true) {
                ElMessage.warning("订单已锁定");
                return;
            }
            if (this.invoiceForm && this.invoiceForm.InvoiceItems) {
                this.invoiceForm.InvoiceItems.splice(index, 1);
            }

            if (this.invoiceForm) {
                if(this.invoiceForm.InvoiceItems == null || this.invoiceForm.InvoiceItems==undefined || this.invoiceForm.InvoiceItems.length<=0){
                    this.invoiceForm.Status = 10;
                }
            }

            if (this.invoiceForm && this.invoiceForm.InvoiceItems!=null && this.invoiceForm.InvoiceItems!=undefined && this.invoiceForm.InvoiceItems.length>0){
                for(var it of this.EnumInvoiceStatusOption){
                    if(it.Value == 10){
                        it.disabled = true;
                    }
                }
            }
            else{
                for(var it1 of this.EnumInvoiceStatusOption){
                    if(it1.Value == 10){
                        it1.disabled = true;
                    }
                }
            }

            this.onInvoiceSum();
        }

        onAddInvoice(): void {
            if (this.isLock == true) {
                ElMessage.warning("订单已锁定");
                return;
            }
            if (this.invoiceForm && this.invoiceForm.InvoiceItems) {
                var t = new DtoFromInvoiceItem();
                t.CollectionTitle = this.invoiceForm.CollectionTitle;
                t.PaymentTitle = this.invoiceForm.PaymentTitle;
                t.InvoiceDate = this.invoiceFormInit.CurrentTime;
                t.IsChangeSettlementCurrencyId = true;
                this.invoiceForm.InvoiceItems.push(t);
                this.invoiceForm.Status = 30;

                if (this.invoiceForm && this.invoiceForm.InvoiceItems!=null && this.invoiceForm.InvoiceItems!=undefined && this.invoiceForm.InvoiceItems.length>0){
                    for(var it of this.EnumInvoiceStatusOption){
                        if(it.Value == 10){
                            it.disabled = true;
                        }
                    }
                }
            }
        }
        isString(str: string, row: any | undefined): string {
            if (row != undefined && row != null) {
                if (row.FromId == undefined || row.FromId == null) {
                    if (str == null || str == undefined || str == "") {
                        return "暂无数据";
                    }
                    else {
                        return str;
                    }
                }
                else {
                    if (str == null || str == undefined || str == "") {
                        return "—";
                    }
                    else {
                        return str;
                    }
                }
            }
            else {
                if (str == null || str == undefined || str == "") {
                    return "暂无数据";
                }
                else {
                    return str;
                }
            }
        }

        //监听路由是否有变化
        @Watch("$route")
        onRouteChanged(): void {
            this.handleGet();
        }

        /**查询列表 */
        handleGet(): void {
            console.log(this.EnumInvoiceSettlementStatusOption);
            var BatchOperationId = this.$route.query.BatchOperationId as string;
            if (BatchOperationId != null && BatchOperationId != undefined && BatchOperationId != "" && this.list && this.list.Dto) {
                this.list.Dto.BatchOperationId = BatchOperationId;
                this.list.Back = true;
            }
            else {
                if (this.list && this.list.Dto) {
                    this.list.Dto.BatchOperationId = undefined;
                }
                this.list.Back = false;
            }
            if (this.list && this.list.Dto) {
                this.list.Dto.DynamicSearchId = this.$route.query.DynamicSearchId as string;
                this.list.Dto.OpenSearch = this.$route.query.OpenSearch as string;
            }
            this.isLoading = true;
            this.list.DefaultTip = "";
            if (this.list && this.list.Data && this.list.Data.Items) {
                this.list.Data.Items = [];
            }
            if (this.list && this.list.Dto) {
                this.list.Dto.IsStat = false;
            }

            FinanceAppService.Get(this.list.Dto).then((r) => {
                this.list.Data = r.data.Data;
                this.isLoading = false;

                if (this.list && this.list.Data && this.list.Data.Items) {
                    for (var row of this.list.Data.Items) {
                        row.IsOpen = false;
                        //(this.$refs["$FinanceTable"] as any).onChild(row);
                    }
                }
            });
        }

        onTik(): void {
            this.dialogVisible = true;
        }

        onCloseTip(): void {
            this.dialogVisible = false;
        }

        onStatTip(): void {

            this.isLoading = true
            if (this.list && this.list.Dto) {
                this.list.Dto.IsStat = true;
            }
            this.isFinishStat = false;
            FinanceAppService.Get(this.list.Dto).then((r2) => {
                this.isLoading = false;
                var item = r2.data.Data;
                this.isFinishStat = true;
                if (item && item.Sum) {
                    var r1 = item.Sum;
                    var collection = "";
                    if (r1.Collecionts) {
                        for (var c of r1.Collecionts) {
                            collection += " " + c.CurrencySystgemCode + c.Price;
                        }
                    }

                    var noCollection = "";
                    if (r1.NoCollecionts) {
                        for (var c1 of r1.NoCollecionts) {
                            noCollection += " " + c1.CurrencySystgemCode + c1.Price;
                        }
                    }

                    var payment = "";
                    if (r1.Payments) {
                        for (var p of r1.Payments) {
                            payment += " " + p.CurrencySystgemCode + p.Price;
                        }
                    }

                    var noPayment = "";
                    if (r1.NoPayments) {
                        for (var p1 of r1.NoPayments) {
                            noPayment += " " + p1.CurrencySystgemCode + p1.Price;
                        }
                    }

                    var profit = "";
                    if (r1.Profits) {
                        for (var pro of r1.Profits) {
                            profit += " " + pro.CurrencySystgemCode + pro.Price;
                        }
                    }

                    var badCollection = "";
                    if (r1.BadCollections) {
                        for (var bc of r1.BadCollections) {
                            badCollection += " " + bc.CurrencySystgemCode + bc.Price;
                        }
                    }
                    var badPayment = "";
                    if (r1.BadPayments) {
                        for (var bp of r1.BadPayments) {
                            badPayment += " " + bp.CurrencySystgemCode + bp.Price;
                        }
                    }

                    var invoice = "";
                    if (r1.Invoices) {
                        for (var inv of r1.Invoices) {
                            invoice += " " + inv.CurrencySystgemCode + inv.Price;
                        }
                    }

                    if (collection == "") {
                        collection = "CNY0";
                    }
                    if (payment == "") {
                        payment = "CNY0";
                    }
                    if (profit == "") {
                        profit = "CNY0";
                    }
                    if (noCollection == "") {
                        noCollection = "CNY0";
                    }
                    if (noPayment == "") {
                        noPayment = "CNY0";
                    }
                    if (invoice == "") {
                        invoice = "CNY0";
                    }
                    if (badCollection == "") {
                        badCollection = "CNY0";
                    }
                    if (badPayment == "") {
                        badPayment = "CNY0";
                    }

                    var collectionColor = "black";
                    var paymentColor = "black";
                    if (r1.CollectionStatus == 20) {
                        collectionColor = "red";
                    }
                    else if (r1.CollectionStatus == 30) {
                        collectionColor = "#fe995b";
                    }
                    if (r1.PaymentStatus == 20) {
                        paymentColor = "red";
                    }
                    else if (r1.PaymentStatus == 30) {
                        paymentColor = "#fe995b";
                    }

                    var collectionInvoice = "";
                    if (r1.CollectionInvoices) {
                        for (var cInv of r1.CollectionInvoices) {
                            collectionInvoice += " " + cInv.CurrencySystgemCode + cInv.Price;
                        }
                    }

                    var paymentInvoice = "";
                    if (r1.PaymentInvoices) {
                        for (var pInv of r1.PaymentInvoices) {
                            paymentInvoice += " " + pInv.CurrencySystgemCode + pInv.Price;
                        }
                    }

                    if (collectionInvoice == "") {
                        collectionInvoice = "CNY0";
                    }

                    if (paymentInvoice == "") {
                        paymentInvoice = "CNY0";
                    }

                    var collectionInvoiceSettlement = "";
                    if (r1.CollectionInvoiceSettlements) {
                        for (var cInv1 of r1.CollectionInvoiceSettlements) {
                            collectionInvoiceSettlement += " " + cInv1.CurrencySystgemCode + cInv1.Price;
                        }
                    }

                    var paymentInvoiceSettlement = "";
                    if (r1.PaymentInvoiceSettlements) {
                        for (var pInv1 of r1.PaymentInvoiceSettlements) {
                            paymentInvoiceSettlement += " " + pInv1.CurrencySystgemCode + pInv1.Price;
                        }
                    }

                    if (collectionInvoiceSettlement == "") {
                        collectionInvoiceSettlement = "CNY0";
                    }

                    if (paymentInvoiceSettlement == "") {
                        paymentInvoiceSettlement = "CNY0";
                    }

                    var collectionInvoiceSettlementString = collectionInvoiceSettlement != collectionInvoice ? ("<br/>" + "应收发票换算合计：" + collectionInvoiceSettlement + " "):"";
                    var paymentInvoiceSettlementString = paymentInvoiceSettlement != paymentInvoice ? ("<br/>应付发票换算合计：" + paymentInvoiceSettlement + " "):"";

                    this.list.DefaultTip1 = "<div style='height:20px;'><div style='font-weight:900;font-size:14px;float:left'>应收/应付合计根据搜索结果统计得出，毛利合计=应收合计-应付合计，</div>";
                    this.list.DefaultTip1 += "<div style='float:left;width:30px;height:14px;background-color:red;'></div><div style='font-weight:900;font-size:14px;float:left;margin-left:5px;'> 应收/应付合计全部未核销；</div>";
                    this.list.DefaultTip1 += "<div style='float:left;width:30px;height:14px;background-color:#fe995b;'></div><div style='font-weight:900;font-size:14px;float:left;margin-left:5px;'> 应收/应付合计部分核销；</div>";
                    this.list.DefaultTip1 += "<div style='float:left;width:30px;height:14px;background-color:black;'></div><div style='font-weight:900;font-size:14px;float:left;margin-left:5px;'> 应收/应付合计全部核销。</div>";
                    this.list.DefaultTip1 += "<div style='clear:both;'>单项合计<span style='color:red;'>红色字体</span>表示该信息为未提交状态</div></div>";

                    if (item != null && item.CollectionCount != null && item.PaymentCount != null) {
                        if (item.CollectionCount > 0 && item.PaymentCount <= 0) {
                            this.list.DefaultTip = "<div style='clear:both;font-size:14px;line-height:26px;font-weight:900;margin-top:-10px;padding-left:5px;padding-right:5px;'>"
                                + "应收合计：<span style='color:" + collectionColor + ";'>" + collection + "</span>   "
                                + "<br/>"
                                + "应收坏账合计：" + badCollection + " "
                                + "<br/>"
                                + "未收合计：" + noCollection + " "
                                + "<br/>"
                                + "关联应收发票合计：" + collectionInvoice + " "
                                + collectionInvoiceSettlementString
                                + "</div>";
                        }
                        else if (item.CollectionCount <= 0 && item.PaymentCount > 0) {
                            this.list.DefaultTip = "<div style='clear:both;font-size:14px;line-height:26px;font-weight:900;margin-top:-10px;padding-left:5px;padding-right:5px;'>"
                                + "应付合计：<span style='color:" + paymentColor + ";'>" + payment + "</span>   "
                                + "<br/>"
                                + "应付坏账合计：" + badPayment + " "
                                + "<br/>"
                                + "未付合计：" + noPayment + " "
                                + "<br/>"
                                + "关联应付发票合计：" + paymentInvoice + " "
                                + paymentInvoiceSettlementString

                                + "</div>";
                        }
                        else {
                            this.list.DefaultTip = "<div style='clear:both;font-size:14px;line-height:26px;font-weight:900;margin-top:-10px;padding-left:5px;padding-right:5px;'>"
                                + "应收合计：<span style='color:" + collectionColor + ";'>" + collection + "</span>   "
                                + "<br/>"
                                + "应付合计：<span style='color:" + paymentColor + ";'>" + payment + "</span>   "
                                + "<br/>"
                                + "应收坏账合计：" + badCollection + " "
                                + "<br/>"
                                + "应付坏账合计：" + badPayment + " "
                                + "<br/>"
                                + "毛利合计：" + profit + " "
                                + "<br/>"
                                + "未收合计：" + noCollection + " "
                                + "<br/>"
                                + "未付合计：" + noPayment + " "
                                + "<br/>"
                                + "关联应收发票合计：" + collectionInvoice + " "
                                + "<br/>"
                                + "关联应付发票合计：" + paymentInvoice + " "
                                + collectionInvoiceSettlementString
                                + paymentInvoiceSettlementString

                                + "</div>";
                        }
                    }
        
          
                }
                this.statDialogVisible = true;

            })

          
        }

        onCloseStatTip(): void {
            this.statDialogVisible = false;
        }

        onPool(row: any) {
            // const save = function (e: any) {
            //     e.clipboardData.setData('text/plain', row.BookingNo)
            //     e.preventDefault() // 阻止默认行为
            // }
            // document.addEventListener('copy', save) // 添加一个copy事件
            // document.execCommand('copy') // 执行copy方法
            // ElMessage.success("号码已复制");

            let routeUrl = this.$router.resolve({
                path: "/pool/bill/" + row.PoolId,
                query: { backUrl: this.$route.fullPath },
            });
            //window.open(routeUrl.href, '_blank');
            window.location.href = routeUrl.href;
        }
        onOrder(row: any) {
            // const save = function (e: any) {
            //     e.clipboardData.setData('text/plain', row.Code)
            //     e.preventDefault() // 阻止默认行为
            // }
            // document.addEventListener('copy', save) // 添加一个copy事件
            // document.execCommand('copy') // 执行copy方法
            // ElMessage.success("号码已复制");

            let routeUrl = this.$router.resolve({
                path: "/order/bill/" + row.OrderId,
                query: { backUrl: this.$route.fullPath },
            });
            //window.open(routeUrl.href, '_blank');
            window.location.href = routeUrl.href;
        }
        onBookingNo(row: any) {
            var value = row.BookingNo;
            if (row.BookingNo != null && row.BookingNo != undefined && row.BookingNo != "") {
                DynamicSearchAppService.Post({
                    Code: "OrderManagement_Order",
                    Brackets: [
                        {
                            Relation: EnumRelation.And,
                            Items: [
                                {
                                    Relation: EnumRelation.And,
                                    Where: {
                                        Prop: "DynamicSearch_BookingNo",
                                        Compare: EnumCompare.Contain,
                                        Value: value,
                                    },
                                },
                            ],
                        },
                    ],
                }).then((r) => {
                    window.open(
                        `#/order?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                        "_blank"
                    );
                });
            }
            else if (row.Code != null && row.Code != undefined && row.Code != "") {
                DynamicSearchAppService.Post({
                    Code: "OrderManagement_Order",
                    Brackets: [
                        {
                            Relation: EnumRelation.And,
                            Items: [
                                {
                                    Relation: EnumRelation.And,
                                    Where: {
                                        Prop: "DynamicSearch_Code",
                                        Compare: EnumCompare.Contain,
                                        Value: row.Code,
                                    },
                                },
                            ],
                        },
                    ],
                }).then((r) => {
                    window.open(
                        `#/order?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                        "_blank"
                    );
                });
            }

        }

        onBLNO(row: any) {
            var value = row.BLNO;
            DynamicSearchAppService.Post({
                Code: "OrderManagement_Order",
                Brackets: [
                    {
                        Relation: EnumRelation.And,
                        Items: [
                            {
                                Relation: EnumRelation.And,
                                Where: {
                                    Prop: "DynamicSearch_MasterBillNo",
                                    Compare: EnumCompare.Contain,
                                    Value: value,
                                },
                            },
                        ],
                    },
                ],
            }).then((r) => {
                window.open(
                    `#/order?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                    "_blank"
                );
            });
        }


        handleSelectionChange(val: any, index: number) {

            this.selectRows = val;
        }


        /**顶部按钮点击 */
        handleTopButtonClick(btn: MiezzButton, complete: () => void): void {

            switch (btn.Code) {
                //新增
                case "add":
                    this.modalForm.Type = MiezzModalType.Dialog;
                    this.modalForm.Show = true;
                    this.modalForm.Title = "添加";
                    this.modalForm.Width = "50%";
                    this.modalForm.Height = "550px";
                    this.modalForm.Top = "30px";
                    this.id = undefined;
                    this.type = btn.Command;
                    complete();
                    break;
                case "back":
                    this.handleGet();
                    break;
                default:
                    break;
            }
        }

        /**行按钮点击 */
        handleRowButtonClick(
            btn: MiezzButton,
            row: DtoListItemFinance,
            complete: () => void
        ): void {
            this.id = row.Id;
            switch (btn.Code) {
                //详细
                case "detail":
                    this.modalDetail.Show = true;
                    this.modalDetail.Title = `账单详细`;
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**底部按钮点击 */
        handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
            this.list.SelectRows = [];
            this.list.SelectIds = [];
            if (this.selectRows && this.selectRows.length > 0) {
                this.list.SelectRows = JSON.parse(JSON.stringify(this.selectRows));
                for (var select1 of this.selectRows) {
                    this.list.SelectIds.push(select1.Id);
                }
            }


            switch (btn.Code) {
                //批量删除
                case "batch-delete":
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一条信息");
                        complete();
                        return;
                    }

                    if (this.list.SelectRows) {
                        for (var row11 of this.list.SelectRows) {
                            if (row11.IsLock == true) {
                                ElMessage.warning("包含已锁定的订单，请重新选择");
                                complete();
                                return;
                            }
                        }
                    }

                    if (this.list.SelectRows) {
                        for (var row21 of this.list.SelectRows) {
                            if (row21.IsSave == true) {
                                ElMessage.warning("包含已锁定的账单，请重新选择");
                                complete();
                                return;
                            }
                        }
                    }

                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    this.lockParam.BillId = this.list.SelectIds;
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("包含已锁定的订单，请重新选择");
                            complete();
                            return;
                        }
                        else {
                            ElMessageBox.confirm("您确定要删除选中的信息吗？", "提示", {
                                confirmButtonText: "确认",
                                cancelButtonText: "取消",
                                type: "warning",
                            })
                                .then(() => {

                                    FinanceAppService.Delete(this.list.SelectIds).then(() => {
                                        var pa = new DtoRequestInvoice();
                                        pa.BillIds = this.list.SelectIds;
                                        FinanceAppService.FillBillDatas(pa).then((r1) => {
                                                ElMessage.success("删除成功");
                                                this.handleGet();
                                                complete();
                                            })
                                        })
                                        .catch(() => {
                                            this.isLoading = false;
                                            complete();
                                        });

                                })
                                .catch(() => {
                                    this.isLoading = false;
                                    complete();
                                });
                        }
                    })

                    break;
                case "export":
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一条信息");
                        complete();
                        return;
                    }
                   
                    if (btn.Command == "1") {
                        if (
                            this.list.SelectRows.map((it) => it.ClientCompanyId).distinct()
                                .length > 1
                        ) {
                            ElMessage.warning("不同委托公司的账单不能同时导出，请重新选择");
                            complete();
                            break;
                        }
                        var param100 = new DtoRequestBill();
                        param100.BillIds = this.list.SelectIds;
                        this.isLoading = true;
                        BillAppService.ExportPoolBill(param100)
                            .then((ep) => {
                                this.isLoading = false;
                                window.open(process.env.VUE_APP_BASE_URL + ep.data.Data, "_blank");
                                complete();
                            })
                            .catch(() => {
                                this.isLoading = false;
                                complete();
                            });
                    }
                    else {
                       
                        var param10 = new DtoPageListSearchFinance();
                        if (btn.Command == "10") {
                            param10.IsProfit = false;
                        }
                        else {
                            param10.IsProfit = true;
                        }
                        param10.ExportId = this.list.SelectIds;
                        param10.IsStat = true;
                        param10.DynamicSearchId = this.$route.query.DynamicSearchId as string;
                        param10.CurrentPage = 1;
                        param10.PageSize = 999999;
                        this.isLoading = true;
                        FinanceAppService.Export(param10)
                            .then((ep) => {
                                this.isLoading = false;
                                window.open(process.env.VUE_APP_BASE_URL + ep.data.Data, "_blank");
                                complete();
                            })
                            .catch(() => {
                                this.isLoading = false;
                                complete();
                            });
                    }
       


                    break;
                case "batch-edit":
                    if (this.selectRows == null || this.selectRows == undefined || this.selectRows.length <= 0) {
                        ElMessage.warning("请至少选择一条信息");
                        complete();
                        return;
                    }
                    if (this.list.SelectRows) {
                        for (var row10 of this.list.SelectRows) {
                            if (row10.IsLock == true) {
                                ElMessage.warning("包含已锁定的订单，请重新选择");
                                complete();
                                return;
                            }
                        }
                    }

                    if (this.list.SelectRows) {
                        for (var row20 of this.list.SelectRows) {
                            if (row20.IsSave == true) {
                                ElMessage.warning("包含已锁定的账单，请重新选择");
                                complete();
                                return;
                            }
                        }
                    }

                    if (this.list.SelectRows) {
                        for (var row of this.list.SelectRows) {
                            if (row.SettlementType == 30 || row.SettlementType == 20) {
                                ElMessage.warning("已核销、部分核销的账单不能编辑，请重新选择");
                                complete();
                                return;
                            }
                        }

                        for (var row9 of this.list.SelectRows) {
                            if (row9.InvoiceNumber != null && row9.InvoiceNumber != undefined && row9.InvoiceNumber != "") {
                                ElMessage.warning("已开发票的账单不能编辑，请重新选择");
                                complete();
                                return;
                            }
                        }
                    }

                    var param = new DtoRequestFinance();
                    param.BIllIds = this.list.SelectIds;
                    FinanceAppService.PreBatchEdit(param).then((pre) => {
                            if (pre.data.Data) {
                                this.$router.push(
                                    `/finance/bill/batch/edit/${pre.data.Data}` +
                                    "?backUrl=" +
                                    this.$route.fullPath
                                );
                            }
                            complete();
                        })
                        .catch(() => {
                            this.isLoading = false;
                            complete();
                        });

                    break;
                case "batch-financialCycle":
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一条信息");
                        complete();
                        return;
                    }


                    this.startDateRuleForm.SelectId = this.list.SelectIds;
                    this.startDateRuleForm.Time = undefined;
                    this.startDateRuleForm.Visiable = true;
                    this.$nextTick(() => {
                        MiezzForm.clearCustomValidate(this.$refs, "refStartDateRuleForm", "Time");
                    })
                    complete();
                    break;
                case "batch-startDate":
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一条信息");
                        complete();
                        return;
                    }

                    var type000 = "";
                    var count000 = 0;
                    var temp110 = [];
                    if (this.list.SelectRows) {
                        for (var bill000 of this.list.SelectRows) {
                            if (bill000.TypeString != undefined && bill000.TypeString != null && bill000.TypeString != "") {
                                var number000 = bill000.TypeString + "";
                                var isExist110 = false;
                                for (var tp110 of temp110) {
                                    if (tp110 == number000) {
                                        isExist110 = true;
                                    }
                                }
                                if (isExist110 == false) {
                                    temp110.push(number000);
                                }
                            }
                        }
                    }
                    if (temp110.length > 1) {
                        ElMessage.warning("应收账单与应付账单不能同时编辑，请重新选择");
                        complete();
                        return;
                    }

                    type000 = "";
                    count000 = 0;
                    if (this.list.SelectRows) {
                        for (var bill100000 of this.list.SelectRows) {
                            var number1000 = bill100000.BillObjectId as string;
                            if (type000 != number1000) {
                                count000++;
                                type000 = number1000;
                            }
                        }
                    }
                    if (count000 > 1) {
                        ElMessage.warning("所选账单信息包含多个对账对象，不能同时编辑，请重新选择");
                        complete();
                        return;
                    }


                    this.startDateRuleForm.SelectId = this.list.SelectIds;
                    this.startDateRuleForm.Time = undefined;
                    this.startDateRuleForm.Visiable = true;
                    this.$nextTick(() => {
                        MiezzForm.clearCustomValidate(this.$refs, "refStartDateRuleForm", "Time");
                    })
                    complete();
                    break;
                case "batch-accountPeriod":

                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一条信息");
                        complete();
                        return;
                    }
    
                    var type00 = "";
                    var count00 = 0;
                    var temp1100 = [];
                    if (this.list.SelectRows) {
                        for (var bill00 of this.list.SelectRows) {
                            if (bill00.TypeString != undefined && bill00.TypeString != null && bill00.TypeString != "") {
                                var number00 = bill00.TypeString + "";
                                var isExist1100 = false;
                                for (var tp1100 of temp1100) {
                                    if (tp1100 == number00) {
                                        isExist1100 = true;
                                    }
                                }
                                if (isExist1100 == false) {
                                    temp1100.push(number00);
                                }
                            }
                        }
                    }
                    if (temp1100.length > 1) {
                        ElMessage.warning("应收账单与应付账单不能同时编辑，请重新选择");
                        complete();
                        return;
                    }

                    type00 = "";
                    count00 = 0;
                    if (this.list.SelectRows) {
                        for (var bill10000 of this.list.SelectRows) {
                            var number100 = bill10000.BillObjectId as string;
                            if (type00 != number100) {
                                count00++;
                                type00 = number100;
                            }
                        }
                    }
                    if (count00 > 1) {
                        ElMessage.warning("所选账单信息包含多个对账对象，不能同时编辑，请重新选择");
                        complete();
                        return;
                    }


                    this.accountPeriodRuleForm.SelectId = this.list.SelectIds;
                    this.accountPeriodRuleForm.AccountPeriod = "";
                    this.accountPeriodRuleForm.Time = undefined;
                    this.accountPeriodRuleForm.Visiable = true;
                    this.$nextTick(() => {
                        MiezzForm.clearCustomValidate(this.$refs, "refAccountPeriodRuleForm", "AccountPeriod");
                    })
                    complete();
                    break;
                case "batch-checkStatus":
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一条信息");
                        complete();
                        return;
                    }

                    this.checkStatusForm.BillId = [];
                    this.checkStatusForm.BillId = this.list.SelectIds;
                    FinanceAppService.GetCheckStatus(this.checkStatusForm).then((r) => {
                        if (r.data.Data) {
                            this.checkStatusForm = r.data.Data;
                            complete();
                        }
                    })
                    break;
                case "batch-badSettlement":
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一条信息");
                        complete();
                        return;
                    }
                    if (this.list.SelectRows) {
                        for (var row120 of this.list.SelectRows) {
                            if (row120.IsLock == true) {
                                ElMessage.warning("包含已锁定的订单，请重新选择");
                                complete();
                                return;
                            }
                        }
                    }

                    var isSave3 = true;
                    this.isLock = false;
                    if (this.list.SelectRows) {
                        for (var select20 of this.list.SelectRows) {
                            if (select20.IsLock == true) {
                                this.isLock = true;
                                break;
                            }
                            if (select20.IsSave != true) {
                                isSave3 = false;
                            }
                        }
                    }

                    if (isSave3 == false) {
                        if (this.FinancialManagement_BillSummary_AddInvoicewriteOffForUnpaidBills != true) {
                            ElMessage.warning("您没有对未提交的账单进行添加发票/核销的权限，请联系有权限的用户进行操作");
                            complete();
                            return;
                        }
                    }

                    this.isBatchSettlementTime = true;
                    var typeBatchSettlement0 = "";
                    var countBatchSettlement0 = 0;
                    var countBillObject0 = 0;
                    var billObject0 = "";
                    if (this.list.SelectRows) {
                        var currecnyNumber0 = 0;
                        var currencySystemId0 = "";
                        for (var billBatchSettlement0 of this.list.SelectRows) {
                            var numberBatchSettlement = billBatchSettlement0.TypeString + "";
                            if (typeBatchSettlement0 != numberBatchSettlement) {
                                countBatchSettlement0++;
                                typeBatchSettlement0 = numberBatchSettlement;
                            }
                            if (billBatchSettlement0.CurrencySystemId != currencySystemId0) {
                                currecnyNumber0++;
                                currencySystemId0 = billBatchSettlement0.CurrencySystemId + "";
                            }

                            if (billObject0 != billBatchSettlement0.BillObject) {
                                billObject0 = billBatchSettlement0.BillObject as string;
                                countBillObject0++;
                            }
                        }
                        if (currecnyNumber0 > 1) {
                            this.isBatchSettlementTime = false;
                        }
                    }
                    if (countBatchSettlement0 > 1) {
                        ElMessage.warning("应收账单与应付账单不能同时批量操作，请重新选择");
                        complete();
                        return;
                    }


                    if (countBillObject0 > 1) {
                        ElMessage.warning("对账对象不同不能同时批量操作，请重新选择");
                        complete();
                        return;
                    }


                    if (this.list.SelectRows) {
                        for (var row100 of this.list.SelectRows) {
                            if (row100.SettlementType == 30) {
                                ElMessage.warning("已核销的账单不能做坏账核销，请重新选择");
                                complete();
                                return;
                            }
                        }
                    }
                    var paramBatchSettlement0 = new DtoRequestFinance();
                    paramBatchSettlement0.BIllIds = this.list.SelectIds;
                    paramBatchSettlement0.IsBad = true;
                    FinanceAppService.GetBatchSettlement(paramBatchSettlement0)
                        .then((s) => {
                            if (s.data.Data) {
                                this.batchBadSettlementForm = s.data.Data;
                                this.$nextTick(() => {
                                    if (this.batchBadSettlementForm && this.batchBadSettlementForm.Items && this.batchBadSettlementForm.Items.length > 0) {
                                        for (var i = 0; i < this.batchBadSettlementForm.Items.length; i++) {
                                            var name = 'Items.' + i + '.PaymentTitle';
                                            MiezzForm.clearCustomValidate(this.$refs, "refBatchBadSettlementForm", name);

                                            name = 'Items.' + i + '.CollectionTitle';
                                            MiezzForm.clearCustomValidate(this.$refs, "refBatchBadSettlementForm", name);

                                            name = 'Items.' + i + '.Price';
                                            MiezzForm.clearCustomValidate(this.$refs, "refBatchBadSettlementForm", name);

                                            name = 'Items.' + i + '.DisplayRate';
                                            MiezzForm.clearCustomValidate(this.$refs, "refBatchBadSettlementForm", name);

                                            name = 'Items.' + i + '.SettlementDate';
                                            MiezzForm.clearCustomValidate(this.$refs, "refBatchBadSettlementForm", name);
                                        }
                                    }
                                });

                            }
                            //this.onBatchSettlementSum();
                            complete();
                        })
                        .catch(() => {
                            this.isLoading = false;
                            complete();
                        });
                    break;
                case "batch-settlement":
                    var isSave2 = true;
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一条信息");
                        complete();
                        return;
                    }
                    if (this.list.SelectRows) {
                        for (var row12 of this.list.SelectRows) {
                            if (row12.IsLock == true) {
                                ElMessage.warning("包含已锁定的订单，请重新选择");
                                complete();
                                return;
                            }
                        }
                    }

                    this.isLock = false;
                    if (this.list.SelectRows) {
                        for (var select2 of this.list.SelectRows) {
                            if (select2.IsLock == true) {
                                this.isLock = true;
                                break;
                            }

                            if (select2.IsSave != true) {
                                isSave2 = false;
                            }
                        }
                    }

                    if (isSave2 == false) {
                        if (this.FinancialManagement_BillSummary_AddInvoicewriteOffForUnpaidBills != true) {
                            ElMessage.warning("您没有对未提交的账单进行添加发票/核销的权限，请联系有权限的用户进行操作");
                            complete();
                            return;
                        }
                    }

                    this.isBatchSettlementTime = true;
                    var typeBatchSettlement = "";
                    var countBatchSettlement = 0;
                    var countBillObject = 0;
                    var billObject = "";
                    if (this.list.SelectRows) {
                        var currecnyNumber = 0;
                        var currencySystemId = "";
                        for (var billBatchSettlement of this.list.SelectRows) {
                            var numberBatchSettlement0 = billBatchSettlement.TypeString + "";
                            if (typeBatchSettlement != numberBatchSettlement0) {
                                countBatchSettlement++;
                                typeBatchSettlement = numberBatchSettlement0;
                            }
                            if (billBatchSettlement.CurrencySystemId != currencySystemId) {
                                currecnyNumber++;
                                currencySystemId = billBatchSettlement.CurrencySystemId + "";
                            }

                            if (billBatchSettlement.Type == 10) {
                                if (billObject != billBatchSettlement.ClientCompanyId) {
                                    billObject = billBatchSettlement.ClientCompanyId as string;
                                    countBillObject++;
                                }
                            }
                            else if (billBatchSettlement.Type == 20) {
                                if (billObject != billBatchSettlement.SupplierCompanyId) {
                                    billObject = billBatchSettlement.SupplierCompanyId as string;
                                    countBillObject++;
                                }
                            }

                       
                        }
                        if (currecnyNumber > 1) {
                            this.isBatchSettlementTime = false;
                        }
                    }
                    if (countBatchSettlement > 1) {
                        ElMessage.warning("应收账单与应付账单不能同时批量核销，请重新选择");
                        complete();
                        return;
                    }

                    if (countBillObject > 1) {
                        ElMessage.warning("对账对象不同不能同时批量核销，请重新选择");
                        complete();
                        return;
                    }


                    if (this.list.SelectRows) {
                        for (var row1 of this.list.SelectRows) {
                            if (row1.SettlementType == 30) {
                                ElMessage.warning("已核销的账单不能批量核销，请重新选择");
                                complete();
                                return;
                            }
                        }
                    }
                    var paramBatchSettlement = new DtoRequestFinance();
                    paramBatchSettlement.BIllIds = this.list.SelectIds;
                    FinanceAppService.GetBatchSettlement(paramBatchSettlement)
                        .then((s) => {
                            if (s.data.Data) {
                                this.batchSettlementForm = s.data.Data;
                                this.$nextTick(() => {
                                    if (this.batchSettlementForm && this.batchSettlementForm.Items && this.batchSettlementForm.Items.length > 0) {
                                        for (var i = 0; i < this.batchSettlementForm.Items.length; i++) {
                                            var name = 'Items.' + i + '.PaymentTitle';
                                            MiezzForm.clearCustomValidate(this.$refs, "refBatchSettlementForm", name);

                                            name = 'Items.' + i + '.CollectionTitle';
                                            MiezzForm.clearCustomValidate(this.$refs, "refBatchSettlementForm", name);

                                            name = 'Items.' + i + '.Price';
                                            MiezzForm.clearCustomValidate(this.$refs, "refBatchSettlementForm", name);

                                            name = 'Items.' + i + '.DisplayRate';
                                            MiezzForm.clearCustomValidate(this.$refs, "refBatchSettlementForm", name);

                                            name = 'Items.' + i + '.SettlementDate';
                                            MiezzForm.clearCustomValidate(this.$refs, "refBatchSettlementForm", name);
                                        }
                                    }
                                });

                            }
                            this.onBatchSettlementSum();
                            complete();
                        })
                        .catch(() => {
                            this.isLoading = false;
                            complete();
                        });

                    break;
                case "removeSettlement":
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一条信息");
                        complete();
                        return;
                    }

                     var isLock01 = false;
                    var isSave101 = true;
                    if (this.list.SelectRows) {
                        for (var select2001 of this.list.SelectRows) {
                            if (select2001.IsLock == true) {
                                isLock01 = true;
                                break;
                            }

                            if (select2001.IsSave != true) {
                                isSave101 = false;
                            }

                        }
                    }
                    if (isSave101 == false) {
                        if (this.FinancialManagement_BillSummary_AddInvoicewriteOffForUnpaidBills != true) {
                            ElMessage.warning("您没有对未提交的账单进行添加发票/核销的权限，请联系有权限的用户进行操作");
                            complete();
                            return;
                        }
                    }

                    if (isLock01 == true) {
                        if (this.list.SelectIds && this.list.SelectIds.length == 1) {
                            ElMessage.warning("订单已锁定");
                            complete();
                            return;
                        }
                        else {
                            ElMessage.warning("包含已锁定的订单，请重新选择");
                            complete();
                            return;
                        }
                    }



                    ElMessageBox.confirm("是否确认删除核销记录？", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {

                            var param1201 = new DtoAssociationInvoice();
                            param1201.BillId = this.list.SelectIds;
                            this.isLoading = true;
                            FinanceAppService.RemoveSettlement(param1201).then(() => {
                                    var pa01 = new DtoRequestInvoice();
                                    pa01.BillIds = param1201.BillId;
                                    FinanceAppService.FillBillDatas(pa01).then((r1) => {

                                        this.isLoading = false;
                                        ElMessage.success("删除成功");
                                        this.handleGet();
                                        complete();
                                    })

                                })
                                .catch(() => {
                                    this.isLoading = false;
                                    complete();
                                });

                        })
                        .catch(() => {
                            complete();
                        });

                    
                    break;
                case "removeInvoice":
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一条信息");
                        complete();
                        return;
                    }

                    var isLock = false;
                    var isSave1 = true;
                    if (this.list.SelectRows) {
                        for (var select200 of this.list.SelectRows) {
                            if (select200.IsLock == true) {
                                isLock = true;
                                break;
                            }

                            if (select200.IsSave != true) {
                                isSave1 = false;
                            }

                        }
                    }
                    if (isSave1 == false) {
                        if (this.FinancialManagement_BillSummary_AddInvoicewriteOffForUnpaidBills != true) {
                            ElMessage.warning("您没有对未提交的账单进行添加发票/核销的权限，请联系有权限的用户进行操作");
                            complete();
                            return;
                        }
                    }

                    if (isLock == true) {
                        if (this.list.SelectIds && this.list.SelectIds.length == 1) {
                            ElMessage.warning("订单已锁定");
                            complete();
                            return;
                        }
                        else {
                            ElMessage.warning("包含已锁定的订单，请重新选择");
                            complete();
                            return;
                        }
                    }



                    ElMessageBox.confirm("您确定要删除选中的信息吗？", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {

                            var param12 = new DtoAssociationInvoice();
                            param12.BillId = this.list.SelectIds;
                            this.isLoading = true;
                            FinanceAppService.RemoveInvoice(param12).then(() => {
                                    var pa = new DtoRequestInvoice();
                                    pa.BillIds = param12.BillId;
                                    FinanceAppService.FillBillDatas(pa).then((r1) => {
                                        ElMessage.success("删除成功");
                                        this.isLoading = false;
                                        this.handleGet();
                                        complete();
                                    })

                                })
                                .catch(() => {
                                    this.isLoading = false;
                                    complete();
                                });

                        })
                        .catch(() => {
                            complete();
                        });


                    break;
                case "addInvoice":
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一条信息");
                        complete();
                        return;
                    }
                    this.setinvoiceForm.InvoiceId = undefined;
                    this.setinvoiceForm.BillId = [];

                    var type0 = "";
                    var count0 = 0;
                    var temp10 = [];
                    if (this.list.SelectRows) {
                        for (var bill0 of this.list.SelectRows) {
                            if (bill0.TypeString != undefined && bill0.TypeString != null && bill0.TypeString != "") {
                                var number0 = bill0.TypeString + "";
                                var isExist10 = false;
                                for (var tp10 of temp10) {
                                    if (tp10 == number0) {
                                        isExist10 = true;
                                    }
                                }
                                if (isExist10 == false) {
                                    temp10.push(number0);
                                }
                            }

                        }
                    }
                    if (temp10.length > 1) {
                        ElMessage.warning("应收账单与应付账单不能同时编辑发票信息，请重新选择");
                        complete();
                        return;
                    }

                    type0 = "";
                    count0 = 0;
                    var temp = [];
                    if (this.list.SelectRows) {
                        for (var bill10 of this.list.SelectRows) {
                            if (bill10.BillObjectId != undefined && bill10.BillObjectId != null && bill10.BillObjectId != "") {
                                var number10 = bill10.BillObjectId as string;
                                var isExist = false;
                                for (var tp of temp) {
                                    if (tp == number10) {
                                        isExist = true;
                                    }
                                }
                                if (isExist == false) {
                                    temp.push(number10);
                                }
                            }
                        }
                    }

                    if (temp.length > 1) {
                        ElMessage.warning("所选账单信息包含多个对账对象，不能同时编辑发票信息，请重新选择");
                        complete();
                        return;
                    }

                    type0 = "";
                    count0 = 0;
                    if (this.list.SelectRows) {
                        for (var bill100 of this.list.SelectRows) {
                            if (bill100.InvoiceNumber != null && bill100.InvoiceNumber != undefined && bill100.InvoiceNumber != '') {
                                count0++;
                            }
                        }
                    }
                    if (count0 > 0) {
                        ElMessage.warning("仅未添加发票号码的信息可关联，请重新选择");
                        complete();
                        return;
                    }
                    this.setinvoiceForm.BillId = [];
                    if (this.list.SelectRows) {
                        for (var bill1000 of this.list.SelectRows) {
                            if (bill1000.Id) {
                                this.setinvoiceForm.BillId.push(bill1000.Id);
                            }
                        }
                    }
                    FinanceAppService.GetInvoices(this.setinvoiceForm).then((r) => {
                        if (r.data.Data) {
                            this.invoices = r.data.Data;
                            this.setinvoiceForm.Visiable = true;
                            this.onInvoiceSum();
                        }
                        complete();
                    })

                    break;
                case "invoice":
                    var isSave = true;
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一条信息");
                        complete();
                        return;
                    }

                    if (this.list.SelectRows) {
                        for (var row14 of this.list.SelectRows) {
                            if (row14.IsLock == true) {
                                ElMessage.warning("包含已锁定的订单，请重新选择");
                                complete();
                                return;
                            }


                            if (row14.IsSave != true) {
                                isSave = false;
                            }
                        }
                    }

                    if (isSave == false) {
                        if (this.FinancialManagement_BillSummary_AddInvoicewriteOffForUnpaidBills != true) {
                            ElMessage.warning("您没有对未提交的账单进行添加发票/核销的权限，请联系有权限的用户进行操作");
                            complete();
                            return;
                        }
                    }


                    this.isLock = false;
                    if (this.list.SelectRows) {
                        for (var select of this.list.SelectRows) {
                            if (select.IsLock == true) {
                                this.isLock = true;
                                break;
                            }
                        }
                    }
                    var type = "";
                    var count = 0;
                    var temp11 = [];
                    if (this.list.SelectRows) {
                        for (var bill of this.list.SelectRows) {
                            if (bill.TypeString != undefined && bill.TypeString != null && bill.TypeString != "") {
                                var number = bill.TypeString + "";
                                var isExist11 = false;
                                for (var tp11 of temp11) {
                                    if (tp11 == number) {
                                        isExist11 = true;
                                    }
                                }
                                if (isExist11 == false) {
                                    temp11.push(number);
                                }
                            }
                        }
                    }
                    if (temp11.length > 1) {
                        ElMessage.warning("应收账单与应付账单不能同时编辑发票信息，请重新选择");
                        complete();
                        return;
                    }

                    type = "";
                    count = 0;
                    var temp1 = [];
                    if (this.list.SelectRows) {
                        for (var bill1 of this.list.SelectRows) {
                            if (bill1.BillObjectId != undefined && bill1.BillObjectId != null && bill1.BillObjectId != "") {
                                var number1 = bill1.BillObjectId as string;
                                var isExist1 = false;
                                for (var tp1 of temp1) {
                                    if (tp1 == number1) {
                                        isExist1 = true;
                                    }
                                }
                                if (isExist1 == false) {
                                    temp1.push(number1);
                                }
                            }
                        }
                    }
                    if (temp1.length > 1) {
                        ElMessage.warning("所选账单信息包含多个对账对象，不能同时编辑发票信息，请重新选择");
                        complete();
                        return;
                    }

                    type = "";
                    count = 0;
                    var invoiceTemp2 = [];
                    if (this.list.SelectRows) {
                        for (var bill2 of this.list.SelectRows) {
                            if (bill2.InvoiceNumber != undefined && bill2.InvoiceNumber != null && bill2.InvoiceNumber != "") {
                                var number2 = bill2.InvoiceNumber + "";
                                var isExist20 = false;
                                for (var tp20 of invoiceTemp2) {
                                    if (tp20 == number2) {
                                        isExist20 = true;
                                    }
                                }
                                if (isExist20 == false) {
                                    invoiceTemp2.push(number2);
                                }
                            }
                        }
                    }
                    if (invoiceTemp2.length > 1) {
                        ElMessage.warning("所选账单信息的发票号码不同，无法同时编辑，请重新选择");
                        complete();
                        return;
                    }

                    if (this.FinancialManagement_BillSummary_InvoiceInformation == true) {
                        var param1 = new DtoRequestFinance();
                        param1.BillIds = this.list.SelectIds;
                        FinanceAppService.GetInvoice(param1).then((r) => {
                            if (r.data.Data) this.invoiceForm = r.data.Data;
                            //this.invoiceForm.PaymentTitle = this.invoiceForm.PaymentTitle;
                            //this.invoiceForm.CollectionTitle =
                            this.invoiceFormInit = JSON.parse(JSON.stringify(this.invoiceForm));
                            this.onInvoiceSum();
                            complete();
                        }).catch(() => {
                            this.isLoading = false;
                            complete();
                        });
                    }
                    break;
                case "stat":
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一条信息");
                        complete();
                        return;
                    }
                    this.isLoading = true;
                    FinanceAppService.Stat(this.list.SelectRows).then((r) => {
                        this.isLoading = false;
                        if (r.data.Data) {
                            this.statForm = r.data.Data;
                            this.statForm.Visiable = true;

                            if (this.statForm && this.statForm.Sum) {
                                var r1 = this.statForm.Sum;
                                var collection = "";
                                if (r1.Collecionts) {
                                    for (var c of r1.Collecionts) {
                                        collection += " " + c.CurrencySystgemCode + c.Price;
                                    }
                                }

                                var noCollection = "";
                                if (r1.NoCollecionts) {
                                    for (var c1 of r1.NoCollecionts) {
                                        noCollection += " " + c1.CurrencySystgemCode + c1.Price;
                                    }
                                }

                                var payment = "";
                                if (r1.Payments) {
                                    for (var p of r1.Payments) {
                                        payment += " " + p.CurrencySystgemCode + p.Price;
                                    }
                                }

                                var noPayment = "";
                                if (r1.NoPayments) {
                                    for (var p1 of r1.NoPayments) {
                                        noPayment += " " + p1.CurrencySystgemCode + p1.Price;
                                    }
                                }

                                var profit = "";
                                if (r1.Profits) {
                                    for (var pro of r1.Profits) {
                                        profit += " " + pro.CurrencySystgemCode + pro.Price;
                                    }
                                }

                                var badCollection = "";
                                if (r1.BadCollections) {
                                    for (var bc of r1.BadCollections) {
                                        badCollection += " " + bc.CurrencySystgemCode + bc.Price;
                                    }
                                }

                                var badPayment = "";
                                if (r1.BadPayments) {
                                    for (var bp of r1.BadPayments) {
                                        badPayment += " " + bp.CurrencySystgemCode + bp.Price;
                                    }
                                }

                                var invoice = "";
                                if (r1.Invoices) {
                                    for (var inv of r1.Invoices) {
                                        invoice += " " + inv.CurrencySystgemCode + inv.Price;
                                    }
                                }

                                var collectionInvoice = "";
                                if (r1.CollectionInvoices) {
                                    for (var cInv of r1.CollectionInvoices) {
                                        collectionInvoice += " " + cInv.CurrencySystgemCode + cInv.Price;
                                    }
                                }

                                var paymentInvoice = "";
                                if (r1.PaymentInvoices) {
                                    for (var pInv of r1.PaymentInvoices) {
                                        paymentInvoice += " " + pInv.CurrencySystgemCode + pInv.Price;
                                    }
                                }

                                var collectionInvoiceSettlement = "";
                                if (r1.CollectionInvoiceSettlements) {
                                    for (var cInv1 of r1.CollectionInvoiceSettlements) {
                                        collectionInvoiceSettlement += " " + cInv1.CurrencySystgemCode + cInv1.Price;
                                    }
                                }

                                var paymentInvoiceSettlement = "";
                                if (r1.PaymentInvoiceSettlements) {
                                    for (var pInv1 of r1.PaymentInvoiceSettlements) {
                                        paymentInvoiceSettlement += " " + pInv1.CurrencySystgemCode + pInv1.Price;
                                    }
                                }

                                var collectionColor = "black";
                                var paymentColor = "black";
                                if (r1.CollectionStatus == 20) {
                                    collectionColor = "red";
                                }
                                else if (r1.CollectionStatus == 30) {
                                    collectionColor = "#fe995b";
                                }
                                if (r1.PaymentStatus == 20) {
                                    paymentColor = "red";
                                }
                                else if (r1.PaymentStatus == 30) {
                                    paymentColor = "#fe995b";
                                }

                                if (collection == "") {
                                    collection = "CNY0";
                                }
                                if (payment == "") {
                                    payment = "CNY0";
                                }
                                if (profit == "") {
                                    profit = "CNY0";
                                }
                                if (noCollection == "") {
                                    noCollection = "CNY0";
                                }
                                if (noPayment == "") {
                                    noPayment = "CNY0";
                                }
                                if (badCollection == "") {
                                    badCollection = "CNY0";
                                }
                                if (badPayment == "") {
                                    badPayment = "CNY0";
                                }

                                if (invoice == "") {
                                    invoice = "CNY0";
                                }
                                if (collectionInvoice == "") {
                                    collectionInvoice = "CNY0";
                                }
                                if (paymentInvoice == "") {
                                    paymentInvoice = "CNY0";
                                }

                                if (collectionInvoiceSettlement == "") {
                                    collectionInvoiceSettlement = "CNY0";
                                }
                                if (paymentInvoiceSettlement == "") {
                                    paymentInvoiceSettlement = "CNY0";
                                }

                                var collectionInvoiceSettlementString = collectionInvoiceSettlement != collectionInvoice ? ("应收发票换算合计：" + collectionInvoiceSettlement + " ") : "";
                                var paymentInvoiceSettlementString = paymentInvoiceSettlement != paymentInvoice ? ("应付发票换算合计：" + paymentInvoiceSettlement + " ") : "";

                                if (this.statForm != null && this.statForm.CollectionCount != null && this.statForm.PaymentCount != null) {
                                    if (this.statForm.CollectionCount > 0 && this.statForm.PaymentCount <= 0) {
                                        this.statForm.SumString = "<div style='clear:both;font-size:14px;line-height:26px;font-weight:900;margin-top:-15px;'>"
                                            + "应收合计：<span style='color:" + collectionColor + ";'>" + collection + "</span>   "
                                            + "应收坏账合计：" + badCollection + " "
                                            + "<br/>"
                                            + "未收合计：" + noCollection + " "
                                            + "<br/>"
                                            + "关联应收发票合计：" + collectionInvoice + " "
                                            + "<br/>"
                                            + collectionInvoiceSettlementString
                                            + "</div>";
                                    }
                                    else if (this.statForm.CollectionCount <= 0 && this.statForm.PaymentCount > 0) {
                                        this.statForm.SumString = "<div style='clear:both;font-size:14px;line-height:26px;font-weight:900;margin-top:-15px;'>"
                                            + "应付合计：<span style='color:" + paymentColor + ";'>" + payment + "</span>   "
                                            + "应付坏账合计：" + badPayment + " "
                                            + "<br/>"
                                            + "未付合计：" + noPayment + " "
                                            + "<br/>"
                                            + "关联应付发票合计：" + paymentInvoice + " "
                                            + "<br/>"
                                            + paymentInvoiceSettlementString
                                            + "</div>";
                                    }
                                    else {
                                        this.statForm.SumString = "<div style='clear:both;font-size:14px;line-height:26px;font-weight:900;margin-top:-15px;'>"
                                            + "应收合计：<span style='color:" + collectionColor + ";'>" + collection + "</span>   "
                                            + "应付合计：<span style='color:" + paymentColor + ";'>" + payment + "</span>   "
                                            + "应收坏账合计：" + badCollection + " "
                                            + "应付坏账合计：" + badPayment + " "
                                            + "毛利合计：" + profit + " "
                                            + "<br/>"
                                            + "未收合计：" + noCollection + " "
                                            + "未付合计：" + noPayment + " "
                                            + "<br/>"
                                            + "关联应收发票合计：" + collectionInvoice + " "
                                            + "关联应付发票合计：" + paymentInvoice + " "
                                            + "<br/>"
                                            + collectionInvoiceSettlementString
                                            + paymentInvoiceSettlementString
                                            + "</div>";
                                    }
                                }
        

                                if (this.statForm && this.statForm.InvoiceItems) {
                                    for (var inv1 of this.statForm.InvoiceItems) {
                                        inv1.InvoiceDateString = "";
                                        if (inv1.InvoiceDate) {
                                            inv1.InvoiceDateString = moment(inv1.InvoiceDate).format("YYYY/MM/DD");
                                        }
                                    }
                                }
                            }
                        }
                        complete();
                    }).catch(() => {
                        this.isLoading = false;
                        complete();
                    });

                    break;
                default:
                    break;
            }
        }
        onAccountPeriod(row: any): void {
         
            this.isSelectAccount = true;
            this.accounts = [];
            var param = new DtoRequestAccount();
            if (row.Cost != null && row.Cost != undefined) {
                param.CostName = row.Cost.FullName;
            }
            if (row.Type == 10) {
                param.CompanyId = row.ClientCompanyId;
            }
            else if(row.Type == 20)
            {
                param.CompanyId = row.SupplierCompanyId;
            }
            //BillAppService.GetAccount(param).then((r) => {
            //    if (r.data.Data) {
            //        this.accounts = r.data.Data;
            //    }
            //    this.accountPeriodRuleForm.SelectId = [];
            //    this.accountPeriodRuleForm.SelectId.push(row.Id);
            //    this.accountPeriodRuleForm.AccountPeriod = "";
            //    this.accountPeriodRuleForm.Visiable = true;
            //    this.$nextTick(() => {
            //        MiezzForm.clearCustomValidate(this.$refs, "refAccountPeriodRuleForm", "AccountPeriod");
            //    })

            //});


            var pa = new DtoAssociationInvoice();
            pa.BillId = [];
            pa.BillId.push(row.Id);
            FinanceAppService.GetAccountPeriod(pa).then((r1) => {
                this.accountPeriodRuleForm.SelectId = [];
                this.accountPeriodRuleForm.SelectId.push(row.Id);
                if (r1.data.Data && r1.data.Data.AccountPeriod) {
                    this.accountPeriodRuleForm.AccountPeriod = r1.data.Data?.AccountPeriod + "";
                }
                else {
                    this.accountPeriodRuleForm.AccountPeriod = "";
                }
                this.accountPeriodRuleForm.Time = r1.data.Data?.Time;
                this.accountPeriodRuleForm.Visiable = true;
                this.$nextTick(() => {
                    MiezzForm.clearCustomValidate(this.$refs, "refAccountPeriodRuleForm", "AccountPeriod");
                    MiezzForm.clearCustomValidate(this.$refs, "refAccountPeriodRuleForm", "Time");
                })
            })

           
        }
        handleClickAccountPeriod(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    MiezzForm.customSubmit(this.$refs, "refAccountPeriodRuleForm", (v) => {
                        if (v == true) {
                            var param = new DtoFormAccountPeriod();
                            param.BillIds = this.accountPeriodRuleForm.SelectId;
                            param.AccountPeriod = Number(this.accountPeriodRuleForm.AccountPeriod);
                            param.Time = this.accountPeriodRuleForm.Time;
                            FinanceAppService.UpdateAccountPeriod(param).then((r) => {
                                var pa = new DtoRequestInvoice();
                                pa.BillIds = param.BillIds;
                                FinanceAppService.FillBillDatas(pa).then((r1) => {
                                    this.handleGet();
                                    ElMessage.success("设置账期成功");
                                    this.isSelectAccount = false;
                                    this.accountPeriodRuleForm.Visiable = false;
                                    complete();
                                })
                            })
                        }
                        else {
                            complete();
                        }
                    });
                    break;
                case "close":
                    this.isSelectAccount = false;
                    this.accountPeriodRuleForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
        onChangePeriod(): void {
            if (this.accountPeriodRuleForm) {
                var accountPeriod = this.accountPeriodRuleForm.AccountPeriod;
                if (accountPeriod != undefined) {
                    var result = setDecimal(accountPeriod + "", 0, true, false);
                    if (result != "") {
                        this.accountPeriodRuleForm.AccountPeriod = Number(result) + "";
                    }
                    else {
                        this.accountPeriodRuleForm.AccountPeriod = "";
                    }
                }
            }
        }
        querySearchAccount(queryString: string, cb: any) {
            var results = [];
            var accounts = this.accounts;
            if (accounts) {
                var restaurants = accounts;
                if (!restaurants) {
                    restaurants = [];
                }

                if (queryString != null && queryString && queryString != "null" && queryString != '' && accounts) {
                    queryString = queryString.toLowerCase();
                    var number = 0;
                    for (var it of accounts) {
                        if (it.Label) {
                            var Name = it.Label.toLowerCase();
                            if (Name.indexOf(queryString) >= 0 && number < 30) {
                                results.push(it);
                                number++;
                            }
                        }
                    }
                }
                else {
                    if (this.accounts) {
                        var number1 = 0;
                        if (number1 <= 0) {
                            for (var i = 0; i < accounts.length; i++) {
                                results.push(accounts[i])
                            }
                        }
                    }
                }
            }
            cb(results);

        }
        handleSelectAccount(item: UiSelectOption<string>) {
            if (this.accountPeriodRuleForm) {
                this.accountPeriodRuleForm.AccountPeriod = item.Data.AccountPeriodDay;
            }
        }
        onChangeAccountPeriod(): void {
            if (this.accountPeriodRuleForm) {
                var accountPeriod = this.accountPeriodRuleForm.AccountPeriod;
                if (accountPeriod != undefined) {
                    var result = setDecimal(accountPeriod.toString(), 0, true, false);
                    if (result != "") {
                        this.accountPeriodRuleForm.AccountPeriod = Number(result) + "";
                    }
                    else {
                        this.accountPeriodRuleForm.AccountPeriod = "";
                    }
                }
            }
        }

        onStartDate(row: any): void {
            this.startDateRuleForm.SelectId = [];
            this.startDateRuleForm.SelectId.push(row.Id);
            this.startDateRuleForm.Time = undefined;
            this.startDateRuleForm.Visiable = true;
            this.$nextTick(() => {
                MiezzForm.clearCustomValidate(this.$refs, "refStartDateRuleForm", "Time");
            })
        }

        onFinancialCycle(row: any): void {
            this.startDateRuleForm.SelectId = [];
            this.startDateRuleForm.SelectId.push(row.Id);
            this.startDateRuleForm.Time = undefined;
            this.startDateRuleForm.Visiable = true;
            this.$nextTick(() => {
                MiezzForm.clearCustomValidate(this.$refs, "refStartDateRuleForm", "Time");
            })
        }


        handleClickStartDate(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    MiezzForm.customSubmit(this.$refs, "refTimeRuleForm", (v) => {
                        if (v == true) {
                            var param = new DtoFormStartDate();
                            param.BillIds = this.startDateRuleForm.SelectId;
                            param.StartDate = this.startDateRuleForm.Time;
                            FinanceAppService.UpdateFinancialCycle(param).then((r) => {
                                var pa = new DtoRequestInvoice();
                                pa.BillIds = param.BillIds;
                                FinanceAppService.FillBillDatas(pa).then((r1) => {
                                    this.handleGet();
                                    ElMessage.success("设置财务周期成功");
                                    this.startDateRuleForm.Visiable = false;
                                })
                            })
                            complete();
                        }
                        else {
                            complete();
                        }
                    });

                    break;
                case "close":
                    this.startDateRuleForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**提交表单回调 */
        handleSubmit(): void {
            this.modalForm.Show = false;
            this.handleGet();
        }

        /**删除回调 */
        handleDelete(): void {
            this.modalDetail.Show = false;
            this.handleGet();
        }

        onChangeInvoiceNumber(row: any): void {
            if (row.Number != null && row.Number != undefined) {
                row.Number = trimSpecial(row.Number).toUpperCase();
            }

            var count = 0;
            if (this.invoiceForm.InvoiceItems) {
                for (var it1 of this.invoiceForm.InvoiceItems) {
                    if (it1.Number == row.Number) {
                        count++;
                    }
                }
            }
            if (count > 1) {
                return;
            }

            var param = new DtoDetailInvoice();
            param.Number = row.Number;
            param.BillId = this.invoiceForm.BillIds;
            FinanceAppService.GetOneInvoice(param).then((r) => {
                if (r.data.Data) {
                    if (r.data.Data.IsInvoice == true && r.data.Data.BillObjectCount != undefined && r.data.Data.BillObjectCount <= 0) {
                        row.CollectionTitle = r.data.Data.CollectionTitle;
                        row.PaymentTitle = r.data.Data.PaymentTitle;
                        row.InvoiceDate = r.data.Data.InvoiceDate;
                        row.Annexs = r.data.Data.Annexs;
                        row.Remark = r.data.Data.Remark;
                        row.CurrencySystemId = r.data.Data.CurrencySystemId;
                        row.CurrencySystem = r.data.Data.CurrencySystem;
                        row.SettlementCurrencySystemId = r.data.Data.SettlementCurrencySystemId;
                        row.SettlementPrice = r.data.Data.SettlementPrice;
                        row.SettlementRate = r.data.Data.SettlementRate;
                        row.Price = r.data.Data.Price;
                    }
                    else {
                        row.Annexs = undefined;
                        //row.InvoiceDate = undefined;
                    }
                }
                this.onInvoiceSum();
            })
        }

        IsInvoiceSettlementSame = true; //发票合计与发票换算合计金额是否一致
        IsBillSame = true; // 账单合计与发票换算合计金额是否一致
        IsInvoiceSame = true; //账单合计与发票合计金额是否一致
        async onInvoiceSum(): Promise<void> {
            //var bill = 0;
            //var totalBill = 0;
            //var invoice = 0;
            //var settlementInvoice = 0;


            var billSum = 0; //账单金额
            var settlementSum = 0;//发票换算合计金额
            var invoiceSum = 0; //发票合计
            if (this.invoiceForm) {
                this.IsInvoiceSettlementSame = true; //发票合计与发票换算合计金额是否一致
                this.IsBillSame = true; // 账单合计与发票换算合计金额是否一致
                this.IsInvoiceSame = true; //账单合计与发票合计金额是否一致
                if (this.invoiceForm.Bills && this.invoiceForm.Bills.length > 0) {
                    for (var b of this.invoiceForm.Bills) {
 
                        if (b.CurrencySystemCode != undefined && b.CurrencySystemCode != null && b.CurrencySystemCode != "") {
                            if (b.CurrencySystemCode.toLocaleUpperCase() == "CNY") {
                                if (b.Price) {
                                    billSum += b.Price;
                                }
                            }
                            else {
                                await ParamCurrencySystemAppService.GetExchangeRateByFromByTo(b.CurrencySystemCode, "CNY").then((r1) => {
                                    var rate1 = r1.data.Data;
                                    if (rate1 != undefined && rate1 != "") {
                                        if (b.Price) {
                                            billSum += b.Price * Number(rate1);
                                        }
                                    }
                                })
                            }
                        }
                    }
                }
                this.invoiceForm.InvoiceString = "CNY0";
                this.invoiceForm.SettlementString = "CNY0";
                var priceString = "";
                var settlementPriceString = "";
                if (this.invoiceForm.InvoiceItems && this.invoiceForm.InvoiceItems.length > 0) {
                    var codes = [];
                    var settlementCodes = [];
                    for (var it of this.invoiceForm.InvoiceItems) {
                        if (it.CurrencySystem != undefined && it.CurrencySystem != null) {
                            var exist = false;
                            for (var code of codes) {
                                if (code == it.CurrencySystem.Code) {
                                    exist = true;
                                    break;
                                }
                            }
                            if (exist == false) {
                                codes.push(it.CurrencySystem.Code);
                            }

                            if (it.CurrencySystem.Code?.toLocaleUpperCase() == "CNY") {
                                if (it.Price) {
                                    invoiceSum += it.Price;
                                }
                            }
                            else {
                                await ParamCurrencySystemAppService.GetExchangeRateByFromByTo(it.CurrencySystem.Code, "CNY").then((r100) => {
                                    var rate100 = r100.data.Data;
                                    if (rate100 != undefined && rate100 != "") {
                                        if (it.Price) {
                                            invoiceSum += it.Price * Number(rate100);
                                        }
                                    }
                                })
                            }
                        }

                        if (it.SettlementCurrencySystem != undefined && it.SettlementCurrencySystem != null) {
                            var exist01 = false;
                            for (var settlementCode of settlementCodes) {
                                if (settlementCode == it.SettlementCurrencySystem.Code) {
                                    exist01 = true;
                                    break;
                                }
                            }
                            if (exist01 == false) {
                                settlementCodes.push(it.SettlementCurrencySystem.Code);
                            }

                            if (it.SettlementCurrencySystem.Code?.toLocaleUpperCase() == "CNY") {
                                if (it.SettlementPrice) {
                                    settlementSum += it.SettlementPrice;
                                }
                            }
                            else {
                                await ParamCurrencySystemAppService.GetExchangeRateByFromByTo(it.SettlementCurrencySystem.Code, "CNY").then((r101) => {
                                    var rate101 = r101.data.Data;
                                    if (rate101 != undefined && rate101 != "") {
                                        if (it.SettlementPrice) {
                                            settlementSum += it.SettlementPrice * Number(rate101);
                                        }
                                    }
                                })
                            }
                        }
                    }
                    if (codes.length > 0) {
                        for (var c of codes) {
                            var price = 0;
                            for (var it1 of this.invoiceForm.InvoiceItems) {
                                if (it1.CurrencySystem != null && it1.CurrencySystem != undefined) {
                                    if (it1.CurrencySystem.Code == c) {
                                        if (it1.Price) {
                                            price += it1.Price;
                                        }
                                    }
                                }
                            }
                            if (price > 0 && c) {

                                var result1 = setDecimal(price.toString(), 2, false, true);
                                if (result1 != "") {
                                    price = Number(result1);
                                }

                                priceString += c + result1 + " ";
                            }
                        }
                    }
                    if (settlementCodes.length > 0) {
                        for (var c1 of settlementCodes) {
                            var price1 = 0;
                            for (var it11 of this.invoiceForm.InvoiceItems) {
                                if (it11.SettlementCurrencySystem != null && it11.SettlementCurrencySystem != undefined) {
                                    if (it11.SettlementCurrencySystem.Code == c1) {
                                        if (it11.SettlementPrice) {
                                            price1 += it11.SettlementPrice;
                                        }
                                    }
                                }
                            }
                            if (price1 > 0 && c1) {

                                var result11 = setDecimal(price1.toString(), 2, false, true);
                                if (result11 != "") {
                                    price = Number(result11);
                                }

                                settlementPriceString += c1 + result11 + " ";
                            }
                        }
                    }
                }
              


                this.$nextTick(() => {
                    if (this.invoiceForm && this.invoiceForm.InvoiceItems) {
                        for (var i = 0; i < this.invoiceForm.InvoiceItems.length; i++) {
                            MiezzForm.clearCustomValidate(this.$refs, "refInvoiceForm", "InvoiceItems." + i + ".Number");
                        }
                    }

                    if (this.invoiceForm && priceString != "") {
                        this.invoiceForm.InvoiceString = priceString;
                    }
                    if (this.invoiceForm && settlementPriceString != "") {
                        this.invoiceForm.SettlementString = settlementPriceString;
                    }
                   
                    if (billSum) {
                        billSum = parseFloat(billSum.toFixed(2));
                        //billSum = parseInt((billSum + ""), 10);
                    }
                    if (settlementSum) {
                        settlementSum = parseFloat(settlementSum.toFixed(2));
                        //settlementSum = parseInt((settlementSum + ""), 10);
                    }
                    if (invoiceSum) {
                        invoiceSum = parseFloat(invoiceSum.toFixed(2));
                        //invoiceSum = parseInt((invoiceSum + ""), 10);
                    }

      
                    if (settlementSum > 0 && settlementSum != invoiceSum) {
                        this.IsInvoiceSettlementSame = false;
                    }
                    if (billSum > 0 && billSum != settlementSum) {
                        this.IsBillSame = false;
                    }
                    if (billSum > 0 && billSum != invoiceSum) {
                        this.IsInvoiceSame = false;
                    }

                });


            }
        }

        onSettlementSum(): void {
            if (this.settlementForm) {
                this.settlementForm.SettlementPrice = "CNY0";
                var priceString = "";
                if (this.settlementForm.Items && this.settlementForm.Items.length > 0) {
                    var codes = [];
                    for (var it of this.settlementForm.Items) {
                        if (it.CurrencySystem != undefined && it.CurrencySystem != null) {
                            var exist = false;
                            for (var code of codes) {
                                if (code == it.CurrencySystem.Code) {
                                    exist = true;
                                    break;
                                }
                            }
                            if (exist == false) {
                                codes.push(it.CurrencySystem.Code);
                            }
                        }
                    }
                    if (codes.length > 0) {
                        for (var c of codes) {
                            var price = 0;
                            for (var it1 of this.settlementForm.Items) {
                                if (it1.CurrencySystem != null && it1.CurrencySystem != undefined) {
                                    if (it1.CurrencySystem.Code == c) {
                                        if (it1.Price) {
                                            price += it1.Price;
                                        }
                                    }
                                }
                            }
                            if (price > 0 && c) {

                                var result1 = setDecimal(price.toString(), 2, false, true);
                                if (result1 != "") {
                                    price = Number(result1);
                                }


                                priceString += c + price + " ";
                            }
                        }
                    }
                }
                if (priceString != "") {
                    this.settlementForm.SettlementPrice = priceString;
                }
            }
        }



        onBatchSettlementSum(): void {
            if (this.batchSettlementForm && this.batchSettlementForm.Items && this.batchSettlementForm.Items.length > 0) {
                this.batchSettlementForm.Sums = [];

                var billObjects = [];
                for (var it of this.batchSettlementForm.Items) {
                    var flag = false;
                    for (var obj of billObjects) {
                        if (obj == it.BillObject) {
                            flag = true;
                        }
                    }
                    if (flag == false) {
                        billObjects.push(it.BillObject);
                    }
                }
                if (billObjects.length > 0) {
                    for (var bObj of billObjects) {
                        var item = [];
                        for (var it1 of this.batchSettlementForm.Items) {
                            if (it1.BillObject == bObj) {
                                item.push(it1);
                            }
                        }
                        //var su
                        var priceString = "";
                        var codes = [];
                        for (var it2 of item) {
                            if (it2.CurrencySystem != undefined && it2.CurrencySystem != null) {
                                var exist = false;
                                for (var code of codes) {
                                    if (code == it2.CurrencySystem.Code) {
                                        exist = true;
                                        break;
                                    }
                                }
                                if (exist == false) {
                                    codes.push(it2.CurrencySystem.Code);
                                }
                            }
                        }

                        if (codes.length > 0) {
                            for (var c of codes) {
                                var price = 0;
                                for (var it3 of item) {
                                    if (it3.CurrencySystem != null && it3.CurrencySystem != undefined) {
                                        if (it3.CurrencySystem.Code == c) {
                                            if (it3.Price) {
                                                price += it3.Price;
                                            }
                                        }
                                    }
                                }
                                if (price != 0 && c) {
                                    var result11 = setDecimal(price.toString(), 2, false, true);
                                    if (result11 != "") {
                                        price = Number(result11);

                                    }

                                    priceString += c + price + " ";
                                }
                            }
                        }
                        priceString = priceString != "" ? priceString : "CNY0";


                        var billPriceString = "";
                        var billCodes = [];
                        for (var it4 of item) {
                            if (it4.BillCurrencySystem != undefined && it4.BillCurrencySystem != null) {
                                var exist1 = false;
                                for (var code1 of billCodes) {
                                    if (code1 == it4.BillCurrencySystem.Code) {
                                        exist1 = true;
                                        break;
                                    }
                                }
                                if (exist1 == false) {
                                    billCodes.push(it4.BillCurrencySystem.Code);
                                }
                            }
                        }

                        var isZero = true;
                        if (billCodes.length > 0) {

                            for (var c1 of billCodes) {
                                var price1 = 0;
                                for (var it5 of item) {
                                    if (it5.BillCurrencySystem != null && it5.BillCurrencySystem != undefined && it5.Price) {
                                        if (it5.BillCurrencySystem.Code == c1) {
                                            if (it5.NoSettlementPrice && it5.Price && it5.Rate) {
                                                price1 += it5.NoSettlementPrice - it5.Price / it5.Rate;
                                            }
                                        }
                                    }
                                    else {
                                        if (it5.BillCurrencySystem != null && it5.BillCurrencySystem != undefined && it5.NoSettlementPrice && it5.BillCurrencySystem.Code == c1) {
                                            price1 += it5.NoSettlementPrice;
                                        }
                                    }
                                }

                                if (price1 != 0 && c1) {
                                    var result1 = setDecimal(price1.toString(), 2, false, true);
                                    if (result1 != "") {
                                        price1 = Number(result1);

                                    }
                                    else {
                                        price1 = 0;
                                    }
                                    if (price1 != 0) {
                                        billPriceString += "<span style='color:red;'>" + c1 + price1 + " " + '</span>';
                                        isZero = false;
                                    }
                                    else {
                                        billPriceString += c1 + price1 + " ";
                                    }
                                }
                            }
                        }
                        billPriceString = billPriceString != "" ? billPriceString : "CNY0";

                        if (this.batchSettlementForm.IsNumber == true) {
                            if (isZero == true) {
                                this.batchSettlementForm.IsNumber = false;
                            }
                        }


                        var sumString = "对账企业：" + bObj + " 本次核销后待核销合计：" + billPriceString + " 本次核销金额：" + priceString;
                        this.batchSettlementForm.Sums.push(sumString);

                    }

                }
            }
        }

        querySearchBLNOAsync(queryString: string, cb: any) {
            var restaurants = this.blnos;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.blnos) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.blnos) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 10) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.blnos) {
                    for (var i = 0; i < this.blnos.length; i++) {
                        if (i < 10) {
                            results.push(this.blnos[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectBLNO(item: UiSelectOption<string>, model: any) {
            model.Where.Value = item.Label;
        }

        querySearchSalerAsync(queryString: string, cb: any) {
            var restaurants = this.salers;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.salers) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.salers) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 10) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.salers) {
                    for (var i = 0; i < this.salers.length; i++) {
                        if (i < 10) {
                            results.push(this.salers[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectSaler(item: UiSelectOption<string>, model: any) {
            model.Where.Value = item.Label;
        }

        querySearchCostAsync(queryString: string, cb: any) {
            var restaurants = this.costs;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.costs) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.costs) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 10) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.costs) {
                    for (var i = 0; i < this.costs.length; i++) {
                        if (i < 10) {
                            results.push(this.costs[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectCost(item: UiSelectOption<string>, model: any) {
            model.Where.Value = item.Label;
        }

        querySearchBoxNumberAsync(queryString: string, cb: any) {
            var restaurants = this.containers;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.containers) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.containers) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 10) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.containers) {
                    for (var i = 0; i < this.containers.length; i++) {
                        if (i < 10) {
                            results.push(this.containers[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectBoxNumber(item: UiSelectOption<string>, model: any) {
            model.Where.Value = item.Label;
        }

        querySearchObjectAsync(queryString: string, cb: any) {
            var restaurants = this.billObjects;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.billObjects) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.billObjects) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 10) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.billObjects) {
                    for (var i = 0; i < this.billObjects.length; i++) {
                        if (i < 10) {
                            results.push(this.billObjects[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectObject(item: UiSelectOption<string>, model: any) {
            model.Where.Value = item.Label;
        }

        //makers
        querySearchMakeUserAsync(queryString: string, cb: any) {
            var restaurants = this.makers;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.makers) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.makers) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 10) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.makers) {
                    for (var i = 0; i < this.makers.length; i++) {
                        if (i < 10) {
                            results.push(this.makers[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectMakeUser(item: UiSelectOption<string>, model: any) {
            model.Where.Value = item.Label;
        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    /*isLessZero：是否允许输入小于0 */
    var setDecimal = function (val: string, num: number, isZero: boolean, isLessZero: boolean) {

        var str = val;
        if (val == null || val == undefined || val == '') {
            return '';
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, '$1$2.$3'); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
            if (isLessZero == true) {
                if (str[0] == "-") {
                    val = "-" + val;
                }
            }
        }

        if (val == "" || val == undefined || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        }
        else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        }
        else {
            return result;
        }
    };

    //去除特殊字符~!@#$^-&*()=|{}':;',\[].<>/?~！@#￥……&*（）——|{}【】'；：""'。，、？
    function trimSpecial(str: string) {

        var name = "";
        if (str != undefined && str != "") {
            str = str.replace(/[\u4e00-\u9fa5]/g, '');
            for (var i = 0; i < str.length; i++) {
                if (str[i] == "　") {
                    name += " ";
                }
                else {
                    name += str[i];
                }
            }
            //全角空格转换成半角空格
            str = name;
            name = "";

            for (var j = 0; j < str.length; j++) {
                //if (str[j] != " " && str[j] != "~" && str[j] != "!" && str[j] != "@"
                //    && str[j] != "#" && str[j] != "$" && str[j] != "^"
                //    && str[j] != "-" && str[j] != "&" && str[j] != "*"
                //    && str[j] != "(" && str[j] != ")" && str[j] != "="
                //    && str[j] != "|" && str[j] != "{" && str[j] != "}"
                //    && str[j] != "'" && str[j] != ":" && str[j] != "'"
                //    && str[j] != "," && str[j] != "\\" && str[j] != "["
                //    && str[j] != "]" && str[j] != "." && str[j] != "<"
                //    && str[j] != ">" && str[j] != "/" && str[j] != "?"
                //    && str[j] != "~" && str[j] != "！" && str[j] != "@"
                //    && str[j] != "#" && str[j] != "￥" && str[j] != "…"
                //    && str[j] != "&" && str[j] != "*" && str[j] != "（"
                //    && str[j] != "）" && str[j] != "—" && str[j] != "|"
                //    && str[j] != "{" && str[j] != "}" && str[j] != "【"
                //    && str[j] != "】" && str[j] != "'" && str[j] != "；"
                //    && str[j] != "：" && str[j] != "\"" && str[j] != "\""
                //    && str[j] != "'" && str[j] != "。" && str[j] != "，"
                //    && str[j] != "、" && str[j] != "？"
                //) {
                //    name += str[j];
                //}
                if (str[j] != " ") {
                    name += str[j];
                }
            }

            str = str.replace(/[\u00A0-\u00FF]|[\u0100-\u017F]|[\u0180-\u024F]/g, '?');
            //替换罗马数字到千位
            str = str.replace(/(?=[MDCLXVI])M*(C[MD]|D?C{0,3})(X[CL]|L?X{0,3})(I[XV]|V?I{0,3})$/g, '?');
            //罗马1-9个数位限制和整数位
            str = str.replace(/[\u2160-\u2183]/g, '?');
            //替换中文标点符号
            str = str.replace(/[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g, '?');

        }
        return name;
    }


    import moment from "moment";
    import MiezzForm from "@/models/MiezzForm";
    import CurrentLogier from "@/apis/CurrentLogier";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzModal from "@/models/MiezzModal";
    import MiezzButton from "@/models/MiezzButton";
    import BillAppService from "@/apis.machine/BillAppService";
    import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
    import DtoCallbackSingleBill from "@/models.machine/DtoCallbackSingleBill";
    import DtoCallbackShare from "@/models.machine/DtoCallbackShare";
    import DtoRequestBill, {
        DtoRequestBillMiezzDetailItems,
        DtoRequestBillRules,
    } from "@/models.machine/DtoRequestBill";
    import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
    import { ElMessageBox } from "element-plus";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import { EnumBillShareType, EnumBillShareTypeOption } from "@/models.machine/EnumBillShareType";
    import DtoRequestOrderLock from "@/models.machine/DtoRequestOrderLock";
    import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
    import { EnumRelation } from "@/models.machine/EnumRelation";
    import { EnumCompare } from "@/models.machine/EnumCompare";
    import {
        EnumBillType
    } from "@/models.machine/EnumBillType";
    export default class BillPageList extends Vue {
        lockParam = new DtoRequestOrderLock();
        EnumBillShareTypeOption = EnumBillShareTypeOption;
        /**分页列表 */
        dto = new DtoCallbackSingleBill();
        init = new DtoCallbackSingleBill();
        form = new DtoCallbackShare();
        param = new DtoRequestBill();
        menuTab = new MiezzMenuTab();
        /*委托书主键 */
        id?: string;
        /*订单主键 */
        orderId?: string;
        /*0表示委托书账单，1表示订单账单 */
        type = 0;
        /*账单类型 */
        billType = EnumBillType.Share;
        title = "分摊应付账单";
        isLoading = false;

        selects?: string[];
        selectTos?: string[];

        isBatchRate = false;
        batchRateForm: {
            Visiable: boolean,
            Rate?: number,
        } = {
                Visiable: false,
                Rate: undefined,
            };
        batchRateBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "save",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        batchVolumeRatioForm: {
            Visiable: boolean,
            VolumeRatio?: number,
        } = {
                Visiable: false,
                VolumeRatio: undefined,
            };
        batchVolumeRatioBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "save",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        batchMiniStandardRatioForm: {
            Visiable: boolean,
            MiniStandardRatio?: number,
        } = {
                Visiable: false,
                MiniStandardRatio: undefined,
            };
        batchMiniStandardRatioBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "save",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        bottomButtons?: MiezzButton[] = [
            {
                Code: "back",
                Label: "返回",
                MiezzIcon: "back",
                Type: "text",
                Size: "small",
            },
            {
                Code: "share",
                Label: "分摊",
                Icon: "Share",
                Type: "text",
                Size: "small",
            },
            //{
            //    Code: "reset",
            //    Label: "重置",
            //    Icon: "Refresh",
            //    Type: "text",
            //    Size: "small",
            //},
        ];

      //shareBottomButtons?: MiezzButton[] = [
      //      {
      //          Code: "next",
      //          Label: "下一步",
      //          Type: "primary",
      //          Size: "small",

      //      },
      //      {
      //          Code: "save",
      //          Label: "确定",
      //          Type: "primary",
      //          Size: "small",
      //          Hide: true,
      //      },
      //      {
      //          Code: "pre",
      //          Label: "返回上一步",
      //          Type: "primary",
      //          Size: "small",
      //          Hide: true,
      //      },
      //      {
      //          Code: "close",
      //          Label: "关闭",
      //          Type: "default",
      //          Size: "small",
      //      },
      //  ];

        shareBottomButtons?: MiezzButton[] = [
            {
                IsBlue:true,
                Code: "next",
                Label: "下一步",
                Type: "primary",
                Size: "small",

            },
            {
                IsBlue: true,
                Code: "save",
                Label: "确定",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
            {
                IsBlue: true,
                Code: "pre",
                Label: "上一步",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        currencyIndex = "";

        onBatchRate(): void {
            this.batchRateForm.Visiable = true;
            this.batchRateForm.Rate = undefined;
            //MiezzForm.clearCustomValidate(this.$refs, "$batchRateForm", "Rate");
        }
        onBatchVolumeRatio(): void {
            this.batchVolumeRatioForm.Visiable = true;
            this.batchVolumeRatioForm.VolumeRatio = undefined;
            //MiezzForm.clearCustomValidate(this.$refs, "$batchVolumeRatioForm", "VolumeRatio");
        }
        onBatchMiniStandardRatio(): void {
            this.batchMiniStandardRatioForm.Visiable = true;
            this.batchMiniStandardRatioForm.MiniStandardRatio = undefined;
            //MiezzForm.clearCustomValidate(this.$refs, "$batchMiniStandardRatioForm", "MiniStandardRatio");
        }

        /**创建时 */
        created(): void {


            this.orderId = this.$route.params.id as string;
            this.type = 1;
            this.billType = EnumBillType.Share;
            this.menuTab.Back = true;
            this.menuTab.Url = `/order/bill/share/${this.orderId}`;
            this.menuTab.Menus = [
                {
                    Label: "分摊应付账单",
                    Url: `/order/bill/share/${this.orderId}`,
                    Power: "OrderManagement_Order_Summary_BillingInformation_EditSettlementPrice",
                },
            ];
            this.title = "分摊应付账单";

            this.handleGet();

        }
        onBookingNo(row: any) {
            window.open(
                `#/pool/departure/wharf/` + row.PoolId,
                "_blank"
            );
        }
        onOrder(): void {
            DynamicSearchAppService.Post({
                Code: "FinancialManagement_BillSummary",
                Brackets: [
                    {
                        Relation: EnumRelation.And,
                        Items: [
                            {
                                Relation: EnumRelation.And,
                                Where: {
                                    Prop: "DynamicSearch_Order",
                                    Compare: EnumCompare.Equal,
                                    Value: this.dto.Code,
                                },
                            },
                        ],
                    },
                ],
            }).then((r) => {
                window.open(
                    `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                    "_blank"
                );
                //window.location.href = `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`;
            });
        }
        onRevoke(row: any) {

            this.lockParam.BillId = [];
            this.lockParam.OrderId = [];
            this.lockParam.PoolId = [];
            if (this.id != undefined && this.id != "") {
                this.lockParam.PoolId.push(this.id as string);
            }
            else if (this.orderId != undefined && this.orderId != "") {
                this.lockParam.OrderId.push(this.orderId as string);
            }
            BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                if (lock.data.Data == true) {
                    ElMessage.warning("订单已锁定");
                    return;
                }
                else {


                    ElMessageBox.confirm("是否撤销该账单的分摊？", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {

                            this.isLoading = true;
                            BillAppService.RevokeShareById(row.Id).then((r) => {
                                ElMessage.success("撤销分摊成功");
                                this.handleGet();
                            })
                            .catch(() => {
                                this.isLoading = false;
                            });

                        })
                        .catch(() => {
                            console.log("取消撤销");
                        });

                }
            })



        }
        onDisplayChild(row: any) {
            if (this.dto && this.dto.Items) {
                for (var it of this.dto.Items) {
                    it.IsOpen = false;
                    it.Child = [];
                }
                if (this.init && this.init.Items) {
                    for (var it1 of this.dto.Items) {
                        if (it1.Id == row.Id) {
                            it1.IsOpen = true;
                            for (var it2 of this.init.Items) {
                                if (it2.Id == row.Id) {
                                    it1.Child = JSON.parse(JSON.stringify(it2.Child));
                                }
                            }
                        }
                    }
                    //console.log(index);
                    //console.log(this.init.Items[index].Child);
                    //this.dto.Items[index].IsOpen = true;
                    //this.dto.Items[index].Child = JSON.parse(JSON.stringify(this.init.Items[index].Child));
                }
            }
        }
        onHideChild(index: number) {
            if (this.dto && this.dto.Items) {
                for (var it of this.dto.Items) {
                    it.IsOpen = false;
                    it.Child = [];
                }
            }
        }
        onCurrency(currencyId: string, index: string): void {
            this.currencyIndex = index;
            const Currency = this.$refs["Currency"] as any;
            Currency.onInit(currencyId);
        }

        onBatchCurrency() {
            this.currencyIndex = "";
            const Currency = this.$refs["Currency"] as any;
            Currency.onInit(undefined);
        }

        onSelectCurrency(row: any): void {
            var fromCode = undefined;
            var toCode = "";

            if (this.form && this.form.Froms) {
                for (var it of this.form.Froms) {
                    if (this.currencyIndex != "") {
                        if (it.Id == this.currencyIndex) {
                            it.ShareCurrencySystemId = row.Value;
                            it.ShareCurrencySystem = row.Data;
                            if (it.CurrencySystem) {
                                fromCode = it.CurrencySystem.Code;
                            }
                            toCode = row.Data.Code;
                        }
                    }
                    else {
                        it.ShareCurrencySystemId = row.Value;
                        it.ShareCurrencySystem = row.Data;
                        if (it.CurrencySystem) {
                            fromCode = it.CurrencySystem.Code;
                        }
                        toCode = row.Data.Code;
                    }

                }
            }

            if (fromCode != undefined && fromCode != "" && toCode != undefined && toCode != "") {
                if (fromCode != toCode) {
                    ParamCurrencySystemAppService.GetExchangeRateByFromByTo(fromCode, toCode).then((r) => {
                        if (this.form && this.form.Froms) {
                            for (var it1 of this.form.Froms) {
                                if (this.currencyIndex != "") {
                                    if (it1.Id == this.currencyIndex) {
                                        if (r.data.Data) {
                                            var rate = r.data.Data;
                                            if (rate != undefined && rate != "") {
                                                it1.Rate = Number(rate);
                                            }
                                            else {
                                                it1.Rate = undefined;
                                            }
                                        }
                                    }
                                }
                                else {
                                    if (r.data.Data) {
                                        rate = r.data.Data;
                                        if (rate != undefined && rate != "") {
                                            it1.Rate = Number(rate);
                                        }
                                        else {
                                            it1.Rate = undefined;
                                        }
                                    }
                                }
                            }
                        }
                    })
                }
                else {
                    if (this.form && this.form.Froms) {
                        for (var it2 of this.form.Froms) {
                            if (this.currencyIndex != "") {
                                if (it2.Id == this.currencyIndex) {
                                    it2.Rate = 1;
                                }
                            }
                            else {
                                it2.Rate = 1;
                            }
                        }
                    }
                }
            }

            this.isBatchRate = true;
            if (this.form && this.form.Froms) {
                for (var f of this.form.Froms) {
                    if (f.ShareCurrencySystemId == f.CurrencySystemId && f.CurrencySystemId != null && f.CurrencySystemId != undefined) {
                        this.isBatchRate = false;
                    }
                }
            }
        }
        onChangeBatchMiniStandardRatio() {
            var num = this.batchMiniStandardRatioForm.MiniStandardRatio;
            if (num != undefined) {
                var result = setDecimal(num.toString(), 2, false, false);
                if (result != "") {
                    this.batchMiniStandardRatioForm.MiniStandardRatio = Number(result);
                }
                else {
                    this.batchMiniStandardRatioForm.MiniStandardRatio = undefined;
                }
            }
        }
        onChangeBatchVolumeRatio() {
            var num = this.batchVolumeRatioForm.VolumeRatio;
            if (num != undefined) {
                var result = setDecimal(num.toString(), 2, false, false);
                if (result != "") {
                    this.batchVolumeRatioForm.VolumeRatio = Number(result);
                }
                else {
                    this.batchVolumeRatioForm.VolumeRatio = undefined;
                }
            }
        }
        onChangeBatchRate() {
            var rate = this.batchRateForm.Rate;
            if (rate != undefined) {
                var result = setDecimal(rate.toString(), 4, false, false);
                if (result != "") {
                    this.batchRateForm.Rate = Number(result);
                }
                else {
                    this.batchRateForm.Rate = undefined;
                }
            }
        }
        onChangeRate(index: number) {
            if (this.form && this.form.Froms) {
                var rate = this.form.Froms[index].Rate;
                if (rate != undefined) {
                    var result = setDecimal(rate.toString(), 4, false, false);
                    if (result != "") {
                        this.form.Froms[index].Rate = Number(result);
                    }
                    else {
                        this.form.Froms[index].Rate = undefined;
                    }
                }
            }
        }
        onChangeFormVolumeRatio() {
            if (this.form) {
                var volumeRatio = this.form.VolumeRatio;
                if (volumeRatio != undefined) {
                    var result = setDecimal(volumeRatio.toString(), 2, false, false);
                    if (result != "") {
                        this.form.VolumeRatio = Number(result);
                    }
                    else {
                        this.form.VolumeRatio = undefined;
                    }
                }
            }


            if (this.form && this.form.Froms) {
                for (var it of this.form.Froms) {
                    it.VolumeRatio = this.form.VolumeRatio;
                }
            }
        }
        onChangeVolumeRatio(index: number) {
            if (this.form && this.form.Froms) {
                var volumeRatio = this.form.Froms[index].VolumeRatio;
                if (volumeRatio != undefined) {
                    var result = setDecimal(volumeRatio.toString(), 2, false, false);
                    if (result != "") {
                        this.form.Froms[index].VolumeRatio = Number(result);
                    }
                    else {
                        this.form.Froms[index].VolumeRatio = undefined;
                    }
                }
            }
        }
        onChangeFormMiniStandard() {
            if (this.form) {
                var miniStandard = this.form.MiniStandard;
                if (miniStandard != undefined) {
                    var result = setDecimal(miniStandard.toString(), 2, false, false);
                    if (result != "") {
                        this.form.MiniStandard = Number(result);
                    }
                    else {
                        this.form.MiniStandard = undefined;
                    }
                }
            }

            if (this.form && this.form.Froms) {
                for (var it of this.form.Froms) {
                    it.MiniStandard = this.form.MiniStandard;
                }
            }
        }
        onChangeMiniStandard(index: number) {
            if (this.form && this.form.Froms) {
                var miniStandard = this.form.Froms[index].MiniStandard;
                if (miniStandard != undefined) {
                    var result = setDecimal(miniStandard.toString(), 2, false, false);
                    if (result != "") {
                        this.form.Froms[index].MiniStandard = Number(result);
                    }
                    else {
                        this.form.Froms[index].MiniStandard = undefined;
                    }
                }
            }
        }
        onChangeFormOneMiniStandard() {
            if (this.form) {
                var miniStandard = this.form.OneMiniStandard;
                if (miniStandard != undefined) {
                    var result = setDecimal(miniStandard.toString(), 2, false, false);
                    if (result != "") {
                        this.form.OneMiniStandard = Number(result);
                    }
                    else {
                        this.form.OneMiniStandard = undefined;
                    }
                }
            }

            if (this.form && this.form.Froms) {
                for (var it of this.form.Froms) {
                    it.OneMiniStandard = this.form.OneMiniStandard;
                }
            }
        }
        onChangeOneMiniStandard(index: number) {
            if (this.form && this.form.Froms) {
                var miniStandard = this.form.Froms[index].OneMiniStandard;
                if (miniStandard != undefined) {
                    var result = setDecimal(miniStandard.toString(), 2, false, false);
                    if (result != "") {
                        this.form.Froms[index].OneMiniStandard = Number(result);
                    }
                    else {
                        this.form.Froms[index].OneMiniStandard = undefined;
                    }
                }
            }
        }
        onChangeType() {
            if (this.form && this.form.Froms) {
                for (var it of this.form.Froms) {
                    it.MiniStandard = undefined;
                    it.OneMiniStandard = undefined;
                    it.VolumeRatio = undefined;
                    this.form.VolumeRatio = undefined;
                    this.form.OneMiniStandard = undefined;
                    this.form.MiniStandard = undefined;

                    if (this.form.Type == EnumBillShareType.Number) {
                        it.MiniStandardUnit = undefined;
                        this.form.MiniStandardUnit = undefined;
                    }
                    else if (this.form.Type == EnumBillShareType.Volume) {
                        it.MiniStandardUnit = "CBM";
                        this.form.MiniStandardUnit = "CBM";
                    }
                    else {
                        it.MiniStandardUnit = "KGS";
                        this.form.MiniStandardUnit = "KGS";
                    }
                }
            }
        }

        selectableShare(row: any, index: number): boolean {
            var result = true;
            //if (row.IsSave == true) {
            //    result = false;
            //}
            return result;
        }


        selectable(row: any, index: number): boolean {
            var result = true;
            if (row.FromId != undefined && row.FromId != null || (row.ChildCount > 0)) {
                result = false;
            }
            return result;
        }
        handleSelectionChange(val: any, index: number): void {
            this.selects = [];
            if (val != undefined && val.length > 0) {
                for (var it of val) {
                    var id = it.Id as string;
                    this.selects.push(id);
                }
            }
        }

        handleSelectionChangeTo(val: any, index: number): void {
            this.selectTos = [];
            if (val != undefined && val.length > 0) {
                for (var it of val) {
                    var id = it.Id as string;
                    this.selectTos.push(id);
                }
            }
        }


        isString(str: string, row: any | undefined): string {
            if (row != undefined && row != null) {
                if (row.FromId == undefined || row.FromId == null) {
                    if (str == null || str == undefined || str == "") {
                        return "暂无数据";
                    }
                    else {
                        return str;
                    }
                }
                else {
                    if (str == null || str == undefined || str == "") {
                        return "—";
                    }
                    else {
                        return str;
                    }
                }
            }
            else {
                if (str == null || str == undefined || str == "") {
                    return "暂无数据";
                }
                else {
                    return str;
                }
            }
        }

        /**查询列表 */
        handleGet(): void {
            this.param.Type = this.billType;
            if (this.type == 0) {
                this.param.OrderId = undefined;
                this.param.PoolId = this.id;
            }
            else if (this.type == 1) {
                this.param.PoolId = undefined;
                this.param.OrderId = this.orderId;
            }
            this.param.IsAccount = false;
            this.isLoading = true;
            BillAppService.ImportPoolCost(this.param).then((r1) => {
                this.param.IsShare = true;
                BillAppService.GetSingleBill(this.param).then((r) => {
                    if (r.data.Data != undefined) {
                        this.init = JSON.parse(JSON.stringify(r.data.Data));
                        this.dto = JSON.parse(JSON.stringify(r.data.Data));
                        if (this.dto && this.dto.IsSave == true && this.bottomButtons) {
                            this.bottomButtons[1].Hide = true;
                        }
                        if (this.dto && this.dto.Items) {
                            for (var it of this.dto.Items) {
                                it.StartDateString = "";
                                if (it.StartDate != undefined) {
                                    it.StartDateString = moment(it.StartDate).format("YYYY/MM/DD");
                                }
                                it.ChildCount = 0;
                                it.IsOpen = false;
                                if (it.Child != undefined && it.Child.length > 0) {
                                    it.ChildCount = it.Child.length;
                                    it.Child = [];
                                }
                            }
                        }

                    }

                    this.onSum();
                    this.isLoading = false;
                });
            })
        }
        handleBack(): void {
            //var url = this.$route.query.backUrl as string;
            //if (url != null && typeof (url) != "undefined" && url != "undefined" && url != "") {
            //    this.$router.push(url);
            //}
            //else {
            //    history.go(-1);
            //}
            history.go(-1);
        }
        tableRowClassName(row: any): string {
            if (row != 0) {
                if (row.colorType % 2 == 0) {
                    return "chooseColor";
                }
                else {
                    return "cursor";
                }
            }
            else {
                return "";
            }
        }
        handleClick(btn: MiezzButton, complete: () => void): void {


            switch (btn.Code) {
                case "share":
                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    if (this.id != undefined && this.id != "") {
                        this.lockParam.PoolId.push(this.id as string);
                    }
                    else if (this.orderId != undefined && this.orderId != "") {
                        this.lockParam.OrderId.push(this.orderId as string);
                    }
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("订单已锁定");
                            complete();
                            return;
                        }
                        else {


                            if (this.selects == undefined || this.selects.length <= 0) {
                                ElMessage.warning("请至少勾选一条信息");
                                complete();
                                return;
                            }
                            this.param.BillIds = this.selects;
                            this.param.OrderId = this.orderId;
                            this.isLoading = true;
                            this.isBatchRate = true;
                            BillAppService.GetShare(this.param).then((r) => {
                                this.isLoading = false;
                                if (r.data.Data != undefined) {
                                    if (this.shareBottomButtons) {
                                        this.shareBottomButtons[0].Hide = false;
                                        this.shareBottomButtons[1].Hide = true;
                                        this.shareBottomButtons[2].Hide = true;
                                    }
                                    this.form = r.data.Data;

                                    if (this.form && this.form.Froms) {
                                        for (var f of this.form.Froms) {
                                            if (f.ShareCurrencySystemId == f.CurrencySystemId && f.CurrencySystemId != null && f.CurrencySystemId != undefined) {
                                                this.isBatchRate = false;
                                            }
                                        }
                                    }
                                    this.form.Visiable = true;
                                }
                                complete();
                            })

                        }
                    })


                    break;
                case "reset":
                    complete();
                    break;
                case "back":
                    this.handleBack();
                    complete();
                    break;
                default:
                    break;
            }
        }
        handleClickBatchMiniStandardRatio(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "save":
                    MiezzForm.customSubmit(this.$refs, "$batchMiniStandardRatioForm", (v) => {
                        if (v == true) {
                            if (this.form && this.form.Froms) {
                                for (var it of this.form.Froms) {
                                    it.MiniStandard = this.batchMiniStandardRatioForm.MiniStandardRatio;
                                }
                            }
                            this.batchMiniStandardRatioForm.Visiable = false;

                        }
                        complete();
                    })

                    break;
                case "close":
                    this.batchMiniStandardRatioForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
        handleClickBatchVolumeRatio(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "save":
                    MiezzForm.customSubmit(this.$refs, "$batchVolumeRatioForm", (v) => {
                        if (v == true) {
                            if (this.form && this.form.Froms) {
                                for (var it of this.form.Froms) {
                                    it.VolumeRatio = this.batchVolumeRatioForm.VolumeRatio;
                                }
                            }
                            this.batchVolumeRatioForm.Visiable = false;

                        }
                        complete();
                    })

                    break;
                case "close":
                    this.batchVolumeRatioForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
        handleClickBatchRate(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "save":
                    MiezzForm.customSubmit(this.$refs, "$batchRateForm", (v) => {
                        if (v == true) {
                            if (this.form && this.form.Froms) {
                                for (var it of this.form.Froms) {
                                    it.Rate = this.batchRateForm.Rate;
                                }
                            }
                            this.batchRateForm.Visiable = false;

                        }
                        complete();
                    })

                    break;
                case "close":
                    this.batchRateForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
        handleClickShare(btn: MiezzButton, complete: () => void): void {

            switch (btn.Code) {
                case "next":

                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    if (this.id != undefined && this.id != "") {
                        this.lockParam.PoolId.push(this.id as string);
                    }
                    else if (this.orderId != undefined && this.orderId != "") {
                        this.lockParam.OrderId.push(this.orderId as string);
                    }
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("订单已锁定");
                            complete();
                            return;
                        }
                        else {

                            MiezzForm.customSubmit(this.$refs, "$sharRuleForm", (v) => {
                                if (v == true) {
                                    this.form.Step = 2;
                                    if (this.shareBottomButtons) {
                                        this.shareBottomButtons[0].Hide = true;
                                        this.shareBottomButtons[1].Hide = false;
                                        this.shareBottomButtons[2].Hide = false;
                                    }


                                    if (this.form && this.form.Froms) {
                                        if (this.form.Type == EnumBillShareType.Weight || this.form.Type == EnumBillShareType.Volume || this.form.Type == EnumBillShareType.GrossWeight) {

                                            BillAppService.CountWeight(this.form).then((r) => {
                                                if (r.data.Data != undefined)
                                                {
                                                    this.form = r.data.Data;
                                                }
                                                complete();
                                            })

                                            //var volumeRatio = 0;
                                            //if (this.form && this.form.Froms) {
                                            //    for (var from of this.form.Froms) {
                                            //        if (from.VolumeRatio != undefined && from.VolumeRatio != null) {
                                            //            if (from.VolumeRatio > volumeRatio) {
                                            //                volumeRatio = from.VolumeRatio;
                                            //            }
                                            //        }
                                            //    }
                                            //}

                                            //if (this.form && this.form.Tos) {
                                            //    for (var to of this.form.Tos) {
                                            //        to.InStockWeight = 0;
                                            //        to.OutStockWeight = 0;
                                            //        if (to.InStocks) {
                                            //            for (var instock of to.InStocks) {
                                            //                var inStockVolume = 0;
                                            //                if (instock.Volume != undefined && instock.Volume != null) {
                                            //                    inStockVolume = volumeRatio * instock.Volume;
                                            //                }

                                            //                if (instock.GrossWeight == null || instock.GrossWeight == undefined) {
                                            //                    instock.GrossWeight = inStockVolume;
                                            //                }
                                            //                else if (inStockVolume > instock.GrossWeight) {
                                            //                    instock.GrossWeight = inStockVolume;
                                            //                }

                                            //                if (instock.Quantity != undefined) {
                                            //                    to.InStockWeight += instock.GrossWeight * instock.Quantity;
                                            //                }

                                            //            }
                                            //        }

                                            //        if (to.OutStocks) {
                                            //            for (var outstock of to.OutStocks) {
                                            //                var outStockVolume = 0;
                                            //                if (outstock.Volume != undefined && outstock.Volume != null) {
                                            //                    outStockVolume = volumeRatio * outstock.Volume;
                                            //                }

                                            //                if (outstock.GrossWeight == null || outstock.GrossWeight == undefined) {
                                            //                    outstock.GrossWeight = outStockVolume;
                                            //                }
                                            //                else if (outStockVolume > outstock.GrossWeight) {
                                            //                    outstock.GrossWeight = outStockVolume;
                                            //                }

                                            //                console.log(outstock.GrossWeight);
                                            //                if (outstock.Quantity != undefined) {
                                            //                    to.OutStockWeight += outstock.GrossWeight * outstock.Quantity;
                                            //                }
                                            //            }
                                            //        }
                                            //    }
                                            //}
                                        }
                                        else {
                                            complete();
                                        }
                                    }
                                    
                                }
                                else {
                                    complete();
                                }
                            });

                        }
                    })


                    break;
                case "save":
                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    if (this.id != undefined && this.id != "") {
                        this.lockParam.PoolId.push(this.id as string);
                    }
                    else if (this.orderId != undefined && this.orderId != "") {
                        this.lockParam.OrderId.push(this.orderId as string);
                    }
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("订单已锁定");
                            complete();
                            return;
                        }
                        else {

                            if (this.selectTos == undefined || this.selectTos.length <= 0) {
                                ElMessage.warning("请至少勾选一条信息");
                                complete();
                                return;
                            }

                            if (this.form && this.form.Tos && this.selectTos) {
                                for (var to of this.form.Tos) {
                                    to.IsSelected = false;
                                    for (var selectTo of this.selectTos) {
                                        if (to.Id == selectTo) {
                                            to.IsSelected = true;
                                            if (this.form.Type == 40) {
                                                if (to.ChildWeight == null || to.ChildWeight == undefined || to.ChildWeight == 0) {
                                                    ElMessage.warning("无入库数据，无法分摊");
                                                    complete();
                                                    return;
                                                }
                                            }
                                            else if (this.form.Type == 30) {
                                                if (to.InStockVolume == null || to.InStockVolume == undefined || to.InStockVolume == 0) {
                                                    ElMessage.warning("无入库数据，无法分摊");
                                                    complete();
                                                    return;
                                                }
                                            }
                                            else if (this.form.Type == 20) {
                                                if (to.InStockGrossWeight == null || to.InStockGrossWeight == undefined || to.InStockGrossWeight == 0) {
                                                    ElMessage.warning("无入库数据，无法分摊");
                                                    complete();
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            this.isLoading = true;
                            BillAppService.SaveShare(this.form).then((r1) => {
                                this.isLoading = false;
                                ElMessage.success("分摊成功");
                                this.form.Visiable = false;
                                this.handleGet();
                                complete();
                            }).catch(() => {
                                this.isLoading = false;
                                complete();
                            });
                        }
                    })

                    break;
                case "pre":
                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    if (this.id != undefined && this.id != "") {
                        this.lockParam.PoolId.push(this.id as string);
                    }
                    else if (this.orderId != undefined && this.orderId != "") {
                        this.lockParam.OrderId.push(this.orderId as string);
                    }
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("订单已锁定");
                            complete();
                            return;
                        }
                        else {

                            this.form.Step = 1;
                            if (this.shareBottomButtons) {
                                this.shareBottomButtons[0].Hide = false;
                                this.shareBottomButtons[1].Hide = true;
                                this.shareBottomButtons[2].Hide = true;
                            }
                            complete();
                        }
                    })

                    break;
                case "close":
                    this.form.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        onSum(): void {
            if (this.dto) {
                this.dto.Total = "合计金额：CNY0";
            }
            if (this.dto && this.dto.Items && this.dto.Items.length > 0) {
                for (var it of this.dto.Items) {
                    it.FirstChargeUnitValue = undefined;
                    if (it.FirstChargeUnitNumber != undefined && it.FirstChargeUnitId != undefined) {
                        it.FirstChargeUnitValue = it.FirstChargeUnitNumber;
                    }
                    it.TotalPrice = undefined;
                    if (it.UnitPrice != undefined && it.FirstChargeUnitNumber != undefined && it.UnitPrice != null && it.FirstChargeUnitNumber != null) {
                        if (it.SecondChargeUnitNumber != undefined && it.SecondChargeUnitNumber != null) {
                            it.TotalPrice = it.UnitPrice * it.FirstChargeUnitNumber * it.SecondChargeUnitNumber;
                        }
                        else {
                            it.TotalPrice = it.UnitPrice * it.FirstChargeUnitNumber;
                        }
                    }
                    if (it.TotalPrice != undefined) {
                        var result = setDecimal(it.TotalPrice.toString(), 4, true, true);
                        if (result != "") {
                            it.TotalPrice = Number(result);
                        }
                        else {
                            it.TotalPrice = undefined;
                        }
                    }
                }

                var currencys = [];
                for (var it1 of this.dto.Items) {
                    var exist = false;
                    for (var cu of currencys) {
                        if (cu == it1.CurrencySystemId) {
                            exist = true;
                        }
                    }
                    if (exist == false) {
                        currencys.push(it1.CurrencySystemId);
                    }
                }
                this.dto.Total = "合计金额：";
                for (var cu1 of currencys) {
                    var code = "";
                    var totalPrice = 0;
                    for (var it2 of this.dto.Items) {
                        if (it2.CurrencySystemId == cu1) {
                            if (it2.TotalPrice != undefined) {
                                totalPrice += it2.TotalPrice;
                            }
                            if (it2.CurrencySystem && it2.CurrencySystem.Code != undefined) {
                                code = it2.CurrencySystem.Code;
                            }
                        }
                    }
                    this.dto.Total += " " + code + totalPrice;
                }
            }
        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    /*isLessZero：是否允许输入小于0 */
    var setDecimal = function (val: string, num: number, isZero: boolean, isLessZero: boolean) {

        var str = val;
        if (val == null || val == undefined || val == '') {
            return '';
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, '$1$2.$3'); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
        }

        if (val == "" || val == undefined || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        }
        else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        }
        else {
            if (isLessZero == true) {
                if (str[0] == "-") {
                    result = "-" + result;
                }
            }
            return result;
        }
    };

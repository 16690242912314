import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57beed96"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createBlock(_component_miezz_form, {
    modelValue: _ctx.form,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form) = $event)),
    ref: "ruleForm"
  }, {
    "form-item": _withCtx(({ item }) => [
      (_ctx.importFromCost && item.Prop == 'SupplierId')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            prop: item.Prop,
            label: item.Label
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_select, {
                modelValue: _ctx.form.Data.SupplierId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.SupplierId) = $event)),
                options: _ctx.companies,
                clearable: false,
                labelKey: "NameZh",
                valueKey: "Id"
              }, null, 8, ["modelValue", "options"])
            ]),
            _: 2
          }, 1032, ["prop", "label"]))
        : _createCommentVNode("", true),
      (item.Prop == 'Formula')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            prop: item.Prop,
            label: _ctx.label
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data.Formula,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.Formula) = $event)),
                type: "textarea",
                rows: 5,
                pattern: _ctx.pattern,
                onHandleBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleGetResult())),
                onHandleKeyup: _ctx.handleKeyupResult
              }, null, 8, ["modelValue", "pattern", "onHandleKeyup"]),
              (_ctx.importFromCost)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 0,
                    title: "仅可输入‘+’、‘-’、‘×’或‘*’、‘÷’、‘(’、‘)’、数字、‘采购价’",
                    type: "info",
                    effect: "light",
                    "show-icon": "",
                    style: {"margin-top":"5px"}
                  }))
                : _createCommentVNode("", true),
              (_ctx.importFromCost)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 1,
                    title: `“采购价”是指成本管理中《${_ctx.priceName}》中的所有价格信息`,
                    type: "info",
                    effect: "light",
                    "show-icon": "",
                    style: {"margin-top":"5px"}
                  }, null, 8, ["title"]))
                : _createCommentVNode("", true),
              (_ctx.importFromCost)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 2,
                    title: "示例：（采购价+100）×100",
                    type: "info",
                    effect: "light",
                    "show-icon": "",
                    style: {"margin-top":"5px"}
                  }))
                : _createCommentVNode("", true),
              (!_ctx.importFromCost && _ctx.amountType == _ctx.EnumPriceAmountType.Benchmark)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 3,
                    title: "仅可输入‘+’、‘-’、‘×’或‘*’、‘÷’、‘(’、‘)’、数字、成本{ID}",
                    type: "info",
                    effect: "light",
                    "show-icon": "",
                    style: {"margin-top":"5px"}
                  }))
                : _createCommentVNode("", true),
              (!_ctx.importFromCost && _ctx.amountType == _ctx.EnumPriceAmountType.Benchmark)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 4,
                    title: "注：成本{ID}括弧内填写成本管理中信息的Id",
                    type: "info",
                    effect: "light",
                    "show-icon": "",
                    style: {"margin-top":"5px"}
                  }))
                : _createCommentVNode("", true),
              (!_ctx.importFromCost && _ctx.amountType == _ctx.EnumPriceAmountType.Benchmark)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 5,
                    title: "示例：（成本{b22a355b-9c28-49d8-b7a1-8ba5e1d3ef45}+100）×100",
                    type: "info",
                    effect: "light",
                    "show-icon": "",
                    style: {"margin-top":"5px"}
                  }))
                : _createCommentVNode("", true),
              (_ctx.amountType == _ctx.EnumPriceAmountType.Settlement)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 6,
                    title: "仅可输入‘+’、‘-’、‘×’或‘*’、‘÷’、‘(’、‘）’、‘基准价’、数字",
                    type: "info",
                    effect: "light",
                    "show-icon": "",
                    style: {"margin-top":"5px"}
                  }))
                : _createCommentVNode("", true),
              (_ctx.amountType == _ctx.EnumPriceAmountType.Settlement)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 7,
                    title: "示例：基准价+100",
                    type: "info",
                    effect: "light",
                    "show-icon": "",
                    style: {"margin-top":"5px"}
                  }))
                : _createCommentVNode("", true),
              (_ctx.amountType == _ctx.EnumPriceAmountType.Guide)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 8,
                    title: "仅可输入‘+’、‘-’、‘×’或‘*’、‘÷’、‘(’、‘）’、‘基准价’、数字",
                    type: "info",
                    effect: "light",
                    "show-icon": "",
                    style: {"margin-top":"5px"}
                  }))
                : _createCommentVNode("", true),
              (_ctx.amountType == _ctx.EnumPriceAmountType.Guide)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 9,
                    title: "示例：基准价+100",
                    type: "info",
                    effect: "light",
                    "show-icon": "",
                    style: {"margin-top":"5px"}
                  }))
                : _createCommentVNode("", true),
              (_ctx.amountType == _ctx.EnumPriceAmountType.Agreement)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 10,
                    title: "仅可输入‘+’、‘-’、‘×’或‘*’、‘÷’、‘(’、‘）’、‘基准价’、‘结算价’、‘指导价’、数字",
                    type: "info",
                    effect: "light",
                    "show-icon": "",
                    style: {"margin-top":"5px"}
                  }))
                : _createCommentVNode("", true),
              (_ctx.amountType == _ctx.EnumPriceAmountType.Agreement)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 11,
                    title: "示例：指导价+100",
                    type: "info",
                    effect: "light",
                    "show-icon": "",
                    style: {"margin-top":"5px"}
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["prop", "label"]))
        : _createCommentVNode("", true),
      (
          item.Prop == 'Owners' &&
          _ctx.result &&
          _ctx.result.length > 0 &&
          _ctx.result.any((it) => it.Owners.length > 0)
        )
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 2,
            prop: item.Prop,
            label: item.Label
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.result, (calc) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: calc.PriceId
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(calc.Owners, (owner, i) => {
                    return (_openBlock(), _createElementBlock("div", { key: i }, [
                      _createTextVNode(_toDisplayString(owner.Id) + " 来源：", 1),
                      _createElementVNode("a", {
                        href: "javascript:void",
                        onClick: ($event: any) => (_ctx.handleLinkCost(owner))
                      }, "【" + _toDisplayString(owner.TableName) + "/" + _toDisplayString(owner.SupplierName) + "】", 9, _hoisted_1)
                    ]))
                  }), 128))
                ]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["prop", "label"]))
        : _createCommentVNode("", true),
      (
          item.Prop == 'Result' &&
          _ctx.result &&
          _ctx.result.length > 0 &&
          ((!_ctx.importFromCost && _ctx.amountType == _ctx.EnumPriceAmountType.Benchmark) ||
            _ctx.result.any((it) => it.Errors?.length ?? 0 > 0))
        )
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 3,
            prop: item.Prop,
            label: item.Label
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.result, (calc) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: calc.PriceId
                }, [
                  _createElementVNode("div", null, [
                    (calc.CalcShow && calc.CalcShow != _ctx.form.Data.Formula)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(calc.CalcShow) + " = ", 1))
                      : _createCommentVNode("", true),
                    (calc.Amount != undefined)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.getNumberText(calc.Amount)), 1))
                      : _createCommentVNode("", true)
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(calc?.Errors, (error, errorIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: errorIndex,
                      class: "error",
                      title: error
                    }, _toDisplayString(error), 9, _hoisted_4))
                  }), 128))
                ]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["prop", "label"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchPriceServiceShareSearch from "../models.machine/DtoPageListSearchPriceServiceShareSearch";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListPriceServiceShareSearch from "../models.machine/DtoPageListPriceServiceShareSearch";
import DtoDetailPriceServiceShareSearch from "../models.machine/DtoDetailPriceServiceShareSearch";
import DtoFormPriceServiceShareSearch from "../models.machine/DtoFormPriceServiceShareSearch";

/**
 * 价格表共享搜索
 * @description 自动生成的代码,请勿修改
 */
export default class PriceServiceShareSearchAppService {
    /**
     * 获取价格表共享搜索分页列表
     * @param {DtoPageListSearchPriceServiceShareSearch} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListPriceServiceShareSearch>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchPriceServiceShareSearch): AxiosPromise<RESTfulResult<DtoPageListPriceServiceShareSearch>> {
        const url = `/api/PriceServiceShareSearch/Get`;
        return service.get<RESTfulResult<DtoPageListPriceServiceShareSearch>>(url, { params: dto });
    }

    /**
     * 获取价格表共享搜索详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailPriceServiceShareSearch>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailPriceServiceShareSearch>> {
        const url = `/api/PriceServiceShareSearch/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailPriceServiceShareSearch>>(url);
    }

    /**
     * 添加价格表共享搜索
     * @param {DtoFormPriceServiceShareSearch} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormPriceServiceShareSearch): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/PriceServiceShareSearch/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除价格表共享搜索
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceServiceShareSearch/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

}

import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";
import { EnumPriceTableShowRange, EnumPriceTableShowRangeOption } from "./EnumPriceTableShowRange";

/**
 * 基础价格表分页列表-搜索条件分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchPriceTable extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 
     */
    ShowRange?: EnumPriceTableShowRange;
}

/**
 * 基础价格表分页列表-搜索条件分页列表-搜索条件-表单校验
 */
export const DtoPageListSearchPriceTableRules = {
}

/**
 * 基础价格表分页列表-搜索条件分页列表-搜索条件-动态搜索配置项
 */
export const DtoPageListSearchPriceTableDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
  {
    Prop: "DynamicSearch_Name",
    Label: "表格名称",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_ShowRange",
    Label: "显示范围",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
]


import { ElInput } from "element-plus";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class extends Vue {
  @Model() modelValue?: any;
  @Model() label?: string;
  @Prop() clearable = true;
  @Prop() number = false;
  @Prop() boolean = false;
  @Prop() options?: any[] = [];
  @Prop() labelKey = "Label";
  @Prop() valueKey = "Value";
  @Prop() markKey?: string;
  @Prop() disabledKey = "Disabled";
  @Prop() allowCreate = false;
  @Prop() multiple = false;
  @Prop() disabled = false;
  @Prop() noDataText = "无匹配数据";
  @Prop() filterMethod?: (q?: string) => void;
  @Prop() remote?: boolean;
  @Prop() remoteMethod?: (q?: string) => void;
  @Prop() placeholder?: string;
  @Prop() disabledValueChange?: boolean;
  @Prop() red?: boolean;
  @Prop() className?: string;

  select: {
    value?: any;
    label?: string;
    options: any[];
    ref?: any;
  } = {
    options: [],
  };

  created(): void {
    this.onOptionChanged(this.options, null);
    this.update();
    this.handleLabel();
  }

  mounted(): void {
    this.select.ref = this.$refs["miezz-select"] as any;
  }

  update(): void {
    if (this.number) {
      this.select.value =
        this.modelValue || this.modelValue == 0
          ? parseInt(this.modelValue)
          : undefined;
    } else if (this.boolean) {
      this.select.value =
        this.modelValue == "true" ||
        this.modelValue == "True" ||
        this.modelValue == true
          ? true
          : this.modelValue == "false" ||
            this.modelValue == "False" ||
            this.modelValue == false
          ? false
          : undefined;
    } else {
      this.select.value = this.modelValue;
    }
  }

  handleLabel(): void {
    this.$nextTick(() => {
      this.select.label = this.label;
      const select = this.select.options?.firstOrDefault(
        (it) => it.value == this.select.value
      );
      if (select) {
        this.select.label = select.label;
        this.$emit("update:label", this.select.label);
      } else if (!this.select.value && (this.select.options?.length ?? 0) > 0) {
        this.select.label = undefined;
        this.$emit("update:label", this.select.label);
      }
      this.handleSelectedLabelChanged();
    });
  }

  getLabel(it: any): any {
    let label: any = "";
    if (this.labelKey) {
      if (this.labelKey.contains("/")) {
        var labelKeys = this.labelKey.split("/");
        for (let i = 0; i < labelKeys.length; i++) {
          const labelKey = labelKeys[i];
          let value: any;
          if (labelKey) {
            value = it[labelKey];
          }
          if (value) {
            label += `${i > 0 ? "/" : ""}${value}`;
          }
        }
      } else if (this.labelKey.contains(".")) {
        var labelSplits = this.labelKey.split(".");
        label = it;
        for (const labelSplit of labelSplits) {
          label = label[labelSplit];
        }
      } else {
        label = it[this.labelKey];
      }
    } else {
      label = it["Label"];
    }
    return label;
  }

  getValue(it: any): any {
    let value;
    if (this.valueKey) {
      if (this.valueKey.contains(".")) {
        var splits = this.valueKey.split(".");
        value = it;
        for (const split of splits) {
          value = value[split];
        }
      } else {
        value = it[this.valueKey];
      }
    } else {
      value = it["Value"];
    }
    return value;
  }

  getMark(it: any): any {
    if (this.markKey) {
      let mark;
      if (this.markKey.contains(".")) {
        var splits = this.markKey.split(".");
        mark = it;
        for (const split of splits) {
          mark = mark[split];
        }
      } else {
        mark = it[this.markKey];
      }
      return mark;
    }
  }

  @Watch("options")
  onOptionChanged(nv: any, ov: any): void {
    if (nv != ov) {
      this.select.options =
        this.options?.map((it) => {
          let label = this.getLabel(it);
          let value = this.getValue(it);
          let mark = this.getMark(it);
          let disabled = false;
          if (this.disabledKey) {
            disabled = it[this.disabledKey];
          } else {
            disabled = it["Disabled"];
          }
          return {
            label,
            value,
            mark,
            disabled,
            data: it,
          };
        }) ?? [];
      this.handleLabel();
    }
  }

  @Watch("select.ref.selectedLabel")
  handleSelectedLabelChanged(): void {
    this.$nextTick(() => {
      if (
        /[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F ]{12}[}]?/.test(
          this.select.ref.selectedLabel
        ) &&
        this.select.value &&
        this.select.label &&
        this.select.ref.selectedLabel != this.select.label
      ) {
        this.select.ref.selectedLabel = this.select.label;
      }
    });
  }

  @Watch("modelValue")
  onModelValueChanged(nv: any, ov: any): void {
    if (nv != ov && nv != this.select.value) {
      this.update();
      if (!this.disabledValueChange) {
        this.select.value = this.modelValue;
        this.select.label = this.label;
        this.handleChange();
      } else {
        this.handleLabel();
      }
    }
  }

  @Emit()
  handleChange(): void {
    this.$emit("update:modelValue", this.select.value);
    this.handleLabel();
  }

  @Emit()
  handleClick(): void {
    //
  }

  @Emit()
  handleFocus(): void {
    //
  }

  handleClear(): void {
    this.select.value = undefined;
    this.select.label = undefined;
    this.$emit("update:modelValue", undefined);
    this.$emit("update:label", undefined);
    if (this.filterMethod) this.filterMethod();
  }

  @Emit()
  handleVisibleChange(show: boolean): void {
    //
  }
}

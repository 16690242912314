import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e3ff1b3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "miezz-color" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_color_picker = _resolveComponent("el-color-picker")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_el_color_picker, {
      modelValue: _ctx.value.color,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.color) = $event)),
      predefine: _ctx.predefineColors,
      onChange: _ctx.handleBeforePicker,
      disabled: _ctx.model.Disabled
    }, null, 8, ["modelValue", "predefine", "onChange", "disabled"])
  ]))
}
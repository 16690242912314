import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoFormNoticeSend from "../models.machine/DtoFormNoticeSend";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoDetailNotice from "../models.machine/DtoDetailNotice";

/**
 * 通知
 * @description 自动生成的代码,请勿修改
 */
export default class NoticeAppService {
    /**
     * 发送
     * @param {DtoFormNoticeSend} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Send(dto?: DtoFormNoticeSend): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Notice/Send`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 已读
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static ReadById(id?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Notice/Read/${id}`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 获取未下载文件
     * @returns {AxiosPromise<RESTfulResult<DtoDetailNotice>>} 
     */
    static GetUnDownloadFile(): AxiosPromise<RESTfulResult<DtoDetailNotice>> {
        const url = `/api/Notice/GetUnDownloadFile`;
        return service.get<RESTfulResult<DtoDetailNotice>>(url);
    }

}

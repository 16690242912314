import { AxiosPromise } from "axios";
import service from "../apis/Service";
import RESTfulResult from "../models.machine/RESTfulResult";
import WebOptions from "../models.machine/WebOptions";
import DtoFormSysLog from "../models.machine/DtoFormSysLog";
import DtoRequestCompareExcelRepeatValues from "../models.machine/DtoRequestCompareExcelRepeatValues";
import DtoCallbackCompareExcelRepeatValues from "../models.machine/DtoCallbackCompareExcelRepeatValues";

/**
 * 系统
 * @description 自动生成的代码,请勿修改
 */
export default class SysAppService {
    /**
     * 获取网站信息
     * @returns {AxiosPromise<RESTfulResult<WebOptions>>} 
     */
    static GetWebInfo(): AxiosPromise<RESTfulResult<WebOptions>> {
        const url = `/api/Sys/GetWebInfo`;
        return service.get<RESTfulResult<WebOptions>>(url);
    }

    /**
     * 是否可以注册服务商
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static CanRegServiceProvider(): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/Sys/CanRegServiceProvider`;
        return service.post<RESTfulResult<boolean>>(url);
    }

    /**
     * 记录日志
     * @param {DtoFormSysLog} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Log(dto?: DtoFormSysLog): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Sys/Log`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 比对两个Excel重复值
     * @param {any} [fileA] 
     * @param {any} [fileB] 
     * @param {DtoRequestCompareExcelRepeatValues} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackCompareExcelRepeatValues>>} 
     */
    static CompareExcelRepeatValues(fileA?: any, fileB?: any, dto?: DtoRequestCompareExcelRepeatValues): AxiosPromise<RESTfulResult<DtoCallbackCompareExcelRepeatValues>> {
        const url = `/api/Sys/CompareExcelRepeatValues`;
        return service.post<RESTfulResult<DtoCallbackCompareExcelRepeatValues>>(url, fileB);
    }

}

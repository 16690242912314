
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import CurrentLogier from "@/apis/CurrentLogier";
import Logier from "@/models.machine/Logier";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import { EnumTransportDirection } from "@/models.machine/EnumTransportDirection";
import UiSelectOption from "@/models.machine/UiSelectOption";
import ParamPortAppService from "@/apis.machine/ParamPortAppService";
import ParamCountryAreaAppService from "@/apis.machine/ParamCountryAreaAppService";
import DtoSelectOptionParamCountryArea from "@/models.machine/DtoSelectOptionParamCountryArea";
import ParamCarrierAppService from "@/apis.machine/ParamCarrierAppService";
import ParamTransportChannelAppService from "@/apis.machine/ParamTransportChannelAppService";
import { EnumTransportPaymentTypeOption } from "@/models.machine/EnumTransportPaymentType";
import ParamChargeUnitAppService from "@/apis.machine/ParamChargeUnitAppService";
import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
import { EnumGoodsTransportTypeOption } from "@/models.machine/EnumGoodsTransportType";
import OrderFormContainer from "../../Container/OrderFormContainer.vue";
import OrderFormProductsSummary from "../../ProductSummary/OrderFormProductsSummary.vue";
import OrderFormLadingBill from "../../LadingBill/OrderFormLadingBill.vue";
import OrderFormCompanyBusinessCard from "../../CompanyBusinessCard/OrderFormCompanyBusinessCard.vue";
import { EnumTransportContainerLoad } from "@/models.machine/EnumTransportContainerLoad";
import { EnumTransportType } from "@/models.machine/EnumTransportType";
import DtoFormOrderPartBill, {
  DtoFormOrderPartBillMiezzFormItems,
  DtoFormOrderPartBillRules,
} from "@/models.machine/DtoFormOrderPartBill";
import OrderPartBillAppService from "@/apis.machine/OrderPartBillAppService";
import DtoTabOrderPartBill from "@/models.machine/DtoTabOrderPartBill";
import DtoDetailOrderPartBill from "@/models.machine/DtoDetailOrderPartBill";
import moment from "moment";
import CompanyBusinessCardForm from "@/views/Company/CompanyBusinessCard/CompanyBusinessCardForm.vue";
import { EnumOrderStatus } from "@/models.machine/EnumOrderStatus";
import MiezzCard from "@/components/MiezzCard.vue";
import OrderMainBillAppService from "@/apis.machine/OrderMainBillAppService";

@Options({
  components: {
    OrderFormCompanyBusinessCard,
    CompanyBusinessCardForm,
    OrderFormContainer,
    OrderFormProductsSummary,
    OrderFormLadingBill,
  },
})
export default class OrderPartBillForm extends Vue {
  logier?: Logier;
  id = "";
  tabs: DtoTabOrderPartBill[] = [];
  form = new MiezzForm<DtoFormOrderPartBill, string>();
  loading = false;
  hide = true;
  topButtons: MiezzButton[] = [
    {
      Code: "toggle",
      Title: "展开",
      Icon: "ArrowDownBold",
      Type: "default",
      Size: "small",
    },
  ];
  buttons: MiezzButton[] = [];

  detail?: DtoDetailOrderPartBill;

  EnumCompanyType = EnumCompanyType;
  EnumTransportType = EnumTransportType;
  EnumTransportContainerLoad = EnumTransportContainerLoad;
  EnumTransportPaymentTypeOption = EnumTransportPaymentTypeOption;
  EnumGoodsTransportTypeOption = EnumGoodsTransportTypeOption;

  currencySystems: UiSelectOption<string>[] = [];
  chargeUnits: UiSelectOption<string>[] = [];

  addDeparturePortBookingPartBill = false;

  created(): void {
    // this.addDeparturePortBookingPartBill =
    //   this.$route.query.task == "AddDeparturePortBookingPartBill";

    this.logier = CurrentLogier.GetLogier();

    //表单-元素配置
    this.form.Items = DtoFormOrderPartBillMiezzFormItems;

    ParamChargeUnitAppService.GetSelect().then((r) => {
      this.chargeUnits = r.data.Data ?? [];
    });

    ParamCurrencySystemAppService.GetSelect().then((r) => {
      this.currencySystems = r.data.Data ?? [];
    });

    this.handleGet();
  }

  handleGet(): void {
    const id = this.$route.params.id as string | undefined;
    OrderPartBillAppService.GetTabsById(id).then((r) => {
      this.tabs = r.data.Data ?? [];
      if (this.$route.query.id) {
        const poolId = this.$route.query.id as string;
        this.id =
          this.tabs.firstOrDefault((it) => it.PoolId == poolId)?.Id ?? "";
      } else {
        this.id = this.tabs.firstOrDefault()?.Id ?? "";
      }
      this.handleTabClick();
    });
  }

  handleSave(): void {
    if (this.form.Data) {
      if (this.detail?.OrderLock) {
        ElMessage.warning("订单已锁定");
        return;
      }
      if (this.detail?.OrderStatus == EnumOrderStatus.Canceled) {
        ElMessage.warning("订单已取消，无法修改");
        return;
      }
      this.loading = true;
      this.form.Data.Sure = true;
      OrderPartBillAppService.Post(this.form.Data).then(() => {
        ElMessage.success("已暂存");
        this.loading = false;
      });
    }
  }

  handleSelect(): void {
    this.$nextTick(() => {
      if (this.id) {
        OrderPartBillAppService.GetById(this.id).then((r) => {
          if (r.data.Data) {
            this.detail = JSON.copy(r.data.Data);
            this.form.Data = r.data.Data;

            this.buttons = [
              {
                Code: "back",
                Label: "返回",
                MiezzIcon: "back",
                Type: "default",
                Size: "small",
              },
              {
                Code: "submit",
                Label: "保存",
                Icon: "select",
                Type: "primary",
                Size: "small",
              },
            ];
            // if (!this.form.Data.Sure) {
            this.buttons.push({
              Code: "save",
              Label: "暂存",
              Icon: "Finished",
              Type: "default",
              Size: "small",
            });
            // }

            if (
              this.form.Data.LoadingPortId &&
              this.form.Data.LoadingPortLabel &&
              this.loadingPorts.length == 0
            ) {
              this.getLoadingPorts(this.form.Data.LoadingPortLabel);
            }
            if (
              this.form.Data.DischargePortId &&
              this.form.Data.DischargePortLabel &&
              this.dischargePorts.length == 0
            ) {
              this.getDischargePorts(this.form.Data.DischargePortLabel);
            }
            if (
              this.form.Data.DeliveryPortId &&
              this.form.Data.DeliveryPortLabel &&
              this.deliveryPorts.length == 0
            ) {
              this.getDeliveryPorts(this.form.Data.DeliveryPortLabel);
            }
            if (
              this.form.Data.CarrierId &&
              this.form.Data.CarrierLabel &&
              this.carriers.length == 0
            ) {
              this.getCarriers(this.form.Data.CarrierLabel);
            }

            this.initRules();
          }
        });
      }
    });
  }

  handleTabClick(): void {
    this.handleSave();
    this.handleSelect();
  }

  /**初始化表单校验 */
  initRules(): void {
    //表单-校验
    this.form.Rules = JSON.copy(DtoFormOrderPartBillRules);

    //目的港ATA时间
    this.form.Rules.ActualArrivalDate = [
      {
        validator: (rule: any, value: Date, callback: any) => {
          if (value) {
            //目的港ATA时间晚于装货港ATD时间
            if (
              this.form.Data.ActualDepartureDate &&
              moment(value) <= moment(this.form.Data.ActualDepartureDate)
            ) {
              this.form.Data.ActualArrivalDate = undefined;
              return callback(new Error("ATA时间不能早于ATD时间"));
            }
          }
          callback();
        },
        trigger: "change",
      },
    ];
    //装货港ATD时间
    this.form.Rules.ActualDepartureDate = [
      {
        validator: (rule: any, value: Date, callback: any) => {
          if (value) {
            //目的港ATA时间晚于装货港ATD时间
            if (
              this.form.Data.ActualArrivalDate &&
              moment(this.form.Data.ActualArrivalDate) <= moment(value)
            ) {
              this.form.Data.ActualDepartureDate = undefined;
              return callback(new Error("ATA时间不能早于ATD时间"));
            }
          }
          callback();
        },
        trigger: "change",
      },
    ];
    //目的港ETA时间
    this.form.Rules.EstimatedArrivalDate = [
      {
        validator: (rule: any, value: Date, callback: any) => {
          if (value) {
            //目的港ETA时间晚于装货港ETD时间
            if (
              this.form.Data.EstimatedDepartureDate &&
              moment(value) <= moment(this.form.Data.EstimatedDepartureDate)
            ) {
              this.form.Data.EstimatedArrivalDate = undefined;
              return callback(new Error("ETA时间不能早于ETD时间"));
            }
          }
          callback();
        },
        trigger: "change",
      },
    ];
    //装货港ETD时间
    this.form.Rules.EstimatedDepartureDate = [
      {
        validator: (rule: any, value: Date, callback: any) => {
          if (value) {
            if (
              this.form.Data.EstimatedArrivalDate &&
              moment(this.form.Data.EstimatedArrivalDate) <= moment(value)
            ) {
              this.form.Data.EstimatedDepartureDate = undefined;
              return callback(new Error("ETA时间不能早于ETD时间"));
            }
          }
          callback();
        },
        trigger: "change",
      },
    ];

    const requireds = [
      "ShipperContent",
      "ConsigneeContent",
      "NotifyPartyContent",
      "LoadingPortId",
      "DeliveryPortId",
      "TransportPaymentType",
      "MarksNotes",
      "PackagesNumber",
      "PackagesKind",
      "GoodsDescription",
      "GrossWeight",
      "Volume",
      "LadingBillType",
    ];
    for (const required of requireds) {
      if (!this.form.Rules[required]) {
        this.form.Rules[required] = [];
      }
      this.form.Rules[required].push(
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        }
      );
    }
  }

  loadingPorts: UiSelectOption<string>[] = [];
  /**获取装货港 */
  getLoadingPorts(q: string): void {
    if (q) {
      ParamPortAppService.GetSelect({
        Keywords: q,
        // CountryNameEn:
        //   this.detail?.TransportDirection == EnumTransportDirection.Export
        //     ? "China"
        //     : undefined,
      }).then((r) => {
        this.loadingPorts = r.data.Data ?? [];
      });
    }
  }

  dischargePorts: UiSelectOption<string>[] = [];
  /**获取卸货港 */
  getDischargePorts(q: string): void {
    if (q) {
      ParamPortAppService.GetSelect({ Keywords: q }).then((r) => {
        this.dischargePorts = r.data.Data ?? [];
      });
    }
  }

  deliveryPorts: UiSelectOption<string>[] = [];
  /**获取目的港 */
  getDeliveryPorts(q: string): void {
    if (q) {
      ParamPortAppService.GetSelect({
        Keywords: q,
        // CountryNameEn:
        //   this.detail?.TransportDirection == EnumTransportDirection.Import
        //     ? "China"
        //     : undefined,
      }).then((r) => {
        this.deliveryPorts = r.data.Data ?? [];
      });
    }
  }

  /**获取交货地点 */
  getDeliveryPlaces(q: string, cb: any): void {
    if (q && q.length >= 2) {
      ParamCountryAreaAppService.GetSelect({
        Keywords: q,
      }).then((r) => {
        cb(r.data.Data ?? []);
      });
    } else {
      cb([]);
    }
  }

  /**选择交货地点 */
  handleSelectDeliveryPlace(item: DtoSelectOptionParamCountryArea): void {
    this.form.Data.DeliveryPlaceAddress = item?.DetailedAddress;
  }

  carriers: UiSelectOption<string>[] = [];
  /**获取承运人 */
  getCarriers(q: string): void {
    ParamCarrierAppService.GetSelect({ Keywords: q, Take: 10 }).then((r) => {
      this.carriers = r.data.Data ?? [];
    });
  }

  channels: UiSelectOption<string>[] = [];
  /**获取渠道 */
  getChannels(q: string): void {
    ParamTransportChannelAppService.GetSelect({ Keywords: q, Take: 10 }).then(
      (r) => {
        this.channels = r.data.Data ?? [];
      }
    );
  }

  sync(complete: () => void): void {
    if (
      this.detail?.PackagesNumber != this.form.Data.PackagesNumber ||
      this.detail?.PackagesKind != this.form.Data.PackagesKind ||
      this.detail?.GoodsDescription != this.form.Data.GoodsDescription ||
      this.detail?.GrossWeight != this.form.Data.GrossWeight ||
      this.detail?.Volume != this.form.Data.Volume
    ) {
      ElMessageBox.confirm("货物信息发生变化，是否同步主单信息？", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          OrderPartBillAppService.GetSyncMainBillDataByOrderId(
            this.detail?.OrderId
          )
            .then((sync) => {
              OrderMainBillAppService.GetById(this.detail?.OrderId)
                .then((main) => {
                  const mainData = main.data.Data;
                  if (mainData) {
                    mainData.PackagesNumber = sync.data.Data?.PackagesNumber;
                    mainData.PackagesKind = sync.data.Data?.PackagesKind;
                    mainData.GoodsDescription =
                      sync.data.Data?.GoodsDescription;
                    mainData.GrossWeight = sync.data.Data?.GrossWeight;
                    mainData.Volume = sync.data.Data?.Volume;
                    OrderMainBillAppService.Post(mainData)
                      .then((r) => {
                        ElMessage.success("同步成功");
                        complete();
                      })
                      .catch(complete);
                  }
                })
                .catch(complete);
            })
            .catch(complete);
        })
        .catch(complete);
    } else {
      complete();
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "toggle":
        this.hide = !this.hide;
        btn.Icon = this.hide ? "ArrowDownBold" : "ArrowUpBold";
        btn.Title = this.hide ? "展开" : "收缩";
        if (!this.hide && this.tabs.length == 0) {
          this.handleGet();
        }
        complete();
        break;
      //保存表单
      case "submit":
        if (this.detail?.OrderLock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        if (this.detail?.OrderStatus == EnumOrderStatus.Canceled) {
          ElMessage.warning("订单已取消，无法修改");
          complete();
          return;
        }
        (this.$refs["ruleForm"] as any).validate((v: boolean) => {
          if (!v) {
            setTimeout(() => {
              const error = document.getElementsByClassName("is-error");
              error[0]?.querySelector("input")?.focus();
            }, 1);
            complete();
          } else {
            this.form.Data.Sure = true;
            this.loading = true;
            OrderPartBillAppService.Post(this.form.Data)
              .then(() => {
                ElMessage.success("保存成功");
                this.sync(() => {
                  const tab = this.tabs.firstOrDefault(
                    (it) => it.Id == this.form.Data.Id
                  );
                  if (tab) tab.Sure = true;
                  if (this.tabs.length == 1) {
                    CurrentLogier.Back();
                  } else {
                    const notSure = this.tabs.firstOrDefault(
                      (it) =>
                        !it.Sure &&
                        it.Pool?.DeparturePortBooking &&
                        it.Pool?.DeparturePortLadingBill
                    );
                    if (notSure) {
                      this.id = notSure.Id as string;
                      this.handleSelect();
                      (
                        (this.$refs["ref-card"] as MiezzCard).$refs[
                          "ref-scrollbar"
                        ] as any
                      ).setScrollTop(0);
                    } else {
                      CurrentLogier.Back();
                    }
                  }
                  this.loading = false;
                  complete();
                });
              })
              .catch(complete);
          }
        });
        break;
      case "save":
        if (this.detail?.OrderLock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        if (this.detail?.OrderStatus == EnumOrderStatus.Canceled) {
          ElMessage.warning("订单已取消，无法修改");
          complete();
          return;
        }
        this.loading = true;
        this.form.Data.Sure = true;
        OrderPartBillAppService.Post(this.form.Data)
          .then(() => {
            ElMessage.success("已暂存");
            this.sync(() => {
              this.loading = false;
              complete();
            });
          })
          .catch(complete);
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }
}

import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailOrderPool_Order from "./DtoDetailOrderPool_Order";
import { EnumTransportDirection, EnumTransportDirectionOption } from "./EnumTransportDirection";
import { EnumTransportType, EnumTransportTypeOption } from "./EnumTransportType";
import { EnumTransportContainerLoad, EnumTransportContainerLoadOption } from "./EnumTransportContainerLoad";
import { EnumCustomsClearanceHeaderProvider, EnumCustomsClearanceHeaderProviderOption } from "./EnumCustomsClearanceHeaderProvider";
import { EnumTransportPaymentType, EnumTransportPaymentTypeOption } from "./EnumTransportPaymentType";
import { EnumGoodsTransportType, EnumGoodsTransportTypeOption } from "./EnumGoodsTransportType";
import DtoDetailOrderPool_OrderPoolPackage from "./DtoDetailOrderPool_OrderPoolPackage";
import DtoDetailOrderPool_OrderPoolContainer from "./DtoDetailOrderPool_OrderPoolContainer";
import DtoDetailOrderPool_OrderPoolCost from "./DtoDetailOrderPool_OrderPoolCost";
import DtoDetailOrderPool_OrderPoolSupplierCost from "./DtoDetailOrderPool_OrderPoolSupplierCost";
import { EnumLadingBillType, EnumLadingBillTypeOption } from "./EnumLadingBillType";
import { EnumLadingBillNumber, EnumLadingBillNumberOption } from "./EnumLadingBillNumber";
import { EnumOrderPoolStatus, EnumOrderPoolStatusOption } from "./EnumOrderPoolStatus";
import UiGroup from "./UiGroup";

/**
 * 委托书详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailOrderPool extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.ClientCompanyBuildSettlementBill = false;
        this.DeparturePortBooking = false;
        this.DeparturePortTake = false;
        this.DeparturePortWarehouse = false;
        this.DeparturePortDeclareCustoms = false;
        this.DestinationPortClearCustoms = false;
        this.DestinationPortDelivery = false;
        this.DestinationPortWarehouse = false;
        this.ShowPackages = false;
        this.Packages = [];
        this.Containers = [];
        this.Costs = [];
        this.SupplierCosts = [];
        this.SettlementCosts = [];
    }

    [proName: string]: any;
    /**
     * 
     */
    Order?: DtoDetailOrderPool_Order;
    /**
     * 创建公司Id
     */
    CreatedCompanyId?: string;
    /**
     * 创建人公司用户Id
     */
    CreatedCompanyUserId?: string;
    /**
     * 委托公司Id
     */
    ClientCompanyId?: string;
    /**
     * 生成结算账单
     */
    ClientCompanyBuildSettlementBill?: boolean;
    /**
     * 委托人Id
     */
    ClientUserId?: string;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 运输方向
     */
    TransportDirection?: EnumTransportDirection;
    /**
     * 运输类型
     */
    TransportType?: EnumTransportType;
    /**
     * 集装箱装载方式
     */
    TransportContainerLoad?: EnumTransportContainerLoad;
    /**
     * 装货港服务-订舱
     */
    DeparturePortBooking?: boolean;
    /**
     * 装货港服务-是否签发提单
     */
    DeparturePortLadingBill?: boolean;
    /**
     * 装货港服务-提货
     */
    DeparturePortTake?: boolean;
    /**
     * 装货港服务-提货方式-集装箱拖车
     */
    DeparturePortContainer?: boolean;
    /**
     * 装货港服务-提货方式-干线物流
     */
    DeparturePortTrunk?: boolean;
    /**
     * 装货港服务-提货方式-快递出现
     */
    DeparturePortExpress?: boolean;
    /**
     * 装货港服务-仓库
     */
    DeparturePortWarehouse?: boolean;
    /**
     * 装货港服务-报关
     */
    DeparturePortDeclareCustoms?: boolean;
    /**
     * 装货港服务-是否退税
     */
    DeparturePortDrawback?: boolean;
    /**
     * 目的港服务-清关
     */
    DestinationPortClearCustoms?: boolean;
    /**
     * 目的港服务-清关抬头
     */
    DestinationPortCustomsClearanceHeaderProvider?: EnumCustomsClearanceHeaderProvider;
    /**
     * 目的港服务-是否出具税单
     */
    DestinationPortCustomsClearanceIssueTaxReceipt?: boolean;
    /**
     * 目的港服务-送货
     */
    DestinationPortDelivery?: boolean;
    /**
     * 目的港服务-送货方式-集装箱拖车
     */
    DestinationPortContainer?: boolean;
    /**
     * 目的港服务-送货方式-干线物流
     */
    DestinationPortTrunk?: boolean;
    /**
     * 目的港服务-送货方式-快递出现
     */
    DestinationPortExpress?: boolean;
    /**
     * 目的港服务-仓库
     */
    DestinationPortWarehouse?: boolean;
    /**
     * 发货人Id
     */
    ShipperId?: string;
    /**
     * 发货人名称
     */
    ShipperName?: string;
    /**
     * 发货人内容
     */
    ShipperContent?: string;
    /**
     * 收货人Id
     */
    ConsigneeId?: string;
    /**
     * 收货人名称
     */
    ConsigneeName?: string;
    /**
     * 收货人内容
     */
    ConsigneeContent?: string;
    /**
     * 通知人Id
     */
    NotifyPartyId?: string;
    /**
     * 通知人名称
     */
    NotifyPartyName?: string;
    /**
     * 通知人内容
     */
    NotifyPartyContent?: string;
    /**
     * Booking No
     */
    BookingNo?: string;
    /**
     * 预计货好时间
     */
    EstimatedGoodsReadyDate?: Date;
    /**
     * Port of  Loading/装货港Id
     */
    LoadingPortId?: string;
    /**
     * Port of  Loading/装货港
     */
    LoadingPortLabel?: string;
    /**
     * Port of Discharge/卸货港Id
     */
    DischargePortId?: string;
    /**
     * Port of Discharge/卸货港
     */
    DischargePortLabel?: string;
    /**
     * Port of Delivery/目的港Id
     */
    DeliveryPortId?: string;
    /**
     * Port of Delivery/目的港
     */
    DeliveryPortLabel?: string;
    /**
     * Place of Delivery/交货地点
     */
    DeliveryPlaceLabel?: string;
    /**
     * 详细地址
     */
    DeliveryPlaceAddress?: string;
    /**
     * 承运人Id
     */
    CarrierId?: string;
    /**
     * 承运人
     */
    CarrierLabel?: string;
    /**
     * 渠道Id
     */
    TransportChannelId?: string;
    /**
     * 渠道
     */
    TransportChannelLabel?: string;
    /**
     * 合约号
     */
    ContractNo?: string;
    /**
     * 船名
     */
    ShipName?: string;
    /**
     * 航次
     */
    VoyageNumber?: string;
    /**
     * 航班
     */
    Flight?: string;
    /**
     * 班次
     */
    RailwayNumber?: string;
    /**
     * 装货港ETD
     */
    EstimatedDepartureDate?: Date;
    /**
     * 目的港ETA
     */
    EstimatedArrivalDate?: Date;
    /**
     * 运费支付方式
     */
    TransportPaymentType?: EnumTransportPaymentType;
    /**
     * 运输类型
     */
    GoodsTransportType?: EnumGoodsTransportType;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 币制
     */
    CurrencySystemId?: string;
    /**
     * 币制代码
     */
    CurrencySystemCode?: string;
    /**
     * 币制
     */
    CurrencySystemLabel?: string;
    /**
     * 显示清单
     */
    ShowPackages?: boolean;
    /**
     * 货物清单
     */
    Packages?: DtoDetailOrderPool_OrderPoolPackage[];
    /**
     * 唛头
     */
    MarksNotes?: string;
    /**
     * 装运数量
     */
    PackagesNumber?: number;
    /**
     * 装运包装
     */
    PackagesKind?: string;
    /**
     * 装运包装与数量
     */
    PackagesNumberKind?: string;
    /**
     * 品名
     */
    GoodsDescription?: string;
    /**
     * 品名备注
     */
    GoodsDescriptionRemark?: string;
    /**
     * 毛重
     */
    GrossWeight?: number;
    /**
     * 尺码
     */
    Volume?: number;
    /**
     * 集装箱信息
     */
    Containers?: DtoDetailOrderPool_OrderPoolContainer[];
    /**
     * 费用信息
     */
    Costs?: DtoDetailOrderPool_OrderPoolCost[];
    /**
     * 成本信息
     */
    SupplierCosts?: DtoDetailOrderPool_OrderPoolSupplierCost[];
    /**
     * 业务员结算价
     */
    SettlementCosts?: DtoDetailOrderPool_OrderPoolCost[];
    /**
     * 提单类型
     */
    LadingBillType?: EnumLadingBillType;
    /**
     * 提单份数
     */
    LadingBillNumber?: EnumLadingBillNumber;
    /**
     * 备注
     */
    LadingBillRemark?: string;
    /**
     * 订单Id
     */
    OrderId?: string;
    /**
     * 订单锁定
     */
    OrderLock?: boolean;
    /**
     * 状态
     */
    Status?: EnumOrderPoolStatus;
}

/**
 * 委托书详细-表单校验
 */
export const DtoDetailOrderPoolRules = {
}

/**
 * 委托书详细-设置项
 */
export const DtoDetailOrderPoolMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Order",
    Label: "",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedCompanyId",
    Label: "创建公司Id",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedCompanyUserId",
    Label: "创建人公司用户Id",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ClientCompanyId",
    Label: "委托公司Id",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ClientCompanyBuildSettlementBill",
    Label: "生成结算账单",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "ClientUserId",
    Label: "委托人Id",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Code",
    Label: "编号",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "TransportDirection",
    Label: "运输方向",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumTransportDirectionOption,
  },
  {
    Prop: "TransportType",
    Label: "运输类型",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumTransportTypeOption,
  },
  {
    Prop: "TransportContainerLoad",
    Label: "集装箱装载方式",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumTransportContainerLoadOption,
  },
  {
    Prop: "DeparturePortBooking",
    Label: "装货港服务-订舱",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DeparturePortLadingBill",
    Label: "装货港服务-是否签发提单",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DeparturePortTake",
    Label: "装货港服务-提货",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DeparturePortContainer",
    Label: "装货港服务-提货方式-集装箱拖车",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DeparturePortTrunk",
    Label: "装货港服务-提货方式-干线物流",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DeparturePortExpress",
    Label: "装货港服务-提货方式-快递出现",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DeparturePortWarehouse",
    Label: "装货港服务-仓库",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DeparturePortDeclareCustoms",
    Label: "装货港服务-报关",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DeparturePortDrawback",
    Label: "装货港服务-是否退税",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DestinationPortClearCustoms",
    Label: "目的港服务-清关",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DestinationPortCustomsClearanceHeaderProvider",
    Label: "目的港服务-清关抬头",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumCustomsClearanceHeaderProviderOption,
  },
  {
    Prop: "DestinationPortCustomsClearanceIssueTaxReceipt",
    Label: "目的港服务-是否出具税单",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DestinationPortDelivery",
    Label: "目的港服务-送货",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DestinationPortContainer",
    Label: "目的港服务-送货方式-集装箱拖车",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DestinationPortTrunk",
    Label: "目的港服务-送货方式-干线物流",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DestinationPortExpress",
    Label: "目的港服务-送货方式-快递出现",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DestinationPortWarehouse",
    Label: "目的港服务-仓库",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "ShipperId",
    Label: "发货人Id",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ShipperName",
    Label: "发货人名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ShipperContent",
    Label: "发货人内容",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ConsigneeId",
    Label: "收货人Id",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ConsigneeName",
    Label: "收货人名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ConsigneeContent",
    Label: "收货人内容",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "NotifyPartyId",
    Label: "通知人Id",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "NotifyPartyName",
    Label: "通知人名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "NotifyPartyContent",
    Label: "通知人内容",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "BookingNo",
    Label: "Booking No",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "EstimatedGoodsReadyDate",
    Label: "预计货好时间",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "LoadingPortId",
    Label: "Port of  Loading/装货港Id",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "LoadingPortLabel",
    Label: "Port of  Loading/装货港",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DischargePortId",
    Label: "Port of Discharge/卸货港Id",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DischargePortLabel",
    Label: "Port of Discharge/卸货港",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DeliveryPortId",
    Label: "Port of Delivery/目的港Id",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DeliveryPortLabel",
    Label: "Port of Delivery/目的港",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DeliveryPlaceLabel",
    Label: "Place of Delivery/交货地点",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DeliveryPlaceAddress",
    Label: "详细地址",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CarrierId",
    Label: "承运人Id",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CarrierLabel",
    Label: "承运人",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "TransportChannelId",
    Label: "渠道Id",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "TransportChannelLabel",
    Label: "渠道",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ContractNo",
    Label: "合约号",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ShipName",
    Label: "船名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "VoyageNumber",
    Label: "航次",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Flight",
    Label: "航班",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "RailwayNumber",
    Label: "班次",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "EstimatedDepartureDate",
    Label: "装货港ETD",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "EstimatedArrivalDate",
    Label: "目的港ETA",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "TransportPaymentType",
    Label: "运费支付方式",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumTransportPaymentTypeOption,
  },
  {
    Prop: "GoodsTransportType",
    Label: "运输类型",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumGoodsTransportTypeOption,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CurrencySystemId",
    Label: "币制",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CurrencySystemCode",
    Label: "币制代码",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CurrencySystemLabel",
    Label: "币制",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ShowPackages",
    Label: "显示清单",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "Packages",
    Label: "货物清单",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "MarksNotes",
    Label: "唛头",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "PackagesNumber",
    Label: "装运数量",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "PackagesKind",
    Label: "装运包装",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "PackagesNumberKind",
    Label: "装运包装与数量",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "GoodsDescription",
    Label: "品名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "GoodsDescriptionRemark",
    Label: "品名备注",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "GrossWeight",
    Label: "毛重",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Volume",
    Label: "尺码",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Containers",
    Label: "集装箱信息",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Costs",
    Label: "费用信息",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SupplierCosts",
    Label: "成本信息",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SettlementCosts",
    Label: "业务员结算价",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "LadingBillType",
    Label: "提单类型",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumLadingBillTypeOption,
  },
  {
    Prop: "LadingBillNumber",
    Label: "提单份数",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumLadingBillNumberOption,
  },
  {
    Prop: "LadingBillRemark",
    Label: "备注",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "OrderId",
    Label: "订单Id",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "OrderLock",
    Label: "订单锁定",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "Status",
    Label: "状态",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumOrderPoolStatusOption,
  },
]


import SysAppService from "@/apis.machine/SysAppService";
import DtoCallbackCompareExcelRepeatValuesRepeatItem from "@/models.machine/DtoCallbackCompareExcelRepeatValuesRepeatItem";
import DtoRequestCompareExcelRepeatValues from "@/models.machine/DtoRequestCompareExcelRepeatValues";
import DtoRequestCompareExcelRepeatValues_Column from "@/models.machine/DtoRequestCompareExcelRepeatValues_Column";
import DtoRequestCompareExcelRepeatValues_Position from "@/models.machine/DtoRequestCompareExcelRepeatValues_Position";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import axios from "axios";
import { number } from "echarts";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";

export default class CompareExcelRepeatValues extends Vue {
  form = new MiezzForm<DtoRequestCompareExcelRepeatValues, string>();
  rules = {};

  btnShowCompareIgnore: MiezzButton = {
    Code: "show-compare-ignore",
    Label: "+添加列",
    Type: "default",
    Size: "small",
  };
  btnDeleteColumn: MiezzButton = {
    Code: "delete-column",
    Label: "删除",
    Type: "danger",
    Size: "small",
  };
  btnAddColumn: MiezzButton = {
    Code: "add-column",
    Label: "+添加列",
    Type: "default",
    Size: "small",
  };

  fileA?: any;
  fileB?: any;

  btnSubmit: MiezzButton = {
    Code: "submit",
    Label: "提交",
    Type: "primary",
    Size: "small",
  };

  created(): void {
    this.form.Data = new DtoRequestCompareExcelRepeatValues();
    this.form.Data.Columns?.push({
      Name: "名称",
      Power: 100,
      CompareIgnore: "",
      CompareIgnoreVisible: false,
      CompareIgnores: [],
    });
    this.form.Data.PositionA = {
      Row: 2,
      Columns: [1],
    };
    this.form.Data.PositionB = {
      Row: 2,
      Columns: [1],
    };
    this.form.Data.MinRate = 30;

    this.rules = {
      ColumnName: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
      ],
      ColumnPower: [
        {
          validator: (rule: any, value: number, callback: any) => {
            if (!value) {
              return callback(new Error("信息不能为空"));
            } else {
              let power = 0;
              this.form.Data.Columns?.map((it) => {
                return parseInt(it.Power?.toString() ?? "0");
              }).forEach((it) => (power = power + (it ?? 0)));
              if (power > 100) {
                return callback(
                  new Error(`权重总数不可超过100，当前为：${power}`)
                );
              }
            }
            callback();
          },
          trigger: "blur",
        },
      ],
      MinRate: [
        {
          validator: (rule: any, value: number, callback: any) => {
            if (!value) {
              return callback(new Error("信息不能为空"));
            } else {
              if (value > 100) {
                return callback(new Error("最低相似度不能超过100"));
              }
            }
            callback();
          },
          trigger: "blur",
        },
      ],
      PositionAFile: [
        {
          validator: (rule: any, value: string, callback: any) => {
            console.log(this.fileA);
            if (!this.fileA) {
              return callback(new Error("文件不能为空"));
            }
            callback();
          },
          trigger: "blur",
        },
      ],
      PositionARow: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
      ],
      PositionAColumns: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
      ],
      PositionBFile: [
        {
          validator: (rule: any, value: string, callback: any) => {
            console.log(this.fileB);
            if (!this.fileB) {
              return callback(new Error("文件不能为空"));
            }
            callback();
          },
          trigger: "blur",
        },
      ],
      PositionBRow: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
      ],
      PositionBColumns: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
      ],
    };
  }

  handleAddCompareIgnore(column: DtoRequestCompareExcelRepeatValues_Column) {
    if (column.CompareIgnore.trim()) {
      column.CompareIgnores?.push(column.CompareIgnore.trim());
      column.CompareIgnore = "";
      column.CompareIgnoreVisible = false;
    }
  }
  handleRemoveCompareIgnore(
    column: DtoRequestCompareExcelRepeatValues_Column,
    index: number
  ) {
    column.CompareIgnores?.splice(index, 1);
  }

  handleRowButtonClick(
    btn: MiezzButton,
    row: any,
    complete: () => void,
    index: number
  ): void {
    switch (btn.Code) {
      case "show-compare-ignore":
        row.CompareIgnoreVisible = true;
        complete();
        break;
      case "delete-column":
        this.form.Data.Columns?.splice(index, 1);
        complete();
        break;

      default:
        break;
    }
  }

  handleUploadA(file: any): any {
    this.fileA = file;
    console.log(this.fileA);
    return false;
  }
  handleUploadB(file: any): any {
    this.fileB = file;
    console.log(this.fileB);
    return false;
  }
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "add-column":
        this.form.Data.Columns?.push({
          Name: "",
          Power: 0,
          CompareIgnore: "",
          CompareIgnoreVisible: false,
          CompareIgnores: [],
        });
        complete();
        break;
      case "submit":
        (this.$refs["ruleForm"] as any).validate((v: boolean) => {
          if (v) {
            const formData = new FormData();
            formData.append("fileA", this.fileA);
            formData.append("fileB", this.fileB);
            formData.append(
              "PositionA.Row",
              this.form.Data.PositionA?.Row?.toString() ?? ""
            );
            for (const element of this.form.Data.PositionA?.Columns ?? []) {
              formData.append("PositionA.Columns", element.toString());
            }
            formData.append(
              "PositionB.Row",
              this.form.Data.PositionB?.Row?.toString() ?? ""
            );
            for (const element of this.form.Data.PositionB?.Columns ?? []) {
              formData.append("PositionB.Columns", element.toString());
            }
            for (let i = 0; i < (this.form.Data?.Columns ?? []).length; i++) {
              const element = (this.form.Data?.Columns ?? [])[i];
              formData.append(`Columns[${i}].Name`, element.Name ?? "");
              formData.append(
                `Columns[${i}].Power`,
                element.Power?.toString() ?? ""
              );
              for (const ignore of element.CompareIgnores ?? []) {
                formData.append(`Columns[${i}].CompareIgnores`, ignore);
              }
            }
            formData.append(
              "MinRate",
              this.form.Data.MinRate?.toString() ?? ""
            );
            axios
              .create({
                baseURL: process.env.VUE_APP_BASE_URL,
                withCredentials: true,
              })
              .post("/api/Sys/CompareExcelRepeatValues", formData)
              .then((r) => {
                console.log(r);
                window.open(
                  process.env.VUE_APP_BASE_URL + r.data.Data?.ExcelPath,
                  "_blank"
                );
              });
          }
        });
        complete();
        break;
      default:
        complete();
        break;
    }
  }
}

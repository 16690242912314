
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzModal from "@/models/MiezzModal";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoDetailOrderPoolProcess from "@/models.machine/DtoDetailOrderPoolProcess";
import MiezzPageList from "@/models/MiezzPageList";
import DtoDetailOrderPoolProcess_DeparturePortBooking, {
  DtoDetailOrderPoolProcess_DeparturePortBookingMiezzListItems,
} from "@/models.machine/DtoDetailOrderPoolProcess_DeparturePortBooking";
import DtoDetailOrderPoolProcess_OrderPoolContainerTrailer, {
  DtoDetailOrderPoolProcess_OrderPoolContainerTrailerMiezzListItems,
} from "@/models.machine/DtoDetailOrderPoolProcess_OrderPoolContainerTrailer";
import DtoDetailOrderPoolProcess_OrderPoolTrunk, {
  DtoDetailOrderPoolProcess_OrderPoolTrunkMiezzListItems,
} from "@/models.machine/DtoDetailOrderPoolProcess_OrderPoolTrunk";
import DtoDetailOrderPoolProcess_OrderPoolExpress, {
  DtoDetailOrderPoolProcess_OrderPoolExpressMiezzListItems,
} from "@/models.machine/DtoDetailOrderPoolProcess_OrderPoolExpress";
import DtoDetailOrderPoolProcess_OrderPoolCustoms, {
  DtoDetailOrderPoolProcess_OrderPoolCustomsMiezzListItems,
} from "@/models.machine/DtoDetailOrderPoolProcess_OrderPoolCustoms";
import DtoDetailOrderPoolProcess_OrderPoolWharf, {
  DtoDetailOrderPoolProcess_OrderPoolWharfMiezzListItems,
} from "@/models.machine/DtoDetailOrderPoolProcess_OrderPoolWharf";
import { EnumTransportContainerLoad } from "@/models.machine/EnumTransportContainerLoad";
import { EnumCustomsClearanceHeaderProvider } from "@/models.machine/EnumCustomsClearanceHeaderProvider";
import MiezzButton from "@/models/MiezzButton";
import { RouteLocation } from "vue-router";
import { DtoDetailOrderProcess_OrderPoolTrunkMiezzListItems } from "@/models.machine/DtoDetailOrderProcess_OrderPoolTrunk";
import { DtoDetailOrderProcess_OrderPoolExpressMiezzListItems } from "@/models.machine/DtoDetailOrderProcess_OrderPoolExpress";
import ExpressTracking from "./ExpressPage/ExpressTracking.vue";
import { it } from "element-plus/es/locale";
import moment from "moment";
import DtoCallbackOrderMainBillAutoPullThreeApiData_CustomsProcess from "@/models.machine/DtoCallbackOrderMainBillAutoPullThreeApiData_CustomsProcess";
import OrderMainBillAppService from "@/apis.machine/OrderMainBillAppService";
import DtoCallbackOrderMainBillAutoPullThreeApiData from "@/models.machine/DtoCallbackOrderMainBillAutoPullThreeApiData";
@Options({
  components: {
    ExpressTracking,
  },
})
export default class OrderPoolProcess extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  moment = moment;
  powers?: string[] = [];

  service = "";
  services: UiSelectOptionWithStringValue[] = [];
  detail?: DtoDetailOrderPoolProcess;

  tab = "";
  tabs: any[] = [];

  listDeparturePortBooking = new MiezzPageList<
    any,
    DtoDetailOrderPoolProcess_DeparturePortBooking,
    any
  >();
  listContainer = new MiezzPageList<
    any,
    DtoDetailOrderPoolProcess_OrderPoolContainerTrailer,
    any
  >();
  listTrunk = new MiezzPageList<
    any,
    DtoDetailOrderPoolProcess_OrderPoolTrunk,
    any
  >();
  listExpress = new MiezzPageList<
    any,
    DtoDetailOrderPoolProcess_OrderPoolExpress,
    any
  >();
  listWharf = new MiezzPageList<
    any,
    DtoDetailOrderPoolProcess_OrderPoolWharf,
    any
  >();
  listCustoms = new MiezzPageList<
    any,
    DtoDetailOrderPoolProcess_OrderPoolCustoms,
    any
  >();

  btnDetail: MiezzButton = {
    Code: "detail",
    Label: "查看详情",
    Size: "small",
    Type: "text",
  };

  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "detail":
        var taskNames = this.detail?.TaskActives?.firstOrDefault(
          (it) => it.Service == this.service
        )?.Types;
        var routeData: any;
        switch (this.service) {
          case "DeparturePortBooking":
            routeData = this.$router.resolve({
              path: `/order/pool/departure-port-booking/${this.id}`,
              query: { task: taskNames, code: this.service },
            });
            break;
          case "DeparturePortContainer":
            routeData = this.$router.resolve({
              path: `/order/pool/departure-port-container/${this.id}`,
              query: { task: taskNames, code: this.service },
            });
            break;
          case "DestinationPortContainer":
            routeData = this.$router.resolve({
              path: `/order/pool/destination-port-container/${this.id}`,
              query: { task: taskNames, code: this.service },
            });
            break;
          case "DeparturePortTrunk":
            routeData = this.$router.resolve({
              path: `/order/pool/departure-port-trunk/${this.id}`,
              query: { task: taskNames, code: this.service },
            });
            break;
          case "DestinationPortTrunk":
            routeData = this.$router.resolve({
              path: `/order/pool/destination-port-trunk/${this.id}`,
              query: { task: taskNames, code: this.service },
            });
            break;
          case "DeparturePortExpress":
            routeData = this.$router.resolve({
              path: `/order/pool/departure-port-express/${this.id}`,
              query: { task: taskNames, code: this.service },
            });
            break;
          case "DestinationPortExpress":
            routeData = this.$router.resolve({
              path: `/order/pool/destination-port-express/${this.id}`,
              query: { task: taskNames, code: this.service },
            });
            break;
          case "DeparturePortDeclareCustoms":
            routeData = this.$router.resolve({
              path: `/pool/departure/customs/${this.id}`,
              query: {
                task: taskNames,
                code: this.service,
                backUrl: this.$route.fullPath,
              },
            });
            break;
          case "DestinationPortClearCustoms":
            routeData = this.$router.resolve({
              path: `/pool/destination/customs/${this.id}`,
              query: {
                task: taskNames,
                code: this.service,
                backUrl: this.$route.fullPath,
              },
            });
            break;
          case "DeparturePortWarehouse":
            routeData = this.$router.resolve({
              path: `/pool/departure/wharf/${this.id}`,
              query: {
                task: taskNames,
                code: this.service,
                backUrl: this.$route.fullPath,
              },
            });
            break;
          case "DestinationPortWarehouse":
            routeData = this.$router.resolve({
              path: `/pool/destination/wharf/${this.id}`,
              query: {
                task: taskNames,
                code: this.service,
                backUrl: this.$route.fullPath,
              },
            });
            break;
          case "Bill":
            routeData = this.$router.resolve({
              path: `/pool/bill/${this.id}`,
              query: {
                task: taskNames,
                code: this.service,
                backUrl: this.$route.fullPath,
              },
            });
            break;
          case "ClientBill":
            routeData = this.$router.resolve({
              path: `/pool/bill/client/${this.id}`,
              query: {
                task: taskNames,
                code: this.service,
                backUrl: this.$route.fullPath,
              },
            });
            break;
          default:
            break;
        }
        if (routeData) {
          window.open(routeData.href, "_blank");
        }
        complete();
        break;
      default:
        break;
    }
  }

  created(): void {
    this.listDeparturePortBooking.Fix = false;
    this.listDeparturePortBooking.HideCard = true;
    this.listDeparturePortBooking.EnabledDynamicSearch = false;
    this.listDeparturePortBooking.EnabledRadioSearch = false;
    this.listDeparturePortBooking.EnabledSelect = false;
    this.listDeparturePortBooking.EnabledPagination = false;
    this.listDeparturePortBooking.Items =
      DtoDetailOrderPoolProcess_DeparturePortBookingMiezzListItems;
    this.listDeparturePortBooking.Data = {
      Items: [],
    };

    this.listContainer.Fix = false;
    this.listContainer.HideCard = true;
    this.listContainer.EnabledDynamicSearch = false;
    this.listContainer.EnabledRadioSearch = false;
    this.listContainer.EnabledSelect = false;
    this.listContainer.EnabledPagination = false;
    this.listContainer.Items =
      DtoDetailOrderPoolProcess_OrderPoolContainerTrailerMiezzListItems;
    this.listContainer.Data = {
      Items: [],
    };
    this.listTrunk.Fix = false;
    this.listTrunk.HideCard = true;
    this.listTrunk.EnabledDynamicSearch = false;
    this.listTrunk.EnabledRadioSearch = false;
    this.listTrunk.EnabledSelect = false;
    this.listTrunk.EnabledPagination = false;
    this.listTrunk.Items =
      DtoDetailOrderPoolProcess_OrderPoolTrunkMiezzListItems;
    this.listTrunk.Data = {
      Items: [],
    };
    this.listExpress.Fix = false;
    this.listExpress.HideCard = true;
    this.listExpress.EnabledDynamicSearch = false;
    this.listExpress.EnabledRadioSearch = false;
    this.listExpress.EnabledSelect = false;
    this.listExpress.EnabledPagination = false;
    this.listExpress.Items =
      DtoDetailOrderPoolProcess_OrderPoolExpressMiezzListItems;
    this.listExpress.Data = {
      Items: [],
    };
    this.listWharf.Fix = false;
    this.listWharf.HideCard = true;
    this.listWharf.EnabledDynamicSearch = false;
    this.listWharf.EnabledRadioSearch = false;
    this.listWharf.EnabledSelect = false;
    this.listWharf.EnabledPagination = false;
    this.listWharf.Items =
      DtoDetailOrderPoolProcess_OrderPoolWharfMiezzListItems;
    this.listWharf.Data = {
      Items: [],
    };
    this.listCustoms.Fix = false;
    this.listCustoms.HideCard = true;
    this.listCustoms.EnabledDynamicSearch = false;
    this.listCustoms.EnabledRadioSearch = false;
    this.listCustoms.EnabledSelect = false;
    this.listCustoms.EnabledPagination = false;
    this.listCustoms.Items =
      DtoDetailOrderPoolProcess_OrderPoolCustomsMiezzListItems;
    this.listCustoms.Data = {
      Items: [],
    };
    CurrentLogier.GetPowerCodes().then((r) => {
      this.powers = r ?? [];
      this.handleGet();
    });
  }

  api: DtoCallbackOrderMainBillAutoPullThreeApiData = {};

  @Watch("id")
  handleGet(): void {
    this.services = [];
    OrderPoolAppService.GetProcessById(this.id).then((r) => {
      this.detail = r.data.Data;
      if (this.detail) {
        if (!this.api.Query) {
          OrderMainBillAppService.AutoPullThreeApiData({
            OrderId: this.detail.OrderId,
            UpdateData: true,
          }).then((rr) => {
            this.api = rr.data.Data ?? {};
            this.api.Query = true;
            for (const item of this.detail?.DeparturePortBookingList ?? []) {
              item.EstimatedDepartureDate =
                rr.data.Data?.EstimatedDepartureDate;
              item.ActualDepartureDate = rr.data.Data?.ActualDepartureDate;
              item.EstimatedArrivalDate = rr.data.Data?.EstimatedArrivalDate;
              item.ActualArrivalDate = rr.data.Data?.ActualArrivalDate;
            }
            for (const item of this.detail?.DeparturePortContainerList ?? []) {
              const containerTrailer =
                rr.data.Data?.ContainerTrailers?.firstOrDefault(
                  (it) => it.Id == item.Id
                );
              if (containerTrailer) {
                item.CarryContainerTime = containerTrailer.CarryContainerTime;
                item.BackContainerTime = containerTrailer.BackContainerTime;
              }
            }
            for (const item of this.detail?.DestinationPortContainerList ??
              []) {
              const containerTrailer =
                rr.data.Data?.ContainerTrailers?.firstOrDefault(
                  (it) => it.Id == item.Id
                );
              if (containerTrailer) {
                item.CarryContainerTime = containerTrailer.CarryContainerTime;
                item.BackContainerTime = containerTrailer.BackContainerTime;
              }
            }
            for (const item of this.detail?.DeparturePortDeclareCustomsList ??
              []) {
              const customs = rr.data.Data?.CustomsList?.firstOrDefault(
                (it) => it.Id == item.Id
              );
              if (customs) {
                item.ReleaseTime = customs.ReleaseTime;
              }
            }
            for (const item of this.detail?.DestinationPortClearCustomsList ??
              []) {
              const customs = rr.data.Data?.CustomsList?.firstOrDefault(
                (it) => it.Id == item.Id
              );
              if (customs) {
                item.ReleaseTime = customs.ReleaseTime;
              }
            }
          });
        }

        //装货港-订舱
        if (
          this.detail.DeparturePortBooking &&
          this.powers?.contains(
            "OrderManagement_Pool_Summary_DeparturePortBooking"
          )
        ) {
          this.services.push({
            Value: "DeparturePortBooking",
            Label: "装货港-订舱",
            Class:
              (this.detail.DeparturePortBookingList?.length ?? 0) > 0 &&
              this.detail.DeparturePortBookingList?.all(
                (it) =>
                  it.EstimatedDepartureDate &&
                  it.ActualDepartureDate &&
                  it.EstimatedArrivalDate &&
                  it.ActualArrivalDate
              )
                ? "ed"
                : (this.detail.DeparturePortBookingList?.length ?? 0) > 0 &&
                  this.detail.DeparturePortBookingList?.any(
                    (it) =>
                      it.EstimatedDepartureDate ||
                      it.ActualDepartureDate ||
                      it.EstimatedArrivalDate ||
                      it.ActualArrivalDate
                  )
                ? "ing"
                : "not",
          });
        }
        //装货港-集装箱拖车
        if (
          //直订订单 并且 委托书FCL 并且有集装箱拖车
          this.detail.DirectOrder &&
          this.detail.TransportContainerLoad ==
            EnumTransportContainerLoad.FCL &&
          this.detail.DeparturePortContainer &&
          this.powers?.contains(
            "OrderManagement_Pool_Summary_DeparturePortContainer"
          )
        ) {
          this.services.push({
            Value: "DeparturePortContainer",
            Label: "装货港-集装箱拖车",
            Class:
              (this.detail.DeparturePortContainerList?.length ?? 0) > 0 &&
              this.detail.DeparturePortContainerList?.all(
                (it) =>
                  it.CarryContainerTime &&
                  it.PlanTime &&
                  it.ArrivalTime &&
                  it.BackContainerTime
              )
                ? "ed"
                : (this.detail.DeparturePortContainerList?.length ?? 0) > 0 &&
                  this.detail.DeparturePortContainerList?.any(
                    (it) =>
                      it.CarryContainerTime ||
                      it.PlanTime ||
                      it.ArrivalTime ||
                      it.BackContainerTime
                  )
                ? "ing"
                : "not",
          });
        }
        //装货港-快递
        if (
          this.detail.DeparturePortExpress &&
          this.powers?.contains(
            "OrderManagement_Pool_Summary_DeparturePortExpress"
          )
        ) {
          this.services.push({
            Value: "DeparturePortExpress",
            Label: "装货港-快递",
            Class:
              (this.detail.DeparturePortExpressList?.length ?? 0) > 0 &&
              this.detail.DeparturePortExpressList?.all(
                (it) =>
                  it.AllCarryTime && it.PlanDeliveryTime && it.AllDeliveryTime
              )
                ? "ed"
                : (this.detail.DeparturePortExpressList?.length ?? 0) > 0 &&
                  this.detail.DeparturePortExpressList?.any(
                    (it) =>
                      it.AllCarryTime ||
                      it.PlanDeliveryTime ||
                      it.AllDeliveryTime
                  )
                ? "ing"
                : "not",
          });
        }
        //装货港-干线物流
        if (
          this.detail.DeparturePortTrunk &&
          this.powers?.contains(
            "OrderManagement_Pool_Summary_DeparturePortTrunk"
          )
        ) {
          this.services.push({
            Value: "DeparturePortTrunk",
            Label: "装货港-干线物流",
            Class:
              (this.detail.DeparturePortTrunkList?.length ?? 0) > 0 &&
              this.detail.DeparturePortTrunkList?.all(
                (it) =>
                  it.AllCarryTime && it.PlanDeliveryTime && it.AllDeliveryTime
              )
                ? "ed"
                : (this.detail.DeparturePortTrunkList?.length ?? 0) > 0 &&
                  this.detail.DeparturePortTrunkList?.any(
                    (it) =>
                      it.AllCarryTime ||
                      it.PlanDeliveryTime ||
                      it.AllDeliveryTime
                  )
                ? "ing"
                : "not",
          });
        }
        //装货港-仓库/堆场/码头
        if (
          this.detail.DeparturePortWarehouse &&
          this.powers?.contains(
            "OrderManagement_Pool_Summary_DeparturePortWarehouse"
          )
        ) {
          this.services.push({
            Value: "DeparturePortWarehouse",
            Label: "装货港-仓库/堆场/码头",
            Class:
              (this.detail.DeparturePortWarehouseList?.length ?? 0) > 0 &&
              this.detail.DeparturePortWarehouseList?.all(
                (it) => it.InStockTime && it.OutStockTime
              )
                ? "ed"
                : (this.detail.DeparturePortWarehouseList?.length ?? 0) > 0 &&
                  this.detail.DeparturePortWarehouseList?.any(
                    (it) => it.InStockTime || it.OutStockTime
                  )
                ? "ing"
                : "not",
          });
        }
        //装货港-报关
        if (
          this.detail.DeparturePortDeclareCustoms &&
          this.detail.DeparturePortDrawback &&
          this.powers?.contains(
            "OrderManagement_Pool_Summary_PortOfDepartureCustomsAffairs"
          )
        ) {
          this.services.push({
            Value: "DeparturePortDeclareCustoms",
            Label: "装货港-报关",
            Class:
              (this.detail.DeparturePortDeclareCustomsList?.length ?? 0) > 0 &&
              this.detail.DeparturePortDeclareCustomsList?.all(
                (it) => it.ReleaseTime
              )
                ? "ed"
                : (this.detail.DeparturePortDeclareCustomsList?.length ?? 0) >
                    0 &&
                  this.detail.DeparturePortDeclareCustomsList?.any(
                    (it) => it.ReleaseTime
                  )
                ? "ing"
                : "not",
          });
        }
        //目的港-清关
        if (
          this.detail.DestinationPortClearCustoms &&
          this.detail.DestinationPortCustomsClearanceHeaderProvider ==
            EnumCustomsClearanceHeaderProvider.Client &&
          this.powers?.contains(
            "OrderManagement_Pool_Summary_DestinationPortClearCustoms"
          )
        ) {
          this.services.push({
            Value: "DestinationPortClearCustoms",
            Label: "目的港-清关",
            Class:
              (this.detail.DestinationPortClearCustomsList?.length ?? 0) > 0 &&
              this.detail.DestinationPortClearCustomsList?.all(
                (it) => it.ReleaseTime
              )
                ? "ed"
                : (this.detail.DestinationPortClearCustomsList?.length ?? 0) >
                    0 &&
                  this.detail.DestinationPortClearCustomsList?.any(
                    (it) => it.ReleaseTime
                  )
                ? "ing"
                : "not",
          });
        }
        //目的港-集装箱拖车
        if (
          //配载订单 并且 委托书任意(LCL有集装箱拖车) 并且 已关联订单
          ((!this.detail.DirectOrder &&
            this.detail.TransportContainerLoad ==
              EnumTransportContainerLoad.LCL &&
            this.detail.DestinationPortContainer &&
            this.detail.OrderId) ||
            //直订订单 并且 有集装箱拖车
            (this.detail.DirectOrder &&
              this.detail.DestinationPortContainer)) &&
          this.powers?.contains(
            "OrderManagement_Pool_Summary_DestinationPortContainer"
          )
        ) {
          this.services.push({
            Value: "DestinationPortContainer",
            Label: "目的港-集装箱拖车",
            Class:
              (this.detail.DestinationPortContainerList?.length ?? 0) > 0 &&
              this.detail.DestinationPortContainerList?.all(
                (it) =>
                  it.CarryContainerTime &&
                  it.PlanTime &&
                  it.ArrivalTime &&
                  it.BackContainerTime
              )
                ? "ed"
                : (this.detail.DestinationPortContainerList?.length ?? 0) > 0 &&
                  this.detail.DestinationPortContainerList?.any(
                    (it) =>
                      it.CarryContainerTime ||
                      it.PlanTime ||
                      it.ArrivalTime ||
                      it.BackContainerTime
                  )
                ? "ing"
                : "not",
          });
        }
        if (
          this.detail.DestinationPortWarehouse &&
          this.powers?.contains(
            "OrderManagement_Pool_Summary_DestinationPortWarehouse"
          )
        ) {
          this.services.push({
            Value: "DestinationPortWarehouse",
            Label: "目的港-仓库/堆场/码头",
            Class:
              (this.detail.DestinationPortWarehouseList?.length ?? 0) > 0 &&
              this.detail.DestinationPortWarehouseList?.all(
                (it) => it.InStockTime && it.OutStockTime
              )
                ? "ed"
                : (this.detail.DestinationPortWarehouseList?.length ?? 0) > 0 &&
                  this.detail.DestinationPortWarehouseList?.any(
                    (it) => it.InStockTime || it.OutStockTime
                  )
                ? "ing"
                : "not",
          });
        }
        //目的港-干线物流
        if (
          this.detail.DestinationPortTrunk &&
          this.powers?.contains(
            "OrderManagement_Pool_Summary_DestinationPortTrunk"
          )
        ) {
          this.services.push({
            Value: "DestinationPortTrunk",
            Label: "目的港-干线物流",
            Class:
              (this.detail.DestinationPortTrunkList?.length ?? 0) > 0 &&
              this.detail.DestinationPortTrunkList?.all(
                (it) =>
                  it.AllCarryTime && it.PlanDeliveryTime && it.AllDeliveryTime
              )
                ? "ed"
                : (this.detail.DestinationPortTrunkList?.length ?? 0) > 0 &&
                  this.detail.DestinationPortTrunkList?.any(
                    (it) =>
                      it.AllCarryTime ||
                      it.PlanDeliveryTime ||
                      it.AllDeliveryTime
                  )
                ? "ing"
                : "not",
          });
        }
        //目的港-快递
        if (
          this.detail.DestinationPortExpress &&
          this.powers?.contains(
            "OrderManagement_Pool_Summary_DestinationPortExpress"
          )
        ) {
          this.services.push({
            Value: "DestinationPortExpress",
            Label: "目的港-快递",
            Class:
              (this.detail.DestinationPortExpressList?.length ?? 0) > 0 &&
              this.detail.DestinationPortExpressList?.all(
                (it) =>
                  it.AllCarryTime && it.PlanDeliveryTime && it.AllDeliveryTime
              )
                ? "ed"
                : (this.detail.DestinationPortExpressList?.length ?? 0) > 0 &&
                  this.detail.DestinationPortExpressList?.any(
                    (it) =>
                      it.AllCarryTime ||
                      it.PlanDeliveryTime ||
                      it.AllDeliveryTime
                  )
                ? "ing"
                : "not",
          });
        }

        this.handleServiceClick(this.services.firstOrDefault()?.Value ?? "");
      }
    });
  }

  handleServiceClick(service: string): void {
    this.service = service;
    this.$nextTick(() => {
      switch (this.service) {
        case "DeparturePortBooking":
          this.tabs = this.detail?.DeparturePortBookingList ?? [];
          break;
        case "DeparturePortContainer":
          this.tabs = this.detail?.DeparturePortContainerList ?? [];
          break;
        case "DeparturePortExpress":
          this.tabs = this.detail?.DeparturePortExpressList ?? [];
          break;
        case "DeparturePortTrunk":
          this.tabs = this.detail?.DeparturePortTrunkList ?? [];
          break;
        case "DeparturePortDeclareCustoms":
          this.tabs = this.detail?.DeparturePortDeclareCustomsList ?? [];
          break;
        case "DeparturePortWarehouse":
          this.tabs = this.detail?.DeparturePortWarehouseList ?? [];
          break;
        case "DestinationPortClearCustoms":
          this.tabs = this.detail?.DestinationPortClearCustomsList ?? [];
          break;
        case "DestinationPortContainer":
          this.tabs = this.detail?.DestinationPortContainerList ?? [];
          break;
        case "DestinationPortTrunk":
          this.tabs = this.detail?.DestinationPortTrunkList ?? [];
          break;
        case "DestinationPortExpress":
          this.tabs = this.detail?.DestinationPortExpressList ?? [];
          break;
        case "DestinationPortWarehouse":
          this.tabs = this.detail?.DestinationPortWarehouseList ?? [];
          break;

        default:
          break;
      }
      this.tab = this.tabs.firstOrDefault()?.Id;
      this.handleTabClick();
    });
  }

  handleTabClick(): void {
    this.$nextTick(() => {
      if (this.tab) {
        switch (this.service) {
          case "DeparturePortBooking":
            this.listDeparturePortBooking.Data = {
              Items: [this.tabs.firstOrDefault((it) => it.Id == this.tab)],
            };
            break;
          case "DeparturePortContainer":
          case "DestinationPortContainer":
            for (const item of this.listContainer.Items ?? []) {
              switch (item.Prop) {
                case "PlanTime":
                  item.Label =
                    this.service == "DeparturePortContainer"
                      ? "计划装柜时间"
                      : "计划卸货时间";
                  break;
                case "ArrivalTime":
                  item.Label =
                    this.service == "DeparturePortContainer"
                      ? "装柜时间"
                      : "卸货时间";
                  break;
                case "BackContainerTime":
                  item.Label =
                    this.service == "DeparturePortContainer"
                      ? "进港时间"
                      : "还柜时间";
                  break;
              }
            }
            this.listContainer.Data = {
              Items: [this.tabs.firstOrDefault((it) => it.Id == this.tab)],
            };
            break;
          case "DeparturePortExpress":
          case "DestinationPortExpress":
            this.listExpress.Data = {
              Items: [this.tabs.firstOrDefault((it) => it.Id == this.tab)],
            };
            if (
              this.service == "DestinationPortExpress" &&
              this.detail?.ShowCarryContainerTime
            ) {
              this.listExpress.Items =
                DtoDetailOrderProcess_OrderPoolExpressMiezzListItems;
            } else {
              this.listExpress.Items =
                DtoDetailOrderProcess_OrderPoolExpressMiezzListItems.filter(
                  (it) => it.Prop != "CarryContainerTime"
                );
            }
            break;
          case "DeparturePortTrunk":
          case "DestinationPortTrunk":
            this.listTrunk.Data = {
              Items: [this.tabs.firstOrDefault((it) => it.Id == this.tab)],
            };
            if (
              this.service == "DestinationPortTrunk" &&
              this.detail?.ShowCarryContainerTime
            ) {
              this.listTrunk.Items =
                DtoDetailOrderProcess_OrderPoolTrunkMiezzListItems;
            } else {
              this.listTrunk.Items =
                DtoDetailOrderProcess_OrderPoolTrunkMiezzListItems.filter(
                  (it) => it.Prop != "CarryContainerTime"
                );
            }
            break;
          case "DeparturePortDeclareCustoms":
          case "DestinationPortClearCustoms":
            this.listCustoms.Data = {
              Items: [this.tabs.firstOrDefault((it) => it.Id == this.tab)],
            };
            break;
          case "DeparturePortWarehouse":
          case "DestinationPortWarehouse":
            this.listWharf.Data = {
              Items: [this.tabs.firstOrDefault((it) => it.Id == this.tab)],
            };
            break;

          default:
            break;
        }
      }
    });
  }
}

import { AxiosPromise } from "axios";
import service from "../apis/Service";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoTabOrderPartBill from "../models.machine/DtoTabOrderPartBill";
import DtoDetailOrderPartBill from "../models.machine/DtoDetailOrderPartBill";
import DtoFormOrderPartBill from "../models.machine/DtoFormOrderPartBill";
import DtoDetailOrderPartBillSyncMainBillData from "../models.machine/DtoDetailOrderPartBillSyncMainBillData";
import DtoOptionOrderPartBillElement from "../models.machine/DtoOptionOrderPartBillElement";
import DtoDetailOrderPartBillTemplate from "../models.machine/DtoDetailOrderPartBillTemplate";
import DtoFormOrderPartBillTemplate from "../models.machine/DtoFormOrderPartBillTemplate";

/**
 * 订单-分单信息
 * @description 自动生成的代码,请勿修改
 */
export default class OrderPartBillAppService {
    /**
     * 获取标签页信息
     * @param {string} [id] 订单Id
     * @returns {AxiosPromise<RESTfulResult<DtoTabOrderPartBill[]>>} 标签页信息
     */
    static GetTabsById(id?: string): AxiosPromise<RESTfulResult<DtoTabOrderPartBill[]>> {
        const url = `/api/OrderPartBill/GetTabs/${id}`;
        return service.get<RESTfulResult<DtoTabOrderPartBill[]>>(url);
    }

    /**
     * 获取订单-分单信息详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderPartBill>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailOrderPartBill>> {
        const url = `/api/OrderPartBill/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailOrderPartBill>>(url);
    }

    /**
     * 添加订单-分单信息
     * @param {DtoFormOrderPartBill} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormOrderPartBill): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/OrderPartBill/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 获取同步主单数据
     * @param {string} [orderId] 
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderPartBillSyncMainBillData>>} 
     */
    static GetSyncMainBillDataByOrderId(orderId?: string): AxiosPromise<RESTfulResult<DtoDetailOrderPartBillSyncMainBillData>> {
        const url = `/api/OrderPartBill/GetSyncMainBillData/${orderId}`;
        return service.get<RESTfulResult<DtoDetailOrderPartBillSyncMainBillData>>(url);
    }

    /**
     * 获取订单-分单信息模板元素
     * @param {string} [id] 
     * @param {string} [orderPartBillId] 
     * @returns {AxiosPromise<RESTfulResult<DtoOptionOrderPartBillElement[]>>} 
     */
    static GetOrderPartBillElementByIdByOrderPartBillId(id?: string, orderPartBillId?: string): AxiosPromise<RESTfulResult<DtoOptionOrderPartBillElement[]>> {
        const url = `/api/OrderPartBill/GetOrderPartBillElement/${id}/${orderPartBillId}`;
        return service.get<RESTfulResult<DtoOptionOrderPartBillElement[]>>(url);
    }

    /**
     * 获取正负提单模板
     * @param {string} [id] 
     * @param {string} [orderPartBillId] 
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderPartBillTemplate[]>>} 
     */
    static GetOrderPartBillTempalateByIdByOrderPartBillId(id?: string, orderPartBillId?: string): AxiosPromise<RESTfulResult<DtoDetailOrderPartBillTemplate[]>> {
        const url = `/api/OrderPartBill/GetOrderPartBillTempalate/${id}/${orderPartBillId}`;
        return service.get<RESTfulResult<DtoDetailOrderPartBillTemplate[]>>(url);
    }

    /**
     * 提交提单模板
     * @param {number} [pageNumber] 
     * @param {DtoFormOrderPartBillTemplate} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostOrderPartBillTemplateByPageNumber(pageNumber?: number, dto?: DtoFormOrderPartBillTemplate): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPartBill/PostOrderPartBillTemplate/${pageNumber}`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}

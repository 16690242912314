import { EnumPriceAmountType, EnumPriceAmountTypeOption } from "./EnumPriceAmountType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceTableShowPower {
    constructor() {
        //无参构造函数
        this.TargetIds = [];
    }

    [proName: string]: any;
    /**
     * 价格表Id
     */
    TableId?: string;
    /**
     * 价格金额类型
     */
    AmountType?: EnumPriceAmountType;
    /**
     * 
     */
    TargetIds?: string[];
}

/**
 * -表单校验
 */
export const DtoFormPriceTableShowPowerRules = {
}

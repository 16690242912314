
    import { ElMessageBox } from "element-plus";
    import OSS from "ali-oss";
    import AliyunOSS from "@/apis/AliyunOSS";
    import moment from "moment";
    import CurrentLogier from "@/apis/CurrentLogier";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzModal from "@/models/MiezzModal";
    import MiezzButton from "@/models/MiezzButton";
    import BillAppService from "@/apis.machine/BillAppService";
    import BillHistoryRecordAppService from "@/apis.machine/BillHistoryRecordAppService";
    import DtoCallbackBill from "@/models.machine/DtoCallbackBill";
    import DtoRequestOrderLock from "@/models.machine/DtoRequestOrderLock";
    import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
    import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
    import { EnumRelation } from "@/models.machine/EnumRelation";
    import { EnumCompare } from "@/models.machine/EnumCompare";
    import { EnumBillType } from "@/models.machine/EnumBillType";
    import DtoRequestUnlock from "@/models.machine/DtoRequestUnlock";
    import DtoRequestBill from "@/models.machine/DtoRequestBill";
    import DtoFinishTask from "@/models.machine/DtoFinishTask";
    import DtoPageListSearchBillHistoryRecord, { DtoPageListSearchBillHistoryRecordDynamicSearchItems } from "@/models.machine/DtoPageListSearchBillHistoryRecord";
    import DtoPageListBillHistoryRecord from "@/models.machine/DtoPageListBillHistoryRecord";
    import DtoRequestInvoice from "@/models.machine/DtoRequestInvoice";
    import DtoRequestFinance from "@/models.machine/DtoRequestFinance";
    import FinanceAppService from "@/apis.machine/FinanceAppService";
    import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
    import DtoRequestSettlement from "@/models.machine/DtoRequestSettlement";

    import DtoListItemBillHistoryRecord, {
        DtoListItemBillHistoryRecordMiezzListItems,
    } from "@/models.machine/DtoListItemBillHistoryRecord";
    export default class BillPageList extends Vue {
        client?: OSS;
        /**分页列表 */
        dto = new DtoCallbackBill();
        init = new DtoCallbackBill();
        lockParam = new DtoRequestOrderLock();
        menuTab = new MiezzMenuTab();
        /*委托书主键 */
        id?: string;
        /*订单主键 */
        orderId?: string;
        /*0表示委托书账单，1表示订单账单 */
        type = 0;
        isCollection = true;
        isPayment = true;
        isSaler = true;
        isHistory = true;
        isLoading = false;

        isCollectionExport = true;
        isPaymentExport = true;
        isSalerExport = true;
        historyCurrentPage = 1;
        historyPageSize = 5;

        /**分页列表 */
        historys = new MiezzPageList<DtoPageListSearchBillHistoryRecord, DtoListItemBillHistoryRecord, string>();

        //查看应收
        OrderManagement_Pool_Summary_BillingInformation_ViewAccountsReceivable = false;
        //查看应付
        OrderManagement_Pool_Summary_BillingInformation_ViewAccountsPayable = false;
        //查看结算
        OrderManagement_Pool_Summary_BillingInformation_ViewSettlement = false;
        //查看应收
        OrderManagement_Order_Summary_BillingInformation_ViewAccountsReceivable = false;
        //查看应付
        OrderManagement_Order_Summary_BillingInformation_ViewAccountsPayable = false;
        //查看结算
        OrderManagement_Order_Summary_BillingInformation_ViewSettlementPrice = false;
        btnKey = 0;
        bottomButtons?: MiezzButton[] = [
            {
                Code: "back",
                Label: "返回",
                MiezzIcon: "back",
                Type: "text",
                Size: "small",
            },
            {
                Code: "collection",
                Label: "编辑应收",
                Icon: "edit",
                Type: "text",
                Size: "small",
                Hide: true,
                Breathe: false,
            },
            {
                Code: "payment",
                Label: "编辑应付",
                Icon: "edit",
                Type: "text",
                Size: "small",
                Hide: true,
                Breathe: false,
            },
            {
                Code: "saler",
                Label: "编辑结算价",
                Icon: "edit",
                Type: "text",
                Size: "small",
                Hide: true,
                Breathe: false,
            },
            {
                Code: "share",
                Label: "分摊应付账单",
                Icon: "Share",
                Type: "text",
                Size: "small",
                Hide: true,
            },
            {
                IsDropdown: true,
                Code: "unlock",
                Label: "解锁账单",
                Icon: "unlock",
                Type: "text",
                Size: "small",
                Hide: true,
                Options: [
                    {
                        Code: "10",
                        Label: "解锁应收",
                        Size: "small",
                        Hide: false,
                    },
                    {
                        Code: "20",
                        Label: "解锁应付",
                        Size: "small",
                        Hide: false,
                    },
                    {
                        Code: "30",
                        Label: "解锁结算价",
                        Size: "small",
                        Hide: false,
                    },
                    {
                        Code: "40",
                        Label: "解锁全部",
                        Size: "small",
                        Hide: false,
                    }
                ],
            },
            {
                IsDropdown: true,
                Code: "export",
                Label: "导出账单",
                Icon: "download",
                Type: "text",
                Size: "small",
                Hide: false,
                Options: [
                    {
                        Code: "10",
                        Label: "应收账单",
                        Size: "small",
                        Hide: false,
                    },
                    {
                        Code: "20",
                        Label: "应付账单",
                        Size: "small",
                        Hide: false,
                    },
                    {
                        Code: "30",
                        Label: "业务员账单",
                        Size: "small",
                        Hide: false,
                    },
                    {
                        Code: "40",
                        Label: "导出全部",
                        Size: "small",
                        Hide: false,
                    }
                ],
            },
        ];

        FinancialManagement_BillSummary = false;
        //监听路由是否有变化
        @Watch("$route")
        onRouteChanged(): void {
            this.id = undefined;
            this.orderId = undefined;
            this.onInit();
        }

        invoiceForm = new DtoRequestInvoice();
        invoiceFormInit = new DtoRequestInvoice();
        invoiceBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        settlementForm = new DtoRequestSettlement();
        settlementBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
            //{
            //    //Icon: "select",
            //    Code: "import",
            //    Label: "核销",
            //    Type: "primary",
            //    Size: "small",
            //},
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        onSettlement(row: any) {
            
            FinanceAppService.GetSettlementByBillId(row.Id).then((r) => {
                if (r.data.Data) this.settlementForm = r.data.Data;
            })

            if (this.historys.Dto) {
                this.historys.Dto.CurrentPage = 1;
                this.historys.Dto.PageSize = 1 * this.historyPageSize;
                this.historys.Dto.BillId = row.Id;
            }
            this.isHistory = true;
            BillHistoryRecordAppService.Get(this.historys.Dto).then((r) => {
                this.historys.Data = r.data.Data;
            });

        }

        handleClickSettlement(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.settlementForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }


        handleClickInvoice(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.invoiceForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
        onAnnex(row: any) {
            var url = this.client?.signatureUrl(row.Url as string);
            window.open(
                url,
                "_blank"
            );
        }
        onInvoice(row: any) {


            var param = new DtoRequestFinance();
            param.BillIds = [];
            param.BillIds.push(row.Id);
            FinanceAppService.GetInvoice(param).then((r) => {
                if (r.data.Data) this.invoiceForm = r.data.Data;
                this.invoiceFormInit = JSON.parse(JSON.stringify(this.invoiceForm));
                this.$nextTick(() => {
                    if (this.invoiceForm && this.invoiceForm.InvoiceItems) {
                        this.onInvoiceSum();
                    }
                });

            })
        }
        async onInvoiceSum(): Promise<void> {
            var bill = 0;
            var invoice = 0;


            if (this.invoiceForm) {

                if (this.invoiceForm.Bills && this.invoiceForm.Bills.length > 0) {
                    for (var b of this.invoiceForm.Bills) {
                        if (b.CurrencySystemCode != undefined && b.CurrencySystemCode != null && b.CurrencySystemCode != "") {
                            if (b.CurrencySystemCode.toLocaleUpperCase() == "CNY") {
                                if (b.Price) {
                                    bill += b.Price;
                                }
                            }
                            else {
                                await ParamCurrencySystemAppService.GetExchangeRateByFromByTo(b.CurrencySystemCode, "CNY").then((r1) => {
                                    var rate1 = r1.data.Data;
                                    if (rate1 != undefined && rate1 != "") {
                                        if (b.Price) {
                                            bill += b.Price * Number(rate1);
                                        }
                                    }
                                })
                            }
                        }
                    }
                }

                this.invoiceForm.InvoiceString = "CNY0";
                var priceString = "";
                if (this.invoiceForm.InvoiceItems && this.invoiceForm.InvoiceItems.length > 0) {
                    var codes = [];
                    for (var it of this.invoiceForm.InvoiceItems) {
                        if (it.CurrencySystem != undefined && it.CurrencySystem != null) {
                            var exist = false;
                            for (var code of codes) {
                                if (code == it.CurrencySystem.Code) {
                                    exist = true;
                                    break;
                                }
                            }
                            if (exist == false) {
                                codes.push(it.CurrencySystem.Code);
                            }

                            if (it.CurrencySystem.Code?.toLocaleUpperCase() == "CNY") {
                                if (it.Price) {
                                    invoice += it.Price;
                                }
                            }
                            else {
                                await ParamCurrencySystemAppService.GetExchangeRateByFromByTo(it.CurrencySystem.Code, "CNY").then((r) => {
                                    var rate = r.data.Data;
                                    if (rate != undefined && rate != "") {
                                        if (it.Price) {
                                            invoice += it.Price * Number(rate);
                                        }
                                    }
                                })
                            }
                        }
                    }
                    if (codes.length > 0) {
                        for (var c of codes) {
                            var price = 0;
                            for (var it1 of this.invoiceForm.InvoiceItems) {
                                if (it1.CurrencySystem != null && it1.CurrencySystem != undefined) {
                                    if (it1.CurrencySystem.Code == c) {
                                        if (it1.Price) {
                                            price += it1.Price;
                                        }
                                    }
                                }
                            }
                            if (price > 0 && c) {

                                var result1 = setDecimal(price.toString(), 4, false, true);
                                if (result1 != "") {
                                    price = Number(result1);
                                }

                                priceString += c + price + " ";
                            }
                        }
                    }
                }
                if (priceString != "") {
                    this.invoiceForm.InvoiceString = priceString;
                }
                this.invoiceForm.IsSame = false;
                if (bill == invoice && invoice > 0) {
                    this.invoiceForm.IsSame = true;
                }
            }
        }

        onFinishTask(): void {
            this.isPayment = !this.isPayment;
            if (this.id != null && this.id != undefined && this.id != "") {

                ElMessageBox.confirm("您是否确认关闭当前委托书的待办《制作应付账单》？", "提示", {
                    confirmButtonText: "确认",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {

                        var param = new DtoFinishTask();
                        param.PoolId = this.id;
                        this.isLoading = true;
                        BillAppService.FinishTask(param).then((r) => {
                            this.isLoading = false;

                            //this.dto.IsPaymentTask == false;
                            /*if (this.bottomButtons) {
                                this.bottomButtons[5].Hide = false;
                                if (this.bottomButtons[5].Options) {
                                    this.bottomButtons[5].Options[1].Hide = false;
                                    this.bottomButtons[5].Options[3].Hide = false;
                                }
                            }*/

                            //this.handleGet();
                            //this.onInit();
                            //this.btnKey = Math.random();


                            ElMessage.success("已关闭当前委托书的待办《制作应付账单》");
                            setTimeout(function () {
                                location.reload();
                            }, 10)
                            //this.$nextTick(function () {
                            //    location.reload();
                            //});


                        })
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
        onInit(): void {
            CurrentLogier.GetPowerCodes().then((r) => {
                if (r?.contains("FinancialManagement_BillSummary")) {
                    this.FinancialManagement_BillSummary = true;
                }
            })
            this.client = AliyunOSS.GetClient();
            //列表-传参
            this.historys.Dto = new DtoPageListSearchBillHistoryRecord();
            //列表-动态搜索-项
            this.historys.DynamicSearchItems = DtoPageListSearchBillHistoryRecordDynamicSearchItems;
            //列表-数据-初始
            this.historys.Data = new DtoPageListBillHistoryRecord();
            if (this.$route && this.$route.matched) {
                for (var matched of this.$route.matched) {
                    if (matched.path.toLowerCase() == "/pool/bill/:id") {
                        this.id = this.$route.params.id as string;
                        if (this.historys.Dto) {
                            this.historys.Dto.PoolId = this.id;
                            this.historys.Dto.OrderId = undefined;
                        }
                        this.type = 0;
                        this.menuTab.Back = true;
                        this.menuTab.Url = `/pool/bill/${this.id}`;
                        this.menuTab.Menus = [
                            {
                                Label: "账单信息",
                                Url: `/pool/bill/${this.id}`,
                                Power: "OrderManagement_Pool_Summary_BillingInformation",
                            },
                        ];
                        CurrentLogier.GetPowerCodes().then((r) => {

                            if (r?.contains("OrderManagement_Pool_Summary_BillingInformation_EditAR")) {
                                if (this.bottomButtons != undefined) {
                                    this.bottomButtons[1].Hide = false;
                                }
                            }
                            if (r?.contains("OrderManagement_Pool_Summary_BillingInformation_EditAP")) {
                                if (this.bottomButtons) {
                                    this.bottomButtons[2].Hide = false;
                                }
                            }
                            if (r?.contains("OrderManagement_Pool_Summary_BillingInformation_EditSettlementPrice")) {
                                if (this.bottomButtons) {
                                    this.bottomButtons[3].Hide = false;
                                }
                            }
                            if (r?.contains("OrderManagement_Pool_Summary_BillingInformation_UnlockBilling")) {
                                if (this.bottomButtons) {
                                    this.bottomButtons[5].Hide = false;
                                }
                            }
                            //查看应收
                            this.OrderManagement_Pool_Summary_BillingInformation_ViewAccountsReceivable = false;
                            //查看应付
                            this.OrderManagement_Pool_Summary_BillingInformation_ViewAccountsPayable = false;
                            //查看结算
                            this.OrderManagement_Pool_Summary_BillingInformation_ViewSettlement = false;

                            if (r?.contains("OrderManagement_Pool_Summary_BillingInformation_ViewAccountsReceivable")) {
                                this.OrderManagement_Pool_Summary_BillingInformation_ViewAccountsReceivable = true;
                            }
                            if (r?.contains("OrderManagement_Pool_Summary_BillingInformation_ViewAccountsPayable")) {
                                this.OrderManagement_Pool_Summary_BillingInformation_ViewAccountsPayable = true;
                            }
                            if (r?.contains("OrderManagement_Pool_Summary_BillingInformation_ViewSettlement")) {
                                this.OrderManagement_Pool_Summary_BillingInformation_ViewSettlement = true;
                            }
                            this.handleGet();
                        });
                    }
                    else if (matched.path.toLowerCase() == "/order/bill/:id") {
                        //查看应收
                        this.OrderManagement_Pool_Summary_BillingInformation_ViewAccountsReceivable = false;
                        //查看应付
                        this.OrderManagement_Pool_Summary_BillingInformation_ViewAccountsPayable = false;
                        //查看结算
                        this.OrderManagement_Pool_Summary_BillingInformation_ViewSettlement = false;
                        this.orderId = this.$route.params.id as string;
                        if (this.historys.Dto) {
                            this.historys.Dto.PoolId = undefined;
                            this.historys.Dto.OrderId = this.orderId;
                        }
                        this.type = 1;
                        this.menuTab.Back = true;
                        this.menuTab.Url = `/order/bill/${this.orderId}`;
                        this.menuTab.Menus = [
                            {
                                Label: "账单信息",
                                Url: `/order/bill/${this.orderId}`,
                                Power: "OrderManagement_Order_Summary_BillingInformation",
                            },
                        ];
                        CurrentLogier.GetPowerCodes().then((r) => {
                            if (r?.contains("OrderManagement_Order_Summary_BillingInformation_EditAR")) {
                                if (this.bottomButtons) {
                                    this.bottomButtons[1].Hide = false;
                                }
                            }
                            if (r?.contains("OrderManagement_Order_Summary_BillingInformation_EditAP")) {
                                if (this.bottomButtons) {
                                    this.bottomButtons[2].Hide = false;
                                }
                            }
                            if (r?.contains("OrderManagement_Order_Summary_BillingInformation_EditSettlementPrice")) {
                                if (this.bottomButtons) {
                                    this.bottomButtons[3].Hide = false;
                                }
                            }
                            if (r?.contains("OrderManagement_Order_Summary_BillingInformation_AllocateAccountsPayable")) {
                                if (this.bottomButtons) {
                                    this.bottomButtons[4].Hide = false;
                                }
                            }
                            if (r?.contains("OrderManagement_Order_Summary_BillingInformation_UnlockBilling")) {
                                if (this.bottomButtons) {
                                    this.bottomButtons[5].Hide = false;
                                }
                            }

                            if (r?.contains("OrderManagement_Order_Summary_BillingInformation_ViewAccountsReceivable")) {
                                this.OrderManagement_Order_Summary_BillingInformation_ViewAccountsReceivable = true;
                            }
                            if (r?.contains("OrderManagement_Order_Summary_BillingInformation_ViewAccountsPayable")) {
                                this.OrderManagement_Order_Summary_BillingInformation_ViewAccountsPayable = true;
                            }
                            if (r?.contains("OrderManagement_Order_Summary_BillingInformation_ViewSettlementPrice")) {
                                this.OrderManagement_Order_Summary_BillingInformation_ViewSettlementPrice = true;
                            }
                            this.handleGet();
                        });
                    }
                }
            }


            //if (this.historys.Dto) {
            //    this.historys.Dto.CurrentPage = 1;
            //    this.historys.Dto.PageSize = this.historyCurrentPage * this.historyPageSize;
            //}
            //BillHistoryRecordAppService.Get(this.historys.Dto).then((r) => {
            //    this.historys.Data = r.data.Data;
            //    this.historyCurrentPage++;
            //    this.historyPageSize = 10;
            //});


        }
        onClickHistory(): void {
            if (this.isHistory == true) {
                if (this.historys && this.historys.Data && (this.historys.Data.Items == undefined || this.historys.Data.Items == null ||  this.historys.Data.Items && this.historys.Data.Items?.length <= 0)) {
                    this.historys.Data.Items = [];
                    if (this.historys.Dto) {
                        this.historys.Dto.CurrentPage = 1;
                        this.historys.Dto.PageSize = this.historyPageSize;
                    }
                    this.isLoading = true;
                    BillHistoryRecordAppService.Get(this.historys.Dto).then((r) => {
                        this.historys.Data = r.data.Data;
                        this.historyCurrentPage++;
                        this.historyPageSize = 5;
                        this.isLoading = false;
                        this.isHistory = false;
                    });

                }
                else {
                    this.isHistory = false;
                }
            }
            else {
                this.isHistory = true;
            }
           
        }
        /**创建时 */
        created(): void {


            this.onInit();
        }
        onMoreHistyory(): void {
            if (this.historys.Dto) {
                this.historys.Dto.CurrentPage = this.historyCurrentPage;
                this.historys.Dto.PageSize = this.historyPageSize;
            }
            this.isLoading = true;
            BillHistoryRecordAppService.Get(this.historys.Dto).then((r) => {
                this.isLoading = false;
                //this.historys.Data = r.data.Data;
                if (this.historys && this.historys.Data) {
                    this.historys.Data.TotalCount = r.data.Data?.TotalCount;
                    this.historys.Data.Total = r.data.Data?.Total;
                    if (this.historys.Data.Items && r.data.Data && r.data.Data.Items) {
                        for (var it of r.data.Data.Items) {
                            this.historys.Data.Items.push(it);
                        }
                    }
                }
                this.historyCurrentPage++;
            });

        }
        onOrder(): void {
            DynamicSearchAppService.Post({
                Code: "FinancialManagement_BillSummary",
                Brackets: [
                    {
                        Relation: EnumRelation.And,
                        Items: [
                            {
                                Relation: EnumRelation.And,
                                Where: {
                                    Prop: "DynamicSearch_Order",
                                    Compare: EnumCompare.Equal,
                                    Value: this.dto.Code,
                                },
                            },
                        ],
                    },
                ],
            }).then((r) => {
                window.open(
                    `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                    "_blank"
                );
                //window.location.href = `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`;
            });
        }
        onChild(row: any): void {
            row.IsOpen = !row.IsOpen;
            (this.$refs["paymentBillTable"] as any).toggleRowExpansion(row);
        }
        isString(str: string, row: any | undefined): string {
            if (row != undefined && row != null) {
                if (row.FromId == undefined || row.FromId == null) {
                    if (str == null || str == undefined || str == "") {
                        return "暂无数据";
                    }
                    else {
                        return str;
                    }
                }
                else {
                    if (str == null || str == undefined || str == "") {
                        return "—";
                    }
                    else {
                        return str;
                    }
                }
            }
            else {
                if (str == null || str == undefined || str == "") {
                    return "暂无数据";
                }
                else {
                    return str;
                }
            }
        }

        /**查询列表 */
        handleGet(): void {

            setTimeout(function () {
                var message = document.getElementsByClassName("testMessage") as any;
                if (message.length > 0 && message[0].style) {
                    message[0].style = "display:none;";
                }
            }, 200)

            var param = {
                OrderId: "",
                PoolId: "",
            };
            if (this.type == 0 && this.id != undefined) {
                param.PoolId = this.id as string;
            }
            else if (this.type == 1 && this.orderId != undefined) {
                param.OrderId = this.orderId as string;
            }
            this.isLoading = true;
            BillAppService.GetBill(param).then((r) => {
                if (r.data.Data != undefined) {
                    this.dto = JSON.parse(JSON.stringify(r.data.Data));
                    this.init = JSON.parse(JSON.stringify(r.data.Data));
                    //if (this.dto && this.dto.Items && this.init && this.init.Items) {
                    //    for (var i = 0; i < this.dto.Items.length; i++) {
                    //        this.dto.Items[i].Child = undefined;
                    //    }
                    //}
                }

                var task = this.$route.query.task;
                if (task == null || task == undefined || task == "") {
                    task = this.$route.query.Task;
                }
                if (this.bottomButtons) {
                    if (task == "AddCollectionBill" && this.dto.IsTaskCollection == true) {
                        this.bottomButtons[1].Breathe = true;
                    }
                    if (task == "AddPaymentBill" && this.dto.IsTaskPayment == true) {
                        this.bottomButtons[2].Breathe = true;
                    }
                    if (task == "ConfirmSettlementPrice" && this.dto.IsTaskSaler == true) {
                        this.bottomButtons[3].Breathe = true;
                    }
                }

                if (this.dto && this.dto.IsSaler == false && this.bottomButtons) {
                    this.bottomButtons[3].Hide = true;
                }

                if (this.dto) {
                    if (this.orderId != null && this.orderId != undefined && this.orderId != '') {
                        if (this.dto.IsCollectionOrder != true && this.dto.IsPaymentOrder != true && this.dto.IsSalerOrder != true) {
                            if (this.bottomButtons && this.bottomButtons[5].Hide == false) {
                                this.bottomButtons[5].Hide = true;
                            }
                        }


                        if (this.bottomButtons && this.bottomButtons[5].Hide == false) {
                            if (this.dto.IsCollectionOrder != true) {
                                var btn11 = this.bottomButtons[5];
                                if (btn11 && btn11.Options) {
                                    var option11 = btn11.Options[0];
                                    if (option11) {
                                        option11.Hide = true;
                                    }
                                }
                            }
                        }


                        if (this.bottomButtons && this.bottomButtons[5].Hide == false) {
                            var btn22 = this.bottomButtons[5];
                            if (btn22 && btn22.Options) {
                                var option22 = btn22.Options[1];
                                if (option22) {
                                    option22.Hide = false;
                                    if (this.dto.IsPaymentOrder != true) {
                                        option22.Hide = true;
                                    }
                                }
                            }
                        }


                        if (this.bottomButtons && this.bottomButtons[5].Hide == false) {
                            if (this.dto.IsSalerOrder != true) {
                                var btn33 = this.bottomButtons[5];
                                if (btn33 && btn33.Options) {
                                    var option33 = btn33.Options[2];
                                    if (option33) {
                                        option33.Hide = true;
                                    }
                                }
                            }
                        }

                        this.isSalerExport = true;
                        if (this.dto.IsSaler != true && this.bottomButtons) {
                            this.isSalerExport = false;
                            this.OrderManagement_Pool_Summary_BillingInformation_ViewSettlement = false;
                            this.OrderManagement_Order_Summary_BillingInformation_ViewSettlementPrice = false;
                            var btn = this.bottomButtons[5];
                            if (btn && btn.Options) {
                                var option = btn.Options[2];
                                if (option) {
                                    option.Hide = true;
                                }
                            }

                            var btnSaler = this.bottomButtons[6];
                            if (btnSaler && btnSaler.Options) {
                                var optionSaler = btnSaler.Options[2];
                                if (optionSaler) {
                                    optionSaler.Hide = true;
                                }
                            }
                        }

                    }
                    else {
                        if (this.dto.IsCollectionOrder != true && this.dto.IsPaymentOrder != true && this.dto.IsSalerOrder != true
                            && this.dto.IsCollectionPool != true && this.dto.IsPaymentPool != true && this.dto.IsSalerPool != true
                        ) {
                            if (this.bottomButtons && this.bottomButtons[5].Hide == false) {
                                this.bottomButtons[5].Hide = true;
                            }
                        }


                        this.isCollectionExport = true;
                        if (this.bottomButtons && this.bottomButtons[5].Hide == false) {
                           
                            if (this.dto.IsCollectionOrder == true) {
                                var btn1 = this.bottomButtons[5];
                                if (btn1 && btn1.Options) {
                                    var option1 = btn1.Options[0];
                                    if (option1) {
                                        option1.Hide = false;
                                    }
                                }
                            }
                            else if (this.dto.IsCollectionPool != true) {
                                var btn10 = this.bottomButtons[5];
                                if (btn10 && btn10.Options) {
                                    var option10 = btn10.Options[0];
                                    if (option10) {
                                        option10.Hide = true;
                                    }
                                }
                            }
                        }

                        this.isPaymentExport = true;
                        if (this.bottomButtons && this.bottomButtons[5].Hide == false) {

                            if (this.dto.IsPaymentOrder == true) {
                                var btn2 = this.bottomButtons[5];
                                if (btn2 && btn2.Options) {
                                    var option2 = btn2.Options[1];
                                    if (option2) {
                                        option2.Hide = false;
                                    }
                                }
                            }
                            else if (this.dto.IsPaymentPool != true) {
                                var btn20 = this.bottomButtons[5];
                                if (btn20 && btn20.Options) {
                                    var option20 = btn20.Options[1];
                                    if (option20) {
                                        option20.Hide = true;
                                    }
                                }
                            }


                            //alert(this.dto.IsPaymentOrder);
                            //var btn20 = this.bottomButtons[5];
                            //if (btn20 && btn20.Options) {
                            //    var option20 = btn20.Options[1];
                            //    if (option20) {
                            //        option20.Hide = false;
                            //        if (this.dto.IsPaymentOrder == true) {
                            //            option20.Hide = false;
                            //        }
                            //        else {
                            //            option20.Hide = true;
                            //        }
                            //    }
                            //}
                        }

                        if (this.bottomButtons && this.bottomButtons[5].Hide == false) {
                            if (this.dto.IsSalerOrder == true) {
                                var btn3 = this.bottomButtons[5];
                                if (btn3 && btn3.Options) {
                                    var option3 = btn3.Options[2];
                                    if (option3) {
                                        option3.Hide = false;
                                    }
                                }
                            }
                            else if (this.dto.IsSalerPool != true) {
                                var btn30 = this.bottomButtons[5];
                                if (btn30 && btn30.Options) {
                                    var option30 = btn30.Options[2];
                                    if (option30) {
                                        option30.Hide = true;
                                    }
                                }
                            }
                        }
                        if (this.bottomButtons && this.bottomButtons[5].Hide == false) {
                            var btn40 = this.bottomButtons[5];
                            if (btn40 && btn40.Options) {
                                if (btn40.Options[0].Hide == true && btn40.Options[1].Hide == true && btn40.Options[2].Hide == true) {
                                    btn40.Options[3].Hide = true;
                                    btn40.Hide = true;
                                }
                            }
                        }


                        this.isSalerExport = true;
                        if (this.dto.IsSaler != true && this.bottomButtons) {
                            this.isSalerExport = false;
                            this.OrderManagement_Pool_Summary_BillingInformation_ViewSettlement = false;
                            this.OrderManagement_Order_Summary_BillingInformation_ViewSettlementPrice = false;
                            var btn00 = this.bottomButtons[5];
                            if (btn00 && btn00.Options) {
                                var option00 = btn00.Options[2];
                                if (option00) {
                                    option00.Hide = true;

                                }
                            }

                            var btnSaler01 = this.bottomButtons[6];
                            if (btnSaler01 && btnSaler01.Options) {
                                var optionSaler01 = btnSaler01.Options[2];
                                if (optionSaler01) {
                                    optionSaler01.Hide = true;
                                }
                            }
                        }


                    }

                    if (this.bottomButtons) {
                        var bottomButtons6 = this.bottomButtons[6];
                        if (this.isPaymentExport == true) {
                            if (bottomButtons6 && bottomButtons6.Options) {
                                var optionPayment = bottomButtons6.Options[1];
                                if (optionPayment && this.OrderManagement_Pool_Summary_BillingInformation_ViewAccountsPayable == false && this.OrderManagement_Order_Summary_BillingInformation_ViewAccountsPayable == false) {
                                    optionPayment.Hide = true;
                                    this.isPaymentExport = false;
                                }
                            }
                        }


                        if (this.isCollectionExport == true) {
                            if (bottomButtons6 && bottomButtons6.Options) {
                                var optionCollection = bottomButtons6.Options[0];
                                if (optionCollection && this.OrderManagement_Pool_Summary_BillingInformation_ViewAccountsReceivable == false && this.OrderManagement_Order_Summary_BillingInformation_ViewAccountsReceivable == false) {
                                    optionCollection.Hide = true;
                                    this.isCollectionExport = false;
                                }
                            }
                        }

                        if (this.isSalerExport == true) {
                            if (bottomButtons6 && bottomButtons6.Options) {
                                var optionSaler1 = bottomButtons6.Options[2];
                                if (optionSaler1 && this.OrderManagement_Pool_Summary_BillingInformation_ViewSettlement == false && this.OrderManagement_Order_Summary_BillingInformation_ViewSettlementPrice == false) {
                                    optionSaler1.Hide = true;
                                    this.isSalerExport = false;
                                }
                            }
                        }
                    }



                    //if (this.bottomButtons) {
                    //    if (this.dto.IsPaymentSave == true) {
                    //        var btn4 = this.bottomButtons[4];
                    //        btn4.Hide = true;
                    //    }
                    //}
                }

                //alert(this.dto.IsPaymentPool);
                //alert(this.bottomButtons[5].Options[1].Hide);


                var count = 1;
                var preCount = 1;
                var billId = null;
                if (this.dto && this.dto.Collections) {
                    for (var collection of this.dto.Collections) {
                        collection.StartDateString = "";
                        if (collection.StartDate != undefined) {
                            collection.StartDateString = moment(collection.StartDate).format("YYYY/MM/DD");
                        }

                        collection.colorType = 0;
                        if (collection.PoolId != billId) {
                            collection.colorType = count;
                            billId = collection.PoolId;
                            preCount = count;
                            count++;
                        }
                        else {
                            collection.colorType = preCount;
                        }
                    }
                }

                count = 1;
                preCount = 1;
                billId = null;
                if (this.dto && this.dto.Payments) {
                    for (var payment of this.dto.Payments) {
                        payment.StartDateString = "";
                        payment.IsOpen = false;
                        if (payment.StartDate != undefined) {
                            payment.StartDateString = moment(payment.StartDate).format("YYYY/MM/DD");
                        }

                        payment.colorType = 0;
                        if (payment.PoolId != billId || (billId == null && count == 1)) {
                            payment.colorType = count;
                            billId = payment.PoolId;
                            preCount = count;
                            count++;
                        }
                        else {
                            payment.colorType = preCount;
                        }
                    }
                }

                count = 1;
                preCount = 1;
                billId = null;
                if (this.dto && this.dto.Salers) {
                    for (var saler of this.dto.Salers) {
                        saler.StartDateString = "";
                        if (saler.StartDate != undefined) {
                            saler.StartDateString = moment(saler.StartDate).format("YYYY/MM/DD");
                        }

                        saler.colorType = 0;
                        if (saler.PoolId != billId) {
                            saler.colorType = count;
                            billId = saler.PoolId;
                            preCount = count;
                            count++;
                        }
                        else {
                            saler.colorType = preCount;
                        }
                    }
                }
                this.isLoading = false;
            });
        }
        handleBack(): void {
            //var url = this.$route.query.backUrl as string;
            //if (url != null && typeof (url) != "undefined" && url != "undefined" && url != "") {
            //    this.$router.push(url);
            //}
            //else {
            //    history.go(-1);
            //}
            var url = this.$route.query.backUrl as string;
            if (url != null && typeof (url) != "undefined" && url != "undefined" && url != "") {
                if (Object.prototype.toString.call(url) == '[object Array]') {
                    if (url.length > 0) {
                        var newUrl = url[0];
                        var index = 0;
                        for (var u of url) {
                            if (index > 0) {
                                if (newUrl.indexOf("?") >= 0) {
                                    newUrl += "&backUrl=" + u;
                                }
                                else {
                                    newUrl += "?backUrl=" + u;
                                }
                            }
                            index++;
                        }
                        this.$router.push(newUrl);
                    }

                }
                else {
                    this.$router.push(url);
                }
            }
            else {
                history.go(-1);
            }
        }
        tableRowClassName(row: any): string {
            var result = "";
            if (row.row.colorType % 2 == 0) {
                result = "chooseColor";
            }
            else {
                result = "cursor";
            }
            return result;
        }
        formatTime(v?: Date): string {
            return v ? moment(v).format("YYYY/MM/DD HH:mm") : "暂无数据";
        }
        onDepartureChargeWeight(): void {
            if (this.id != null && this.id != undefined && this.id != "") {
                window.open(
                    `#/pool/departure/wharf/` + this.id,
                    "_blank"
                );
            }
            else if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                window.open(
                    `#/order/departure/wharf/` + this.orderId,
                    "_blank"
                );
            }

        }
        onDestinationChargeWeight(): void {
            if (this.id != null && this.id != undefined && this.id != "") {
                window.open(
                    `#/pool/destination/wharf/` + this.id,
                    "_blank"
                );
            }
            else if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                window.open(
                    `#/order/destination/wharf/` + this.orderId,
                    "_blank"
                );
            }
        }
        handleClick(btn: MiezzButton, complete: () => void): void {
            if (this.dto && this.dto.IsLock == true && btn.Code != "export") {
                ElMessage.warning("订单已锁定");
                complete();
                return;
            }
            switch (btn.Code) {
                case "collection":
                    if (this.type == 0) {
                        //this.lockParam.BillId = [];
                        //this.lockParam.OrderId = [];
                        //this.lockParam.PoolId = [];
                        //this.lockParam.PoolId.push(this.id as string);
                        //BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        //    if (lock.data.Data == true) {
                        //        ElMessage.warning("订单已锁定");
                        //        complete();
                        //        return;
                        //    }
                        //    else {
                        //        this.$router.push(
                        //            `/pool/bill/collection/${this.id}` +
                        //            "?backUrl=" +
                        //            this.$route.fullPath
                        //        );
                        //    }
                        //})
                        //this.$router.push(
                        //    `/pool/bill/collection/${this.id}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);

                        this.$router.push({
                            path: `/pool/bill/collection/${this.id}`,
                            query: { backUrl: this.$route.fullPath },
                        });
                    }
                    else if (this.type == 1) {
                        //this.lockParam.BillId = [];
                        //this.lockParam.OrderId = [];
                        //this.lockParam.PoolId = [];
                        //this.lockParam.OrderId.push(this.orderId as string);
                        //BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        //    if (lock.data.Data == true) {
                        //        ElMessage.warning("订单已锁定");
                        //        complete();
                        //        return;
                        //    }
                        //    else {
                        //        this.$router.push(
                        //            `/order/bill/collection/${this.orderId}` +
                        //            "?backUrl=" +
                        //            this.$route.fullPath
                        //        );
                        //    }
                        //})
                        //this.$router.push(
                        //    `/order/bill/collection/${this.orderId}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);

                        this.$router.push({
                            path: `/order/bill/collection/${this.orderId}`,
                            query: { backUrl: this.$route.fullPath },
                        });
                    }
                    complete();
                    break;
                case "payment":
                    if (this.type == 0) {
                        //this.lockParam.BillId = [];
                        //this.lockParam.OrderId = [];
                        //this.lockParam.PoolId = [];
                        //this.lockParam.PoolId.push(this.id as string);
                        //BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        //    if (lock.data.Data == true) {
                        //        ElMessage.warning("订单已锁定");
                        //        complete();
                        //        return;
                        //    }
                        //    else {

                        //        this.$router.push(
                        //            `/pool/bill/payment/${this.id}` +
                        //            "?backUrl=" +
                        //            this.$route.fullPath
                        //        );
                        //    }
                        //})
                        //this.$router.push(
                        //    `/pool/bill/payment/${this.id}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);

                        this.$router.push({
                            path: `/pool/bill/payment/${this.id}`,
                            query: { backUrl: this.$route.fullPath },
                        });
                    }
                    else if (this.type == 1) {

                        //this.lockParam.BillId = [];
                        //this.lockParam.OrderId = [];
                        //this.lockParam.PoolId = [];
                        //this.lockParam.OrderId.push(this.orderId as string);
                        //BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        //    if (lock.data.Data == true) {
                        //        ElMessage.warning("订单已锁定");
                        //        complete();
                        //        return;
                        //    }
                        //    else {

                        //        this.$router.push(
                        //            `/order/bill/payment/${this.orderId}` +
                        //            "?backUrl=" +
                        //            this.$route.fullPath
                        //        );
                        //    }
                        //})
                        //this.$router.push(
                        //    `/order/bill/payment/${this.orderId}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);

                        this.$router.push({
                            path: `/order/bill/payment/${this.orderId}`,
                            query: { backUrl: this.$route.fullPath },
                        });
                    }
                    complete();
                    break;
                case "saler":
                    if (this.type == 0) {
                        //this.lockParam.BillId = [];
                        //this.lockParam.OrderId = [];
                        //this.lockParam.PoolId = [];
                        //this.lockParam.PoolId.push(this.id as string);
                        //BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        //    if (lock.data.Data == true) {
                        //        ElMessage.warning("订单已锁定");
                        //        complete();
                        //        return;
                        //    }
                        //    else {
                        //        this.$router.push(
                        //            `/pool/bill/saler/${this.id}` +
                        //            "?backUrl=" +
                        //            this.$route.fullPath
                        //        );
                        //    }
                        //})
                        //this.$router.push(
                        //    `/pool/bill/saler/${this.id}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);

                        this.$router.push({
                            path: `/pool/bill/saler/${this.id}`,
                            query: { backUrl: this.$route.fullPath },
                        });
                    }
                    else if (this.type == 1) {

                        //this.lockParam.BillId = [];
                        //this.lockParam.OrderId = [];
                        //this.lockParam.PoolId = [];
                        //this.lockParam.OrderId.push(this.orderId as string);
                        //BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        //    if (lock.data.Data == true) {
                        //        ElMessage.warning("订单已锁定");
                        //        complete();
                        //        return;
                        //    }
                        //    else {
                        //        this.$router.push(
                        //            `/order/bill/saler/${this.orderId}` +
                        //            "?backUrl=" +
                        //            this.$route.fullPath
                        //        );
                        //    }
                        //})
                        //this.$router.push(
                        //    `/order/bill/saler/${this.orderId}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);

                        this.$router.push({
                            path: `/order/bill/saler/${this.orderId}`,
                            query: { backUrl: this.$route.fullPath },
                        });
                    }
                    complete();
                    break;
                case "share":
                    if (this.type == 0) {
                        //this.lockParam.BillId = [];
                        //this.lockParam.OrderId = [];
                        //this.lockParam.PoolId = [];
                        //this.lockParam.PoolId.push(this.id as string);
                        //BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        //    if (lock.data.Data == true) {
                        //        ElMessage.warning("订单已锁定");
                        //        complete();
                        //        return;
                        //    }
                        //    else {
                        //        this.$router.push(
                        //            `/pool/bill/share/${this.id}` +
                        //            "?backUrl=" +
                        //            this.$route.fullPath
                        //        );
                        //    }
                        //})
                        //this.$router.push(
                        //    `/pool/bill/share/${this.id}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);

                        this.$router.push({
                            path: `/pool/bill/share/${this.id}`,
                            query: { backUrl: this.$route.fullPath },
                        });

                    }
                    else if (this.type == 1) {
                        //this.lockParam.BillId = [];
                        //this.lockParam.OrderId = [];
                        //this.lockParam.PoolId = [];
                        //this.lockParam.OrderId.push(this.orderId as string);
                        //BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        //    if (lock.data.Data == true) {
                        //        ElMessage.warning("订单已锁定");
                        //        complete();
                        //        return;
                        //    }
                        //    else {
                        //        this.$router.push(
                        //            `/order/bill/share/${this.orderId}` +
                        //            "?backUrl=" +
                        //            this.$route.fullPath
                        //        );
                        //    }
                        //})
                        //this.$router.push(
                        //    `/order/bill/share/${this.orderId}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);

                        this.$router.push({
                            path: `/order/bill/share/${this.orderId}`,
                            query: { backUrl: this.$route.fullPath },
                        });
                    }
                    complete();
                    break;
                case "back":
                    this.handleBack();
                    complete();
                    break;
                case "unlock":
                    var param = new DtoRequestUnlock();
                    param.OrderId = this.orderId;
                    param.PoolId = this.id;
                    if (param.PoolId == "") { param.PoolId == null; }
                    if (param.OrderId == "") { param.OrderId == null; }
                    if (btn.Command == "10") {
                        param.Type = EnumBillType.Collection;
                    }
                    else if (btn.Command == "20") {
                        param.Type = EnumBillType.Payment;
                    }
                    else if (btn.Command == "30") {
                        param.Type = EnumBillType.Saler;
                    }
                    else if (btn.Command == "40") {
                        param.Type = undefined;
                    }
                    if (this.orderId == null || this.orderId == undefined || this.orderId == "") {
                        if (btn.Command == "10" && this.dto.IsCollectionOrder != true
                            || btn.Command == "20" && this.dto.IsPaymentOrder != true
                            || btn.Command == "30" && this.dto.IsSalerOrder != true
                            || btn.Command == "40" && (this.dto.IsCollectionOrder != true && this.dto.IsPaymentOrder != true && this.dto.IsSalerOrder != true)
                        ) {
                            ElMessageBox.confirm("您是否确定要解锁当前账单？", "提示", {
                                confirmButtonText: "确认",
                                cancelButtonText: "取消",
                                type: "warning",
                            })
                                .then(() => {

                                    this.isLoading = true;
                                    BillAppService.Unlock(param).then((r) => {
                                        this.isLoading = false;
                                        if (r.data.Data == true) {
                                            ElMessage.success("账单信息已解锁");

                                            if (this.historys.Dto) {
                                                this.historys.Dto.CurrentPage = 1;
                                                this.historys.Dto.PageSize = 5;
                                            }
                                            BillHistoryRecordAppService.Get(this.historys.Dto).then((r) => {
                                                this.historys.Data = r.data.Data;
                                            });

                                            this.handleGet();
                                        }
                                        complete();
                                    }).catch(complete);
                                })
                                .catch(() => {
                                    complete();
                                });
                        }
                        else {
                            ElMessage.warning({
                                customClass: "testMessage",
                                dangerouslyUseHTMLString: true,
                                message: '委托书关联的订单的账单信息已锁定，如需修改，请先至<a href="/#/order/bill/' + this.dto.OrderId + '?backUrl=' + this.$route.fullPath + '" style="font-weight:900;" >订单</a>中进行解锁。',
                            })
                        }
                    }
                    else {

                        ElMessageBox.confirm("您是否确定要解锁当前账单？", "提示", {
                            confirmButtonText: "确认",
                            cancelButtonText: "取消",
                            type: "warning",
                        })
                            .then(() => {

                                this.isLoading = true;
                                BillAppService.Unlock(param).then((r) => {
                                    this.isLoading = false;
                                    if (r.data.Data == true) {
                                        ElMessage.success("账单信息已解锁");

                                        if (this.historys.Dto) {
                                            this.historys.Dto.CurrentPage = 1;
                                            this.historys.Dto.PageSize = 5;
                                        }
                                        BillHistoryRecordAppService.Get(this.historys.Dto).then((r) => {
                                            this.historys.Data = r.data.Data;
                                        });

                                        this.handleGet();
                                    }
                                    complete();
                                }).catch(() => {
                                        this.isLoading = false;
                                        complete();
                                    });
                            })
                            .catch(() => {
                                complete();
                            });
                    }





                    break;
                case "export":
                    var param1 = new DtoRequestBill();

                    if (this.type == 0 && this.id != undefined) {
                        param1.PoolId = this.id;
                    }
                    else if (this.type == 1 && this.orderId != undefined) {
                        param1.OrderId = this.orderId;
                    }
                    if (btn.Command == "40") {
                        param1.ExportType = undefined;
                    }
                    else {
                        param1.ExportType = Number(btn.Command);
                    }
                    param1.IsCollection = this.isCollectionExport;
                    param1.IsSaler = this.isSalerExport;
                    param1.IsPayment = this.isPaymentExport;

                    this.isLoading = true;
                    BillAppService.Export(param1).then((r) => {
                        this.isLoading = false;
                        window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
                        complete();
                    })
                    break;
                default:
                    break;
            }
        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    /*isLessZero：是否允许输入小于0 */
    var setDecimal = function (val: string, num: number, isZero: boolean, isLessZero: boolean) {

        var str = val;
        if (val == null || val == undefined || val == '') {
            return '';
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, '$1$2.$3'); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
            if (isLessZero == true) {
                if (str[0] == "-") {
                    val = "-" + val;
                }
            }
        }

        if (val == "" || val == undefined || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        }
        else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        }
        else {
            return result;
        }
    };


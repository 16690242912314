
import { Vue, Options } from "vue-class-component";
import IndexMenu from "./IndexMenu.vue";
import IndexCompany from "./IndexCompany.vue";
import IndexUser from "./IndexUser.vue";
import IndexBreadcrumb from "./IndexBreadcrumb.vue";
import IndexHeaderChil from "./IndexHeaderChil.vue";
import CurrentLogier from "@/apis/CurrentLogier";
import MiezzMenuItem from "@/models/MiezzMenuItem";
import MenuRedDotAppService from "@/apis.machine/MenuRedDotAppService";
import FreighTowerAppService from "@/apis.machine/FreighTowerAppService";
import NoticeAppService from "@/apis.machine/NoticeAppService";
import { ElMessage, ElMessageBox } from "element-plus";
import moment from "moment";
@Options({
  components: {
    IndexCompany,
    IndexMenu,
    IndexUser,
    IndexBreadcrumb,
    IndexHeaderChil,
  },
})
export default class Index extends Vue {
  random = 0;
  menus: MiezzMenuItem[] = [];
  redCodes?: Array<string> = [];

  created(): void {
    CurrentLogier.SetHubConnection();
    CurrentLogier.HubConnection?.on("RefreshMenuRedDot", () => {
      MenuRedDotAppService.Get().then((r) => {
        this.redCodes = r.data.Data;
        console.log("小红点刷新", this.redCodes);
      });
    });
    MenuRedDotAppService.Get().then((r) => {
      this.redCodes = r.data.Data;
    });
    this.loopNotice();
  }

  /**
   * 计时器
   */
  timer = 0;
  /**
   * 循环通知
   */
  loopNotice(): void {
    console.log("正在查询是否有未下载文件");
    //重置计时器
    this.timer = 0;
    //获取未下载文件
    NoticeAppService.GetUnDownloadFile()
      .then((r) => {
        //若有
        if (r?.data?.Data?.Id) {
          console.log("已查到未下载文件", r.data.Data.Id);
          //已读文件
          NoticeAppService.ReadById(r.data.Data.Id)
            .then(() => {
              console.log("已读");
              //清空下载任务
              localStorage.setItem("download", "0");
              ElMessageBox.confirm("导出已完成", "提示", {
                confirmButtonText: "下载",
              }).then(() => {
                //下载
                window.open(
                  process.env.VUE_APP_BASE_URL + r.data.Data?.Message,
                  "_blank"
                );
              });
              console.log("已完成下载");
              //循环计时器
              this.loopTimer();
            })
            .catch(this.loopTimer);
        } else {
          //若无 继续循环计时器
          this.loopTimer();
        }
      })
      .catch((r) => {
        console.log("查询失败");
        this.loopTimer();
      });
  }

  /**
   * 每秒循环计时器
   */
  loopTimer(): void {
    setTimeout(() => {
      const download = localStorage.getItem("download") == "1";
      //当过去5秒 或者 检测到有下载任务 时
      if (this.timer == 4 || download) {
        //循环通知
        this.loopNotice();
      } else {
        //计时器计数
        this.timer++;
        //循环计时器
        this.loopTimer();
      }
    }, 1000);
  }

  HandleMenu(params: MiezzMenuItem[]): void {
    this.menus = params;

    var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
    this.random += random;
  }
}

import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";
import { EnumBookingStatus, EnumBookingStatusOption } from "./EnumBookingStatus";
import { EnumCustomsStatus, EnumCustomsStatusOption } from "./EnumCustomsStatus";
import { EnumContainerTrailerStatus, EnumContainerTrailerStatusOption } from "./EnumContainerTrailerStatus";
import { EnumExpressStatus, EnumExpressStatusOption } from "./EnumExpressStatus";
import { EnumTrunkStatus, EnumTrunkStatusOption } from "./EnumTrunkStatus";
import { EnumIncomingGoodsStatus, EnumIncomingGoodsStatusOption } from "./EnumIncomingGoodsStatus";

/**
 * 委托书分页列表-搜索条件分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchOrderPool extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 销售订单/采购订单主键
     */
    OrderWarehouseId?: string;
    /**
     * 
     */
    BookingStatus?: EnumBookingStatus;
    /**
     * 
     */
    CustomsStatus?: EnumCustomsStatus;
    /**
     * 
     */
    ContainerTrailerStatus?: EnumContainerTrailerStatus;
    /**
     * 
     */
    ExpressStatus?: EnumExpressStatus;
    /**
     * 
     */
    TrunkStatus?: EnumTrunkStatus;
    /**
     * 
     */
    IncomingGoodsStatus?: EnumIncomingGoodsStatus;
    /**
     * 是否用于统计
     */
    IsStat?: boolean;
}

/**
 * 委托书分页列表-搜索条件分页列表-搜索条件-表单校验
 */
export const DtoPageListSearchOrderPoolRules = {
}

/**
 * 委托书分页列表-搜索条件分页列表-搜索条件-动态搜索配置项
 */
export const DtoPageListSearchOrderPoolDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
  {
    Prop: "DynamicSearch_BookingNo",
    Label: "Booking No",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_Code",
    Label: "编号",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_OrderCode",
    Label: "订单编号",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_MasterBillNo",
    Label: "提单号码",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_CustomerSupplierName",
    Label: "客户/供应商名称",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_Color",
    Label: "标记颜色",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_CompanySource",
    Label: "客户来源",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_LoadingPortId",
    Label: "装货港",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_DeliveryPortId",
    Label: "目的港",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_DeliveryPlaceLabel",
    Label: "最终目的地",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_ShipName",
    Label: "船名",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_VoyageNumber",
    Label: "航次",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_Flight",
    Label: "航班",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_RailwayNumber",
    Label: "班次",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_CarrierId",
    Label: "承运人",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_ContainerType",
    Label: "箱型",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_ContainerNo",
    Label: "箱号",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_BoxStickerCode",
    Label: "箱贴号码",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_BusinessCycle",
    Label: "业务周期",
    Type: EnumUiDynamicSearchItemType.MonthRange,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.Between,
      EnumCompare.NoBetween,
    ]
  },
  {
    Prop: "DynamicSearch_EstimatedDepartureDate",
    Label: "ETD",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_ActualDepartureDate",
    Label: "ATD",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_EstimatedArrivalDate",
    Label: "ETA",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_ActualArrivalDate",
    Label: "ATA",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_CreatedTime",
    Label: "创建时间",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_CustomsEndDate",
    Label: "截关时间",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_ClientUserId",
    Label: "委托人",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_CustomerServiceId",
    Label: "客服",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_TaskUserId",
    Label: "待办执行人",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_SalesmanId",
    Label: "业务员",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_SupplierId",
    Label: "供应商",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_TransportDirection",
    Label: "进出口类型",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_TransportType",
    Label: "运输方式",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_TransportContainerLoad",
    Label: "整柜/拼柜/散货",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_ServiceRemark",
    Label: "服务内容",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_Status",
    Label: "委托书状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_Lock",
    Label: "锁定状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_BookingStatus",
    Label: "舱务状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_DeparturePortCustomsStatus",
    Label: "装货港关务状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_DestinationPortCustomsStatus",
    Label: "目的港关务状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_DeparturePortTrunkStatus",
    Label: "装货港干线物流状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_DestinationPortTrunkStatus",
    Label: "目的港干线物流状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_DeparturePortExpressStatus",
    Label: "装货港快递状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_DestinationPortExpressStatus",
    Label: "目的港快递状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_DeparturePortContainerTrailerStatus",
    Label: "装货港拖车状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_DestinationPortContainerTrailerStatus",
    Label: "目的港拖车状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_DeparturePortIncomingGoodsStatus",
    Label: "装货港入货状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_DestinationPortIncomingGoodsStatus",
    Label: "目的港入货状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_CollectionSubmitStatus",
    Label: "应收账单状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_PaymentSubmitStatus",
    Label: "应付账单状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_SalerSubmitStatus",
    Label: "结算账单状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_Remark",
    Label: "备注",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
]

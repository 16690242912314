
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
import DtoFormPriceServiceRemark, {
  DtoFormPriceServiceRemarkMiezzFormItems,
  DtoFormPriceServiceRemarkRules,
} from "@/models.machine/DtoFormPriceServiceRemark";
import PriceServiceAppService from "@/apis.machine/PriceServiceAppService";
import MiezzButton from "@/models/MiezzButton";
import {
  EnumTransportDirection,
  EnumTransportDirectionOption,
} from "@/models.machine/EnumTransportDirection";
import {
  EnumTransportType,
  EnumTransportTypeOption,
} from "@/models.machine/EnumTransportType";
import {
  EnumTransportContainerLoad,
  EnumTransportContainerLoadOption,
} from "@/models.machine/EnumTransportContainerLoad";
import UiSelectOption from "@/models.machine/UiSelectOption";
import CurrentLogier from "@/apis/CurrentLogier";
import PriceServiceDetail from "../PriceServiceDetail.vue";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import Logier from "@/models.machine/Logier";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import {
  EnumCustomsClearanceHeaderProvider,
  EnumCustomsClearanceHeaderProviderOption,
} from "@/models.machine/EnumCustomsClearanceHeaderProvider";
@Options({
  components: {
    PriceServiceDetail,
  },
})
export default class PriceServiceFormRemarkEdit extends Vue {
  id?: string;
  logier?: Logier;
  form = new MiezzForm<DtoFormPriceServiceRemark, string>(
    "PriceManagement_Service_Detail_Remark_EditRemark"
  );
  menuTab: MiezzMenuTab = {
    Back: true,
  };
  buttons: MiezzButton[] = [
    {
      Code: "back",
      Label: "返回",
      MiezzIcon: "back",
      Type: "text",
      Size: "small",
    },
    {
      Code: "submit",
      Label: "保存",
      Icon: "select",
      Type: "text",
      Size: "small",
    },
  ];
  EnumCompanyType = EnumCompanyType;
  EnumTransportDirection = EnumTransportDirection;
  EnumTransportDirectionOption = EnumTransportDirectionOption;
  EnumTransportType = EnumTransportType;
  EnumTransportTypeOption = EnumTransportTypeOption;
  EnumTransportContainerLoadOption = EnumTransportContainerLoadOption;
  departurePortServiceIds: string[] = [];
  departurePortServices: UiSelectOption<string>[] = [];
  destinationPortServiceIds: string[] = [];
  destinationPortServices: UiSelectOption<string>[] = [];
  EnumCustomsClearanceHeaderProviderOption =
    EnumCustomsClearanceHeaderProviderOption;
  EnumCustomsClearanceHeaderProvider = EnumCustomsClearanceHeaderProvider;
  EnumTransportContainerLoad = EnumTransportContainerLoad;
  yesno: UiSelectOption<boolean>[] = [
    {
      Label: "是",
      Value: true,
    },
    {
      Label: "否",
      Value: false,
    },
  ];

  created(): void {
    this.id = this.$route.params.id as string;
    //表单-数据-初始化
    this.form.Data = new DtoFormPriceServiceRemark();
    //表单-校验
    this.form.Rules = Object.copy(DtoFormPriceServiceRemarkRules);
    this.form.Rules.TransportType = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ];
    this.form.Rules.TransportContainerLoad = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ];
    this.form.Rules.DeparturePortTakeMethod = [
      {
        validator: (rule: any, value: any, callback: any) => {
          //勾选了提货时，后方提货方式至少勾选一项，否则保存时下方出现红色字体提示“提货方式至少选择一种”
          if (
            this.form.Data.DeparturePortTake &&
            !this.form.Data.DeparturePortContainer &&
            !this.form.Data.DeparturePortTrunk &&
            !this.form.Data.DeparturePortExpress
          ) {
            return callback(new Error("提货方式至少选择一种"));
          }
          callback();
        },
        trigger: "change",
      },
    ];
    this.form.Rules.DestinationPortDeliveryMethod = [
      {
        validator: (rule: any, value: any, callback: any) => {
          //勾选了提货时，后方提货方式至少勾选一项，否则保存时下方出现红色字体提示“提货方式至少选择一种”
          if (
            this.form.Data.DestinationPortDelivery &&
            !this.form.Data.DestinationPortContainer &&
            !this.form.Data.DestinationPortTrunk &&
            !this.form.Data.DestinationPortExpress
          ) {
            return callback(new Error("送货方式至少选择一种"));
          }
          callback();
        },
        trigger: "change",
      },
    ];
    //表单-元素配置
    this.form.Items = DtoFormPriceServiceRemarkMiezzFormItems;

    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      PriceServiceAppService.GetRemarkById(this.id).then((r) => {
        if (r.data.Data) {
          this.form.Data = r.data.Data;
          this.initWatch();
        }
      });
    }
  }

  initWatch(): void {
    this.$watch(
      "form.Data.TransportDirection",
      this.onTransportDirectionChanged
    );
    this.$watch("form.Data.TransportType", this.onTransportTypeChanged);
    this.$watch(
      "form.Data.TransportContainerLoad",
      this.onTransportContainerLoadChanged
    );
    this.$watch(
      "form.Data.DeparturePortBooking",
      this.onDeparturePortBookingChanged
    );
    this.$watch("form.Data.DeparturePortTake", this.onDeparturePortTakeChanged);
    this.$watch(
      "form.Data.DeparturePortTrunk",
      this.onDeparturePortTrunkChanged
    );
    this.$watch(
      "form.Data.DeparturePortWarehouse",
      this.onDeparturePortWarehouseChanged
    );
    this.$watch(
      "form.Data.DeparturePortDeclareCustoms",
      this.onDeparturePortDeclareCustomsChanged
    );
    this.$watch(
      "form.Data.DestinationPortClearCustoms",
      this.onDestinationPortClearCustomsChanged
    );
    this.$watch(
      "form.Data.DestinationPortCustomsClearanceHeaderProvider",
      this.onDestinationPortCustomsClearanceHeaderProviderChanged
    );
    this.$watch(
      "form.Data.DestinationPortDelivery",
      this.onDestinationPortDeliveryChanged
    );
    this.$watch(
      "form.Data.DestinationPortContainer",
      this.onDestinationPortContainerChanged
    );
    this.$watch(
      "form.Data.DestinationPortTrunk",
      this.onDestinationPortTrunkChanged
    );
    this.$watch(
      "form.Data.DestinationPortExpress",
      this.onDestinationPortExpressChanged
    );
    this.$watch(
      "form.Data.DestinationPortWarehouse",
      this.onDestinationPortWarehouseChanged
    );
  }

  onTransportDirectionChanged(n?: string, o?: string): void {
    if (n == o) return;
    //当业务类型选择进口时，如勾选了装货港服务“报关”，不出现是否退税的信息，但是按照是否退税选择了“否”来执行流程；
    if (
      this.form.Data.TransportDirection == EnumTransportDirection.Import &&
      this.form.Data.DeparturePortDeclareCustoms
    ) {
      this.form.Data.DeparturePortDrawback = false;
    }
  }

  onTransportTypeChanged(n?: string, o?: string): void {
    if (n == o) return;
    //第三个文本框：当第二个文本框为“海运/By Sea”、“铁路/By Railway”时，显示
    if (
      this.form.Data.TransportType != EnumTransportType.Sea &&
      this.form.Data.TransportType != EnumTransportType.Railway
    ) {
      this.form.Data.TransportContainerLoad = undefined;
    }

    //当业务类型是XX/XX/拼柜/LCL，或者XX/空运/By Air时：
    if (
      this.form.Data.TransportType == EnumTransportType.Air ||
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL
    ) {
      //勾选了装货港订舱时，自动勾选目的港-仓库；
      if (this.form.Data.DeparturePortBooking) {
        this.form.Data.DestinationPortWarehouse = true;
      }
      // //取消勾选目的港-仓库，则取消勾选装货港订舱；
      // if (this.form.Data.DestinationPortWarehouse != true) {
      //   this.form.Data.DeparturePortBooking = false;
      // }
    }

    //当业务类型为XX/“空运/By Air”时，或者XX/XX/“散货/BULK”时，无集装箱拖车项
    //LCL时，不勾选订舱，不显示
    if (
      !this.form.Data.DestinationPortDelivery ||
      this.form.Data.TransportType == EnumTransportType.Air ||
      this.form.Data.TransportContainerLoad ==
        EnumTransportContainerLoad.Bulk ||
      (this.form.Data.TransportContainerLoad ==
        EnumTransportContainerLoad.LCL &&
        !this.form.Data.DeparturePortBooking)
    ) {
      this.form.Data.DestinationPortContainer = undefined;
    }

    //修订-----优化为：可以同时选择；
    // //当业务类型为XX/XX/“拼柜/LCL”或者“XX/空运”时，送货方式“快递”、“干线物流”仅能选择其一，选择一个则另一个取消勾选；
    // if (
    //   this.form.Data.TransportType == EnumTransportType.Air ||
    //   this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL
    // ) {
    //   if (this.form.Data.DestinationPortTrunk) {
    //     this.form.Data.DestinationPortExpress = false;
    //   }
    //   if (this.form.Data.DestinationPortExpress) {
    //     this.form.Data.DestinationPortTrunk = false;
    //   }
    // }
  }

  onTransportContainerLoadChanged(n?: string, o?: string): void {
    if (n == o) return;

    //当业务类型为XX/XX/“整柜/FCL”时，默认勾选集装箱拖车
    if (
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.FCL
    ) {
      if (this.form.Data.DeparturePortTake) {
        this.form.Data.DeparturePortContainer = true;
      }
      if (this.form.Data.DestinationPortDelivery) {
        this.form.Data.DestinationPortContainer = true;
      }
    }
    //当业务类型不是XX/XX/“整柜/FCL”时，无集装箱拖车项
    else {
      this.form.Data.DeparturePortContainer = undefined;
    }
    MiezzForm.validateField(this.$refs, "ruleForm", "DeparturePortTakeMethod");

    //当业务类型为XX/“空运/By Air”时，或者XX/XX/“散货/BULK”时，无集装箱拖车项
    //LCL时，不勾选订舱，不显示
    if (
      !this.form.Data.DestinationPortDelivery ||
      this.form.Data.TransportType == EnumTransportType.Air ||
      this.form.Data.TransportContainerLoad ==
        EnumTransportContainerLoad.Bulk ||
      (this.form.Data.TransportContainerLoad ==
        EnumTransportContainerLoad.LCL &&
        !this.form.Data.DeparturePortBooking)
    ) {
      this.form.Data.DestinationPortContainer = undefined;
    }
    MiezzForm.validateField(
      this.$refs,
      "ruleForm",
      "DestinationPortDeliveryMethod"
    );

    //修订-----优化为：当业务类型不是XX/XX/“整柜/FCL”时
    //7-1-1订舱、仓库/堆场/码头、报关勾选其中任一，则同时选中其余项；
    //7-1-2订舱取消勾选，则同时取消勾选报关；
    //7-1-3报关取消勾选，则同时取消勾选订舱；
    //7-1-4仓库/堆场/码头取消勾选，则同时取消订舱与报关；
    //当业务类型不是XX/XX/“整柜/FCL”时，订舱、仓库/堆场/码头、报关勾选或取消勾选其中任一，则同时选中或取消其余项
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL
    ) {
      const check =
        this.form.Data.DeparturePortBooking ||
        this.form.Data.DeparturePortWarehouse ||
        this.form.Data.DeparturePortDeclareCustoms;
      if (check) {
        this.form.Data.DeparturePortBooking = true;
        this.form.Data.DeparturePortWarehouse = true;
        this.form.Data.DeparturePortDeclareCustoms = true;
      } else {
        if (this.form.Data.DeparturePortBooking != true) {
          // this.form.Data.DeparturePortDeclareCustoms = false;
        }
        if (this.form.Data.DeparturePortDeclareCustoms != true) {
          // this.form.Data.DeparturePortBooking = false;
        }
        if (this.form.Data.DeparturePortWarehouse != true) {
          this.form.Data.DeparturePortWarehouse = false;
          // this.form.Data.DeparturePortDeclareCustoms = false;
        }
      }
    }

    //当业务类型为XX.XX./“整柜/FCL”时 如勾选了提货方式“干线物流”或者“快递”时，同时勾选“仓库/堆场/码头”；
    if (
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.FCL &&
      (this.form.Data.DeparturePortTrunk || this.form.Data.DeparturePortExpress)
    ) {
      this.form.Data.DeparturePortWarehouse = true;
    }

    //当业务类型不是XX/XX/“散货/BULK”时 如勾选了送货方式“干线物流”、“快递”时，同时勾选“仓库/堆场/码头”
    if (
      this.form.Data.TransportContainerLoad !=
        EnumTransportContainerLoad.Bulk &&
      (this.form.Data.DestinationPortTrunk ||
        this.form.Data.DestinationPortExpress)
    ) {
      this.form.Data.DestinationPortWarehouse = true;
    }

    //当业务类型不是XX/XX/“散货/BULK”时，并且业务类型不是XX/XX/“整柜/FCL”时
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL &&
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.Bulk
    ) {
      //勾选集装箱拖车，则“干线物流”、“快递”置灰；
      //勾选集装箱拖车，则“仓库/堆场/码头”置灰；
      if (this.form.Data.DestinationPortContainer) {
        this.form.Data.DestinationPortTrunk = false;
        this.form.Data.DestinationPortExpress = false;
        this.form.Data.DestinationPortWarehouse = false;
      }
      //勾选“干线物流”、“快递”任一，则“集装箱拖车”置灰
      //勾选“仓库/堆场/码头”，则“集装箱拖车”置灰；
      if (
        this.form.Data.DestinationPortTrunk ||
        this.form.Data.DestinationPortExpress ||
        this.form.Data.DestinationPortWarehouse
      ) {
        if (this.form.Data.DestinationPortDelivery) {
          this.form.Data.DestinationPortContainer = false;
        } else {
          this.form.Data.DestinationPortContainer = undefined;
        }
      }
    }

    //当业务类型是XX/XX/“散货/BULK”时：
    if (
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.Bulk
    ) {
      //如取消勾选“仓库/堆场/码头”，则同时取消勾选“快递”；
      if (
        !this.form.Data.DestinationPortWarehouse &&
        this.form.Data.DestinationPortExpress
      ) {
        this.form.Data.DestinationPortExpress = false;
      }
      //如勾选了送货方式“快递”时，同时勾选“仓库/堆场/码头”；
      if (this.form.Data.DestinationPortExpress) {
        this.form.Data.DestinationPortWarehouse = true;
      }
    }

    //当业务类型是XX/XX/拼柜/LCL，或者XX/空运/By Air时：
    if (
      this.form.Data.TransportType == EnumTransportType.Air ||
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL
    ) {
      //勾选了装货港订舱时，自动勾选目的港-仓库；
      if (this.form.Data.DeparturePortBooking) {
        this.form.Data.DestinationPortWarehouse = true;
      }
      // //取消勾选目的港-仓库，则取消勾选装货港订舱；
      // if (this.form.Data.DestinationPortWarehouse != true) {
      //   this.form.Data.DeparturePortBooking = false;
      // }
    }
    // //业务类型为XX/XX/拼箱时，如勾选了装货港订舱，未勾选目的港仓库，则默认勾选目的港送货，以及送货方式勾选集装箱拖车；
    // if (
    //   this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL &&
    //   this.form.Data.DeparturePortBooking &&
    //   !this.form.Data.DestinationPortWarehouse
    // ) {
    //   this.form.Data.DestinationPortDelivery = true;
    //   this.form.Data.DestinationPortContainer = true;
    // }

    //修订-----优化为：可以同时选择；
    // //当业务类型为XX/XX/“拼柜/LCL”或者“XX/空运”时，送货方式“快递”、“干线物流”仅能选择其一，选择一个则另一个取消勾选；
    // if (
    //   this.form.Data.TransportType == EnumTransportType.Air ||
    //   this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL
    // ) {
    //   if (this.form.Data.DestinationPortTrunk) {
    //     this.form.Data.DestinationPortExpress = false;
    //   }
    //   if (this.form.Data.DestinationPortExpress) {
    //     this.form.Data.DestinationPortTrunk = false;
    //   }
    // }
  }

  onDeparturePortBookingChanged(n?: string, o?: string): void {
    if (n == o) return;

    //订舱：勾选后，后方是否签发提单默认显示为“是”
    if (this.form.Data.DeparturePortBooking) {
      this.form.Data.DeparturePortLadingBill = true;
    } else {
      this.form.Data.DeparturePortLadingBill = undefined;
    }

    //修订-----优化为：当业务类型不是XX/XX/“整柜/FCL”时
    //7-1-1订舱、仓库/堆场/码头、报关勾选其中任一，则同时选中其余项；
    //7-1-2订舱取消勾选，则同时取消勾选报关；
    //7-1-3报关取消勾选，则同时取消勾选订舱；
    //7-1-4仓库/堆场/码头取消勾选，则同时取消订舱与报关；
    //当业务类型不是XX/XX/“整柜/FCL”时，订舱、仓库/堆场/码头、报关勾选或取消勾选其中任一，则同时选中或取消其余项
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL
    ) {
      if (this.form.Data.DeparturePortBooking) {
        this.form.Data.DeparturePortWarehouse = true;
        this.form.Data.DeparturePortDeclareCustoms = true;
      } else {
        // this.form.Data.DeparturePortDeclareCustoms = false;
      }
    }

    //当业务类型是XX/XX/拼柜/LCL，或者XX/空运/By Air时：
    if (
      this.form.Data.TransportType == EnumTransportType.Air ||
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL
    ) {
      //勾选了装货港订舱时，自动勾选目的港-仓库；
      if (this.form.Data.DeparturePortBooking) {
        this.form.Data.DestinationPortWarehouse = true;
      }
    }
    // //业务类型为XX/XX/拼箱时，如勾选了装货港订舱，未勾选目的港仓库，则默认勾选目的港送货，以及送货方式勾选集装箱拖车；
    // if (
    //   this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL &&
    //   this.form.Data.DeparturePortBooking &&
    //   !this.form.Data.DestinationPortWarehouse
    // ) {
    //   this.form.Data.DestinationPortDelivery = true;
    //   this.form.Data.DestinationPortContainer = true;
    // }

    //当业务类型为XX/“空运/By Air”时，或者XX/XX/“散货/BULK”时，无集装箱拖车项
    //LCL时，不勾选订舱，不显示
    if (
      !this.form.Data.DestinationPortDelivery ||
      this.form.Data.TransportType == EnumTransportType.Air ||
      this.form.Data.TransportContainerLoad ==
        EnumTransportContainerLoad.Bulk ||
      (this.form.Data.TransportContainerLoad ==
        EnumTransportContainerLoad.LCL &&
        !this.form.Data.DeparturePortBooking)
    ) {
      this.form.Data.DestinationPortContainer = undefined;
    }
  }

  onDeparturePortTakeChanged(n?: string, o?: string): void {
    if (n == o) return;

    if (this.form.Data.DeparturePortTake) {
      //当业务类型为XX/XX/“整柜/FCL”时，默认勾选集装箱拖车
      if (
        this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.FCL
      ) {
        this.form.Data.DeparturePortContainer = true;
      }
      //当业务类型不是XX/XX/“整柜/FCL”时，无集装箱拖车项
      else {
        this.form.Data.DeparturePortContainer = undefined;
      }
      if (this.form.Data.DeparturePortExpress == undefined) {
        this.form.Data.DeparturePortExpress = false;
      }
      if (this.form.Data.DeparturePortTrunk == undefined) {
        this.form.Data.DeparturePortTrunk = false;
      }
    } else {
      this.form.Data.DeparturePortContainer = undefined;
      this.form.Data.DeparturePortExpress = undefined;
      this.form.Data.DeparturePortTrunk = undefined;
    }

    MiezzForm.validateField(this.$refs, "ruleForm", "DeparturePortTakeMethod");
  }

  onDeparturePortTrunkChanged(n?: string, o?: string): void {
    if (n == o) return;

    //当业务类型为XX.XX./“整柜/FCL”时 如勾选了提货方式“干线物流”或者“快递”时，同时勾选“仓库/堆场/码头”；
    if (
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.FCL &&
      (this.form.Data.DeparturePortTrunk || this.form.Data.DeparturePortExpress)
    ) {
      this.form.Data.DeparturePortWarehouse = true;
    }
  }

  onDeparturePortExpressChanged(n?: string, o?: string): void {
    if (n == o) return;

    //当业务类型为XX.XX./“整柜/FCL”时 如勾选了提货方式“干线物流”或者“快递”时，同时勾选“仓库/堆场/码头”；
    if (
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.FCL &&
      (this.form.Data.DeparturePortTrunk || this.form.Data.DeparturePortExpress)
    ) {
      this.form.Data.DeparturePortWarehouse = true;
    }
  }

  onDeparturePortWarehouseChanged(n?: string, o?: string): void {
    if (n == o) return;

    //修订-----优化为：当业务类型不是XX/XX/“整柜/FCL”时
    //7-1-1订舱、仓库/堆场/码头、报关勾选其中任一，则同时选中其余项；
    //7-1-2订舱取消勾选，则同时取消勾选报关；
    //7-1-3报关取消勾选，则同时取消勾选订舱；
    //7-1-4仓库/堆场/码头取消勾选，则同时取消订舱与报关；
    //当业务类型不是XX/XX/“整柜/FCL”时，订舱、仓库/堆场/码头、报关勾选或取消勾选其中任一，则同时选中或取消其余项
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL
    ) {
      if (this.form.Data.DeparturePortWarehouse) {
        this.form.Data.DeparturePortBooking = true;
        this.form.Data.DeparturePortDeclareCustoms = true;
      } else {
        this.form.Data.DeparturePortBooking = false;
        // this.form.Data.DeparturePortDeclareCustoms = false;
      }
    }

    // //当业务类型为XX.XX./“整柜/FCL”时 如取消勾选“仓库/堆场/码头”，则同时取消勾选“干线物流”、“快递”；
    // if (
    //   this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.FCL &&
    //   !this.form.Data.DeparturePortWarehouse
    // ) {
    //   if (this.form.Data.DeparturePortTake) {
    //     this.form.Data.DeparturePortTrunk = false;
    //     this.form.Data.DeparturePortExpress = false;
    //   } else {
    //     this.form.Data.DeparturePortTrunk = undefined;
    //     this.form.Data.DeparturePortExpress = undefined;
    //   }
    // }

    //当业务类型不是XX/XX/“散货/BULK”时，并且业务类型不是XX/XX/“整柜/FCL”时
    //勾选“干线物流”、“快递”任一，则“集装箱拖车”置灰
    //勾选“仓库/堆场/码头”，则“集装箱拖车”置灰；
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL &&
      this.form.Data.TransportContainerLoad !=
        EnumTransportContainerLoad.Bulk &&
      (this.form.Data.DestinationPortTrunk ||
        this.form.Data.DestinationPortExpress ||
        this.form.Data.DestinationPortWarehouse)
    ) {
      if (this.form.Data.DestinationPortDelivery) {
        this.form.Data.DestinationPortContainer = false;
      } else {
        this.form.Data.DestinationPortContainer = undefined;
      }
    }
  }

  onDeparturePortDeclareCustomsChanged(n?: string, o?: string): void {
    if (n == o) return;

    //报关：勾选后，后方是否退税默认显示为是
    if (this.form.Data.DeparturePortDeclareCustoms) {
      this.form.Data.DeparturePortDrawback = true;
    } else {
      this.form.Data.DeparturePortDrawback = undefined;
    }

    //当业务类型选择进口时，如勾选了装货港服务“报关”，不出现是否退税的信息，但是按照是否退税选择了“否”来执行流程；
    if (
      this.form.Data.TransportDirection == EnumTransportDirection.Import &&
      this.form.Data.DeparturePortDeclareCustoms
    ) {
      this.form.Data.DeparturePortDrawback = false;
    }

    //修订-----优化为：当业务类型不是XX/XX/“整柜/FCL”时
    //7-1-1订舱、仓库/堆场/码头、报关勾选其中任一，则同时选中其余项；
    //7-1-2订舱取消勾选，则同时取消勾选报关；
    //7-1-3报关取消勾选，则同时取消勾选订舱；
    //7-1-4仓库/堆场/码头取消勾选，则同时取消订舱与报关；
    //当业务类型不是XX/XX/“整柜/FCL”时，订舱、仓库/堆场/码头、报关勾选或取消勾选其中任一，则同时选中或取消其余项
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL
    ) {
      if (this.form.Data.DeparturePortDeclareCustoms) {
        this.form.Data.DeparturePortBooking = true;
        this.form.Data.DeparturePortWarehouse = true;
      } else {
        // this.form.Data.DeparturePortBooking = false;
      }
    }
  }

  onDestinationPortClearCustomsChanged(n?: string, o?: string): void {
    if (n == o) return;

    //清关：勾选后，后方清关抬头默认显示为委托人提供
    if (this.form.Data.DestinationPortClearCustoms) {
      this.form.Data.DestinationPortCustomsClearanceHeaderProvider =
        EnumCustomsClearanceHeaderProvider.Client;
    } else {
      this.form.Data.DestinationPortCustomsClearanceHeaderProvider = undefined;
    }
  }

  onDestinationPortCustomsClearanceHeaderProviderChanged(
    n?: string,
    o?: string
  ): void {
    if (n == o) return;

    if (
      this.form.Data.DestinationPortCustomsClearanceHeaderProvider ==
        EnumCustomsClearanceHeaderProvider.ServiceProvider &&
      this.form.Data.DestinationPortCustomsClearanceIssueTaxReceipt == undefined
    ) {
      this.form.Data.DestinationPortCustomsClearanceIssueTaxReceipt = false;
    } else if (
      this.form.Data.DestinationPortCustomsClearanceHeaderProvider == undefined
    ) {
      this.form.Data.DestinationPortCustomsClearanceIssueTaxReceipt = undefined;
    }
  }

  onDestinationPortDeliveryChanged(n?: string, o?: string): void {
    if (n == o) return;

    //当业务类型为XX/“空运/By Air”时，或者XX/XX/“散货/BULK”时，无集装箱拖车项
    //LCL时，不勾选订舱，不显示
    if (
      !this.form.Data.DestinationPortDelivery ||
      this.form.Data.TransportType == EnumTransportType.Air ||
      this.form.Data.TransportContainerLoad ==
        EnumTransportContainerLoad.Bulk ||
      (this.form.Data.TransportContainerLoad ==
        EnumTransportContainerLoad.LCL &&
        !this.form.Data.DeparturePortBooking)
    ) {
      this.form.Data.DestinationPortContainer = undefined;
    }

    if (this.form.Data.DestinationPortDelivery) {
      //当业务类型为XX/XX/“整柜/FCL”时，默认勾选集装箱拖车
      if (
        this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.FCL
      ) {
        this.form.Data.DestinationPortContainer = true;
      }
      if (this.form.Data.DestinationPortExpress == undefined) {
        this.form.Data.DestinationPortExpress = false;
      }
      if (this.form.Data.DestinationPortTrunk == undefined) {
        this.form.Data.DestinationPortTrunk = false;
      }
    } else {
      this.form.Data.DestinationPortExpress = undefined;
      this.form.Data.DestinationPortTrunk = undefined;
    }

    MiezzForm.validateField(
      this.$refs,
      "ruleForm",
      "DestinationPortDeliveryMethod"
    );
  }

  onDestinationPortContainerChanged(n?: string, o?: string): void {
    if (n == o) return;

    //当业务类型不是XX/XX/“散货/BULK”时，并且业务类型不是XX/XX/“整柜/FCL”时
    //勾选集装箱拖车，则“干线物流”、“快递”置灰；
    //勾选集装箱拖车，则“仓库/堆场/码头”置灰；
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL &&
      this.form.Data.TransportContainerLoad !=
        EnumTransportContainerLoad.Bulk &&
      this.form.Data.DestinationPortContainer
    ) {
      this.form.Data.DestinationPortTrunk = false;
      this.form.Data.DestinationPortExpress = false;
      this.form.Data.DestinationPortWarehouse = false;
    }
  }

  onDestinationPortTrunkChanged(n?: string, o?: string): void {
    if (n == o) return;

    //当业务类型不是XX/XX/“散货/BULK”时 如勾选了送货方式“干线物流”、“快递”时，同时勾选“仓库/堆场/码头”
    if (
      this.form.Data.TransportContainerLoad !=
        EnumTransportContainerLoad.Bulk &&
      (this.form.Data.DestinationPortTrunk ||
        this.form.Data.DestinationPortExpress)
    ) {
      this.form.Data.DestinationPortWarehouse = true;
    }

    //当业务类型不是XX/XX/“散货/BULK”时，并且业务类型不是XX/XX/“整柜/FCL”时
    //勾选“干线物流”、“快递”任一，则“集装箱拖车”置灰
    //勾选“仓库/堆场/码头”，则“集装箱拖车”置灰；
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL &&
      this.form.Data.TransportContainerLoad !=
        EnumTransportContainerLoad.Bulk &&
      (this.form.Data.DestinationPortTrunk ||
        this.form.Data.DestinationPortExpress ||
        this.form.Data.DestinationPortWarehouse)
    ) {
      if (this.form.Data.DestinationPortDelivery) {
        this.form.Data.DestinationPortContainer = false;
      } else {
        this.form.Data.DestinationPortContainer = undefined;
      }
    }

    //修订-----优化为：可以同时选择；
    // //当业务类型为XX/XX/“拼柜/LCL”或者“XX/空运”时，送货方式“快递”、“干线物流”仅能选择其一，选择一个则另一个取消勾选；
    // if (
    //   (this.form.Data.TransportType == EnumTransportType.Air ||
    //     this.form.Data.TransportContainerLoad ==
    //       EnumTransportContainerLoad.LCL) &&
    //   this.form.Data.DestinationPortTrunk
    // ) {
    //   this.form.Data.DestinationPortExpress = false;
    // }
  }

  onDestinationPortExpressChanged(n?: string, o?: string): void {
    if (n == o) return;

    //当业务类型不是XX/XX/“散货/BULK”时 如勾选了送货方式“干线物流”、“快递”时，同时勾选“仓库/堆场/码头”
    if (
      this.form.Data.TransportContainerLoad !=
        EnumTransportContainerLoad.Bulk &&
      (this.form.Data.DestinationPortTrunk ||
        this.form.Data.DestinationPortExpress)
    ) {
      this.form.Data.DestinationPortWarehouse = true;
    }

    //当业务类型不是XX/XX/“散货/BULK”时，并且业务类型不是XX/XX/“整柜/FCL”时
    //勾选“干线物流”、“快递”任一，则“集装箱拖车”置灰
    //勾选“仓库/堆场/码头”，则“集装箱拖车”置灰；
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL &&
      this.form.Data.TransportContainerLoad !=
        EnumTransportContainerLoad.Bulk &&
      (this.form.Data.DestinationPortTrunk ||
        this.form.Data.DestinationPortExpress ||
        this.form.Data.DestinationPortWarehouse)
    ) {
      if (this.form.Data.DestinationPortDelivery) {
        this.form.Data.DestinationPortContainer = false;
      } else {
        this.form.Data.DestinationPortContainer = undefined;
      }
    }

    //当业务类型是XX/XX/“散货/BULK”时：
    if (
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.Bulk
    ) {
      //如勾选了送货方式“快递”时，同时勾选“仓库/堆场/码头”；
      if (this.form.Data.DestinationPortExpress) {
        this.form.Data.DestinationPortWarehouse = true;
      }
    }

    //修订-----优化为：可以同时选择；
    // //当业务类型为XX/XX/“拼柜/LCL”或者“XX/空运”时，送货方式“快递”、“干线物流”仅能选择其一，选择一个则另一个取消勾选；
    // if (
    //   (this.form.Data.TransportType == EnumTransportType.Air ||
    //     this.form.Data.TransportContainerLoad ==
    //       EnumTransportContainerLoad.LCL) &&
    //   this.form.Data.DestinationPortExpress
    // ) {
    //   this.form.Data.DestinationPortTrunk = false;
    // }
  }

  onDestinationPortWarehouseChanged(n?: string, o?: string): void {
    if (n == o) return;

    // //当业务类型不是XX/XX/“散货/BULK”时 如取消勾选“仓库/堆场/码头”，则同时取消勾选“干线物流”、“快递”；
    // if (
    //   this.form.Data.TransportContainerLoad !=
    //     EnumTransportContainerLoad.Bulk &&
    //   !this.form.Data.DestinationPortWarehouse
    // ) {
    //   if (this.form.Data.DestinationPortDelivery) {
    //     this.form.Data.DestinationPortTrunk = false;
    //     this.form.Data.DestinationPortExpress = false;
    //   } else {
    //     this.form.Data.DestinationPortTrunk = false;
    //     this.form.Data.DestinationPortExpress = false;
    //   }
    // }

    //当业务类型不是XX/XX/“散货/BULK”时，并且业务类型不是XX/XX/“整柜/FCL”时
    //勾选“干线物流”、“快递”任一，则“集装箱拖车”置灰
    //勾选“仓库/堆场/码头”，则“集装箱拖车”置灰；
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL &&
      this.form.Data.TransportContainerLoad !=
        EnumTransportContainerLoad.Bulk &&
      (this.form.Data.DestinationPortTrunk ||
        this.form.Data.DestinationPortExpress ||
        this.form.Data.DestinationPortWarehouse)
    ) {
      if (this.form.Data.DestinationPortDelivery) {
        this.form.Data.DestinationPortContainer = false;
      } else {
        this.form.Data.DestinationPortContainer = undefined;
      }
    }

    //当业务类型是XX/XX/“散货/BULK”时：
    if (
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.Bulk
    ) {
      //如取消勾选“仓库/堆场/码头”，则同时取消勾选“快递”；
      if (
        !this.form.Data.DestinationPortWarehouse &&
        this.form.Data.DestinationPortExpress
      ) {
        this.form.Data.DestinationPortExpress = false;
      }
    }

    //当业务类型是XX/XX/拼柜/LCL，或者XX/空运/By Air时：
    if (
      this.form.Data.TransportType == EnumTransportType.Air ||
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL
    ) {
      // //取消勾选目的港-仓库，则取消勾选装货港订舱；
      // if (this.form.Data.DestinationPortWarehouse != true) {
      //   this.form.Data.DeparturePortBooking = false;
      // }
    }
    // //业务类型为XX/XX/拼箱时，如勾选了装货港订舱，未勾选目的港仓库，则默认勾选目的港送货，以及送货方式勾选集装箱拖车；
    // if (
    //   this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL &&
    //   this.form.Data.DeparturePortBooking &&
    //   !this.form.Data.DestinationPortWarehouse
    // ) {
    //   this.form.Data.DestinationPortDelivery = true;
    //   this.form.Data.DestinationPortContainer = true;
    // }
  }

  /**按钮点击 */
  @Emit()
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v: boolean) => {
          if (v) {
            PriceServiceAppService.PostRemark(this.form.Data)
              .then(() => {
                CurrentLogier.Back();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }
}

import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemOrderPoolTrunk_OrderPool from "./DtoListItemOrderPoolTrunk_OrderPool";
import DtoListItemOrderPoolTrunk_CompanyAddress from "./DtoListItemOrderPoolTrunk_CompanyAddress";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";
import { EnumSupplierSource, EnumSupplierSourceOption } from "./EnumSupplierSource";
import DtoListItemOrderPoolTrunk_OrderPoolFile from "./DtoListItemOrderPoolTrunk_OrderPoolFile";

/**
 * 委托书-干线物流分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderPoolTrunk extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.ClientCompany = "暂无数据";
        this.HasCargoQuantities = false;
        this.HasCargos = false;
        this.ExecutorLabel = "暂无数据";
        this.Files = [];
    }

    [proName: string]: any;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 委托书Id
     */
    PoolId?: string;
    /**
     * 委托书
     */
    Pool?: DtoListItemOrderPoolTrunk_OrderPool;
    /**
     * 委托人
     */
    ClientCompany?: string;
    /**
     * 中文名称
     */
    WharfSupplierNameZh?: string;
    /**
     * 英文名称
     */
    WharfSupplierNameEn?: string;
    /**
     * 中文收件地址
     */
    WharfSupplierReceivingAddressZh?: string;
    /**
     * 英文收件地址
     */
    WharfSupplierReceivingAddressEn?: string;
    /**
     * 计划提柜时间
     */
    PlanCarryContainerTime?: Date;
    /**
     * 提柜时间
     */
    CarryContainerTime?: Date;
    /**
     * 计划提取时间
     */
    CarryPlanTime?: Date;
    /**
     * 提货地址Id
     */
    CarryAddressId?: string;
    /**
     * 提货地址
     */
    CarryAddress?: DtoListItemOrderPoolTrunk_CompanyAddress;
    /**
     * 货量
     */
    HasCargoQuantities?: boolean;
    /**
     * 货物清单
     */
    HasCargos?: boolean;
    /**
     * 服务类型
     */
    ServiceType?: EnumOrderServiceType;
    /**
     * 合计件数
     */
    TotalQuantity?: number;
    /**
     * 合计件数-英文计价单位
     */
    TotalQuantityChargeUnitEn?: string;
    /**
     * 合计毛重
     */
    TotalGrossWeight?: number;
    /**
     * 合计体积
     */
    TotalVolume?: number;
    /**
     * 实际提取时间
     */
    AllCarryTime?: Date;
    /**
     * 供应商来源
     */
    SupplierSource?: EnumSupplierSource;
    /**
     * 供应商类型Id
     */
    SupplierCompanyTypeId?: string;
    /**
     * 供应商类型
     */
    SupplierCompanyTypeLabel?: string;
    /**
     * 供应商Id
     */
    SupplierCompanyId?: string;
    /**
     * 供应商
     */
    SupplierCompanyLabel?: string;
    /**
     * 供应商联系人
     */
    SupplierCompanyContactLabel?: string;
    /**
     * 供应商
     */
    SupplierLabel?: string;
    /**
     * 执行人姓名
     */
    Executor?: string;
    /**
     * 执行人联系方式
     */
    ExecutorContactNumber?: string;
    /**
     * 执行人车牌号码
     */
    ExecutorLicensePlate?: string;
    /**
     * 执行人
     */
    ExecutorLabel?: string;
    /**
     * 交货地址Id
     */
    DeliveryAddressId?: string;
    /**
     * 交货地址
     */
    DeliveryAddress?: DtoListItemOrderPoolTrunk_CompanyAddress;
    /**
     * 计划抵达时间
     */
    PlanDeliveryTime?: Date;
    /**
     * 实际送达时间
     */
    AllDeliveryTime?: Date;
    /**
     * 附件
     */
    Files?: DtoListItemOrderPoolTrunk_OrderPoolFile[];
    /**
     * 进程
     */
    Process?: string;
}

/**
 * 委托书-干线物流分页列表-项-表单校验
 */
export const DtoListItemOrderPoolTrunkRules = {
}

/**
 * 委托书-干线物流分页列表-项-设置项
 */
export const DtoListItemOrderPoolTrunkMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Code",
    Label: "编号",
    MaxWidth: "100px",
  },
  {
    Prop: "ClientCompany",
    Label: "委托人",
    MaxWidth: "150px",
  },
  {
    Prop: "PlanCarryContainerTime",
    Label: "计划提柜时间",
    Hide: true,
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "CarryContainerTime",
    Label: "提柜时间",
    Hide: true,
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "CarryPlanTime",
    Label: "计划提取时间",
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "CarryAddress",
    Label: "提货地址",
    MaxWidth: "130px",
    Customize: true,
  },
  {
    Prop: "HasCargoQuantities",
    Label: "货量",
    Customize: true,
  },
  {
    Prop: "HasCargos",
    Label: "货物清单",
    MaxWidth: "90px",
    Customize: true,
  },
  {
    Prop: "AllCarryTime",
    Label: "实际提取时间",
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "SupplierLabel",
    Label: "供应商",
    Customize: true,
  },
  {
    Prop: "ExecutorLabel",
    Label: "执行人",
    Customize: true,
  },
  {
    Prop: "DeliveryAddress",
    Label: "交货地址",
    MaxWidth: "90px",
    Customize: true,
  },
  {
    Prop: "AllDeliveryTime",
    Label: "实际送达时间",
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "Files",
    Label: "附件",
    Customize: true,
  },
  {
    Prop: "Process",
    Label: "进程",
    Customize: true,
  },
]

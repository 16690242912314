
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import DtoListItemPrice from "@/models.machine/DtoListItemPrice";
import MiezzPageList from "@/models/MiezzPageList";
import DtoPageListSearchPrice from "@/models.machine/DtoPageListSearchPrice";
import MiezzButton from "@/models/MiezzButton";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzListItem from "@/models/MiezzListItem";
import UiSelectOption from "@/models.machine/UiSelectOption";
import DtoFormPriceBatchUpdate from "@/models.machine/DtoFormPriceBatchUpdate";
import { EnumPriceAmountType } from "@/models.machine/EnumPriceAmountType";
import DtoFormPriceBatchUpdateItem from "@/models.machine/DtoFormPriceBatchUpdateItem";
import PriceAppService from "@/apis.machine/PriceAppService";
export default class PriceBatchEdit extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() amountType?: EnumPriceAmountType;
  @Prop() companyId?: string;
  @Prop() userId?: string;
  @Prop() table!: MiezzPageList<
    DtoPageListSearchPrice,
    DtoListItemPrice,
    string
  >;
  list = new MiezzPageList<DtoPageListSearchPrice, DtoListItemPrice, string>();
  amountListItem?: MiezzListItem;
  buttons: MiezzButton[] = [
    {
      Code: "plus",
      Icon: "plus",
      Title: "增幅",
      Type: "default",
      Size: "small",
    },
    {
      Code: "minus",
      Icon: "minus",
      Title: "减幅",
      Type: "default",
      Size: "small",
    },
  ];
  modal = new MiezzModal(MiezzModalType.Dialog);
  modalLabel = "增幅";
  modalMethods: UiSelectOption<number>[] = [
    {
      Label: "固定值",
      Value: 1,
    },
    {
      Label: "固定比例（%）",
      Value: 2,
    },
  ];
  modalData: {
    Method?: number;
    Number?: number;
  } = {
    Method: 1,
  };
  modalRules = {
    Method: [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ],
    Number: [
      {
        validator: (rule: any, value: number, callback: any) => {
          if (value != undefined) {
            if (value <= 0) {
              return callback(new Error("仅可填入大于0的数字"));
            }
          } else {
            return callback(new Error("信息不能为空"));
          }
          callback();
        },
        trigger: "blur",
      },
    ],
  };
  EnumPriceAmountType = EnumPriceAmountType;

  created(): void {
    this.modelValue.Title = "批量编辑";
    this.modelValue.Width = "90%";
    this.modelValue.Height = window.innerHeight * 0.8 + "px";
    this.modelValue.AlignCenter = true;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
      {
        Code: "reset",
        Label: "重置",
        Type: "primary",
        Size: "small",
      },
    ];
    this.modelValue.HandleClick = this.handleClick;

    this.list.Fix = false;
    this.list.HideCard = true;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.EnabledSelect = false;
    this.list.EnabledPagination = false;
    this.list.Items = this.table.Items?.map((it) => {
      const item = Object.copy(it);
      if (
        (this.amountType == EnumPriceAmountType.Benchmark &&
          item.Prop == "AmountCustomize") ||
        (this.amountType == EnumPriceAmountType.Cost &&
          item.Prop == "AmountCustomize") ||
        (this.amountType == EnumPriceAmountType.Settlement &&
          item.Prop == "SettlementAmountCustomize") ||
        (this.amountType == EnumPriceAmountType.Guide &&
          item.Prop == "GuideAmountCustomize") ||
        (this.amountType == EnumPriceAmountType.Agreement &&
          item.Prop == "AgreementAmountCustomize")
      ) {
        item.Customize = true;
        item.CustomizeHeader = true;
        item.Width = 150;
        this.amountListItem = item;
      }
      return item;
    });
    this.list.Data = {
      Items: JSON.parse(JSON.stringify(this.table.SelectRows)),
    };

    this.modal.Height = "100px";
    this.modal.Buttons = [
      {
        Code: "modal-submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];
    this.modal.HandleClick = this.handleClick;
  }

  handleCalcAmount(amount?: number): number {
    amount = amount ?? 0;
    if (this.modalData.Method == 1) {
      const num = this.modalData.Number ?? 0;
      if (this.modalLabel == "增幅") {
        amount = amount + num;
      } else {
        amount = amount - num;
      }
    } else {
      const num = (this.modalData.Number ?? 0) / 100;
      if (this.modalLabel == "增幅") {
        amount = amount * (1 + num);
      } else {
        amount = amount * (1 - num);
      }
    }
    return amount;
  }

  /**顶部按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "plus":
        this.modalLabel = "增幅";
        this.modal.Title = `批量${this.modalLabel}`;
        this.modal.Width = "650";
        this.modalData.Number = undefined;
        this.modal.Show = true;
        complete();
        break;
      case "minus":
        this.modalLabel = "减幅";
        this.modal.Title = `批量${this.modalLabel}`;
        this.modal.Width = "650";
        this.modalData.Number = undefined;
        this.modal.Show = true;
        complete();
        break;
      case "modal-submit":
        (this.$refs["modal-form"] as any).validate((v: boolean) => {
          if (v) {
            for (const item of this.list.Data?.Items ?? []) {
              if (
                (this.amountType == EnumPriceAmountType.Benchmark ||
                  this.amountType == EnumPriceAmountType.Cost) &&
                !item.Formula
              ) {
                item.Amount = this.handleCalcAmount(item.Amount);
              } else if (
                this.amountType == EnumPriceAmountType.Settlement &&
                !item.SettlementFormula
              ) {
                item.SettlementAmount = this.handleCalcAmount(
                  item.SettlementAmount
                );
              } else if (
                this.amountType == EnumPriceAmountType.Guide &&
                !item.GuideFormula
              ) {
                item.GuideAmount = this.handleCalcAmount(item.GuideAmount);
              } else if (
                this.amountType == EnumPriceAmountType.Agreement &&
                !item.AgreementFormula
              ) {
                item.AgreementAmount = this.handleCalcAmount(
                  item.AgreementAmount
                );
              }
            }
            this.modal.Show = false;
            complete();
          } else {
            complete();
          }
        });
        break;
      case "submit":
        (this.$refs["list-form"] as any).validate((v: boolean) => {
          if (v) {
            var dto = new DtoFormPriceBatchUpdate();
            dto.AmountType = this.amountType;
            if (dto.AmountType == EnumPriceAmountType.Settlement) {
              dto.UserId = this.userId;
            }
            if (dto.AmountType == EnumPriceAmountType.Agreement) {
              dto.CompanyId = this.companyId;
            }
            dto.Items =
              this.list.Data?.Items?.map((it) => {
                const item = new DtoFormPriceBatchUpdateItem();
                item.Id = it.Id;
                if (
                  dto.AmountType == EnumPriceAmountType.Benchmark ||
                  dto.AmountType == EnumPriceAmountType.Cost
                ) {
                  item.Amount = it.Amount;
                } else if (dto.AmountType == EnumPriceAmountType.Settlement) {
                  item.Amount = it.SettlementAmount;
                } else if (dto.AmountType == EnumPriceAmountType.Guide) {
                  item.Amount = it.GuideAmount;
                } else if (dto.AmountType == EnumPriceAmountType.Agreement) {
                  item.Amount = it.AgreementAmount;
                }
                return item;
              }) ?? [];
            PriceAppService.BatchUpdate(dto)
              .then(() => {
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "reset":
        (this.list.Data!.Items = JSON.parse(
          JSON.stringify(this.table.SelectRows)
        )),
          complete();
        break;
      default:
        break;
    }
  }

  handleLinkClick(row: DtoListItemPrice): void {
    const link = row.FieldValues?.firstOrDefault(
      (it) => it.Column?.Field?.Code == "Link"
    )?.Link;
    if (
      link &&
      /^(http|https):\/\/[a-zA-Z0-9.-]+(:[0-9]+)?(\/[^ ]*)?$/.test(link)
    ) {
      window.open(link);
    } else {
      ElMessage.warning("链接地址无效，无法打开");
    }
  }

  /**提交表单回调 */
  @Emit()
  handleSubmit(): void {
    this.modelValue.Show = false;
  }
}

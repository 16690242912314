import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-610c1698"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "tab-label" }
const _hoisted_4 = ["title"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "tab-label" }
const _hoisted_8 = ["title"]
const _hoisted_9 = { class: "cargos-total" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_menu_tab = _resolveComponent("miezz-menu-tab")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_miezz_card = _resolveComponent("miezz-card")!
  const _component_miezz_upload = _resolveComponent("miezz-upload")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_common_history = _resolveComponent("miezz-common-history")!
  const _component_miezz_button_bar = _resolveComponent("miezz-button-bar")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_menu_tab, {
      modelValue: _ctx.menuTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_miezz_form, {
      modelValue: _ctx.detail,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.detail) = $event)),
      style: {"margin-bottom":"10px"},
      isDetail: true
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'Cargos')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                (
            _ctx.detail.Data.PackagesNumber ||
            _ctx.detail.Data.PackagesKind ||
            _ctx.detail.Data.Weight ||
            _ctx.detail.Data.Volume
          )
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: "javascript:void",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleDetailCargos && _ctx.handleDetailCargos(...args)))
                    }, _toDisplayString(_ctx.detail.Data.PackagesNumber && _ctx.detail.Data.PackagesKind
              ? `${_ctx.detail.Data.PackagesNumber}${_ctx.detail.Data.PackagesKind}`
              : _ctx.detail.Data.PackagesNumber
              ? _ctx.detail.Data.PackagesNumber
              : _ctx.detail.Data.PackagesKind
              ? _ctx.detail.Data.PackagesKind
              : "暂无数据") + "/ " + _toDisplayString(_ctx.detail.Data.Weight ? `${_ctx.detail.Data.Weight}KGS` : "暂无数据") + "/ " + _toDisplayString(_ctx.detail.Data.Volume ? `${_ctx.detail.Data.Volume}CBM` : "暂无数据"), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_1, "暂无数据"))
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'Files')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                ((_ctx.detail.Data.Files?.length ?? 0) > 0)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: "javascript:void",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleDetailFiles && _ctx.handleDetailFiles(...args)))
                    }, " 查看详情 "))
                  : (_openBlock(), _createElementBlock("span", _hoisted_2, "暂无数据"))
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : (item.Prop == 'Schemes')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 2,
                prop: item.Prop,
                labelWidth: "0px"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_tabs, {
                    modelValue: _ctx.schemeName,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.schemeName) = $event)),
                    type: "card",
                    "tab-position": "top"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detail.Data.Schemes, (scheme) => {
                        return (_openBlock(), _createBlock(_component_el_tab_pane, {
                          key: scheme.Name,
                          label: scheme.Name,
                          name: scheme.Name
                        }, {
                          label: _withCtx(() => [
                            _createElementVNode("span", _hoisted_3, [
                              _createElementVNode("span", {
                                title: scheme.Name
                              }, _toDisplayString(scheme.Name), 9, _hoisted_4)
                            ])
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_el_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_col, { span: 8 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, {
                                      prop: "ValidDateStart",
                                      label: "价格有效期"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(scheme.ValidDate), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_el_col, { span: 8 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, {
                                      prop: "ServiceRemark",
                                      label: "服务内容"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(scheme.ServiceRemark), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scheme.FieldValues, (field, field_index) => {
                                  return (_openBlock(), _createBlock(_component_el_col, {
                                    span: 8,
                                    key: field_index
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, {
                                        label: field.Name,
                                        labelWidth: "150px"
                                      }, {
                                        default: _withCtx(() => [
                                          (field.Field?.Code == 'Link')
                                            ? (_openBlock(), _createElementBlock("a", {
                                                key: 0,
                                                href: "javascript:void",
                                                class: _normalizeClass(`field-link ${
                      field.LinkLabel || field.Link ? '' : 'empty'
                    }`),
                                                onClick: ($event: any) => (_ctx.handleLinkClick(field))
                                              }, _toDisplayString(field?.LinkLabel ?? field.Link ?? "暂无数据"), 11, _hoisted_5))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(field.Label ?? "暂无数据"), 1))
                                        ]),
                                        _: 2
                                      }, 1032, ["label"])
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_miezz_card, {
                              title: "报价表",
                              style: {"margin-top":"5px"}
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("h1", null, [
                                  _createTextVNode(" 合计： "),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scheme.Totals, (total, total_index) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                      style: {"margin-right":"5px","color":"red"},
                                      key: total_index
                                    }, _toDisplayString(total.CurrencySystem) + " " + _toDisplayString(total.Amount?.toFixed(2)), 1))
                                  }), 128))
                                ]),
                                _createVNode(_component_el_table, {
                                  data: scheme.Items,
                                  border: "",
                                  stripe: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_table_column, {
                                      "show-overflow-tooltip": true,
                                      prop: "CostId",
                                      label: "费用名称"
                                    }, {
                                      default: _withCtx(({ row }) => [
                                        _createTextVNode(_toDisplayString(row.Cost?.FullName ?? "暂无数据"), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_table_column, {
                                      "show-overflow-tooltip": true,
                                      prop: "CurrencySystemId",
                                      label: "币制",
                                      width: "120px"
                                    }, {
                                      default: _withCtx(({ row }) => [
                                        _createTextVNode(_toDisplayString(row.CurrencySystem?.Code ?? "暂无数据"), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_table_column, {
                                      "show-overflow-tooltip": true,
                                      prop: "Amount",
                                      label: "单价",
                                      width: "120px"
                                    }, {
                                      default: _withCtx(({ row }) => [
                                        _createTextVNode(_toDisplayString(row.Amount ?? "暂无数据"), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_table_column, {
                                      "show-overflow-tooltip": true,
                                      prop: "FirstChargingUnitId",
                                      label: "计价单位",
                                      width: "120px"
                                    }, {
                                      default: _withCtx(({ row }) => [
                                        _createTextVNode(_toDisplayString(row.FirstChargingUnit?.FullName ?? "暂无数据"), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_table_column, {
                                      "show-overflow-tooltip": true,
                                      prop: "FirstChargingNumber",
                                      label: "数量",
                                      width: "120px"
                                    }, {
                                      default: _withCtx(({ row }) => [
                                        _createTextVNode(_toDisplayString(row.FirstChargingNumber ?? "暂无数据"), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_table_column, {
                                      "show-overflow-tooltip": true,
                                      prop: "Total",
                                      label: "合计",
                                      width: "120px"
                                    }, {
                                      default: _withCtx(({ row }) => [
                                        _createTextVNode(_toDisplayString(row.Total?.toFixed(2) ?? "暂无数据"), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_table_column, {
                                      "show-overflow-tooltip": true,
                                      prop: "Description",
                                      label: "说明"
                                    }, {
                                      default: _withCtx(({ row }) => [
                                        _createTextVNode(_toDisplayString(row.Description ?? "暂无数据"), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["data"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_miezz_card, {
                              title: "成本记录",
                              style: {"margin-top":"5px"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_form_item, { labelWidth: "0px" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_tabs, {
                                      modelValue: scheme.SupplierName,
                                      "onUpdate:modelValue": ($event: any) => ((scheme.SupplierName) = $event),
                                      type: "card",
                                      "tab-position": "top"
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scheme.Suppliers, (supplier) => {
                                          return (_openBlock(), _createBlock(_component_el_tab_pane, {
                                            key: supplier.SupplierName,
                                            label: supplier.SupplierName,
                                            name: supplier.SupplierName
                                          }, {
                                            label: _withCtx(() => [
                                              _createElementVNode("span", _hoisted_7, [
                                                _createElementVNode("span", {
                                                  title: 
                            supplier.SupplierName +
                            '/' +
                            (supplier.SupplierContacts ?? '--')
                          
                                                }, _toDisplayString(supplier.SupplierName +
                            "/" +
                            (supplier.SupplierContacts ?? "--")), 9, _hoisted_8)
                                              ])
                                            ]),
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_form_item, {
                                                prop: "ValidDateStart",
                                                label: "价格有效期",
                                                "label-width": "130px",
                                                style: {"width":"50%"}
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(supplier.ValidDate), 1)
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createElementVNode("h1", null, [
                                                _createTextVNode(" 合计： "),
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(supplier.Totals, (total, total_index) => {
                                                  return (_openBlock(), _createElementBlock("span", {
                                                    style: {"margin-right":"5px","color":"red"},
                                                    key: total_index
                                                  }, _toDisplayString(total.CurrencySystem) + " " + _toDisplayString(total.Amount?.toFixed(2)), 1))
                                                }), 128))
                                              ]),
                                              _createVNode(_component_el_table, {
                                                data: supplier.Items,
                                                border: "",
                                                stripe: ""
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_el_table_column, {
                                                    "show-overflow-tooltip": true,
                                                    prop: "CostId",
                                                    label: "费用名称"
                                                  }, {
                                                    default: _withCtx(({ row }) => [
                                                      _createTextVNode(_toDisplayString(row.Cost?.FullName ?? "暂无数据"), 1)
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    "show-overflow-tooltip": true,
                                                    prop: "CurrencySystemId",
                                                    label: "币制",
                                                    width: "120px"
                                                  }, {
                                                    default: _withCtx(({ row }) => [
                                                      _createTextVNode(_toDisplayString(row.CurrencySystem?.Code ?? "暂无数据"), 1)
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    "show-overflow-tooltip": true,
                                                    prop: "Amount",
                                                    label: "单价",
                                                    width: "120px"
                                                  }, {
                                                    default: _withCtx(({ row }) => [
                                                      _createTextVNode(_toDisplayString(row.Amount ?? "暂无数据"), 1)
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    "show-overflow-tooltip": true,
                                                    prop: "FirstChargingUnitId",
                                                    label: "计价单位",
                                                    width: "120px"
                                                  }, {
                                                    default: _withCtx(({ row }) => [
                                                      _createTextVNode(_toDisplayString(row.FirstChargingUnit?.FullName ?? "暂无数据"), 1)
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    "show-overflow-tooltip": true,
                                                    prop: "FirstChargingNumber",
                                                    label: "数量",
                                                    width: "120px"
                                                  }, {
                                                    default: _withCtx(({ row }) => [
                                                      _createTextVNode(_toDisplayString(row.FirstChargingNumber ?? "暂无数据"), 1)
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    "show-overflow-tooltip": true,
                                                    prop: "Total",
                                                    label: "合计",
                                                    width: "120px"
                                                  }, {
                                                    default: _withCtx(({ row }) => [
                                                      _createTextVNode(_toDisplayString(row.Total?.toFixed(2) ?? "暂无数据"), 1)
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_el_table_column, {
                                                    "show-overflow-tooltip": true,
                                                    prop: "Description",
                                                    label: "说明"
                                                  }, {
                                                    default: _withCtx(({ row }) => [
                                                      _createTextVNode(_toDisplayString(row.Description ?? "暂无数据"), 1)
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 2
                                              }, 1032, ["data"]),
                                              _createVNode(_component_el_form_item, {
                                                prop: "Remark",
                                                label: "备注",
                                                "label-width": "40px"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(supplier.Remark), 1)
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_el_form_item, {
                                                prop: "Files",
                                                label: "附件",
                                                "label-width": "40px"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_miezz_upload, {
                                                    modelValue: supplier.Files,
                                                    "onUpdate:modelValue": ($event: any) => ((supplier.Files) = $event),
                                                    prop: "File",
                                                    preview: true
                                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1032, ["label", "name"]))
                                        }), 128))
                                      ]),
                                      _: 2
                                    }, 1032, ["modelValue", "onUpdate:modelValue"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["label", "name"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 2
              }, 1032, ["prop"]))
            : (item.Prop == 'TrackRecords')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 3,
                  prop: item.Prop,
                  labelWidth: "0px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_miezz_page_list, {
                      modelValue: _ctx.listTrack,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.listTrack) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 2
                }, 1032, ["prop"]))
              : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_common_history, {
      modelValue: _ctx.histories,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.histories) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_miezz_button_bar, {
      modelValue: _ctx.buttons,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.buttons) = $event)),
      onHandleClick: _ctx.handleClick
    }, null, 8, ["modelValue", "onHandleClick"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalCargos,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.modalCargos) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, { inline: true }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_el_form_item, {
                label: "合计件数",
                prop: "PackagesNumber",
                "label-width": "80px"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.detail.Data.PackagesNumber && _ctx.detail.Data.PackagesKind
              ? `${_ctx.detail.Data.PackagesNumber}${_ctx.detail.Data.PackagesKind}`
              : _ctx.detail.Data.PackagesNumber
              ? _ctx.detail.Data.PackagesNumber
              : _ctx.detail.Data.PackagesKind
              ? _ctx.detail.Data.PackagesKind
              : "暂无数据"), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "合计毛重",
                prop: "Weight",
                "label-width": "90px"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.detail.Data.Weight ? `${_ctx.detail.Data.Weight}KGS` : "暂无数据"), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "合计体积",
                prop: "Volume",
                "label-width": "90px"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.detail.Data.Volume ? `${_ctx.detail.Data.Volume}CBM` : "暂无数据"), 1)
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_miezz_page_list, {
              modelValue: _ctx.listCargos,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.listCargos) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalFile,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.modalFile) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, { "label-width": "150px" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "文件",
              prop: "Files"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_upload, {
                  modelValue: _ctx.detail.Data.Files,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.detail.Data.Files) = $event)),
                  prop: "File",
                  preview: true
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}

import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import PriceServiceAppService from "@/apis.machine/PriceServiceAppService";
import DtoFormPriceService, {
  DtoFormPriceServiceMiezzFormItems,
  DtoFormPriceServiceRules,
} from "@/models.machine/DtoFormPriceService";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";

export default class PriceServiceForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormPriceService, string>(
    "PriceManagement_Service_AddEdit"
  );
  serviceRemarks: UiSelectOptionWithStringValue[] = [];

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormPriceService();
    //表单-校验
    this.form.Rules = DtoFormPriceServiceRules;
    this.form.Rules.Name = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
      {
        max: 256,
        message: "文本长度不能超过 256 个字符",
        trigger: "blur",
      },
      {
        validator: (rule: any, value: string, callback: any) => {
          if (value) {
            PriceServiceAppService.CheckExist(
              this.form.Data.Id,
              this.form.Data.Name
            ).then((r) => {
              if (r.data.Data) {
                return callback(new Error("信息重复，请重新填写"));
              } else {
                callback();
              }
            });
          }
        },
        trigger: "blur",
      },
    ];
    //表单-元素配置
    this.form.Items = DtoFormPriceServiceMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];
    if (!this.id) {
      this.modelValue.Buttons.push({
        Code: "submit-again",
        Label: "继续添加",
        Type: "primary",
        Size: "small",
      });
    }

    this.handleGet();

    OrderPoolAppService.GetServiceRemarks({ IgnoreService: true }).then((r) => {
      this.serviceRemarks = (r.data.Data ?? []).filter(
        (it) => it.Label != "其他"
      );
    });
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      PriceServiceAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.form.Data = r.data.Data;
      });
    }
  }

  getServiceRemarks(q: string, cb: any): void {
    cb(
      this.serviceRemarks.filter((it) =>
        it.Label?.toUpperCase().contains(q.toUpperCase())
      )
    );
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
      case "submit-again":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            PriceServiceAppService.Post(this.form.Data)
              .then(() => {
                if (btn.Code == "submit-again") {
                  this.form.Data = new DtoFormPriceService();
                } else {
                  this.modelValue.Show = false;
                }
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}


import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import DtoPageListSearchOrderPool, {
  DtoPageListSearchOrderPoolDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchOrderPool";
import DtoPageListOrderPool from "@/models.machine/DtoPageListOrderPool";
import DtoListItemOrderPool, {
  DtoListItemOrderPoolMiezzListItems,
} from "@/models.machine/DtoListItemOrderPool";
import OrderPoolChooseService from "./OrderPoolChooseService.vue";
import {
  EnumOrderPoolStatus,
  EnumOrderPoolStatusOption,
} from "@/models.machine/EnumOrderPoolStatus";
import Logier from "@/models.machine/Logier";
import CurrentLogier from "@/apis/CurrentLogier";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import UiSelectOption from "@/models.machine/UiSelectOption";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import DtoRequestPoolBill from "@/models.machine/DtoRequestPoolBill";
import DtoRequestOrderPoolAssignClientUser from "@/models.machine/DtoRequestOrderPoolAssignClientUser";
import DtoRequestOrderPoolAssignCustomerService from "@/models.machine/DtoRequestOrderPoolAssignCustomerService";
import MiezzListItem from "@/models/MiezzListItem";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import { EnumRelation } from "@/models.machine/EnumRelation";
import { EnumCompare } from "@/models.machine/EnumCompare";
import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
import OrderPoolProcess from "./OrderPoolProcess.vue";
import OrderStowageStackAppService from "@/apis.machine/OrderStowageStackAppService";
import OrderTask from "../OrderTask.vue";
import DtoRequestOrderPoolCopy, {
  DtoRequestOrderPoolCopyMiezzFormItems,
  DtoRequestOrderPoolCopyRules,
} from "@/models.machine/DtoRequestOrderPoolCopy";
import MiezzForm from "@/models/MiezzForm";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import {
  EnumOrderPoolCopyBookingNoBuildType,
  EnumOrderPoolCopyBookingNoBuildTypeOption,
} from "@/models.machine/EnumOrderPoolCopyBookingNoBuildType";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import { EnumTransportDirectionOption } from "@/models.machine/EnumTransportDirection";
import { EnumTransportTypeOption } from "@/models.machine/EnumTransportType";
import {
  EnumTransportContainerLoad,
  EnumTransportContainerLoadOption,
} from "@/models.machine/EnumTransportContainerLoad";
import OrderAppService from "@/apis.machine/OrderAppService";
import BillAppService from "@/apis.machine/BillAppService";
import ListMarkColorAppService from "@/apis.machine/ListMarkColorAppService";
import { EnumContainerTrailerStatusOption } from "@/models.machine/EnumContainerTrailerStatus";
import { EnumIncomingGoodsStatusOption } from "@/models.machine/EnumIncomingGoodsStatus";
import { EnumBookingStatusOption } from "@/models.machine/EnumBookingStatus";
import { EnumCustomsStatusOption } from "@/models.machine/EnumCustomsStatus";
import { EnumExpressStatusOption } from "@/models.machine/EnumExpressStatus";
import { EnumTrunkStatusOption } from "@/models.machine/EnumTrunkStatus";
@Options({
  components: {
    OrderPoolChooseService,
    OrderPoolProcess,
    OrderTask,
  },
})
export default class OrderPoolPageList extends Vue {
  EnumSubmitStatusOption = [
    {
      Value: "1",
      Label: "未录入",
    },
    {
      Value: "10",
      Label: "已全部提交",
    },
    {
      Value: "20",
      Label: "未全部提交",
    },
    {
      Value: "30",
      Label: "已全部核销",
    },
    {
      Value: "40",
      Label: "未全部核销",
    },
  ];
  EnumSubmitStatusOptionSaler = [
    {
      Value: "1",
      Label: "未录入",
    },
    {
      Value: "10",
      Label: "已全部提交",
    },
    {
      Value: "20",
      Label: "未全部提交",
    },
  ];
  EnumSettlementStatusOption = [
    {
      Value: "10",
      Label: "已全部核销",
    },
    {
      Value: "20",
      Label: "未全部核销",
    },
  ];
  lockOptions: UiSelectOption<string>[] = [
    {
      Label: "锁定",
      Value: "True",
    },
    {
      Label: "未锁定",
      Value: "False",
    },
  ];
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchOrderPool,
    DtoListItemOrderPool,
    string
  >("OrderManagement_Pool");
  /**当前Id */
  id?: string = "";
  cancel = false;
  canLinkOrder = false;
  modalChooseService = new MiezzModal(MiezzModalType.Dialog);
  EnumOrderPoolStatusOption = EnumOrderPoolStatusOption.filter(
    (it) => it.Value != EnumOrderPoolStatus.WaitingAdd
  );

  EnumBookingStatusOption = EnumBookingStatusOption;
  EnumCustomsStatusOption = EnumCustomsStatusOption;
  EnumDeparturePortContainerTrailerStatusOption =
    EnumContainerTrailerStatusOption;
  EnumDestinationPortContainerTrailerStatusOption: UiSelectOption<number>[] = [
    { Label: "待提柜", Value: 1, Data: "WaitTake" },
    { Label: "提柜中", Value: 2, Data: "IngTake" },
    { Label: "已提柜", Value: 3, Data: "EdTake" },
    { Label: "卸货中", Value: 4, Data: "IngLoad" },
    { Label: "已卸货", Value: 5, Data: "EdLoad" },
    { Label: "还柜中", Value: 6, Data: "IngPort" },
    { Label: "已还柜", Value: 7, Data: "EdPort" },
  ];
  EnumExpressStatusOption = EnumExpressStatusOption;
  EnumTrunkStatusOption = EnumTrunkStatusOption;
  EnumIncomingGoodsStatusOption = EnumIncomingGoodsStatusOption;
  logier?: Logier;
  EnumCompanyType = EnumCompanyType;

  colors: UiSelectOptionWithStringValue[] = [];
  loadingPorts: UiSelectOption<string>[] = [];
  deliveryPorts: UiSelectOption<string>[] = [];
  deliveryPlaces: UiSelectOptionWithStringValue[] = [];
  shipNames: UiSelectOption<string>[] = [];
  voyageNumbers: UiSelectOption<string>[] = [];
  flights: UiSelectOption<string>[] = [];
  railwayNumbers: UiSelectOption<string>[] = [];
  carriers: UiSelectOption<string>[] = [];
  serviceRemarks: UiSelectOptionWithStringValue[] = [];
  EnumTransportContainerLoad = EnumTransportContainerLoad;
  EnumTransportDirectionOption = EnumTransportDirectionOption;
  EnumTransportTypeOption = EnumTransportTypeOption;
  EnumTransportContainerLoadOption = EnumTransportContainerLoadOption;

  showAssignClientUser = false;
  modalAssignClientUser = new MiezzModal(MiezzModalType.Dialog);
  modalAssignClientUserData = new DtoRequestOrderPoolAssignClientUser();
  modalAssignClientUserRules = {
    ClientUserId: [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
    ],
  };
  clientUsers: UiSelectOption<string>[] = [];
  searchClientUsers: UiSelectOption<string>[] = [];

  showAssignCustomerService = false;
  modalAssignCustomerService = new MiezzModal(MiezzModalType.Dialog);
  modalAssignCustomerServiceData =
    new DtoRequestOrderPoolAssignCustomerService();
  modalAssignCustomerServiceRules = {
    CustomerServiceId: [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
    ],
  };
  customerServices: UiSelectOption<string>[] = [];
  searchCustomerServices: UiSelectOption<string>[] = [];
  taskUsers: UiSelectOption<string>[] = [];
  searchSalesmans: UiSelectOption<string>[] = [];
  suppliers: UiSelectOption<string>[] = [];

  modalProcess = new MiezzModal();
  modalTask = new MiezzModal(MiezzModalType.Dialog);
  modalCopy = new MiezzModal(MiezzModalType.Dialog);
  formCopy = new MiezzForm<DtoRequestOrderPoolCopy, string>();
  copyCompanies: DtoSelectOptionCompany[] = [];
  filterCopyCompanies: DtoSelectOptionCompany[] = [];
  copyUsers: UiSelectOption<string>[] = [];
  EnumOrderPoolCopyBookingNoBuildTypeOption =
    EnumOrderPoolCopyBookingNoBuildTypeOption;
  containerTypes: UiSelectOption<string>[] = [];
  containerNos: UiSelectOptionWithStringValue[] = [];
  companySources: UiSelectOptionWithStringValue[] = [];
  powers: string[] = [];

  /**创建时 */
  created(): void {
    //列表-操作区域宽度
    //this.list.OperationWidth = "100px";
    this.logier = CurrentLogier.GetLogier();
    CurrentLogier.GetPowerCodes().then((r) => {
      this.powers = r ?? [];
      if (r?.contains("OrderManagement_Pool_Assign_ClientUser")) {
        this.showAssignClientUser = true;
      }
      if (r?.contains("OrderManagement_Pool_Assign_CustomerService")) {
        this.showAssignCustomerService = true;
      }
      if (r?.contains("OrderManagement_Order")) {
        this.canLinkOrder = true;
      }
    });
    //列表-传参
    this.list.Dto = new DtoPageListSearchOrderPool();
    this.list.DisabledRadioSearchRefresh = true;
    this.list.RadioSearchItems = this.EnumOrderPoolStatusOption.filter(
      (it) => it.Value != EnumOrderPoolStatus.Canceled
    );
    //列表-数据-初始
    this.list.Data = new DtoPageListOrderPool();
    //列表-列配置
    const items = JSON.copy(
      DtoListItemOrderPoolMiezzListItems
    ) as MiezzListItem[];
    if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      this.list.Items = items.filter(
        (it) =>
          it.Prop != "CustomerSupplierName" &&
          it.Prop != "ClientUserId" &&
          it.Prop != "Code"
      );
    } else {
      this.list.Items = items.filter(
        (it) =>
          it.Prop != "Client" &&
          it.Prop != "OrderCode" &&
          it.Prop != "ServiceRemark"
      );
    }
    for (const item of this.list.Items ?? []) {
      switch (item.Prop) {
        case "Code":
          item.Label =
            this.logier?.CompanyType == EnumCompanyType.ServiceProvider
              ? "委托方的编号"
              : "我的编号";
          break;
        case "CustomerSupplierName":
          item.DisabledTooltip = true;
          break;

        default:
          break;
      }
    }
    //列表-动态搜索-项
    const dynamicSearchItems =
      DtoPageListSearchOrderPoolDynamicSearchItems.filter((it) => {
        if (this.logier?.CompanyType == EnumCompanyType.Customer) {
          return (
            it.Prop != "DynamicSearch_OrderCode" &&
            it.Prop != "DynamicSearch_ServiceRemark" &&
            it.Prop != "DynamicSearch_SalesmanId" &&
            it.Prop != "DynamicSearch_CompanySource" &&
            it.Prop != "DynamicSearch_CollectionSubmitStatus" &&
            it.Prop != "DynamicSearch_PaymentSubmitStatus" &&
            it.Prop != "DynamicSearch_SalerSubmitStatus" &&
            it.Prop != "DynamicSearch_SupplierId"
          );
        } else {
          return it.Prop != "DynamicSearch_CustomerSupplierName";
        }
      });
    for (const item of dynamicSearchItems ?? []) {
      switch (item.Prop) {
        case "DynamicSearch_Code":
          item.Label =
            this.logier?.CompanyType == EnumCompanyType.ServiceProvider
              ? "委托方的编号"
              : "我的编号";
          break;
        case "DynamicSearch_ClientUserId":
          item.Label =
            this.logier?.CompanyType == EnumCompanyType.ServiceProvider
              ? "委托人"
              : "执行人";
          break;
        default:
          break;
      }
    }
    this.list.DynamicSearchItems = dynamicSearchItems;
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "OrderManagement_Pool_Add",
      },
    ];
    //列表-行按钮
    this.list.CheckRowButtonShow = (btn, row) => {
      switch (btn.Code) {
        case "task":
          return (
            row.Status != EnumOrderPoolStatus.Canceled &&
            row.Status != EnumOrderPoolStatus.Completed
          );

        default:
          break;
      }
      return true;
    };
    this.list.HandleBuildRowButton = (btn, row) => {
      if (btn.Code == "task") {
        const model = JSON.copy(btn) as MiezzButton;
        model.Type = row.HasTask ? "warning" : "default";
        model.Plain = row.HasTask;
        return model;
      }
      return btn;
    };
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
        PowerCode: "OrderManagement_Pool_Summary",
      },
      {
        Code: "task",
        Title: "待办",
        Icon: "document",
        Type: "default",
        Size: "small",
        PowerCode: "OrderManagement_Pool_Task",
      },
      {
        Code: "process",
        Title: "进程",
        Icon: "location",
        Type: "default",
        Size: "small",
        PowerCode: "OrderManagement_Pool_Process",
      },
      {
        Code: "copy",
        Title: "复制",
        MiezzIcon: "copy",
        Type: "default",
        Size: "small",
        PowerCode: "OrderManagement_Pool_Copy",
      },
      // {
      //   Code: "lock",
      //   Title: "锁定",
      //   Icon: "lock",
      //   Type: "default",
      //   Size: "small",
      //   PowerCode: "OrderManagement_Pool_Lock",
      // },
      // {
      //   Code: "unlock",
      //   Title: "解锁",
      //   Icon: "unlock",
      //   Type: "default",
      //   Size: "small",
      //   PowerCode: "OrderManagement_Pool_Lock",
      // },
    ];
    this.list.CheckRowButtonShow = (btn, row) => {
      switch (btn.Code) {
        case "lock":
          return (
            row.Status == EnumOrderPoolStatus.Canceled && row.Lock == false
          );
        case "unlock":
          return row.Status == EnumOrderPoolStatus.Canceled && row.Lock == true;
        default:
          break;
      }
      return true;
    };
    //列表-底部按钮
    this.list.BottomButtons = [];
    this.list.BottomButtons.push({
      Code: "mark",
      Label: "标记",
      Type: "color",
      Size: "small",
    });
    if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      this.list.BottomButtons.push({
        Code: "assign-customer-service",
        Label: "指派客服",
        MiezzIcon: "assign",
        Type: "text",
        Size: "small",
        PowerCode: "OrderManagement_Pool_Assign_CustomerService",
      });
    } else {
      this.list.BottomButtons.push({
        Code: "assign-client-user",
        Label: "指派执行人",
        MiezzIcon: "assign",
        Type: "text",
        Size: "small",
        PowerCode: "OrderManagement_Pool_Assign_ClientUser",
      });
    }
    this.list.BottomButtons.push({
      Code: "export",
      Label: "导出货物清单",
      Icon: "bottom",
      Type: "text",
      Size: "small",
    });
    this.list.BottomButtons.push({
      Code: "collection",
      Label: "导出应收",
      Icon: "bottom",
      Type: "text",
      Size: "small",
      PowerCode:
        "OrderManagement_Pool_Summary_BillingInformation_ViewAccountsReceivable",
    });
    this.list.BottomButtons.push({
      Code: "delete",
      Label: "删除",
      Icon: "delete",
      Type: "text",
      Message:
        "删除后信息无法恢复，通过《拼单配载》生成的订单将被保留，如需删除请至《订单列表》中进行删除操作，是否继续？",
      Size: "small",
      PowerCode: "OrderManagement_Pool_Delete",
    });

    this.modalAssignClientUser.Title = "指派执行人";
    this.modalAssignClientUser.Height = "100px";
    this.modalAssignClientUser.HandleClick = this.handleBottomButtonClick;
    this.modalAssignClientUser.Buttons = [
      {
        Code: "assign-client-user-submit",
        Label: "确定",
        Type: "primary",
        Size: "small",
      },
    ];

    this.modalAssignCustomerService.Title = "指派客服";
    this.modalAssignCustomerService.Height = "100px";
    this.modalAssignCustomerService.HandleClick = this.handleBottomButtonClick;
    this.modalAssignCustomerService.Buttons = [
      {
        Code: "assign-customer-service-submit",
        Label: "确定",
        Type: "primary",
        Size: "small",
      },
    ];

    this.modalProcess.Title = "进程";
    this.modalProcess.Width = "100%";
    this.modalProcess.DisabledCloseButton = true;

    if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      JoinCompanyUserAppService.GetSelect({
        CompanyId: this.logier?.CompanyId,
        PowerCodes: ["IdentityPermission_CustomerService"],
      }).then((r) => {
        this.customerServices = r.data.Data ?? [];
      });
    } else {
      JoinCompanyUserAppService.GetSelect({
        CompanyId: this.logier?.CompanyId,
        PowerCodes: ["OrderManagement_Pool"],
      }).then((r) => {
        this.clientUsers = r.data.Data ?? [];
      });
    }

    this.modalCopy.Title = "复制";
    this.modalCopy.Width = "700px";
    this.modalCopy.Height = "150px";
    this.modalCopy.HandleClick = this.handleBottomButtonClick;
    this.modalCopy.Buttons = [
      {
        Code: "copy-submit",
        Label: "确定",
        Type: "primary",
        Size: "small",
      },
    ];
    this.formCopy.Items = DtoRequestOrderPoolCopyMiezzFormItems;
    this.formCopy.Rules = DtoRequestOrderPoolCopyRules;
    OrderPoolAppService.GetContainerTypeSelect().then((r) => {
      this.containerTypes = r.data.Data ?? [];
    });
    OrderPoolAppService.GetContainerNoSelect().then((r) => {
      this.containerNos = r.data.Data ?? [];
    });
    CompanyAppService.GetSources().then((r) => {
      this.companySources = r.data.Data ?? [];
    });

    this.modalTask.ButtonWidth = "100px";
  }

  visibilityChange(e: any, row: any): void {
    if (e.target.scrollWidth == e.target.clientWidth) {
      row.tooltipFlag = true;
    }
  }

  /**查询列表 */
  handleGet(): void {
    if (this.list && this.list.Dto) {
      this.list.Dto.OrderWarehouseId = this.$route.query
        .OrderWarehouseId as string;
    }
    OrderPoolAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
      if (r.data.Extras?.NoPowerMessage) {
        ElMessage.warning(r.data.Extras?.NoPowerMessage);
      }
    });
    OrderPoolAppService.GetServiceRemarkSelect().then((r) => {
      this.serviceRemarks = r.data.Data ?? [];
    });
    OrderPoolAppService.GetColors().then((r) => {
      this.colors = r.data.Data ?? [];
    });
    OrderPoolAppService.GetClientUsers().then((r) => {
      this.searchClientUsers = r.data.Data ?? [];
    });
    OrderPoolAppService.GetCustomerServices(this.list.Dto).then((r) => {
      this.searchCustomerServices = r.data.Data ?? [];
    });
    OrderPoolAppService.GetTaskUsers(this.list.Dto).then((r) => {
      this.taskUsers = r.data.Data ?? [];
    });
    OrderPoolAppService.GetSalesmans(this.list.Dto).then((r) => {
      this.searchSalesmans = r.data.Data ?? [];
    });
    OrderPoolAppService.GetSuppliers(this.list.Dto).then((r) => {
      this.suppliers = r.data.Data ?? [];
    });
    OrderPoolAppService.GetLoadingPorts().then((r) => {
      this.loadingPorts = r.data.Data ?? [];
    });
    OrderPoolAppService.GetDeliveryPorts().then((r) => {
      this.deliveryPorts = r.data.Data ?? [];
    });
    OrderPoolAppService.GetDeliveryPlaces().then((r) => {
      this.deliveryPlaces = r.data.Data ?? [];
    });
    OrderPoolAppService.GetShipNames().then((r) => {
      this.shipNames = r.data.Data ?? [];
    });
    OrderPoolAppService.GetVoyageNumbers().then((r) => {
      this.voyageNumbers = r.data.Data ?? [];
    });
    OrderPoolAppService.GetFlights().then((r) => {
      this.flights = r.data.Data ?? [];
    });
    OrderPoolAppService.GetRailwayNumbers().then((r) => {
      this.railwayNumbers = r.data.Data ?? [];
    });
    OrderPoolAppService.GetCarriers().then((r) => {
      this.carriers = r.data.Data ?? [];
    });
  }

  beforeRadioSearch?: string;
  handleRadioSearch(): void {
    const pageList = this.$refs["ref-miezz-page-list"] as any;
    if (
      this.list.Dto?.RadioSearch == undefined &&
      this.list.Dto?.OpenSearch == "true" &&
      this.beforeRadioSearch != undefined
    ) {
      DynamicSearchAppService.Post({
        Code: "OrderManagement_Pool",
        Brackets: [
          {
            Relation: EnumRelation.And,
            Items: [
              {
                Relation: EnumRelation.And,
                Where: {
                  Prop: "DynamicSearch_Status",
                  Compare: EnumCompare.Equal,
                  Value: this.beforeRadioSearch.toString(),
                },
              },
            ],
          },
        ],
      }).then((r) => {
        if (this.list.Dto) {
          this.list.Dto.DynamicSearchId = r.data.Data;
          pageList.handleGet();
        }
      });
    } else {
      pageList.handleGet();
    }
    this.beforeRadioSearch = this.list.Dto?.RadioSearch;
  }

  onCopyBookingNo(value: string): void {
    navigator.clipboard.writeText(value).then((r) => {
      ElMessage({
        message: "信息已复制",
        type: "success",
      });
    });
  }

  /**选择服务完成回调 */
  handleChooseServiceSubmit(id: string): void {
    this.$router.push(`/order/pool/add/${id}`);
  }

  onCompanyIdChanged(n?: string, o?: string): void {
    if (n == o) return;
    const itemClientUserId = this.formCopy.Items?.firstOrDefault(
      (it) => it.Prop == "ClientUserId"
    );
    if (itemClientUserId) {
      itemClientUserId.Disabled =
        this.formCopy.Data.ClientCompanyId == undefined;
    }
    if (this.formCopy.Data.ClientCompanyId) {
      JoinCompanyUserAppService.GetSelect({
        CompanyId: this.formCopy.Data.ClientCompanyId,
      }).then((r) => {
        this.copyUsers = r.data.Data ?? [];
        if (this.copyUsers.length == 0) {
          if (itemClientUserId) {
            itemClientUserId.Disabled = true;
          }
          this.formCopy.Data.ClientUserId = undefined;
          this.formCopy.Data.ClientUserLabel = undefined;
          MiezzForm.validateField(this.$refs, "ruleFormCopy", "ClientUserId");
        } else if (
          this.copyUsers.all(
            (it) => it.Value != this.formCopy.Data.ClientUserId
          )
        ) {
          this.formCopy.Data.ClientUserId = undefined;
          this.formCopy.Data.ClientUserLabel = undefined;
        }
      });
    } else {
      this.copyUsers = [];
      this.formCopy.Data.ClientUserId = undefined;
      this.formCopy.Data.ClientUserLabel = undefined;
    }
  }

  filterCopyClientCompanies(queryString?: string): void {
    if (this.copyCompanies && this.copyCompanies.length > 0) {
      if (queryString) {
        this.filterCopyCompanies = this.copyCompanies.filter((it) =>
          (it.NameZh + (it.NameEn ? "/" + it.NameEn : ""))?.contains(
            queryString
          )
        );
        if (this.filterCopyCompanies && this.filterCopyCompanies.length > 10)
          this.filterCopyCompanies = this.filterCopyCompanies.slice(0, 10);
      } else if (this.formCopy.Data.ClientCompanyId) {
        this.filterCopyCompanies = this.copyCompanies.filter(
          (it) => it.Id == this.formCopy.Data.ClientCompanyId
        );
      } else {
        this.filterCopyCompanies = JSON.copy(this.copyCompanies).slice(0, 10);
      }
    }
  }

  copy(complete: () => void): void {
    if (this.copyCompanies.length > 0)
      this.formCopy.Data.ClientCompanyNameZh =
        this.copyCompanies.firstOrDefault(
          (it) => it.Id == this.formCopy.Data.ClientCompanyId
        )?.NameZh;
    OrderPoolAppService.Copy(this.formCopy.Data)
      .then((r) => {
        ElMessage.success("复制成功");
        if (r.data.Data?.Status == EnumOrderPoolStatus.WaitingAdd) {
          if (r.data.Data?.OrderId && r.data.Data?.DeparturePortBooking) {
            CurrentLogier.LinkWithBack(
              `/order/booking/edit/${r.data.Data?.OrderId}?task=AddDeparturePortBooking`
            );
            complete();
          } else {
            this.handleGet();
            complete();
          }
        } else {
          this.handleGet();
          complete();
        }
      })
      .catch(complete);
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        this.modalChooseService.Show = true;
        complete();
        break;
      default:
        break;
    }
  }

  handleRowCommand(prop: string, row: DtoListItemOrderPool): void {
    switch (prop) {
      case "OrderCode":
        DynamicSearchAppService.Post({
          Code: "OrderManagement_Order",
          Brackets: [
            {
              Relation: EnumRelation.And,
              Items: [
                {
                  Relation: EnumRelation.And,
                  Where: {
                    Prop: "DynamicSearch_Code",
                    Compare: EnumCompare.Equal,
                    Value: row.OrderCode,
                  },
                },
              ],
            },
          ],
        }).then((r) => {
          if (row.OrderCode) {
            // const save = function (e: any) {
            //   e.clipboardData.setData("text/plain", row.OrderCode);
            //   e.preventDefault(); // 阻止默认行为
            // };
            // document.addEventListener("copy", save); // 添加一个copy事件
            // document.execCommand("copy"); // 执行copy方法
            // ElMessage.success("号码已复制");

            setTimeout(() => {
              window.open(
                `#/order?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                "_blank"
              );
            }, 500);
          }
        });
        break;
      case "ClientUserId":
        this.modalAssignClientUserData.Ids = this.list.SelectIds;
        this.modalAssignClientUserData.ClientUserId = row.ClientUserId;
        this.modalAssignClientUserData.ClientUserLabel = row.ClientUserLabel;
        this.modalAssignClientUserData.Ids = [row.Id ?? ""];
        this.modalAssignClientUser.Show = true;
        break;
      case "CustomerServiceId":
        this.modalAssignCustomerServiceData.Ids = this.list.SelectIds;
        this.modalAssignCustomerServiceData.CustomerServiceId =
          row.CustomerServiceId;
        this.modalAssignCustomerServiceData.CustomerServiceLabel =
          row.CustomerServiceLabel;
        this.modalAssignCustomerServiceData.Ids = [row.Id ?? ""];
        this.modalAssignCustomerService.Show = true;
        break;

      default:
        break;
    }
  }

  handleRowDbClick(row: DtoListItemOrderPool): void {
    if (this.powers.contains("OrderManagement_Pool_Summary")) {
      this.$router.push(`/order/pool/summary/${row.Id}`);
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemOrderPool,
    complete: () => void
  ): void {
    this.id = row.Id;
    this.cancel = row.Status == EnumOrderPoolStatus.Canceled;
    switch (btn.Code) {
      case "detail":
        this.$router.push(`/order/pool/summary/${row.Id}`);
        complete();
        break;
      case "mark":
        ListMarkColorAppService.Post({
          TableName: "OrderPool",
          TableIds: [row.Id ?? ""],
          Color: btn.Color,
        }).then(() => {
          this.handleGet();
          complete();
        });
        break;
      case "task":
        if (row.OrderLock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        this.modalTask.Show = true;
        complete();
        break;
      case "process":
        this.modalProcess.Show = true;
        complete();
        break;
      // 复制
      case "copy":
        this.formCopy.Data = new DtoRequestOrderPoolCopy();
        this.formCopy.Data.Id = row.Id;
        this.formCopy.Data.ClientCompanyId = row.ClientCompanyId;
        this.formCopy.Data.ClientCompanyNameZh = row.ClientCompanyNameZh;
        this.formCopy.Data.ClientCompanyLabel = row.ClientCompanyLabel;
        this.formCopy.Data.ClientUserId = row.ClientUserId;
        this.formCopy.Data.ClientUserLabel = row.ClientUserLabel;
        this.formCopy.Data.BookingNoBuildType =
          EnumOrderPoolCopyBookingNoBuildType.New;
        if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
          CompanyAppService.GetSelect({
            CompanyType: EnumCompanyType.Customer,
            Subordinate: true,
            SeeAll: true,
          }).then((r) => {
            this.copyCompanies = r.data.Data ?? [];
            this.$watch(
              "formCopy.Data.ClientCompanyId",
              this.onCompanyIdChanged
            );
            this.onCompanyIdChanged(
              undefined,
              this.formCopy.Data.ClientCompanyId
            );
            this.filterCopyClientCompanies();
            this.modalCopy.Show = true;
            complete();
          });
        } else {
          this.copy(complete);
        }
        break;
      case "lock":
      case "unlock":
        ElMessageBox.confirm(
          btn.Code == "lock"
            ? "锁定委托书后将无法对委托书行信息相关操作，是否继续?"
            : "是否确定解锁所选的委托书?",
          btn.Code == "lock" ? "锁定" : "解锁",
          {
            confirmButtonText: "确定",
            cancelButtonText: "关闭",
            distinguishCancelAndClose: true,
            showClose: true,
            type: "warning",
          }
        )
          .then(() => {
            OrderPoolAppService.Lock({
              Ids: [row.Id as string],
              Lock: btn.Code == "lock",
            }).then(() => {
              this.handleGet();
              ElMessage.success(btn.Code == "lock" ? "已锁定" : "已解锁");
              complete();
            });
            complete();
          })
          .catch(() => {
            complete();
          });
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    if (
      this.list.SelectRows.any((it) => it.OrderLock) &&
      btn.Code != "export" &&
      btn.Code != "mark" &&
      btn.Code != "collection"
    ) {
      ElMessage.warning("订单已锁定");
      complete();
      return;
    }
    switch (btn.Code) {
      case "mark":
        ListMarkColorAppService.Post({
          TableName: "OrderPool",
          TableIds: this.list.SelectIds,
          Color: btn.Color,
        }).then(() => {
          this.handleGet();
          complete();
        });
        break;
      case "copy-submit":
        MiezzForm.submit(this.$refs, "ruleFormCopy", (v) => {
          if (v) {
            this.copy(complete);
            this.modalCopy.Show = false;
          } else {
            complete();
          }
        });
        break;
      // 指派执行人
      case "assign-client-user":
        var companyIds = this.list.SelectRows.map(
          (it) => it.ClientCompanyId
        ).distinct();
        if (companyIds.length > 1) {
          ElMessage.success("委托公司不一致");
          complete();
        } else {
          this.modalAssignClientUserData.Ids = this.list.SelectIds;
          this.modalAssignClientUserData.ClientUserId = undefined;
          this.modalAssignClientUserData.ClientUserLabel = undefined;
          if (
            this.list.SelectRows.any((it) =>
              this.list.SelectRows.all(
                (t) =>
                  t.ClientUserId == it.ClientUserId &&
                  t.ClientUserLabel == it.ClientUserLabel
              )
            )
          ) {
            this.modalAssignClientUserData.ClientUserId =
              this.list.SelectRows[0].ClientUserId;
            this.modalAssignClientUserData.ClientUserLabel =
              this.list.SelectRows[0].ClientUserLabel;
          }
          this.modalAssignClientUser.Show = true;
          complete();
        }
        break;
      // 指派客服
      case "assign-customer-service":
        this.modalAssignCustomerServiceData.Ids = this.list.SelectIds;
        this.modalAssignCustomerServiceData.CustomerServiceId = undefined;
        this.modalAssignCustomerServiceData.CustomerServiceLabel = undefined;
        if (
          this.list.SelectRows.any((it) =>
            this.list.SelectRows.all(
              (t) =>
                t.CustomerServiceId == it.CustomerServiceId &&
                t.CustomerServiceLabel == it.CustomerServiceLabel
            )
          )
        ) {
          this.modalAssignCustomerServiceData.CustomerServiceId =
            this.list.SelectRows[0].CustomerServiceId;
          this.modalAssignCustomerServiceData.CustomerServiceLabel =
            this.list.SelectRows[0].CustomerServiceLabel;
        }
        this.modalAssignCustomerService.Show = true;
        complete();
        break;
      // 保存执行人
      case "assign-client-user-submit":
        (this.$refs["ref-form-assign-client-user"] as any).validate(
          (v: boolean) => {
            if (v) {
              OrderPoolAppService.AssignClientUser(
                this.modalAssignClientUserData
              )
                .then(() => {
                  ElMessage.success("指派成功");
                  this.handleGet();
                  this.modalAssignClientUser.Show = false;
                  complete();
                })
                .catch(complete);
            }
          }
        );
        complete();
        break;
      // 保存客服
      case "assign-customer-service-submit":
        (this.$refs["ref-form-assign-customer-service"] as any).validate(
          (v: boolean) => {
            if (v) {
              OrderPoolAppService.AssignCustomerService(
                this.modalAssignCustomerServiceData
              )
                .then(() => {
                  ElMessage.success("指派成功");
                  this.handleGet();
                  this.modalAssignCustomerService.Show = false;
                  complete();
                })
                .catch(complete);
            }
          }
        );
        complete();
        break;
      // 取消
      case "delete":
        if (
          this.logier?.CompanyType == EnumCompanyType.Customer &&
          this.list.SelectRows.any((it) => it.OrderId)
        ) {
          ElMessage.warning(
            "您的委托正在执行中，如需取消订单，请通知客服人员进行相应操作"
          );
        } else {
          OrderStowageStackAppService.Delete({
            Ids: this.list.SelectIds,
            Sure: true,
          })
            .then((r) => {
              if (this.list.SelectIds.length > 1) {
                if (
                  r &&
                  r.data &&
                  r.data.Extras &&
                  r.data.Extras.Message != ""
                ) {
                  ElMessage.warning(r.data.Extras.Message);
                }
                ElMessage.success("删除成功");
              } else if (this.list.SelectIds.length <= 1) {
                if (
                  r &&
                  r.data &&
                  r.data.Extras &&
                  r.data.Extras.Message != ""
                ) {
                  ElMessage.warning(r.data.Extras.Message);
                } else {
                  ElMessage.success("删除成功");
                }
              }

              this.handleGet();

              complete();
            })
            .catch(complete);
        }
        break;
      case "export":
        OrderPoolAppService.ExportGoods(this.list.SelectIds)
          .then((r) => {
            window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
            ElMessage.success("导出成功");
            complete();
          })
          .catch(complete);
        break;
      case "collection":
        if (
          this.list.SelectRows.map((it) => it.ClientCompanyId).distinct()
            .length > 1
        ) {
          ElMessage.warning("不同委托公司的账单不能同时导出，请重新选择");
          complete();
          break;
        }
        var param = new DtoRequestPoolBill();
        param.PoolIds = this.list.SelectIds;
        BillAppService.PoolExport(param)
          .then((r) => {
            window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
            ElMessage.success("导出成功");
            complete();
          })
          .catch(complete);
        complete();
        break;
      default:
        break;
    }
  }
}
